import { useState, useEffect } from 'react'
import LogoWhite from "./Assets/Img/LogoWhite.png"
import { ShoppingCart, Trash } from "iconsax-react";
import { IconButton } from '@mui/material';

import './Assets/styles.css'


const ShoppingCartDetail = () => {
        const [nombre, setNombre] = useState("")
        const [numberCard, setNumberCard] = useState("")
        const [expires, setExpires] = useState("")
        const [cvv, setCvv] = useState("")
        const [data, setData] = useState(localStorage.getItem('compras') ? JSON.parse(localStorage.getItem('compras')) : [])
        const [cardMaster, setCardMaster] = useState(false)
        const [cardVisa, setCardVisa] = useState(true)
        const [paypal, setPaypal] = useState(false)

        useEffect(() => {
                localStorage.setItem('compras', JSON.stringify(data) ?? [])
        }, [data])

        const handleElimar = (datos) => {
                if (datos) {
                        const dataNew = data.filter(items => items.title !== datos.title)
                        setData(dataNew)
                }
        }

        const showMethodPay = (data) => {
                if (data === "visa") {
                        setCardVisa(true)
                        setCardMaster(false)
                        setPaypal(false)
                }
                if (data === "master") {
                        setCardVisa(false)
                        setCardMaster(true)
                        setPaypal(false)
                }
                if (data === "paypal") {
                        setCardVisa(false)
                        setCardMaster(false)
                        setPaypal(true)
                }
        }



        return (
                <div className='contained'>
                        <h3 className='title-card-shoppin'>Carrito de Compra</h3>
                        <div className='layout-shoppin-cart'>
                                <div className='contain-all-Items'>
                                        {
                                                data.length > 0 ?
                                                        data.map((topic, index) => {
                                                                return (<div className='elementsShopping' key={index}>
                                                                        <div className='bodyElementsShopping'>
                                                                                <div className='contain-img-cart'><img src={topic.img} alt="" /></div>
                                                                                <div>
                                                                                        <h4>{topic.title}</h4>

                                                                                </div>
                                                                                <div className='contain-button-delete'>
                                                                                        <p>{topic.speciality}</p>
                                                                                        <button className='button-delete-Cart' onClick={() => handleElimar(topic)}>
                                                                                                <IconButton >
                                                                                                        <Trash size="20" color="white" />
                                                                                                </IconButton>

                                                                                        </button>
                                                                                </div>
                                                                        </div>
                                                                        <div className='line' />
                                                                </div>
                                                                )
                                                        })
                                                        :
                                                        <h3>No Hay Items</h3>
                                        }


                                </div>
                                <div className='contain-all-resumen'>
                                        <div>
                                                <h3>Metodos de Pagos</h3>
                                        </div>
                                        <div className='contain-resumen-cart'>
                                                {
                                                        cardVisa ?
                                                                <div className="card-visa">
                                                                        <div className="card__front card__part">
                                                                                <img className="card__front-square card__square" src="https://artesla.com.co/wp-content/uploads/2021/01/LOGO-VISA-1.png" alt='logo cart' />
                                                                                <img className="card__front-logo card__logo" src={LogoWhite} alt="wellezy" />
                                                                                <p className="card_numer">
                                                                                        {
                                                                                                numberCard ?
                                                                                                        `**** **** **** ${numberCard.substring(12, 16)}`
                                                                                                        :
                                                                                                        "**** **** **** 6258"
                                                                                        }</p>
                                                                                <div className="card__space-75">
                                                                                        <span className="card__label">Card holder</span>
                                                                                        <p className="card__info">
                                                                                                {
                                                                                                        nombre ?
                                                                                                                nombre
                                                                                                                :
                                                                                                                'John Doe'
                                                                                                }
                                                                                        </p>
                                                                                </div>
                                                                                <div className="card__space-25">
                                                                                        <span className="card__label">Expires</span>
                                                                                        <p className="card__info">
                                                                                                {
                                                                                                        expires ?
                                                                                                                expires
                                                                                                                :
                                                                                                                '01/25'
                                                                                                }
                                                                                        </p>
                                                                                </div>
                                                                        </div>

                                                                </div>
                                                                : ""
                                                }

                                                {
                                                        cardMaster ?

                                                                <div className="card-visa">
                                                                        <div className="card__front card__part_master">
                                                                                <img className="card__front-square card__square" src="https://lalanternarestaurante.files.wordpress.com/2019/01/mastercard-logo-icon-png_44630-1.png" alt='card logo' />
                                                                                <img className="card__front-logo card__logo" src={LogoWhite} alt="wellezy" />
                                                                                <p className="card_numer">
                                                                                        {
                                                                                                numberCard ?
                                                                                                        `**** **** **** ${numberCard.substring(12, 16)}`
                                                                                                        :
                                                                                                        "**** **** **** 6258"
                                                                                        }</p>
                                                                                <div className="card__space-75">
                                                                                        <span className="card__label">Card holder</span>
                                                                                        <p className="card__info">
                                                                                                {
                                                                                                        nombre ?
                                                                                                                nombre
                                                                                                                :
                                                                                                                'John Doe'
                                                                                                }
                                                                                        </p>
                                                                                </div>
                                                                                <div className="card__space-25">
                                                                                        <span className="card__label">Expires</span>
                                                                                        <p className="card__info">
                                                                                                {
                                                                                                        expires ?
                                                                                                                expires
                                                                                                                :
                                                                                                                '01/25'
                                                                                                }
                                                                                        </p>
                                                                                </div>
                                                                        </div>

                                                                </div>

                                                                : ""
                                                }

                                                <form className='form-card-pay'>
                                                        <div className='campos-cards'>
                                                                <input type="text" onChange={(e) => setNumberCard(e.target.value)} placeholder='************6258' maxLength={16} />
                                                        </div>
                                                        <div className='campos-card-double'>
                                                                <input type="text" onChange={(e) => setExpires(e.target.value)} placeholder='01/25' maxLength={5} />
                                                                <input type="password" onChange={(e) => setCvv(e.target.value)} placeholder='CVV' maxLength={3} />
                                                        </div>
                                                        <div className='campos-cards'>
                                                                <input type="text" onChange={(e) => setNombre(e.target.value)} placeholder='Nombre Titular' />
                                                        </div>
                                                        <button>Pagar</button>
                                                </form>
                                                <div className='contain-method-pay'>
                                                        <button className='button-Paypal' onClick={() => { showMethodPay("paypal") }}>
                                                                <img src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Paypal_2014_logo.png" alt="" />
                                                        </button>
                                                        <button className='button-Paypal' onClick={() => { showMethodPay("master") }}>
                                                                <img src="https://lalanternarestaurante.files.wordpress.com/2019/01/mastercard-logo-icon-png_44630-1.png" alt="" />
                                                        </button>
                                                        <button className='button-Paypal' onClick={() => { showMethodPay("visa") }}>
                                                                <img src="https://artesla.com.co/wp-content/uploads/2021/01/LOGO-VISA-1.png" alt="" />
                                                        </button>
                                                </div>

                                        </div>



                                </div>
                        </div>




                </div>
        )
}

export default ShoppingCartDetail
