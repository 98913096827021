
import React, { useState, forwardRef } from 'react'
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import DatePicker from "react-datepicker"

import "./styles.css"

import { useTranslation } from 'react-i18next'
import { ReactComponent as IconRentCar } from "../assets/svg/IconRentCar.svg"



const SearchRentCar = () => {

    const [t ] = useTranslation("global")

    const [airport, setAirport] = useState("f")
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [selectedDate2, setSelectedDate2] = useState(new Date())

    const handleDateChange = date => {
        setSelectedDate(date);
    };

    const handleDateChange2 = date => {
        setSelectedDate2(date);
    };

    const CustomInput = forwardRef(({ value, onClick }, ref) => {
       
        return (

            <p ref={ref} onClick={onClick} style={{display:'flex', alignItems:"center", justifyContent: "space-around", fontSize: "12px", fontWeight: "400"}}>
                <span style={{ display: "flex", alignItems: "center" }}>
                    <SvgIcon name={iconList.IconCalendar2} size={21} color={"#004274"} />
                </span>
                {value}
            </p>

        )
    });

    const CustomInput2 = forwardRef(({ value, onClick }, ref) => {
      
        return (

            <p ref={ref} onClick={onClick} style={{display:'flex', alignItems:"center", justifyContent: "space-around", fontSize: "12px", fontWeight: "400"}}>
                <span style={{ display: "flex", alignItems: "center" }}>
                    <SvgIcon name={iconList.IconCalendar2} size={21} color={"#004274"} />
                </span>
                {value}
            </p>

        )
    });


    return (
        <>
            <section className='header-rent-car-title'>
                <div style={{ height: "100%", marginRight: "10px" }}>
                    <IconRentCar style={{ height: "100%" }}/>
                </div>
                <div className='content-text-header-rent-car'>
                    <p style={{ fontSize: "20px", fontWeight: "700" }}>
                        {
                            t("car_rental.title")
                        }
                    </p>
                    <p style={{ fontSize: "12px", fontWeight: "700", textAlign: "justify" }}>
                        {
                            t("car_rental.subtitle")
                        }
                    </p>
                </div>
            </section>
            <section className='content-form-search-rent-car'>
                <div className='camp-rent-car'>
                    <label style={{ fontSize: "12px", fontWeight: "700" }} htmlFor="">{t("car_rental.office_delivery")}</label>
                    <select className='input-search-rent-car'>
                        <option value="">{t("car_rental.select_office")}</option>
                    </select>
                </div>
                <div className='camp-rent-car'>
                    <label htmlFor="" style={{ fontSize: "12px", fontWeight: "700" }}>
                        {
                            t("car_rental.airport")
                        }
                    </label>
                    <div className='content-selected-airport-rent'>
                        <section className='selected-rent-car-airport'>

                            <div onClick={() => {
                                setAirport("f")
                            }}>
                                <span style={{ backgroundColor: `${airport === "f" ? "#004274" : ""}`, display: `${airport !== "f" ? "none": ""}` }}>

                                </span>
                            </div>

                            <p>
                                {
                                    t("car_rental.same_place")
                                }
                            </p>
                        </section>
                        <section className='selected-rent-car-airport'>
                            <div onClick={() => {
                                setAirport("m")
                            }}>
                                <span style={{ backgroundColor: `${airport === "m" ? "#004274" : ""}`,  display: `${airport !== "m" ? "none": ""}` }}>

                                </span>
                            </div>

                            <p>
                                {
                                    t("car_rental.another_place")
                                }
                            </p>
                        </section>
                    </div>

                </div>
                {/* <div className='camp-rent-car'>
                    <label style={{ fontWeight: "700", fontSize: "12px" }} htmlFor="">{t("car_rental.from")}</label>
                    <select className='input-search-rent-car'>
                        <option value="">{t("car_rental.select_office")}</option>
                    </select>
                </div> */}
                <div className='camp-rent-car'>
                    <div className='content-date-rent-car'>
                        <div className='input-date-rent'>
                            <label htmlFor="" style={{ fontSize: "12px", fontWeight: "700" }}>
                                {t("car_rental.from")}
                            </label>
                            <div className='input-search-rent-car-data-picker' >
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    customInput={<CustomInput />}
                                    dateFormat="dd-MM-yyyy"
                                />
                            </div>
                        </div>
                        <div className='input-date-rent'>
                            <label htmlFor="" style={{ fontSize: "12px", fontWeight: "700" }}>
                                {
                                    t("car_rental.until")
                                }
                            </label>
                            <div className='input-search-rent-car-data-picker' >
                                <DatePicker
                                    selected={selectedDate2}
                                    onChange={handleDateChange2}
                                    customInput={<CustomInput2 />}
                                    dateFormat="dd-MM-yyyy"
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <button className='btn-search-rent-car'>
                    <SvgIcon name={iconList.IconSearch} size={30} color={"#ffffff"} />
                </button>
            </section>
        </>
    )
}

export default SearchRentCar