import React from "react";
import PackageDataPassenger from "../../Components/PackageDataPassenger/PackageDataPassenger";

const PackageDataPassengerRoute = () => {
    return (
        <div>
            <PackageDataPassenger></PackageDataPassenger>
        </div>
    );
}

export default PackageDataPassengerRoute;