import React from 'react'
import { Link } from 'react-router-dom'
import wellezy from './Assets/Img/wellezy.png'
import Alert from './Assets/Img/Alert.png'
import LogoBlue from './Assets/Img/LogoBlue.png'

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import FlightData from '../FlightData/FlightData'
import useFlightDetail from './useHook';
import './Assets/styles.css'

const FlightDetail = () => {
  const {
    airlines,
    flightsSelect,
    handleChange,
    value,
    flights,
    oneWay,
    roundTrip,
    passengersSelected,
    nameOriginSlice,
    nameDestinySlice,
  } = useFlightDetail();



  if (flightsSelect.length === 0) {
    return (
      <div className='loader'>
        <div class="container">
          <div class="load">
            <img
              className='img'
              src={wellezy}
              alt="agregar equipaje" />
            <div className='containerBalls'>
              <div class="balls"></div>
              <div class="balls"></div>
              <div class="balls"></div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  else {
    return (
      <div className='flightDetail'>

        <div style={{ widows: '100%', height: '290px', borderRadius: '20px', boxShadow: '-4px 4px 4px rgba(0, 0, 0, 0.25)', marginBottom: '20px' }}>
          <Box sx={{ width: '100%', marginBottom: '20px' }}>
            <TabContext value={value}>

              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" value="1">
                  <Tab label="precio por aerolínea" value="1" sx={{ width: '50%' }} />
                  <Tab label="precio +/- 3 días" value="2" sx={{ width: '50%' }} />
                </TabList>
              </Box>

              <TabPanel value="1" sx={{ padding: '0 !important' }}>
                <div className='pricesByAirlines'>
                  <div className='pricesByScales'>
                    <div style={{ width: '100%', height: '60px', borderBottom: '1px solid rgba(0, 0, 0, .3)' }} />
                    <div className='pricesByScales_'>
                      <p>directo</p>
                    </div>
                    <div className='pricesByScales_'>
                      <p>1 escala</p>
                    </div>
                    <div className='pricesByScales_' style={{ border: 'none' }}>
                      <p>2 o más escalas</p>
                    </div>
                  </div>
                  <div className='pricesByAirline'>
                    {airlines.map((airline) => {
                      return (
                        <div key={airline.id} className='pricesByAirline_'>
                          <div className='pricesByAirline_title'>
                            <div className='pricesByAirline_img'>
                              <img src={airline.img} alt={airline.name} />
                            </div>
                            <p>{airline.name}</p>
                          </div>
                          <div className='priceByAirline_price'>
                            <p>$ {airline.priceDirect}</p>
                          </div>
                          <div className='priceByAirline_price'>
                            <p>$ {airline.priceOneScale}</p>
                          </div>
                          <div className='priceByAirline_price'>
                            {airline.priceMoreScale &&
                              <p>$ {airline.priceMoreScale}</p>
                            }
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </TabPanel>
              <TabPanel
                value="2">
                Precios más de tres días
              </TabPanel>
            </TabContext>
          </Box>
        </div>

        <div className='recommendations'>
          <div className='recommendation'>
            <div className='recommendation_'>
              <p>Nuestra recomendadión</p>
              <p>$ 950 - 7h 26m</p>
            </div>
          </div>
          <div className='recommendation' style={{ borderRight: '1px solid rgba(0, 0, 0, .3)', borderLeft: '1px solid rgba(0, 0, 0, .3)' }}>
            <div className='recommendation_'>
              <p>Precio más bajo</p>
              <p>$ 950 - 7h 26m</p>
            </div>
          </div>
          <div className='recommendation'>
            <div className='recommendation_'>
              <p>Menor duración</p>
              <p>$ 1120 - 6h 15m</p>
            </div>
          </div>
        </div>

        <div className='alert'>
          <div className='alert_'>
            <div className='alert_img'>
              <img src={Alert} alt="Alert" />
            </div>
            <div className='alert_text'>
              <h2>Crea una alerta de precios</h2>
              <p>Compartenos tu email y te avisamos cuando los vuelos a Medellín estén a menos de $950. <Link to='/alert'>Crear Alerta</Link></p>
            </div>
            <div className='alert_logo'>
              <div className='alert_logo_'>
                <img src={LogoBlue} alt="Wellezy" />
              </div>
            </div>
          </div>
        </div>

        {
          flights?.recommendation && flights?.recommendation.map((recommendation, key) => {
            return (
              <FlightData
                key={key}
                oneWay={oneWay}
                roundTrip={roundTrip}
                Seg1={flights.Seg1}
                Seg2={flights.Seg2}
                recommendation={recommendation}
                passengersSelected={passengersSelected}
                nameOriginSlice={nameOriginSlice}
                nameDestinySlice={nameDestinySlice}
              />
            )
          })
        }
      </div>
    )
  }
}

export default FlightDetail








