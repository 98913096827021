import React from "react";
import Find from "../share/components/Find/Find";
import CarouselSevices, {
  servicesTravel,
} from "../share/components/CarouselServices/CarouselSevices";
import UseCosmetics from "../../viewModels/Cosmetics/UseCosmetics";
import AllCardsCosmetics from "./AllCards/AllCardsCosmetics";
import Paginado from "./Paginado/Paginado";
import { useTranslation } from "react-i18next";
import AnyQuestions from "../../../ui/views/home/components/anyQuestion";

const Cosmetics = () => {
  const [t, i18n] = useTranslation("global");
  const {
    dataCosmetics,
    loading,
    nextHandler,
    prevHandler,
    currentPage,
    mostrarNext,
    services,
    urlImage,
    detailCosmetics,
  } = UseCosmetics();

  return (
    <div>
      <Find />
      <CarouselSevices current={t("carousel_services.cosmetics")} />
      <AllCardsCosmetics
        dataCosmetics={dataCosmetics}
        loading={loading}
        urlImage={urlImage}
        detailCosmetics={detailCosmetics}
      />
      <Paginado
        currentPage={currentPage}
        data={services}
        mostrarNext={mostrarNext}
        prevHandler={prevHandler}
        nextHandler={nextHandler}
      />
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default Cosmetics;
