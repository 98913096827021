const separateDigits = (value) => {
  const digits = value.toString().padStart(2, '0').split('');
  return digits.map(digit => parseInt(digit));
};

const DateTimeDisplay = ({ value, type, isDanger }) => {
  const separatedDigits = separateDigits(value);

  return (
    <div className={isDanger ? 'countdown danger' : 'countdown'}>
      <p>{separatedDigits[0]}</p>
      <p>{separatedDigits[1]}</p>
    </div>
  );
};

export default DateTimeDisplay;