import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import "./styles.css"

const Recapchat = ({ siteKey, onChangeCaptcha }) => {
    return (
        <ReCAPTCHA sitekey={siteKey} onChange={onChangeCaptcha} style={{border: "none"}} />
    )
}

export default Recapchat