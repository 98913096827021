import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import CarouselSevices, {
  servicesTravel,
} from "../../share/components/CarouselServices/CarouselSevices";
import "./styles.css";
import GalleryImg from "./GalleryImg/GalleryImg";
import ServicesInclude from "./ServicesInclude/ServicesInclude";
import AboutHotel from "./AboutHotel/AboutHotel";
import HabDetail from "./HabDetail/HabDetail";
import CartReserve from "./CartReserve/CartReserve";
import { useTranslation } from "react-i18next";
import SvgIcon, { iconList } from "../../share/components/iconSVG";
import { MdCameraAlt } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import TextComponent from "../../share/components/fonts/TextComponent";
import ReturnPage from "../../share/components/return/ReturnPage";
import AnyQuestions from "../../home/components/anyQuestion";
import useHotelBuildPackage from "@/Hooks/BuildPackage/useHotelBuildPackage/UseHotelBuildPackage";
import SessionModal from "../../session/components/SessionModal/SessionModal";
import ModalContainer from "../../share/components/modalContainer";
import styles from "./styles.module.css"
import style from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";

const DetailHotel = () => {
  const [t] = useTranslation("global");

  let [mountRoom, setMountRoom] = useState([]);
  const [slideShow, setSlideShow] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [listReserve, setListReserve] = useState([]);
  const [buttonBooking, setButtonBooking] = useState(false);
  const [alertList, setAlertList] = useState(false);
  const mountRooms = parseInt(localStorage.getItem("mountRooms"));
  const [selectedRoom, setSelectedRoom] = useState("");
  const [roomId, setRoomId] = useState([]);


  const { dataHab, dataHot, persons, fen, fsa, country_client, bestOffer } =
    useLocation().state;
    

  const navigate = useNavigate();
  let suma = 0;
  let line = [];

  const {
    nextStep,
    activeSessionModal,
    setActiveSessionModal,
    modalQuestion,
    setModalQuestion,
    setUrlSpecialist
  } = useHotelBuildPackage();

  function formatterText(frase) {
    // Dividir la frase en palabras individuales
    let palabras = frase.toLowerCase().split(" ");

    // Capitalizar la primera letra de cada palabra
    for (let i = 0; i < palabras.length; i++) {
      palabras[i] = palabras[i].charAt(0).toUpperCase() + palabras[i].slice(1);
    }

    // Unir las palabras en una sola frase
    let resultado = palabras.join(" ");

    return resultado;
  }

  useEffect(() => {
    // Función local para crear los nuevos objetos

    const newRooms = listReserve.map((item) => {
      const [adults, children] = item?.acomodation?.split("-")?.map(Number);
      return {
        adult: adults,
        child: children,
        age: [],
      };
    });

    setRooms(newRooms);
  }, [listReserve]);

  const location = useLocation();

  const routeStartsWithBuildPackageRoom = location.pathname.startsWith(
    "/build-package/room"
  );

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [location]);

  useEffect(() => {
    const body = document.querySelector("body");
    slideShow ? (body.style.overflow = "hidden") : (body.style.overflow = "");
  }, [slideShow]);

  const navigateReservation = () => {
    listReserve.forEach((item, key) => {
      suma += parseInt(item["@attributes"]?.prr);
      item?.lin.map((item, key) => {
        line.push(item);
      });
    });

    localStorage.setItem("mountRoom", JSON.stringify(mountRoom));
    if (!routeStartsWithBuildPackageRoom) {
      navigate("/hotels/reservation", {
        state: {
          price: suma,
          code: dataHab.cod,
          hotel: dataHab.nom,
          arrival: fen,
          line: line,
          departure: fsa,
          country: "CO",
          rooms: rooms,
          country_client: country_client,
          listReserve: listReserve,
          stars: dataHab?.cat,
          dataHot: dataHot,
          dataHab: dataHab,
        },
      });
    } else {
      nextStep({
        price: suma,
        code: dataHab.cod,
        hotel: dataHab.nom,
        arrival: fen,
        line: line,
        departure: fsa,
        country: "CO",
        rooms: rooms,
        country_client: country_client,
        listReserve: listReserve,
        stars: dataHab?.cat,
        dataHot: dataHot,
        dataHab: dataHab,
      });
    }

  };

  const stars = [];
  const star = dataHot?.categoria;

  for (let i = 0; i < Number(star); i++) {
    stars.push(
      <SvgIcon key={i} name={iconList.IconStar} color={"#ffdd44"} size={30} />
    );
  }


  const [loadingImage, setLoadingImage] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadingImage(false);
    }, 3000);
  }, []);

  const notify = (title, icon) =>
    toast(title, {
      icon: icon,
    });

  const joinReserve = (item, acomodation, roomIdSelected, firstValueFromLin) => {
    const newItem = item?.reg?.[0];

    const isDuplicate = listReserve.some(
      (reserveItem) => reserveItem.acomodation === acomodation
    );

    if (isDuplicate) {
      // notify("Acomodación ya está añadida!", "❌");
      // setAlertList(true);
      // setTimeout(() => {
      //   setAlertList(false);
      // }, 2000);

      setListReserve(
        listReserve.filter((el) => el?.acomodation !== acomodation)
      );
      setListReserve((prevList) => [...prevList, newItem]);
      setRoomId(roomId.filter((el) => el !== roomIdSelected));
      setRoomId((prevList) => [...prevList, roomIdSelected])
    } else {
      notify("Reserva añadida!", "✅");
      setListReserve((prevList) => [...prevList, newItem]);
      setRoomId((prevList) => [...prevList, roomIdSelected])
      setMountRoom((prevMount) => [...prevMount, firstValueFromLin]);
    }
  };

  const deleteReserve = (item, roomIdSelected) => {
    notify("Reserva eliminada!", "❌");
    setListReserve(
      listReserve.filter((el) => el?.acomodation !== item?.acomodation)
    );
    setButtonBooking(false);
    setRoomId(roomId.filter((el) => el !== roomIdSelected));
    setSelectedRoom(roomIdSelected);
  };

  function Image({ url }) {
    const onLoad = () => {
      setLoadingImage(false);
    };
    const onError = () => {
      setLoadingImage(true);
    };
    return (
      <img
        className="image-hotel-detail"
        src={url}
        onLoad={onLoad}
        onError={onError}
        alt="Hotel"
      />
    );
  }

  return (
    <>
      {!loadingImage && (
        <GalleryImg
          Images={dataHot?.fotos?.foto}
          slideShow={slideShow}
          setSlideShow={setSlideShow}
        />
      )}

          <SessionModal
          activeSessionModal={activeSessionModal}
          setActiveSessionModal={setActiveSessionModal}
          url={setUrlSpecialist.specialist}
        />

      <ModalContainer
        show={modalQuestion}
        hiddsetActiveChangeServiceen={setModalQuestion}
        sizeHeight={52}
        width={450}
      >
        <div>
          <div className={styles.modalQuestion}>
            <img src="https://cdn-icons-png.freepik.com/512/5471/5471074.png" alt="question" />
            <p>¿Deseas continuar con tu compra?</p>

            <div className='content-all-info-detail-transport' style={{ margin: "1rem 0" }}>
              <h2>Detalle de la compra</h2>
              <div className='line-separation-transport'></div>
              <div className='info-detail-transport'>
                <div className="camp-detail">
                  <h3><SvgIcon color={"var(--bluePrimary)"} name={iconList.IconDoctor} size={20} />Doctor</h3>
                  <span>Benito Perez</span>
                </div>
                <div className="camp-detail">
                  <h3><SvgIcon color={"var(--bluePrimary)"} name={iconList.IconHospital} size={20} />Clinica</h3>
                  <span>Clinica laser</span>
                </div>
                <div className="camp-detail">
                  <h3><SvgIcon color={"var(--bluePrimary)"} name={iconList.IconPlane2} size={20} />Vuelo</h3>
                  <span>MDE - BOG</span>
                </div>
                <div className="camp-detail">
                  <h3><SvgIcon color={"var(--bluePrimary)"} name={iconList.IconHotel} size={20} />Hotel</h3>
                  <span>Low Tower</span>
                </div>
              </div>
              <div className='line-separation-transport'></div>
              <div className='note-trans'>
                <p className={styles.note}>
                  Podría haber cargos adicionales al pagar tu paquete. De ser así, te los informaremos en detalle antes de finalizar tu pedido.
                </p>
              </div>
            </div>
            <div>
              <button onClick={() => nextStep({
                price: suma,
                code: dataHab.cod,
                hotel: dataHab.nom,
                arrival: fen,
                line: line,
                departure: fsa,
                country: "CO",
                rooms: rooms,
                country_client: country_client,
                listReserve: listReserve,
                stars: dataHab?.cat,
                dataHot: dataHot,
                dataHab: dataHab,
              })}>Continuar</button>
              <button onClick={()=>{
                navigate("/build-package")
              }}>Salir</button>
            </div>
          </div>
        </div>

      </ModalContainer>

      <Toaster position="top-center" reverseOrder={false} />
      {
        !routeStartsWithBuildPackageRoom && (
          <>
            <CarouselSevices current={servicesTravel[1]?.title} />
            <ReturnPage />
          </>
        )
      }
      <div className="container-detail-hotel">
        <div className="detail-hotel">
          <div
            className="contain-img-principal-hotel"
            onClick={() => {
              setSlideShow(!slideShow);
            }}
          >
            <div className="section-img-big">
              <div className="section-img-1">
                {loadingImage ? (
                  <div className="img-hotel-skeleton">
                    <MdCameraAlt size={40} color="#c8c8c8" />
                  </div>
                ) : (
                  <Image url={dataHot?.fotos?.foto[0]} />
                )}
              </div>
              <div className="section-img-2">
                {loadingImage ? (
                  <div className="img-hotel-skeleton">
                    <MdCameraAlt size={40} color="#c8c8c8" />
                  </div>
                ) : (
                  <Image url={dataHot?.fotos?.foto[1]} />
                )}
              </div>
            </div>
            <div className="section-img-layout-for">
              <div className="section-img-layout-1">
                {loadingImage ? (
                  <div className="img-hotel-skeleton">
                    <MdCameraAlt size={40} color="#c8c8c8" />
                  </div>
                ) : (
                  <Image url={dataHot?.fotos?.foto[2]} />
                )}
              </div>
              <div className="section-img-layout-2">
                {loadingImage ? (
                  <div className="img-hotel-skeleton">
                    <MdCameraAlt size={40} color="#c8c8c8" />
                  </div>
                ) : (
                  <Image url={dataHot?.fotos?.foto[3]} />
                )}
              </div>
            </div>
          </div>

          <div className="contain-title-hotel">
            <TextComponent size={"lg"} fontWeight="bold" tag="h1">
              {dataHot?.nombre_h}
            </TextComponent>
            <div className="contain-title-hotel-column">
              <TextComponent size={"md"}>
                {dataHot?.provincia} {formatterText(dataHab.dir)}
              </TextComponent>
              <div className="stars-div">{stars}</div>
              <div className="stars-description">
                <span>{Number(star).toFixed(1)}</span>
                <TextComponent size={"md"} fontWeight="bold">
                  {Number(star) === 5 ? t("hotel.excelent") : t("hotel.good")}
                </TextComponent>
              </div>
            </div>
          </div>

          <ServicesInclude
            information={dataHab?.desc}
            name={dataHot?.nombre_h}
            ubication={dataHab?.como_llegar}
            serviciosHot={dataHot?.servicios?.servicio}
            serviciosHab={dataHot?.servicios_habitacion?.servicio_habitacion}
            checkin={dataHot?.checkin}
            checkout={dataHot?.checkout}
            star={dataHot?.categoria}
          />
          <div className="contain-detail-hotel-reserve">
            <div className="contain-detail-hotel">
              {dataHab.res.pax.map((item, key) => {
                return (
                  <HabDetail
                    Hab={item.hab}
                    key={key}
                    id={key}
                    people={item}
                    rooms={rooms}
                    typeAcomodation={item["@attributes"]?.cod}
                    listReserve={listReserve}
                    setListReserve={setListReserve}
                    buttonBooking={buttonBooking}
                    setButtonBooking={setButtonBooking}
                    joinReserve={joinReserve}
                    deleteReserve={deleteReserve}
                    selectedRoom={selectedRoom}
                    setSelectedRoom={setSelectedRoom}
                    arrival={fen}
                    departure={fsa}
                    bestOffer={bestOffer}
                  />
                );
              })}
            </div>

            <CartReserve
              list={listReserve}
              setList={setListReserve}
              navigateMakeReservation={navigateReservation}
              buttonBooking={buttonBooking}
              setButtonBooking={setButtonBooking}
              deleteReserve={deleteReserve}
              roomId={roomId}
              dataHot={dataHot}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailHotel;
