import React, { useRef } from 'react'
import styled from 'styled-components'
import { motion, useDragControls } from 'framer-motion'
import { Grid } from '@mui/material'
import Img2 from './Assets/Img/Img2.jpg'

import '../HomeTurism/Assets/styles.css'


import CardHomeTurism from '../CardHomeTurism/CardHomeTurism'


const hoteles = [
  {
    id: 1,
    from: "Desde",
    price: "COP 250.000",
    title: "Atlantic Lux",
    img: Img2,
    description: "Wellezy estuvo en colferias en el evento anato que tuvo en Colferias de las ciudad de bogota del 22 al 25 de febrero donde logramos tener muchos más convenios",
    stars: 5
  },
  {
    id: 2,
    from: "Desde",
    price: "COP 250.000",
    title: "Molino de Viento",
    img: Img2,
    description: "Wellezy estuvo en colferias en el evento anato que tuvo en Colferias de las ciudad de bogota del 22 al 25 de febrero donde logramos tener muchos más convenios",
    stars: 5
  }, {
    id: 3,
    from: "Desde",
    price: "COP 250.000",
    title: "Dann Carlton Belfort",
    img: Img2,
    description: "Wellezy estuvo en colferias en el evento anato que tuvo en Colferias de las ciudad de bogota del 22 al 25 de febrero donde logramos tener muchos más convenios",
    stars: 5
  }, {
    id: 4,
    from: "Desde",
    price: "COP 250.000",
    title: "BLUE SUITES",
    img: Img2,
    description: "Wellezy estuvo en colferias en el evento anato que tuvo en Colferias de las ciudad de bogota del 22 al 25 de febrero donde logramos tener muchos más convenios",
    stars: 5
  },
]

const lugares = [
  {
    id: 1,
    from: "Desde",
    price: "COP 250.000",
    title: "Capurgana",
    img: Img2,
    description: "Wellezy estuvo en colferias en el evento anato que tuvo en Colferias de las ciudad de bogota del 22 al 25 de febrero donde logramos tener muchos más convenios",
    stars: 5
  },
  {
    id: 2,
    from: "Desde",
    price: "COP 250.000",
    title: "Santa Marta",
    img: Img2,
    description: "Wellezy estuvo en colferias en el evento anato que tuvo en Colferias de las ciudad de bogota del 22 al 25 de febrero donde logramos tener muchos más convenios",
    stars: 5
  },
  {
    id: 3,
    from: "Desde",
    price: "COP 250.000",
    title: "Cartagena",
    img: Img2,
    description: "Wellezy estuvo en colferias en el evento anato que tuvo en Colferias de las ciudad de bogota del 22 al 25 de febrero donde logramos tener muchos más convenios",
    stars: 5
  },
  {
    id: 4,
    from: "Desde",
    price: "COP 250.000",
    title: "Medellin",
    img: Img2,
    description: "Wellezy estuvo en colferias en el evento anato que tuvo en Colferias de las ciudad de bogota del 22 al 25 de febrero donde logramos tener muchos más convenios",
    stars: 5
  },
]

const MotionConstraints = styled(motion.div)`
    overflow-x: hidden;
  `;

const MotionBox = styled(motion.div)`
    width: 400vw;
  `

const flechas = "<----- Drag ----->"


const FlightsTopHoteles = ({ keys }) => {
  const constraintsRef = useRef(null)

  


  return (
    <div style={{ fontFamily: 'Inter', textAlign: 'center', margin: '32px auto' }}>
      <MotionConstraints className='slider_container' ref={constraintsRef}>
        <MotionBox className='slider_' drag='x' dragConstraints={constraintsRef}  >
          {
            keys === "hotels" &&
            hoteles.map((info) => {
              return (
                <motion.div className='div_homeTurism' key={info.id}>
                  <Grid item xs={6} sm={6} md={3} lg={2} className='grid_cardHomeTurism'>
                    <CardHomeTurism cardImage={info.img} cardTitle={info.title} cardDescription={info.description} star={info.stars} cardPrice={info.price} cardFrom={info.from} link={`/hotel/${info.id}`} />
                  </Grid>
                </motion.div>
              )
            })
          }
          {
            keys === "lugares" &&
            lugares.map((info) => {
              return (

                <motion.div className='div_homeTurism' key={info.id}>
                  <Grid item xs={6} sm={6} md={3} lg={2} className='grid_cardHomeTurism'>
                    <CardHomeTurism cardImage={info.img} cardTitle={info.title} cardDescription={info.description} star={info.stars} cardPrice={info.price} cardFrom={info.from} link={`/hotel/${info.id}`} />
                  </Grid>
                </motion.div>
              )
            })
          }
        </MotionBox>
      </MotionConstraints>
      {/* <div className='control-carousel' onPointerDown={startDrag} >
        <p >{flechas}</p>
      </div> */}
    </div>
  )
}

export default FlightsTopHoteles