import React from "react";

function DetailBuy ({details ,colorTitles}) {
    return (
        <div className="container-data">
            <div className={colorTitles ? "title-form detail-buy-title": "title-form detail-buy-title-main"}>
                <p>Detalles de su compra</p>
            </div>
            <div className="container-detail-buy">
                {
                    details.map((detail, index) => {
                        return(
                            <div className="detail-buy" key={index}>
                                <div className={colorTitles ? "detail-buy-col detail-buy-title": "detail-buy-col detail-buy-title-main"}>
                                    <p>{detail.title}:</p>
                                </div>
                                <div className="detail-buy-col">
                                    <p>{detail.value}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default DetailBuy;