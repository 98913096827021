import React from 'react'
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import { useTranslation } from 'react-i18next'


const SearchHealth = () => {

const [t] = useTranslation("global")

  return (
    <div className='content-search-ambulance'>
            <div className='content-legend-ambulance'>
                <SvgIcon name={iconList.IconBeautySalon} size={50} color={"#004274"} />
                <h3>{t("health_wellness.title")}</h3>
            </div>
            <div className='camp-ambulance'>
                <SvgIcon name={iconList.IconBeautiful} size={30} color={"#004274"} />
                <div>
                    <h4>
                    {t("health_wellness.services")}
                    </h4>
                    <p>
                    {t("health_wellness.description")}
                    </p>
                </div>

            </div>
            <div className='camp-ambulance'>
                <SvgIcon name={iconList.IconCoupon} size={30} color={"#004274"} />
                <div>
                    <h4>
                    {t("health_wellness.products")}
                    </h4>
                    <p>
                    {t("health_wellness.abrevement_product")}
                    </p>
                </div>
            </div>
            <button className='search-ambulance'>
                <SvgIcon name={iconList.IconSearch} size={30} color={"#ffffff"}/>
            </button>
            <div className='camp-ambulance' style={{marginTop:"1.5rem"}}>
                <SvgIcon name={iconList.IconFilters} size={30} color={"#004274"} />
                <div>
                    <h4>
                    {t("health_wellness.filters")}
                    </h4>
                    <p>
                    {t("health_wellness.personalize_search")}
                    </p>
                </div>
                <SvgIcon name={iconList.IconArrowDown2} size={15} color={"#004274"} />

            </div>
        </div>
  )
}

export default SearchHealth