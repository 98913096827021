import { useState, useEffect, useContext } from 'react';
import UserContext from '../../../../Context/user/userProvider';
import {wellezy_api} from '../../../../infra/api';
import { doctorURL } from '../../../../infra/urls';

function useCommercialProfileFormRow() {
  const { userDetail, userToken } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [dataCommercial, setDataCommercial] = useState(null);
  const [form, setForm] = useState(dataCommercial);
  const [btnDisable, setBtnDisable] = useState(true);

  function getFormDataText(event) {
    if (!isEditing) return;
    const value = event.target.value;
    const key = event.target.id;
    const newValue = key === 'experience' ? parseInt(value) : value;
    setForm({
      ...form,
      [key]: newValue
    })
  }

  function getFormDataBoolean(id, value) {
    if (!isEditing) return;
    setForm({
      ...form,
      [id]: value
    })
  }


  const typeInputs = {
    text: 'text',
    check: 'check',
    textArea: 'textArea'
  }


  function getBooleanValues(value) {
    if (value === "0") {
      return false;
    }
    else if (value === "1") {
      return true;
    }
    else {
      return value || '';
    }
  }

  const inputList = [
    { id: 'responsability', value: getBooleanValues(form?.responsability), change: getFormDataBoolean, inputType: typeInputs.check, isRequired: false, label: 'Seguro de responsabilidad civil:', placeHolder: '', type: 'text' },
    { id: 'emergencies', value: getBooleanValues(form?.emergencies), change: getFormDataBoolean, inputType: typeInputs.check, isRequired: false, label: 'Servicio de urgencias en la clínica:', placeHolder: '', type: 'text' },
    { id: 'procedures', value: getBooleanValues(form?.procedures), change: getFormDataBoolean, inputType: typeInputs.check, isRequired: false, label: 'Autorización para realizar procedimientos:', placeHolder: '', type: 'text' },
    { id: 'invima', value: getBooleanValues(form?.invima), change: getFormDataBoolean, inputType: typeInputs.check, isRequired: false, label: 'Materiales con aprobación de INVIMA:', placeHolder: '', type: 'text' },
    { id: 'iso_9001', value: getBooleanValues(form?.iso_9001), change: getFormDataBoolean, inputType: typeInputs.check, isRequired: false, label: 'Certificado ISO 9001 (Calidad):', placeHolder: '', type: 'text' },
    { id: 'people', value: getBooleanValues(form?.people), change: getFormDataText, inputType: typeInputs.text, isRequired: false, label: 'Somos un equipo de:', placeHolder: '', type: 'number' },
    { id: 'experience', value: getBooleanValues(form?.experience), change: getFormDataText, inputType: typeInputs.text, isRequired: false, label: '¿Cuanto tiempo funcionando?', placeHolder: '', type: 'number' },
    { id: 'postoperative', value: getBooleanValues(form?.postoperative), change: getFormDataText, inputType: typeInputs.text, isRequired: false, label: 'Disponemos de seguimiento post operatorio:', placeHolder: '', type: 'text' },
    { id: 'special_agreement', value: getBooleanValues(form?.special_agreement), change: getFormDataText, inputType: typeInputs.text, isRequired: false, label: 'Convenios especiales:', placeHolder: '', type: 'text' },
    { id: 'it_stands_for', value: getBooleanValues(form?.it_stands_for), change: getFormDataText, inputType: typeInputs.text, isRequired: false, label: 'Se destaca por:', placeHolder: '', type: 'text' },
    { id: 'free_valuation', value: getBooleanValues(form?.free_valuation), change: getFormDataText, inputType: typeInputs.text, isRequired: false, label: 'Consulta de valoración gratuita:', placeHolder: '', type: 'text' },
    { id: 'payment_methods', value: getBooleanValues(form?.payment_methods), change: getFormDataText, inputType: typeInputs.text, isRequired: false, label: 'Medios de pago:', placeHolder: '', type: 'text' },
    { id: 'financial_agreements', value: getBooleanValues(form?.financial_agreements), change: getFormDataBoolean, inputType: typeInputs.check, isRequired: false, label: 'Convenios con instituciones financieras:', placeHolder: '', type: 'text' },
  ];


  function cancel() {
    setIsEditing(false);
    setBtnDisable(true);
    setForm(dataCommercial);
  }


  async function save() {
    if (isSending) return;
    try {
      setIsSending(true)
      const url = doctorURL.post.profileMethods;
      const newData = { ...form }
      newData.user_id = userDetail?.id
      const response = await wellezy_api.post(url, newData, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if (response) {
        setMessage(response?.data?.message);
        setShowModal(true);
        await getProfileCommercial();
      }
    } catch (error) {
      console.log("Error in save(): ", error)
    }
    finally {
      setIsSending(false);
      setIsEditing(false);
    }
  }


  async function getProfileCommercial() {
    if (isLoading) return;
    try {
      setIsLoading(true)
      const url = doctorURL.get.profileProfessional;
      const data = { user_id: userDetail?.id };
      const respuesta = await wellezy_api.get(url, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        params: data,
      });
      if (respuesta) {
        setDataCommercial(respuesta?.data?.user);
        setForm(dataCommercial);
      }
    } catch (error) {
      console.log("Error in getProfileCommercial()", error);
    }
    finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isEditing && form !== dataCommercial?.user) {
      setBtnDisable(false);
    }
  }, [form])

  useEffect(() => {
    getProfileCommercial();
  }, [])

  return {
    isLoading,
    showModal,
    setShowModal,
    message,
    inputList,
    isEditing,
    setIsEditing,
    isSending,
    typeInputs,
    btnDisable,
    cancel,
    save,

  }
}

export default useCommercialProfileFormRow;
