import React, { useEffect, useState } from "react";
import InputSimple from "../FormDataPassenger/InputSimple/InputSimple";
import InputDocument from "../FormDataPassenger/InputDocument/InputDocument";
import {ReactComponent as SingQuestion} from "./Assets/svg/SignQuestion.svg"
import "./Assets/styles.css";

function FormEmergencyContact({typeDocument, handleEmergencyChange, setSendEmergency}){

    const [namesEmergency, setNamesEmergency] = useState("");
    const [lastNamesEmergency, setLastNamesEmergency] = useState("");
    const [documentEmergency, setDocumentEmergency] = useState("");
    const [documentTypeIdEmergency, setDocumentTypeIdEmergency] = useState("");
    const [phoneEmergency1, setPhoneEmergency1] = useState("");
    const [phoneEmergency2, setPhoneEmergency2] = useState("");
    const [validate, setValidate] = useState("");

    const validateData = () => {
        if(namesEmergency === "" || namesEmergency.length < 3 || /\d/.test(namesEmergency)){
            setValidate("namesEmergency");
            return false;
        }else if(lastNamesEmergency === "" || lastNamesEmergency.length < 3 || /\d/.test(lastNamesEmergency)){
            setValidate("lastNamesEmergency");
            return false;
        }else if (documentTypeIdEmergency === "3" && isNaN(documentEmergency)) {
            setValidate("documentEmergency");
            return false;
        }else if(/[\s\W]/.test(documentEmergency) || documentEmergency.trim() === "" || documentEmergency.length < 5 || documentTypeIdEmergency.trim() === ""){
            setValidate("documentEmergency");
            return false;
        }else if(isNaN(phoneEmergency1)){
            setValidate("phoneEmergency1Text");
            return false;
        }else if(phoneEmergency1 === "" || phoneEmergency1.length < 8){
            setValidate("phoneEmergency1");
            return false;
        }else if(isNaN(phoneEmergency2)){
            setValidate("phoneEmergency2Text");
            return false;
        }else if(phoneEmergency2 === "" || phoneEmergency2.length < 8){
            setValidate("phoneEmergency2");
            return false;
        }else{
            setValidate("")
        }

        return true;
    }

    useEffect(() => {
        if(validateData()){
            handleEmergencyChange({ 
                passenger_emergency_first_name: namesEmergency, 
                passenger_emergency_last_name: lastNamesEmergency, 
                // documentEmergency, 
                // documentTypeIdEmergency,
                passenger_emergency_phone_1: phoneEmergency1, 
                passenger_emergency_phone_2: phoneEmergency2 
            });
            setSendEmergency(true);
        }else{
            setSendEmergency(false);
        }
    }, [namesEmergency, lastNamesEmergency, documentEmergency, documentTypeIdEmergency, phoneEmergency1, phoneEmergency2]);

    return(
        <div>
            <div className="title-form-emergincy-contact">
                <p>Contacto de Emergencia   <SingQuestion/></p>
            </div>
            <div className="inputs-data">
                <div className={validate === "namesEmergency" ? "validate-input-voucher": null}>
                    <InputSimple label="Nombres" onInputChange={setNamesEmergency} />
                    { validate === "namesEmergency" ? <p className="span-simple-error">El campo es requerido</p> : null}
                </div>
                <div className={validate === "lastNamesEmergency" ? "validate-input-voucher": null}>
                    <InputSimple label="Apellidos" onInputChange={setLastNamesEmergency} />
                    { validate === "lastNamesEmergency" ? <p className="span-simple-error">El es requerido</p> : null}
                </div>
                <div>
                    <InputDocument onInputChange={setDocumentEmergency} validate={validate === "documentEmergency"? true : false} setDocumentTypeId={setDocumentTypeIdEmergency} typeDocument={typeDocument} />
                </div>
            </div>
            <div className="inputs-data">
                <div className={validate === "phoneEmergency1" || validate ===  "phoneEmergency1Text" ? "validate-input-voucher": null}>
                    <InputSimple label="Telefono 1" onInputChange={setPhoneEmergency1} />
                    { validate === "phoneEmergency1"  || validate === "phoneEmergency1Text"? <p className="span-simple-error">{validate === "phoneEmergency1" ? "El campo es requerido": validate === "phoneEmergency1Text" ? "El campo debe ser numerico": null}</p> : null}
                </div>
                <div className={validate === "phoneEmergency2" || validate === "phoneEmergency2Text" ? "validate-input-voucher": null}>
                    <InputSimple label="Telefono 2" onInputChange={setPhoneEmergency2} />
                    { validate === "phoneEmergency2"  || validate === "phoneEmergency2Text"? <p className="span-simple-error">{validate === "phoneEmergency2" ? "El campo es requerido": validate === "phoneEmergency2Text" ? "El campo debe ser numerico": null}</p> : null}
                </div>
            </div>
        </div>
    )
}

export default FormEmergencyContact;