import React, { useState } from 'react'
import Find from '../share/components/Find/Find'
import CarouselSevices from '../share/components/CarouselServices/CarouselSevices'
import Loading from '../share/components/loading'

const WellezyNews = () => {

    const [loading, setLoading] = useState(false)
  return (
    <div >
        <Find></Find>
        <CarouselSevices/>
        <section>
            {
                loading ?
                <Loading/>
                :
                
                <header>
                    
                </header>

            }
        </section>
    </div>
  )
}

export default WellezyNews