import React, { useRef, useEffect, useState } from 'react';
import { Airplane, Calendar, Location, People, Record, RecordCircle, SearchNormal1, User } from 'iconsax-react';
import {
    useNavigate,
    Outlet,
    useLocation,
} from 'react-router-dom'


import FlightsDesinitySearcher from '../FlightsDesinitySearcher/FlightsDesinitySearcher';
import FlightsPassengerDetails from '../FlightsPassengerDetails/FlightsPassengerDetails'
import FlightsDateGoing from '../FlightsDateGoing/FlightsDateGoing'
import axios from 'axios'
import "./Assets/styles.css"
import "./Assets/stylesC.css"



const FlightsFind = ({isIframe}) => {
    const [origins, setOrigins] = useState([])
    const [search, setSearch] = useState()
    const location = useLocation();
    const position = location.pathname.split('/')[1];

    useEffect(() => {
        const obtenerResultado = async () => {
            const url = 'https://travelflight.pdtcomunicaciones.com/api/airports';
            const result = await axios.post(url, {
                "code": `${search}`
            }
            );
            setOrigins(result.data)

        }
        obtenerResultado()
    }, [search]);

    const cardRef = useRef()
    const navigate = useNavigate()

    const [origin, setOrigin] = useState(false)
    const [destinity, setDestinity] = useState(false)
    const [passagerDetails, setPassagerDetails] = useState(false)
    const [dateGoing, setDateGoing] = useState(false)
    const [roundTrip, setRoundTrip] = useState(true);
    const [oneWay, setOneWay] = useState(false)

    const [dateGoingSelected, setDateGoingSelected] = useState('Ida')
    const [dateSreturnSelected, setDateSreturnSelected] = useState('Vuelta')

    const [dateGoingSelectedApi, setDateGoingSelectedApi] = useState('fecha ida API')
    const [dateSreturnSelectedApi, setDateSreturnSelectedApi] = useState('fecha vuelta API')

    const [placeholderOriginFlight, setPlaceholderOriginFlight] = useState('Origen')
    const [buttonOriginFlight, setButtonOriginFlight] = useState('Origen Vuelo')
    const [codeIataOrigin, setCodeIataOrigin] = useState('codigo iata origen')
    const [codeiataDestinity, setCodeiataDestinity] = useState('codigo iata destino')

    const [placeholderDestinityFlight, setPlaceholderDestinityFlight] = useState('Destino')
    const [buttonDestinityFlight, setButtonDestinityFlight] = useState('Destino Vuelo')

    const [DestinityFlight, setDestinityFlight] = useState('hacia donde viajas')

    const [passengersSelecteds, setPassengersSelecteds] = useState({
        adult: 1,
        boy: 0,
        baby: 0
    })

    const [clasSelected, setClasSelected] = useState({
        economic: true,
        ejecutive: false,
        business: false
    })

    const [clasSelectedText, setClasSelectedText] = useState('económico')
    const [buttonPassengerSelected, setButtonPassengerSelected] = useState('Número Pasajeros')


    // {/*  CHECK BOX */ }
    const changeRoundTrip = () => {
        setOneWay(false)
        setRoundTrip(true)
        /*  roundTrip === false && oneWay=== true ? console.log('ida y vuelta') : console.log(' solo ida ') */
    }
    // {/*  CHECK BOX */ }
    const changeOneWay = () => {
        setOneWay(true)
        setRoundTrip(false)
        /*   roundTrip === true && oneWay=== false ? console.log(' solo ida ') : console.log(' ida y vuelta ') */
    }

    const originSearcherSelected = (e) => {
        const resultOrigin = e.target.attributes.category.value
        setPlaceholderOriginFlight(resultOrigin)
        setButtonOriginFlight(resultOrigin)
        setOrigin(false)
        const codeIataOrigin = e.target.attributes.codeIataFlight?.value
        setCodeIataOrigin(codeIataOrigin)
    }

    const desinitySearcherSelected = (e) => {
        const resultDestinity = e.target.attributes.category.value
        setPlaceholderDestinityFlight(resultDestinity)
        setButtonDestinityFlight(resultDestinity)
        setDestinity(false)
        const codeIataDestinity = e.target.attributes.codeIataFlight?.value
        setCodeiataDestinity(codeIataDestinity)
    }




    const handleNumber = (tipe, operation) => {
        setPassengersSelecteds((prev) => {
            return {
                ...prev, [tipe]: operation === 'increase' ? passengersSelecteds[tipe] + 1 : passengersSelecteds[tipe] - 1
            }
        })
    }

    const economicClasFunction = () => {
        setClasSelected({
            economic: true,
            ejecutive: false,
            business: false
        })
        setClasSelectedText('ecónomico')
    }
    const ejecutiveClasFunction = () => {
        setClasSelected({
            economic: false,
            ejecutive: true,
            business: false
        })
        setClasSelectedText('ejecutiva')
    }
    const businessClasFunction = () => {
        setClasSelected({
            economic: false,
            ejecutive: false,
            business: true
        })
        setClasSelectedText('business')
    }
    const passengerContinue = () => {
        const data = `${passengersSelecteds.adult} adulto${passengersSelecteds.boy ? ` ${passengersSelecteds.boy} · niño ·` : ``} ${passengersSelecteds.baby ? `${passengersSelecteds.baby} bebé ` : ``}· clase ${clasSelectedText}`
        setButtonPassengerSelected(data)
        setPassagerDetails(false)
    }

    useEffect(() => {
        const handler = (e) => {
            if (!cardRef?.current?.contains(e.target)) {
                setOrigin(false)
                setDestinity(false)
                setPassagerDetails(false)
                setDateGoing(false)
            }
        }
        document.addEventListener('mousedown', handler)
        return () => {
            document.removeEventListener('mousedown', handler)
        }
    }, [])

    const allpassengersSelecteds = Number(passengersSelecteds.adult + passengersSelecteds.boy + passengersSelecteds.baby)

    const babyValidate = passengersSelecteds.baby === passengersSelecteds.adult || passengersSelecteds.baby < passengersSelecteds.adult ? true : false

    const searcherFlight = () => {
        if (buttonDestinityFlight !== 'Hacia donde viaja' && buttonOriginFlight !== 'Desde donde viaja' && dateGoingSelected !== 'fecha ida' && dateSreturnSelected !== 'fecha vuelta' && roundTrip === true && codeIataOrigin !== codeiataDestinity && allpassengersSelecteds <= 9 && passengersSelecteds.boy <= passengersSelecteds.adult * 2 && babyValidate === true) {

        //     navigate(`/flight/selected?dateSreturnSelectedApi=${dateSreturnSelectedApi}dateGoingSelectedApi=${dateGoingSelectedApi}
        // codeiataDestinity=${codeiataDestinity}codeIataOrigin=${codeIataOrigin}buttonOriginFlight=${buttonOriginFlight}
        // buttonDestinityFlight=${buttonDestinityFlight}passengersSelecteds=${passengersSelecteds}
        // clasSelected=${clasSelected}dateGoingSelected=${dateGoingSelected}dateSreturnSelected=${dateSreturnSelected}
        // oneWay=${oneWay}roundTrip=${roundTrip}DestinityFlight=${DestinityFlight}`,

        navigate('/flight/search',
                {
                    state: {
                        dateSreturnSelectedApi,
                        dateGoingSelectedApi,
                        codeiataDestinity,
                        codeIataOrigin,
                        buttonOriginFlight,
                        buttonDestinityFlight,
                        passengersSelecteds,
                        clasSelected,
                        dateGoingSelected,
                        dateSreturnSelected,
                        oneWay,
                        roundTrip,
                        DestinityFlight
                    }
                }
            )
        } else if (dateGoingSelected !== 'fecha ida' && buttonDestinityFlight !== 'Hacia donde viaja' && buttonOriginFlight !== 'Desde donde viaja' && oneWay === true && codeIataOrigin !== codeiataDestinity && allpassengersSelecteds <= 9 && passengersSelecteds.boy <= passengersSelecteds.adult * 2 && babyValidate === true) {

            // navigate(`/flight/selected?dateSreturnSelectedApi=${dateSreturnSelectedApi}dateGoingSelectedApi=${dateGoingSelectedApi}
            //         codeiataDestinity=${codeiataDestinity}codeIataOrigin=${codeIataOrigin}buttonOriginFlight=${buttonOriginFlight}
            //         buttonDestinityFlight=${buttonDestinityFlight}passengersSelecteds=${passengersSelecteds}
            //         clasSelected=${clasSelected}dateGoingSelected=${dateGoingSelected}dateSreturnSelected=${dateSreturnSelected}
            //         oneWay=${oneWay}roundTrip=${roundTrip}DestinityFlight=${DestinityFlight}`,

                    navigate('/flight/search',

                {
                    state: {
                        dateSreturnSelectedApi,
                        dateGoingSelectedApi,
                        codeiataDestinity,
                        codeIataOrigin,
                        buttonOriginFlight,
                        buttonDestinityFlight,
                        passengersSelecteds,
                        clasSelected,
                        dateGoingSelected,
                        dateSreturnSelected,
                        oneWay,
                        roundTrip,
                        DestinityFlight
                    }
                }
            )
        } else {
            navigate()
        }

    }

    const showButtonSearch = () => {
        let result
        const ida = !!!(dateGoingSelected === 'Ida');
        const vuelta = !!!(dateSreturnSelected === 'Vuelta');
        if (roundTrip) {
            result = ida && vuelta;
        } else {
            result = oneWay && ida;
        }
        ButtonDisable(!result)
    }

    const [buttonDisable, ButtonDisable] = useState(false);

    useEffect(() => {
        showButtonSearch()
    }, [roundTrip, oneWay, dateGoingSelected, dateSreturnSelected]);

    return (
        <div className={position === "fligths" ? 'flightsHeaderFind-c' : 'flightsHeaderFind'} >
            <div className={position === "fligths" ? 'flightsHeaderFind_content-c' : isIframe? 'flightsHeaderFind_content_iframe':'flightsHeaderFind_content'}>
                <div className={position === "fligths" ? 'flightsHeaderFind_content_c' : 'flightsHeaderFind_content_'}>
                    <div className={position === "fligths" ? 'flightsHeaderFind_top-c' : 'flightsHeaderFind_top'}>
                        <div className={position === "fligths" ? 'flightsHeaderFind_logo-c' : 'flightsHeaderFind_logo'}>
                            <Airplane size="36" color="#004274" className='airplane' />
                            <h3>Reserva tu vuelo</h3>
                        </div>
                        <div className={position === "fligths" ? 'flightsHeaderFind_checkBox-c' : 'flightsHeaderFind_checkBox'}>
                            <div className='flightsHeaderFind_checkBoxOne'>
                                <div onClick={changeRoundTrip}>
                                    {roundTrip ?
                                        <RecordCircle style={{ cursor: 'pointer' }} size="28" color="#004274" />
                                        :
                                        <Record style={{ cursor: 'pointer' }} size="28" color="#004274" />
                                    }
                                </div>
                                <p>Ida y vuelta</p>
                            </div>
                            <div className='flightsHeaderFind_checkBoxOne'>
                                <div onClick={changeOneWay}>
                                    {oneWay ?
                                        <RecordCircle style={{ cursor: 'pointer' }} size="28" color="#004274" />
                                        :
                                        <Record style={{ cursor: 'pointer' }} size="28" color="#004274" />
                                    }
                                </div>
                                <p>Solo ida</p>
                            </div>
                        </div>
                    </div>
                    <div className={position === "fligths" ? 'flightsHeaderFind_bottom-c' : 'flightsHeaderFind_bottom'}>
                        <div className={position === "fligths" ? 'flightsHeaderFind_bottom_c' : 'flightsHeaderFind_bottom_'}>
                            {/*inicio - seleccionar punto de partida*/}
                            <div className={position === "fligths" ? 'flightsHeaderFind_buttonSearch-c' : 'flightsHeaderFind_buttonSearch'} onClick={() => setOrigin(!origin)}>
                                <div className='flightsHeaderFind_buttonSearch_div' >
                                    <div className='flightsHeaderFind_buttonSearch_div_div' >
                                        <h2 className='flightsHeaderFind_buttonSearchTitle'>Origen</h2>
                                        {buttonDestinityFlight === buttonOriginFlight ?
                                            <p style={{ color: 'red' }} className='flightsHeaderFind_buttonSearchDescription'>Cambia tu origen</p>
                                            :
                                            <p className='flightsHeaderFind_buttonSearchDescription'>{buttonOriginFlight}</p>
                                        }
                                    </div>
                                    <div className='flightsHeaderFind_buttonSearch_div_div-bro' >
                                        <Location size="28" color="#004274" />
                                    </div>
                                </div>
                            </div>
                            {
                                origin && <FlightsDesinitySearcher
                                    placeholder={placeholderOriginFlight}
                                    setSearch={setSearch}
                                    cardRef={cardRef}
                                    origins={origins}
                                    loadingClass='flight_loadingCitiesOrigin'
                                    flightClass='flight_searchCitiesOrigin'
                                    desinitySearcherSelected={originSearcherSelected} />
                            }
                            {/*fin - seleccionar punto de partida*/}
                            {/*inicio - seleccionar punto de llegada*/}
                            <div className={position === "fligths" ? 'flightsHeaderFind_buttonSearch-c' : 'flightsHeaderFind_buttonSearch'} onClick={() => setDestinity(!destinity)}>
                                <div className='flightsHeaderFind_buttonSearch_div' >
                                    <div className='flightsHeaderFind_buttonSearch_div_div' >
                                        <h2 className='flightsHeaderFind_buttonSearchTitle'>Destino</h2>
                                        {buttonDestinityFlight === buttonOriginFlight ?
                                            <p style={{ color: 'red' }} className='flightsHeaderFind_buttonSearchDescription'>Cambia tu destino</p>
                                            :
                                            <p className='flightsHeaderFind_buttonSearchDescription'>{buttonDestinityFlight}</p>
                                        }
                                    </div>
                                    <div className='flightsHeaderFind_buttonSearch_div_div-bro' >
                                        <Location size="28" color="#004274" />
                                    </div>
                                </div>
                            </div>
                            {destinity && <FlightsDesinitySearcher
                                placeholder={placeholderDestinityFlight}
                                setSearch={setSearch}
                                cardRef={cardRef}
                                origins={origins}
                                loadingClass='flight_loadingCitiesDestinity'
                                flightClass='flight_searchCitiesDestinity'
                                desinitySearcherSelected={desinitySearcherSelected} />
                            }
                            {/*fin - seleccionar punto de llegada*/}
                            <div className={position === "fligths" ? 'flight_dateFind-c' : 'flight_dateFind'} onClick={() => setDateGoing(!dateGoing)}>
                                <div className='flight_dateFind_div' >
                                    <div className='flight_dateFind_div_div' style={{}}>
                                        <h2 className='flightsHeaderFind_buttonSearchTitle'>Fecha</h2>
                                        <p className='flightsHeaderFind_buttonSearchDescription'>{dateGoingSelected}</p>
                                    </div>
                                    <div className='flight_dateFind_div_div_bro' style={{}}>
                                        <Calendar size="28" color="#004274" />
                                    </div>
                                </div>
                            </div>
                            {dateGoing &&
                                <FlightsDateGoing
                                    setOneWay={setOneWay}
                                    oneWay={oneWay}
                                    setRoundTrip={setRoundTrip}
                                    roundTrip={roundTrip}
                                    setDateGoing={setDateGoing}
                                    dateGoing={dateGoing}
                                    cardRef={cardRef}
                                    setDateGoingSelected={setDateGoingSelected}
                                    setDateGoingSelectedApi={setDateGoingSelectedApi}
                                    setDateSreturnSelected={setDateSreturnSelected}
                                    setDateSreturnSelectedApi={setDateSreturnSelectedApi}
                                    searcherFlight={searcherFlight} />
                            }
                            {roundTrip ?
                                <div className={position === "fligths" ? 'flight_dateFind-c' : 'flight_dateFind'}>
                                    <div className='flight_dateFind_div' >
                                        <div className='flight_dateFind_div_div' >
                                            <h2 className='flightsHeaderFind_buttonSearchTitle'>Fecha</h2>
                                            <p className='flightsHeaderFind_buttonSearchDescription'>{dateSreturnSelected}</p>
                                        </div>
                                        <div className='flight_dateFind_div_div_bro'>
                                            <Calendar size="28" color="#004274" />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='flight_dateFindNone'>
                                    <div className='flight_dateFindNone_div' style={{}}>
                                        <div className='flight_dateFindNone_div_div' style={{}}>
                                            <h2 className='flightsHeaderFind_buttonSearchTitle'>Fecha</h2>
                                            <p className='flightsHeaderFind_buttonSearchDescription'>{dateSreturnSelected}</p>
                                        </div>
                                        <div className='flight_dateFindNone_div_div_bro' style={{}}>
                                            <Calendar size="28" color="#004274" />
                                        </div>
                                    </div>
                                </div>
                            }
                            <Outlet />
                            <div className={position === "fligths" ? 'flight_passengersFind-c' : 'flight_passengersFind'} onClick={() => setPassagerDetails(!passagerDetails)}>
                                <div className='flight_passengersFind_div' style={{}}>
                                    <div className='flight_passengersFind_div_div' style={{}}>
                                        <h2 className='flightsHeaderFind_buttonSearchTitle'>Pasajeros</h2>
                                        {passengersSelecteds.adult + passengersSelecteds.boy + passengersSelecteds.baby > 9 || passengersSelecteds.boy > passengersSelecteds.adult * 2 || passengersSelecteds.baby > passengersSelecteds.adult ?
                                            <p style={{ color: 'red' }} className='flightsHeaderFind_buttonPassenger'>Numero Pasajeros Invalido</p>
                                            :
                                            <p className='flightsHeaderFind_buttonPassenger'>{buttonPassengerSelected}</p>
                                        }
                                    </div>
                                    <div className='.flight_passengersFind_div_div_bro' style={{}}>
                                        <People size="28" color="#004274" />
                                    </div>
                                </div>
                                {passagerDetails &&
                                    <FlightsPassengerDetails
                                        cardRef={cardRef}
                                        passengerContinue={passengerContinue}
                                        handleNumber={handleNumber}
                                        adultNum={passengersSelecteds.adult}
                                        boyNum={passengersSelecteds.boy}
                                        babyNum={passengersSelecteds.baby}
                                        passengersSelecteds={passengersSelecteds}
                                        clasSelected={clasSelected}
                                        economicClasFunction={economicClasFunction}
                                        ejecutiveClasFunction={ejecutiveClasFunction}
                                        businessClasFunction={businessClasFunction}
                                    />
                                }
                            </div>

                            <button disabled={buttonDisable}
                                className={`flight_searcherFind${position === 'flights' ? '-c' : ''}${buttonDisable ? ' flight_searcherFindDisable' : ''}`}
                                onClick={searcherFlight}>
                                <div>
                                    <SearchNormal1 size="34" color="#004274" />
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FlightsFind