import React from 'react'
import FligthsNew from '../../Components/FligthsNew/FligthsNew'

const FlightRoute = () => {
  const isWidget = window.location.pathname.startsWith('/widget/travel');
  return (
    <div>
        <FligthsNew isWidget={isWidget}/>
    </div>
  )
}

export default FlightRoute