import React from "react";
import TerrawindGenerateVoucherDetail from "../../Components/TerrawindGenerateVoucher/TerrawindGenerateVoucherDetail";

function TerrawindGenerateVoucher() {
    return (
        <div>
            <TerrawindGenerateVoucherDetail/>
        </div>
    );
}

export default TerrawindGenerateVoucher;