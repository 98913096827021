import React from 'react'
import Pharmacy from '../../ui/views/pharmacy/Pharmacy'

const PharmacyRoute = () => {
  return (
    <div>
        <Pharmacy/>
    </div>
  )
}

export default PharmacyRoute