import planet from '../../../../assets/svg/planet.svg';
import astronaut from '../../../../assets/svg/astronaut.svg';
import satellite from '../../../../assets/svg/satellite.svg';
import './styles.css';

function ErrorMeet({mssg}){
  return(
    <div className="errorMeetPX">
    <p className='errorMeetTitlePX'>Upss!</p>
    <p className='errorMeetDescriptionPX'>ERROR: {mssg}</p>
    <p className='errorMeetContactPX'>PLEASE CONTACT YOUR LINK PROVIDER.</p>
    <div className='errorMeetContainerPX'>
      <div className='astronautPX'>
        <img src={satellite} alt="Mi SVG" />
      </div>
      <div className='planetPX'>
        <img src={planet} alt="Mi SVG" />
      </div>
    </div>
  </div>
  )
}
export default ErrorMeet;