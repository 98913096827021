import React from 'react';
import SuraSummaryPayView from '../../Components/SuraSummaryPay/SuraSummaryPay';

const SuraSummaryPayRoute = () => {
    return (
        <div>
            <SuraSummaryPayView/>
        </div>
    );
};

export default SuraSummaryPayRoute;
