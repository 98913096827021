import HotelSearchCard from "@/Components/HotelSearchCard/HotelSearchCard";
import React, { useEffect, useRef, useState } from "react";
import SvgIcon, { iconList } from "@/ui/views/share/components/iconSVG";
import { useDispatch, useSelector } from "react-redux";
import { setCityHotelData } from "@/store/slices/dataEnginePackage/dataEnginePackageSlice";
import TextComponent from "@/ui/views/share/components/fonts/TextComponent";
import axios from "axios";
import TooltipContainer from "@/ui/views/share/components/tooltipContainer";

const InputSearchCityHotel = ({
  paceholders,
  validationForm,
  destinationSelectionFunc,
  openHotelSearch,
  activeIndex,
  getSelectDestination
}) => {
  // Variables
  const dispatch = useDispatch();

  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [citiesSearch, setCitiesSearch] = useState([]);
  const [cardCities, setCardCities] = useState(false);
  const [showCities, setShowCities] = useState(false);

  const cityRef = useRef(null);
  const inputRef = useRef(null);

  const cityHotelData = useSelector(
    (state) => state.dataSearchEngine.cityHotelData
  );

  // Functions
  const selectFligth = (id)=>{
    const url = `${process.env.REACT_APP_URL_API_FLY}airport/id?id=${id}`;
    axios.get(url)
    .then(res=>{
      getSelectDestination(res.data,true)
    })
    .catch(err=>{
      console.log(err)
    })
  }

  const handleClick = () => {
    paceholders = "";
    setCity("");
    setShowCities(true);
    setCardCities(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  const getCities = () => {
    const url = `${process.env.REACT_APP_URL_API_FINANMED}/list/ciudades`;
    let response = axios.get(url);

    response.then((res) => {
      setCities(res.data);
    })
    .catch((err) => {
      console.error(err);
    });
  }

  // UseEffects
  useEffect(() => {
    getCities();
    paceholders = cityHotelData;
    setCity(paceholders);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
        if(cityRef.current && !cityRef.current.contains(event.target)){
            setShowCities(false);
            setCardCities(false);
            // if(paceholders){
            //     setCity(paceholders);
            // }
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [cityRef, setShowCities]);

  useEffect(() => {
    if (openHotelSearch) {
      inputRef.current.focus();
    }
  }, [openHotelSearch]);

  useEffect(() => {
    dispatch(setCityHotelData(paceholders));
  }, [paceholders]);

  useEffect(() => {
      const sortedCities = [...cities].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      if (city === "") {
          setCitiesSearch(sortedCities.filter((especialty) => 
              especialty.name
          ));
      } else {
          setCitiesSearch(sortedCities.filter((especialty) => 
              especialty.name && especialty.name.toLowerCase().includes(city.toLowerCase())
          ));
      }
  }, [city, cities]);

  return (
    <>
    <div className={`searchBuildPackageContentInput ${paceholders === "" && validationForm && "border-validate"}`}
        onClick={handleClick}
        ref={cityRef}
    >
          <div className="searchBuildPackageContentInputContent">
              <TextComponent
                  children={"Destino"}
                  size={"md2"}
                  tag={"p"}
                  color={
                    paceholders === "" && validationForm
                      ? "var(--redPrimary)"
                      : "var(--greySecundary)"
                  }
              />
              <input 
                  type="text" 
                  placeholder={ showCities ? '' : 'Ciudad' } 
                  ref={inputRef} 
                  value={ city }                  
                  onChange={(e) => setCity(e.target.value)}
                  className={`${paceholders === "" && validationForm ? "text-gray-secondary font-weight-400" : "text-red-primary font-weight-500"}`}
              />
          </div>
          <SvgIcon
            name={iconList.IconPinMap2}
            color={"var(--bluePrimary)"}
            size={30}
          />          
          {
            cardCities && (
                <TooltipContainer
                    show={showCities}
                    hidden={setShowCities}
                    sizeWidth={100}
                    sizeHeight={80}
                >
                    <ul className="contentElemetsSearchInputBuildPackage">
                        {
                            citiesSearch.length === 0 ? (
                                <li className="list-not-results">
                                    No se encontraron resultados
                                </li>
                            ) : (
                              citiesSearch.map((cityItem, index) => (
                                  <li key={index} onClick={(e) => {
                                      e.stopPropagation();
                                      setShowCities(false);
                                      setCardCities(false);
                                      setCity(cityItem.name);
                                      destinationSelectionFunc(cityItem)
                                      dispatch(setCityHotelData(cityItem.name));
                                      if(activeIndex == 2 || activeIndex == 1){
                                        selectFligth(cityItem?.flight_id)
                                      }
                                  }}>
                                      <span>{cityItem.name}</span>
                                  </li>
                                ))
                            )
                        }
                    </ul>
                </TooltipContainer>
            )
          }
      </div>
    </>
  );
};

export default InputSearchCityHotel;
