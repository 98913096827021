import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { User } from "iconsax-react";
import HotelSearchCard from "../../../../Components/HotelSearchCard/HotelSearchCard";
import HotelDateCard from "../../../../Components/HotelDateCard/HotelDateCard";
import HotelRoomsCard from "../../../../Components/HotelRoomsCard/HotelRoomsCard";
import SvgIcon, { iconList } from "../../share/components/iconSVG";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { GoAlertFill } from "react-icons/go";
import Loading from "../../share/components/loading";
import { IoIosArrowDown } from "react-icons/io";
import TextComponent from "../../share/components/fonts/TextComponent";

const HotelSearchEngine = ({
  cardRef,
  openHotelSearch,
  paceholders,
  search,
  setSearch,
  destiny,
  dateEntry,
  setDateEntry,
  datesSelectedEntry,
  datesSelectedExit,
  setDatesSelected,
  cardRooms,
  setCardRooms,
  roomsSelected,
  setRoomsSelected,
  quantityPersons,
  openHotelSearchFuct,
  destinationSelectionFunc,
  searchHotelSelected,
  findCountryClient,
  nacionalityDropdown,
  setNacionalityDropdown,
  dataNacionality,
  selectedNacionality,
  nacionality,
  codeNacionality,
  loading,
  loadingSearch,
  validationForm,
  errApi,
  defaultHotel,
  setDefaultHotel,
}) => {
  const [t] = useTranslation("global");
  const { pathname } = useLocation();
  const inputNacionality = useRef(null);
  const inputCity = useRef(null);

  const formatDate = (date, typeDate) => {
    if (!(date instanceof Date) || isNaN(date)) {
      return typeDate;
    }

    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("es-ES", options).format(
      date
    );

    // Convertir la fecha a un formato más legible
    const [day, month, year] = formattedDate.split(" ");
    const abbreviatedMonth = month.slice(0, 3);

    return `${day}, ${abbreviatedMonth}, ${year}`;
  };

  useEffect(() => {
    if (nacionalityDropdown) {
      inputNacionality.current.focus();
    }
  }, [nacionalityDropdown]);

  useEffect(() => {
    if (openHotelSearch) {
      inputCity.current.focus();
    }
  }, [openHotelSearch]);

  const location = useLocation();
  let data = {};

  const pathSegments = location.pathname.split("/");
  if (pathSegments.length === 3 && pathSegments[1] === "hotels") {
    const dataString = decodeURIComponent(pathSegments[2]);
    const params = new URLSearchParams(dataString);
    data = Object.fromEntries(params.entries());
  }

  useEffect(() => {
    if (!data.departure || !data.arrival) {
      setDatesSelected((prevDates) => ({
        ...prevDates,
        entryDate: "Entrada",
        exitDate: "Salida",
      }));
    }
  }, []);

  const toCamelCaseWithFirstUpper = (str) => {
    const camelCaseStr = str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());
    return camelCaseStr.charAt(0).toUpperCase() + camelCaseStr.slice(1);
  };

  const [isScrolled, setIsScrolled] = useState(false);
  const [showSearchScrolled, setShowSearchScrolled] = useState(false);

  const checkScroll = () => {
    if (window.scrollY > 400 && defaultHotel === false) {
      setIsScrolled(true);
      setShowSearchScrolled(true);
    } else {
      setIsScrolled(false);
      setShowSearchScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, [defaultHotel]);

  useEffect(() => {
    if (dateEntry && !isScrolled) {
      const scrollPosition = window.innerWidth < 765 ? 400 : 350;
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [dateEntry]);
  

  return (
    <div
      className={`${
        pathname === "/hotel"
          ? "searchMenuHotelScrolled"
          : `${
              isScrolled && pathname.includes("/hotels/")
                ? "scrolledHotels"
                : ""
            }
         ${
           isScrolled
             ? "searchMenuHotelScrolled active"
             : "searchMenuHotelScrolled"
         } ${
              showSearchScrolled
                ? "searchMenuHotelScrolled hidden"
                : "searchMenuHotelScrolled "
            }`
      }`}
    >
      {loadingSearch && <Loading size={100} />}
      <div
        className={`${isScrolled ? "" : "searchMenuHotelScrolledMaxWidth"}`}
        style={{ padding: pathname.includes("/hotel") && 0 }}
      >
        <div
          className="container_campoSearch_"
          style={{
            backgroundColor: pathname.includes("/hotels/") && "var(--white)",
            padding: pathname.includes("/hotels/") && "0",
          }}
          // style={{
          //   width: `${widthContain}%`,
          //   right: `${right}`,
          //   position: `${position}`,
          //   top: `${top}`,
          //   boxShadow: `${pathname === "/packages" ? "none" : ""}`,
          // }}
        >
          <div
            style={{
              position: "relative",
              padding: "0px",
              width: "95%",
              maxWidth: "1280px",
            }}
          >
            {cardRooms && defaultHotel && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 999,
                  pointerEvents: "none",
                }}
              />
            )}

            {dateEntry && defaultHotel && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 999,
                  pointerEvents: "none",
                }}
              />
            )}
            <div
              className="contain-campos-hotel"
              style={{
                width: pathname.includes("/hotels/") && "95%",
                padding: pathname.includes("/hotels/") && "0",
                position: "relative",
                zIndex: 1000,
                width: "100%",
              }}
            >
              <div className="contain-header-buscador-hotel">
                <SvgIcon
                  size={30}
                  name={iconList.IconHotel}
                  color={"var(--bluePrimary)"}
                />
                <TextComponent
                  children={t("hotel.search_engine.accommodation")}
                  tag="h3"
                  size={"md"}
                  fontWeight="bold"
                />
              </div>
              <div className="layout-all">
                <div className="layout-responsive">
                  <div
                    className="layout-campo"
                    style={{
                      border:
                        (paceholders === "") & validationForm
                          ? "1px solid var(--redPrimary)"
                          : "1px solid var(--greyPrimary)",
                      padding: pathname.includes("/hotels/") && "8px 12px",
                    }}
                    onClick={openHotelSearchFuct}
                  >
                    <div
                      className="destiny-hotel"
                      style={{
                        borderRadius: !openHotelSearch ? "0px" : "10px",
                        color:
                          (paceholders === "") & validationForm
                            ? "1px solid var(--redPrimary)"
                            : "1px solid var(--bluePrimary)",
                      }}
                    >
                      <TextComponent
                        children={t("fligth.search_fligth.destination")}
                        tag="span"
                        size={"md2"}
                        fontWeight="normal"
                        color={
                          (paceholders === "") & validationForm
                            ? "var(--redPrimary)"
                            : "var(--greySecundary)"
                        }
                      />

                      <TextComponent
                        children={
                          paceholders === ""
                            ? t("hotel.search_engine.choose_destiny")
                            : toCamelCaseWithFirstUpper(paceholders)
                        }
                        size={"md2"}
                        fontWeight={paceholders === "" && validationForm ? "normal" : "semiNormal"}
                        color={
                          paceholders === "" && validationForm
                            ? "var(--greySecundary)"
                            : "var(--bluePrimary)"
                        }
                      />
                    </div>
                    {!openHotelSearch && (
                      <div style={{ zIndex: 9999 }}>
                        <SvgIcon
                          name={iconList.IconPinMap2}
                          color={"var(--bluePrimary)"}
                          size={30}
                        />
                      </div>
                    )}

                    {openHotelSearch && (
                      <HotelSearchCard
                        paceholders={paceholders}
                        validationForm={validationForm}
                        cardRef={cardRef}
                        search={search}
                        setSearch={setSearch}
                        placeholder={paceholders}
                        destiny={destiny}
                        destinationSelectionFunc={destinationSelectionFunc}
                        inputCity={inputCity}
                        isScrolled={isScrolled}
                        showSearchScrolled={showSearchScrolled}
                      />
                    )}
                  </div>

                  <div className="contain_date">
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <div
                        className="layout-campo"
                        style={{
                          height: "100%",
                          border:
                            (datesSelectedEntry === "Entrada" ||
                              datesSelectedEntry === "Entrance") &&
                            (datesSelectedExit === "Salida" ||
                              datesSelectedExit === "Exit") &&
                            validationForm
                              ? "1px solid var(--redPrimary)"
                              : "1px solid var(--greyPrimary)",

                          padding: pathname.includes("/hotels/") && "8px 12px",
                        }}
                        onClick={() => {
                          setDateEntry(!dateEntry);
                          setDefaultHotel(false);
                        }}
                      >
                        <div
                          className="destiny-hotel"
                          style={{
                            color:
                              (datesSelectedEntry === "Entrada" ||
                                datesSelectedEntry === "Entrance") &&
                              (datesSelectedExit === "Salida" ||
                                datesSelectedExit === "Exit") &&
                              validationForm
                                ? "1px solid var(--redPrimary)"
                                : "1px solid var(--bluePrimary)",
                          }}
                        >
                          <TextComponent
                            children={t("fligth.search_fligth.date")}
                            tag="span"
                            size={"md2"}
                            fontWeight="normal"
                            color={
                              (datesSelectedEntry === "Entrada" ||
                                datesSelectedEntry === "Entrance") &&
                              (datesSelectedExit === "Salida" ||
                                datesSelectedExit === "Exit") &&
                              validationForm
                                ? "var(--redPrimary)"
                                : "var(--greySecundary)"
                            }
                          />

                          <TextComponent
                            children={`${formatDate(
                              new Date(datesSelectedEntry),
                              "Entrada"
                            )} - ${formatDate(
                              new Date(datesSelectedExit),
                              "Salida"
                            )}`}
                            size={"md2"}
                            fontWeight={
                              (datesSelectedEntry === "Entrada" ||
                                datesSelectedEntry === "Entrance") &&
                              (datesSelectedExit === "Salida" ||
                                datesSelectedExit === "Exit") &&
                              validationForm
                                ? "normal"
                                : "semiNormal"
                            }
                            color={
                              paceholders === "" && validationForm
                                ? "var(--greySecundary)"
                                : "var(--bluePrimary)"
                            }
                          />
                        </div>
                        <div>
                          <SvgIcon
                            name={iconList.IconCalendar3}
                            color={"var(--bluePrimary)"}
                            size={30}
                          />
                        </div>
                      </div>

                      {dateEntry && !showSearchScrolled && (
                        <HotelDateCard
                          cardRef={cardRef}
                          setDatesSelected={setDatesSelected}
                          setDateEntry={setDateEntry}
                          left={"0"}
                          setCardRooms={setCardRooms}
                          defaultHotel={defaultHotel}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="contain-finish">
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div
                      className="layout-campo"
                      style={{
                        padding: pathname.includes("/hotels/") && "8px 12px",
                      }}
                      onClick={() => setCardRooms(!cardRooms)}
                    >
                      <div className="destiny-hotel">
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "inline-block",
                            maxWidth: "140px",
                            fontSize: "14px",
                            color: "var(--greySecundary)",
                          }}
                        >
                          {t("hotel.search_engine.bedrooms")}
                        </span>
                        <div className="contain_rooms">
                          <div className="contain_rooms_">
                            <svg
                              width="17"
                              height="17"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="sbox5-3-bed-svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 2C6.34315 2 5 3.34315 5 5V10.1707C3.83481 10.5825 3 11.6938 3 13V22C3 22.5523 3.44772 23 4 23C4.55229 23 5 22.5523 5 22L5 19H19V22C19 22.5523 19.4477 23 20 23C20.5523 23 21 22.5523 21 22V13C21 11.6938 20.1652 10.5825 19 10.1707V5C19 3.34315 17.6569 2 16 2H8ZM8 10H7V5C7 4.44772 7.44772 4 8 4H16C16.5523 4 17 4.44772 17 5V10H16V8C16 6.89543 15.1046 6 14 6H10C8.89543 6 8 6.89543 8 8V10ZM10 10H14V8H10V10ZM6 12C5.44772 12 5 12.4477 5 13V17H19V13C19 12.4477 18.5523 12 18 12H6Z"
                                fill="var(--bluePrimary)"
                              ></path>
                            </svg>
                            <p>{roomsSelected.length}</p>
                          </div>
                          <div className="contain_rooms_">
                            <User size="18" color="bluePrimary" />
                            <p>{quantityPersons}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {cardRooms && !showSearchScrolled && (
                      <HotelRoomsCard
                        cardRef={cardRef}
                        roomsSelected={roomsSelected}
                        setRoomsSelected={setRoomsSelected}
                        setCardRooms={setCardRooms}
                        cardRooms={cardRooms}
                      />
                    )}
                  </div>

                  <div
                    className="layout-campo-nationaly"
                    style={{
                      border:
                        (nacionality === "") & validationForm
                          ? "1px solid var(--redPrimary)"
                          : "1px solid var(--greyPrimary)",
                      padding: pathname.includes("/hotels/") && "8px 12px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setNacionalityDropdown(!nacionalityDropdown);
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          color:
                            (nacionality === "") & validationForm
                              ? "var(--redPrimary)"
                              : "var(--bluePrimary)",
                        }}
                      >
                        <TextComponent
                          className={"label-destiny"}
                          children={t("hotel.search_engine.nationality")}
                          size={"md2"}
                          fontWeight="500"
                          color={
                            (paceholders === "") & validationForm
                              ? "var(--redPrimary)"
                              : "var(--greySecundary)"
                          }
                        />

                        <div className="contain-nacionality">
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: nacionality === "" && validationForm ? "400" : "500",
                              color:
                                paceholders === "" && validationForm
                                  ? "var(--greySecundary)"
                                  : "var(--bluePrimary)",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              width: "140px",
                            }}
                          >
                            {nacionality === ""
                              ? t("hotel.search_engine.choose")
                              : `${toCamelCaseWithFirstUpper(nacionality)}`}
                          </p>
                        </div>
                      </div>

                      {nacionalityDropdown && (
                        <div className="dropdown-nacionality">
                          <div className="input-label-nationality">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                
                              }}
                            >
                              <TextComponent
                                children={t("hotel.search_engine.nationality")}
                                size={"md2"}
                                color={
                                  nacionality === "" && validationForm
                                    ? "var(--redPrimary)"
                                    : "var(--greySecundary)"
                                }
                              />

                              <input
                                ref={inputNacionality}
                                type="text"
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  width: "140px",
                                  fontWeight: nacionality ? "semiBold" : "500",
                                  color: "var(--bluePrimary)",
                                }}
                                placeholder={
                                  nacionality === ""
                                    ? t("hotel.search_engine.choose")
                                    : `${nacionality}, ${codeNacionality}`
                                }
                                onChange={(e) => {
                                  findCountryClient(e.target.value);
                                }}
                              />
                            </div>
                            {loading && (
                              <CircularProgress
                                style={{
                                  position: "absolute",
                                  right: "20px",
                                  top: "20px",
                                }}
                                size={25}
                              />
                            )}
                          </div>

                          {dataNacionality.length > 0 &&
                            !(isScrolled && showSearchScrolled) && (
                              <ul>
                                {dataNacionality.length >= 1 &&
                                  dataNacionality.map((item, key) => {
                                    return (
                                      <li
                                        key={key}
                                        onClick={() => {
                                          selectedNacionality(
                                            item.code,
                                            item.name
                                          );
                                          setNacionalityDropdown(false);
                                        }}
                                      >
                                        {item.name}, {item.code}
                                      </li>
                                    );
                                  })}
                              </ul>
                            )}
                        </div>
                      )}
                    </div>
                    {!loading && (
                      <div
                        style={{
                          zIndex: "9999",
                          position: "absolute",
                          right: 10,
                        }}
                      >
                        <SvgIcon
                          name={iconList.IconBander}
                          color={"var(--bluePrimary)"}
                          size={24}
                        />
                      </div>
                    )}
                  </div>
                  {pathname === "/packages" ? (
                    ""
                  ) : (
                    <button
                      className="btn-search-hotel-horizontal"
                      onClick={() => {
                        searchHotelSelected();
                        if (defaultHotel) {
                          localStorage.setItem("defaultHotel", true);
                        } else {
                          localStorage.setItem("defaultHotel", false);
                        }
                      }}
                    >
                      <IoSearchOutline size={30} />
                      {t("browse_home.search")}
                    </button>
                  )}
                </div>
              </div>
              {/* {(nacionality === "" ||
                paceholders === "" ||
                datesSelectedEntry === "Entrada" ||
                "Entrance" || 
                datesSelectedExit === "Salida" ||
                "Exit") & validationForm ? (
                <div className="error-hotel-search"></div>
              ) : null}
              {errApi && <div className="error-hotel-search"></div>} */}
            </div>
          </div>
        </div>
      </div>

      {isScrolled && pathname.includes("/hotels/") && (
        <div
          style={{
            fontSize: 12,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <button
            className={`${
              showSearchScrolled
                ? "buttonArrowSearchScrolled active"
                : "buttonArrowSearchScrolled"
            }`}
            onClick={() => setShowSearchScrolled(!showSearchScrolled)}
          >
            <IoIosArrowDown size={22} />
          </button>

          {showSearchScrolled ? "Ver buscador" : "Ocultar"}
        </div>
      )}
    </div>
  );
};

export default HotelSearchEngine;
