import './styles.css';

function DoubleTitle({ bigText = '', simpleText = '' }) {
  return (
    <div className='pqrsDoubleTitleContainer'>
      <p className='pqrsDoubleTitleContainerSimpleText'>{simpleText}</p>
      <p className='pqrsDoubleTitleContainerBigText'>{bigText}</p>
    </div>
  )
}

export default DoubleTitle;