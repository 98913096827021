import React, { useState } from 'react'
import "../styles.css"
import SvgIcon, { iconList } from '../../../../../share/components/iconSVG'

const SelectedForm = ({ options, seeSelected, setSeeSelected, selectedOption, setSelectedOption, errCamp }) => {
    return (
        <div style={{border:`${errCamp === "birthDate" ? "2px solid #c60000" : ""}`}} className='contain-selected-form-pay' onClick={()=>{
            setSeeSelected(!seeSelected)
        }}>
            <p>{selectedOption === "" ? options[0] : selectedOption}
            </p>
            <SvgIcon name={iconList.IconArrowDown} size={10} color={"#004274"}/>
            {
                seeSelected &&
                <div className='contain-options-selected'>
                    {options.map((item, index) => {
                        return (
                            <p key={index} onClick={()=>{
                                setSelectedOption(item)
                            }}>{item}</p>
                        )
                    })}
                </div>
            }

        </div>
    )
}

export default SelectedForm