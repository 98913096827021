import React, { useEffect, useState } from 'react';
import { keys, get as getLocalStorage, reset } from '../../localStorage';

export async function logOut(){
  try {
    await reset(keys.authUserSession);
    window.location.href = '/';
  } catch (error) {
    console.error("Error in logOut:", error)
  }
}

function AuthSession(){
  const [loading, setloading] = useState(true);
  const [authUser, setAuthUser] = useState(null);
  const [authToken, setAuthToken] = useState(null);


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const responseAuthUSer = getLocalStorage(keys.authUserSession);
        if(responseAuthUSer){
          setAuthUser(responseAuthUSer?.user)
          setAuthToken(responseAuthUSer?.token)
          setloading(false)
        }
      } catch (error) {
        console.error('Error in fetchUserData', error)
      }
    };
    fetchUserData();
  }, []);


  return {
    authUser,
    authToken,
}
}
export default AuthSession;
