import React, { useState } from "react";

import "./Assets/styles.css";
import { SearchNormal1 } from "iconsax-react";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import iconSearchBlue from "../../ui/assets/icons/iconSearchBlue.svg";
import iconAirBlue from "../../ui/assets/icons/iconAirBlue.svg";

const ProcedureSearch = ({ busqueda, loading }) => {
  const [keyword, setKeyword] = useState("");
  const [t, i18n] = useTranslation("global");

  return (
    <>
      <form className="form-search-universal">
        <div className="contain-input-search">
          <input
            type="text"
            placeholder={t("find.place_holder")}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            style={{ fontSize: "11px" }}
          />
        </div>
        <div
          className="button_submitSearch"
          style={{
            pointerEvents: loading === true ? "none" : "auto",
            opacity: loading === true ? 0.5 : 1,
          }}
          onClick={() => {
            busqueda(keyword);
          }}
        >
          {loading === true ? (
            <CircularProgress size={25} />
          ) : (
            <img src={iconSearchBlue} alt="search" />
          )}
        </div>
      </form>
    </>
  );
};

export default ProcedureSearch;
