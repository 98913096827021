import React from 'react'
import Clinics from '../../ui/views/clinics/Clinics'

const ClinicsRoute = () => {
  return (
    <div>
        <Clinics/>
    </div>
  )
}

export default ClinicsRoute