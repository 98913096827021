import React, { useState } from "react";
import SvgIcon, { iconList } from "../../../share/components/iconSVG";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { FaHeartCircleCheck, FaCircleCheck } from "react-icons/fa6";
import TextComponent from "../../../share/components/fonts/TextComponent";

const ServicesInclude = ({
  serviciosHot,
  serviciosHab,
  checkin,
  checkout,
  star,

  information,
  name,
  ubication,
}) => {
  const [t] = useTranslation("global");

  console.log(serviciosHot);


  const [selectedOption, setSelectedOption] = useState("Overview");
  const options = [
    "Overview",
    t("hotel.services_hotel"),
    t("hotel.services_habitation"),
    t("hotel.condition_hot"),
  ];

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const formattedInformation = information.replace(/\./g, ".<br /><br />");

  return (
    <div className="contain-services-include">
      <div className="menu">
        {options.map((option) => (
          <div
            key={option}
            className={`menu-option ${selectedOption === option ? "selected" : ""
              }`}
            onClick={() => handleOptionClick(option)}
          >
            {option}
          </div>
        ))}
      </div>

      <div>
        {selectedOption === "Overview" && (
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <TextComponent size={"md"} color={"#104172"} fontWeight="bold">
              {t("hotel.about")}
            </TextComponent>
            <p
              style={{ color: "#616161", fontSize: 14 }}
              dangerouslySetInnerHTML={{ __html: formattedInformation }}
            />
          </div>
        )}
        {selectedOption === t("hotel.services_hotel") && (
          <ul
          style={{
            color: "#616161",
            fontSize: 14,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: 15,
            justifyContent: "space-between",
          }}
        >
          {serviciosHot === undefined ? (
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: 15,
                width: "100%",
                maxWidth: "290px",
                height: "100%",
              }}
            >
              <FaHeartCircleCheck
                color={"#004274"}
                size={25}
                style={{ height: "100%" }}
              />
              {t("hotel.no_services")}
            </li>
          ) : (
            // Si `serviciosHot` es un array
            Array.isArray(serviciosHot) ? (
              serviciosHot.map((item, key) => (
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 15,
                    marginBottom: 10,
                    width: "100%",
                    maxWidth: "290px",
                    height: "100%",
                  }}
                  key={key}
                >
                  <FaCircleCheck
                    color={"#004274"}
                    size={25}
                    style={{ height: "100%" }}
                  />
                  {item?.desc_serv}
                </li>
              ))
            ) : (
              // Si `serviciosHot` es un objeto, renderiza directamente sus propiedades
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 15,
                  marginBottom: 10,
                  width: "100%",
                  maxWidth: "290px",
                  height: "100%",
                }}
                key={serviciosHot.codigo_servicio} // Usamos el código del servicio como clave
              >
                <FaCircleCheck
                  color={"#004274"}
                  size={25}
                  style={{ height: "100%" }}
                />
                {serviciosHot.desc_serv} {/* Renderiza la descripción del servicio */}
              </li>
            )
          )}
        </ul>
        
        )}

        {selectedOption === t("hotel.services_habitation") && (
          <ul
            style={{
              color: "#616161",
              fontSize: 14,
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              gap: 15,
              justifyContent: "start",
            }}
          >
            {serviciosHab === undefined ? (
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 15,
                  width: "100%",
                  maxWidth: "290px",
                  height: "100%",
                }}
              >
                <FaHeartCircleCheck
                  color={"#004274"}
                  size={25}
                  style={{ height: "100%" }}
                />
                {t("hotel.no_services")}
              </li>
            ) : (
              serviciosHab.map((item, key) => {
                return (
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 15,
                      marginBottom: 10,
                      width: "100%",
                      maxWidth: "290px",
                      height: "100%",
                    }}
                    key={key}
                  >
                    <FaCircleCheck
                      color={"#004274"}
                      size={25}
                      style={{ height: "100%" }}
                    />
                    {item?.descripciones}
                  </li>
                );
              })
            )}
          </ul>
        )}

        {selectedOption === t("hotel.condition_hot") && (
          <div className="contain-checkin">
            <div
              className="check-in-out"
              style={{ borderRight: "2px solid #eeeeee" }}
            >
              <p>{t("hotel.hour_entrance")}:</p>
              <span>
                {t("hotel.from")} {checkin}
              </span>
            </div>
            <div
              className="check-in-out"
              style={{ borderRight: "2px solid #eeeeee" }}
            >
              <p>{t("hotel.hour_departure")}:</p>
              <span>
                {t("hotel.before")} {checkout}
              </span>
            </div>
            <div className="check-in-out">
              <p>{t("hotel.breakfast")}:</p>
              <span>
                {t("hotel.to_hour")} { }
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServicesInclude;
