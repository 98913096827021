import SvgIcon, { iconList } from '../../../share/components/iconSVG'
import "./styles.css"
import { global } from '../../../../global';
import TextComponent from '../../../share/components/fonts/TextComponent';
import { useEffect, useState } from 'react';
import { currencyFormat } from '../../../../utils';
import styles from "../styles.module.css"
import { MdFlight } from 'react-icons/md';


const {
    img: {
        logos: {
            AviancaLogo,
            LatamLogo,
            VivaLogo,
        }
    }

} = global;

const DetailPayFlight = ({ info }) => {

    console.log(info);

    const { state } = info



    const [dollarFlight, setDollarFlight] = useState(localStorage.getItem("dollarFlight") === null ? "COP" : localStorage.getItem("dollarFlight"));
    const typeOfCoin = { name: dollarFlight, abbreviation: dollarFlight, simbol: '$' };

    const airlinesList = [
        {
            id: 1,
            img: VivaLogo,
            name: 'Viva Air',
            priceDirect: 1050,
            priceOneScale: 950,
            priceMoreScale: null,
        },
        {
            id: 2,
            code: "LA",
            img: LatamLogo,
            name: 'Latam Airlines',
            priceDirect: 1100,
            priceOneScale: 1080,
            priceMoreScale: null,
        },
        {
            id: 3,
            code: "AV",
            img: AviancaLogo,
            name: 'Avianca',
            priceDirect: 1120,
            priceOneScale: 1100,
            priceMoreScale: 1050,
        },
    ]

    function formatearCantidad(cantidad) {
        let imp = cantidad * 0.05;
        let total = cantidad + imp;
        const cantidadFormateada = total?.toLocaleString("es", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });

        return cantidadFormateada;
    }

    function convertirFecha(fechaStr) {
        // Separar la cadena en partes: año, mes, día, hora, minuto
        let partes = fechaStr.split(/[-T:+]/);
        let año = partes[0];
        let mes = partes[1];
        let dia = partes[2];
        let hora = partes[3];
        let minuto = partes[4];

        // Obtener el nombre completo del mes
        let meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
        let mesCompleto = meses[parseInt(mes) - 1];

        // Formatear la fecha en el formato deseado
        let fechaFormateada = `${dia} ${mesCompleto}, ${año} ${hora}:${minuto}`;

        return fechaFormateada;
    }


    const getPaymentDeadline = () => {
        const daysOfWeek = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sáb'];
        const monthsOfYear = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sept', 'oct', 'nov', 'dic'];

        const now = new Date();

        // Sumar 10 minutos
        now.setMinutes(now.getMinutes() + 10);

        // Obtener día de la semana, día del mes, mes y año
        const dayOfWeek = daysOfWeek[now.getDay()];
        const day = now.getDate().toString().padStart(2, '0');
        const month = monthsOfYear[now.getMonth()];
        const year = now.getFullYear();

        // Obtener hora y minutos
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');

        // Formato final
        return `${dayOfWeek} ${day} ${month} ${year}, ${hours}:${minutes}hrs`;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);

        // Obtener las partes individuales de la fecha
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Los meses empiezan en 0
        const year = date.getFullYear();

        // Obtener las partes de la hora
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        // Formato final
        return `${day}-${month}-${year} ${hours}:${minutes}`;
    };

    const formatTime = (dateString) => {
        const date = new Date(dateString);

        // Obtener las partes de la hora
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        // Formato final de la hora
        return `${hours}:${minutes}`;
    };



    const [isRoundTripData, setIsRoundTripData] = useState(false)

    useEffect(() => {
        if (state?.requestPNR?.itinerary[1]) {
            setIsRoundTripData(true)
        }
    }, [])

    return (
        <div className={styles.detailPayFlight}>
            <div className={styles.detailPayFlightHeader}>
                <div className={styles.detailPayFlightHeaderImage}>
                    {
                        info?.requestPNR?.company === "LA" &&
                        <img src={LatamLogo} alt={info?.company} />
                    }
                    {
                        info?.requestPNR?.company === "AV" &&
                        <img src={AviancaLogo} alt={info?.company} />
                    }
                    {
                        info?.requestPNR?.company === "VI" &&
                        <img src={VivaLogo} alt={info?.company} />
                    }
                </div>
                <div className={styles.detailPayFlightInfo}>
                    <h2>
                        {info?.requestPNR?.company === "LA"
                            ? "Latam Airlines"
                            : info?.requestPNR?.company === "AV"
                                ? "Avianca"
                                : info?.requestPNR?.company === "VI"
                                ? "Viva Air"
                                : info?.requestPNR?.company}
                    </h2>

                    <div className={styles.detailPayFlightName}>
                        <p>Vuelos {info?.requestPNR?.travellers.length}</p>
                        <span>
                        <MdFlight size={20} />    
                        </span>
                    </div>
                </div>
            </div>

            <h3>Informacion de tu vuelo</h3>

            <div className={styles.itemDetailsFlights}>
                {/* Sección de IDA */}
                <div className={styles.itemDetailsFlights1}>
                    <div className={styles.itemDetailsFlightsColumn1}>
                        <p className={styles.typeFlight}>
                            <strong>IDA</strong> {formatDate(info?.requestPNR?.itinerary[0]?.dateDeparture)}
                        </p>
                    </div>

                    <div className={styles.airlineLogo}>
                        <img
                            src={`https://pics.avs.io/60/60/${info?.requestPNR?.company}.png`}
                            alt={`${info?.requestPNR?.company} logo`}
                        />
                    </div>

                    <div className={styles.itemDetailsFlightsColumn1Footer}>
                        <div className={styles.itemDetailsFlightsColumn1FooterPlace}>
                            <p>{info?.requestPNR?.itinerary[0]?.from}</p>
                            <b>{formatTime(info?.requestPNR?.itinerary[0]?.dateDeparture)}</b>
                        </div>
                        <p>Directo</p>
                        <div className={styles.itemDetailsFlightsColumn1FooterPlace}>
                            <p>{info?.requestPNR?.itinerary[0]?.to}</p>
                            <b>{formatTime(info?.requestPNR?.itinerary[0]?.dateArrival)}</b>
                        </div>
                    </div>
                </div>

                {/* Sección de VUELTA (solo si existe) */}
                {isRoundTripData && (
                    <div className={styles.itemDetailsFlights2}>
                        <div className={styles.itemDetailsFlightsColumn1}>
                            <p className={styles.typeFlight}>
                                <strong>VUELTA</strong> {formatDate(info?.requestPNR?.itinerary[1]?.dateDeparture)}
                            </p>
                        </div>

                        <div className={styles.airlineLogo}>
                            <img
                                src={`https://pics.avs.io/60/60/${info?.requestPNR?.company}.png`}
                                alt={`${info?.requestPNR?.company} logo`}
                            />
                        </div>

                        <div className={styles.itemDetailsFlightsColumn1Footer}>
                            <div
                                className={styles.itemDetailsFlightsColumn1FooterPlace}
                            >
                                <p>{info?.requestPNR?.itinerary[1]?.from} </p>
                                <b>{formatTime(info?.requestPNR?.itinerary[1]?.dateDeparture)}</b>
                            </div>
                            <p>Directo</p>
                            <div
                                className={styles.itemDetailsFlightsColumn1FooterPlace}
                            >
                                <p>{info?.requestPNR?.itinerary[1]?.to}</p>
                                <b>{formatTime(info?.requestPNR?.itinerary[1]?.dateArrival)}</b>
                            </div>
                        </div>
                    </div>
                )}

                {!isRoundTripData && (
                    <div className={styles.itemDetailsFlights2Any}>
                        No hay vuelo de vuelta
                        <MdFlight size={20} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default DetailPayFlight