import React, { useEffect, useState, useRef } from "react";
import SvgIcon, { iconList } from "../../../../share/components/iconSVG";
import { useSSR, useTranslation } from "react-i18next";
import { Calendar as CalendarNew } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./styles.css";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import DatePicker from "react-datepicker";
import { IoIosArrowDown } from "react-icons/io";
import { FaBullseye, FaCircleCheck } from "react-icons/fa6";
import toast, { Toaster } from "react-hot-toast";
import * as locales from "react-date-range/dist/locale";

import "react-datepicker/dist/react-datepicker.css";
import TextComponent from "../../../../share/components/fonts/TextComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { addDays, addMonths, addYears } from "date-fns";


const validateNom = (nom) => {
  if (nom === "") {
    return "Este campo es obligatorio";
  }
  return "";
}

const validateLastName = (lastName) => {
  if (lastName === "") {
    return "Este campo es obligatorio";
  }
  return "";
}

const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (email === "") {
    return "Este campo es obligatorio";
  } else if (!emailRegex.test(email) || /[\s]/.test(email)) {
    return "Este campo debe ser valido";
  }

  return "";
}

const validateTel = (tel) => {
  if(tel === "") {
    return "Este campo es obligatorio";
  } else if (isNaN(tel)) {
    return "Este campo debe ser numerico";
  }

  return "";
}

const validateCountry = (country) => {
  if (country === "") {
    return "Este campo es obligatorio";
  }
  return "";
}

const validateTypeDocument = (typeDocument) => {
  if (typeDocument === "" || typeDocument === "Tipo Documento") {
    return "Este campo es obligatorio";
  }

  return "";
}

const validateDocument = (document) => {
  if (document === "") {
    return "Este campo es obligatorio";
  }

  return "";
}

const validateDateExpiration = (expiration) => {
  if (expiration === "") {
    return "Este campo es obligatorio";
  }

  return "";
}

const validateDateBirth = (birth) => {
  if (birth === "") {
    return "Este campo es obligatorio";
  }

  return "";
}

const validateOffspring = (offspring) => {
  if (offspring === "") {
    return "Este campo es obligatorio";
  }

  return "";
}

const validateSex = (sex) => {
  if (sex === "") {
    return "Este campo es obligatorio";
  }

  return "";
}

const PersonFormFlight = ({ 
  index, 
  type, 
  setPaxes, 
  typeFlight, 
  setAccordion, 
  accordion, 
  saveInfo, 
  onCount, 
  setSaveInfo, 
  savePax, 
  paxes,
  validate,
  setValidate,
  submitData,
  setSubmitData,
}) => {


  const nameMapper = {
    ar: "Arabic",
    bg: "Bulgarian",
    ca: "Catalan",
    cs: "Czech",
    cy: "Welsh",
    da: "Danish",
    de: "German",
    el: "Greek",
    enGB: "English (United Kingdom)",
    enUS: "English (United States)",
    eo: "Esperanto",
    es: "Spanish",
    et: "Estonian",
    faIR: "Persian",
    fi: "Finnish",
    fil: "Filipino",
    fr: "French",
    hi: "Hindi",
    hr: "Croatian",
    hu: "Hungarian",
    hy: "Armenian",
    id: "Indonesian",
    is: "Icelandic",
    it: "Italian",
    ja: "Japanese",
    ka: "Georgian",
    ko: "Korean",
    lt: "Lithuanian",
    lv: "Latvian",
    mk: "Macedonian",
    nb: "Norwegian Bokmål",
    nl: "Dutch",
    pl: "Polish",
    pt: "Portuguese",
    ro: "Romanian",
    ru: "Russian",
    sk: "Slovak",
    sl: "Slovenian",
    sr: "Serbian",
    sv: "Swedish",
    th: "Thai",
    tr: "Turkish",
    uk: "Ukrainian",
    vi: "Vietnamese",
    zhCN: "Chinese Simplified",
    zhTW: "Chinese Traditional",
  };

  function range(start, end, step) {
    let array = [];
    for (let i = start; i < end; i += step) {
      array.push(i);
    }
    return array;
  }

  const [hasMounted, setHasMounted] = useState(false);

  const location = useLocation();
  const [locale, setLocale] = useState("es");
  const localeOptions = Object.keys(locales)
    .map((key) => ({
      value: key,
      label: `${key} - ${nameMapper[key] || ""}`,
    }))
    .filter((item) => nameMapper[item.value]);
  const yearsExpiration = range(2023, new Date().getFullYear() + 20, 1);
  const yearsBirth = range(1943, new Date().getFullYear() + 20, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [sex, setSex] = useState("");
  const [nom, setNom] = useState("");
  const [email, setEmail] = useState(localStorage.getItem(`email${index}`) === null ? "" : localStorage.getItem(`email${index}`));
  const [tel, setTel] = useState(localStorage.getItem(`tel${index}`) === null ? "" : localStorage.getItem(`tel${index}`));
  const [lastName, setLastName] = useState(localStorage.getItem(`lastName${index}`) === null ? "" : localStorage.getItem(`lastName${index}`));
  const [age, setAge] = useState("");
  const [t] = useTranslation("global");
  const [addHErr, setAddHErr] = useState(false);
  const [error, setError] = useState("");
  const [checkSuccess, setCheckSuccess] = useState(false);
  const [typeDocument, setTypeDocument] = useState("");
  const [Document, setDocument] = useState("");
  const dateMax = new Date();
  const [errors, setErrors] = useState({
    nom: "",
    lastName: "",
    email: "",
    tel: "",
    country: "",
    typeDocument: "",
    document: "",
    expiration: "",
    birth: "",
    offspring: "",
    sex: "",
  });

  dateMax.setDate(dateMax.getDate() + 360);

  const [modalTypeDoc, setModalTypeDoc] = useState(false);
  const [birth, setBirth] = useState();
  const [expiration, setExpiration] = useState("");
  const [offspring, setOffspring] = useState("");
  const [code, setCode] = useState("");
  const [country, setCountry] = useState("");
  const [allCountries, setAllCountries] = useState([]);
  const [search, setSearch] = useState('');
  const [modalCountry, setModalCountry] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [startDateEx, setStartDateEx] = useState(localStorage.getItem(`startDateEx${index}`) === null ? new Date() : new Date(localStorage.getItem(`startDateEx${index}`)));
  const [dateDoc, setDateDoc] = useState(localStorage.getItem(`startDateEx${index}`) === null ? addMonths(new Date(),1) : new Date(localStorage.getItem(`startDateEx${index}`)));
  const [startDateBi, setStartDateBi] = useState(localStorage.getItem(`startDateBi${index}`) === null ? new Date() : new Date(localStorage.getItem(`startDateBi${index}`)));
  const [conditionSave, setConditionSave] = useState(false)
  const [date, setDate] = useState(localStorage.getItem(`startDateBi${index}`) === null ? type === "adult" ? addMonths(new Date(),-144) : type === "child" ? addYears(new Date(), -2) : type === "baby" || type === "seat" ? addDays(new Date(), -1):"" : new Date(localStorage.getItem(`startDateBi${index}`)))

  const handleBlur = (field, value) => {
    let error = "";

    switch (field) {
      case "nom":
        error = validateNom(value);
        break;
      case "lastName":
        error = validateLastName(value);
        break;
      case "email":
        error = validateEmail(value);
        break;
      case "tel":
        error = validateTel(value);
        break;
      case "country":
        error = validateCountry(value);
        break;
      case "typeDocument":
        error = validateTypeDocument(value);
        break;
      case "document":
        error = validateDocument(value);
        break;
      case "expiration":
        error = validateDateExpiration(value);
        break;
      case "birth":
        error = validateDateBirth(value);
        break;
      case "offspring":
        error = validateOffspring(value);
        break;
      case "sex":
        error = validateSex(value);
        break;
      default:
        break;
      }

      setErrors((prev) => ({ ...prev, [field]: error }));
  };
  const listTypeDocColombia = [
    {
      value: "ID",
      field: "Número de identificación",
    },
    {
      value: "PP",
      field: "Pasaporte",
    },
  ];
  const listTypeDocInternational = [
    {
      value: "PP",
      field: "Pasaporte",
    },
  ];

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11, so we add 1
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    onChangeOnlyDateOne(date);
    onChangeOnlyDateOneDoc(dateDoc);
  }, []);

  const onChangeOnlyDateOne = (item) => {

    setBirth(formatDate(item));
    localStorage.setItem(`birth${index}`, formatDate(item))
    localStorage.setItem(`startDateBi${index}`, item)
    setDate(item)
  };

  const onChangeOnlyDateOneDoc = (item) => {
    setExpiration(formatDate(item));
    localStorage.setItem(`expiration${index}`, formatDate(item))
    localStorage.setItem(`startDateEx${index}`, item)
    setDateDoc(item)
  };

  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const calendarRef = useRef(null);
  const inputRef = useRef(null);

  const [isCalendarVisibleDoc, setIsCalendarVisibleDoc] = useState(false);
  const calendarDocRef = useRef(null);
  const inputDocRef = useRef(null);

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
      setIsCalendarVisible(false);
    }
  };

  const handleClickOutsideDoc = (event) => {
    if (calendarDocRef.current && !calendarDocRef.current.contains(event.target) && !inputDocRef.current.contains(event.target)) {
      setIsCalendarVisibleDoc(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('mousedown', handleClickOutsideDoc);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mousedown', handleClickOutsideDoc);
    };
  }, []);

  const notify = (title, icon) =>
    toast(title, {
      icon: icon,
    });

  useEffect(() => {
    const hasErrors = (type === "adult" &&
      (
        nom === "" ||
        lastName === "" ||
        email === "" ||
        tel === "" ||
        country === "" ||
        typeDocument === "" ||
        typeDocument === "Tipo Documento" ||
        Document === "" ||
        (typeFlight === true && expiration === "") ||
        birth === "" ||
        sex === ""
      ) &&
      index === 1
    ) || (type === "adult" &&
      (
        nom === "" ||
        lastName === "" ||
        country === "" ||
        typeDocument === "" ||
        typeDocument === "Tipo Documento" ||
        Document === "" ||
        (typeFlight === true && expiration === "") ||
        birth === "" ||
        sex === ""
      )
    ) || (type === "child" &&
      (
        nom === "" ||
        lastName === "" ||
        country === "" ||
        typeDocument === "" ||
        typeDocument === "Tipo Documento" ||
        Document === "" ||
        (typeFlight === true && expiration === "") ||
        birth === "" ||
        sex === ""
      )
    ) || (type === "seat" &&
      (
        nom === "" ||
        lastName === "" ||
        country === "" ||
        typeDocument === "" ||
        typeDocument === "Tipo Documento" ||
        Document === "" ||
        (typeFlight === true && expiration === "") ||
        birth === "" ||
        offspring === "" ||
        sex === ""
      )
    ) || (type === "baby" &&
      (
        nom === "" ||
        lastName === "" ||
        country === "" ||
        typeDocument === "" ||
        typeDocument === "Tipo Documento" ||
        Document === "" ||
        (typeFlight === true && expiration === "") ||
        birth === "" ||
        offspring === "" ||
        sex === ""
      )
    );

    if (hasErrors) {
      setAddHErr(true);
      setCheckSuccess(false);
    }else{
      setAddHErr(false);
      const newPaxes = {
        name: nom,
        lastname: lastName,
        country: country,
        typeDoc: typeDocument,
        doc: Document,
        expiration: expiration,
        birth: birth,
        sex: sex,
        ...(
          index === 1 && { 
            email: email,
            tel: code + tel, 
          }
        ),
        ...(
          type === "adult" && { adult: "ADT" }
        ),
        ...(
          type === "child" && { child: "CHD" }
        ),
        ...(
          type === "seat" && { seat: "SEAT", offspring: offspring }
        ),
        ...(
          type === "baby" && { baby: "Baby" }
        ),
      };
      setPaxes((prevList) => [...prevList, newPaxes]);
      setCheckSuccess(true);
    }
  }, 
  [
    nom, 
    lastName, 
    birth, 
    Document, 
    typeDocument,  
    email, 
    tel,
    expiration,
    birth,
    offspring,
    sex
  ]);

  const validateForm = () => {
    const nomError = validateNom(nom);
    const lastNameError = validateLastName(lastName);
    const emailError = validateEmail(email);
    const telError = validateTel(tel);
    const countryError = validateCountry(country);
    const typeDocumentError = validateTypeDocument(typeDocument);
    const documentError = validateDocument(Document);
    const expirationError = validateDateExpiration(expiration);
    const birthError = validateDateBirth(birth);
    const offspringError = validateOffspring(offspring);
    const sexError = validateSex(sex)

    setErrors({
      nom: nomError,
      lastName: lastNameError,
      email: emailError,
      tel: telError,
      country: countryError,
      typeDocument: typeDocumentError,
      document: documentError,
      expiration: expirationError,
      birth: birthError,
      offspring: offspringError,
      sex: sexError
    })

    if (type === "adult" && (nomError || lastNameError || emailError || telError || countryError || typeDocumentError || documentError || (typeFlight === true && expirationError) || birthError || sexError) && index === 1) {
      return false;
    } else if (type === "adult" && (nomError || lastNameError || countryError || typeDocumentError || documentError || (typeFlight === true && expirationError) || birthError || sexError)) {
      return false;
    }else if (type === "child" && (nomError || lastNameError || countryError || typeDocumentError || documentError || (typeFlight === true && expirationError) || birthError || sexError)) {
      return false;
    }else if (type === "seat" && (nomError || lastNameError || countryError || typeDocumentError || documentError || (typeFlight === true && expirationError) || birthError || offspringError || sexError)) {
      return false;
    }else if (type === "baby" && (nomError || lastNameError || countryError || typeDocumentError || documentError || (typeFlight === true && expirationError) || birthError || offspringError || sexError)) {
      return false;
    }

    return true;
  }

  useEffect(() => {

    if (submitData) {
      setSubmitData(false);
      setValidate(validateForm());
    }
  }, [submitData]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const searchCountry = async () => {
      try {
        if (search === "") {
          setLoadingSearch(false);
          return;
        } else {
          const url = `${process.env.REACT_APP_URL_API_FLY}countries/v1`;
          setLoadingSearch(true);
          setModalCountry(false);
          const result = await axios.post(
            url,
            { code: `${search}` },
            { cancelToken: source.token }
          );
          if (result?.data?.length === 0) {
            // setError(true)
            setLoadingSearch(false);
            // setSearch(`Invalido: ${search}`)
            setModalCountry(true);
            setAllCountries([{ nameCountry: "Pais no encontrado" }]);
          } else {
            setLoadingSearch(false);
            setModalCountry(true);
            setAllCountries(result.data);
          }
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("Error in searchCity: ", error);
          setLoadingSearch(false);
          setAllCountries([{ nameCountry: "Error en la peticion" }]);
          setModalCountry(true);
          notify("Error al obtener el pais", "❌");
        }
      }
    };

    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          func(...args);
        }, delay);
      };
    };

    const debouncedSearchCountry = debounce(searchCountry, 2000);

    debouncedSearchCountry();

    return () => {
      source.cancel("Componente desmontado. Cancelar solicitudes pendientes.");
    };
  }, [search, error]);

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <section
        className="accordion-detail-form-hotel"
        style={{
          backgroundColor: `${checkSuccess ? "var(--greenPrimary)" : ""}`,
        }}
        onClick={() => {
          setAccordion(index);
        }}
      >
        <div>
          <TextComponent size={"md"} fontWeight="bold" tag="h2">
            {type === "child" ? "Niño" : type === "adult" ? "Adulto" : "Bebe"}{" "}
            {index}
          </TextComponent>
          {
            type === "adult" && index === 1 ?
              <TextComponent size={"sm"} fontWeight="bold">
                Pasajero a cargo
              </TextComponent>
              : ""
          }
          {type === "baby" ? (
            <TextComponent size={"sm"} fontWeight="bold">
              Bebe sin asiento
            </TextComponent>
          ) : type === "seat" ? (
            <TextComponent size={"sm"} fontWeight="bold">
              Bebe con asiento
            </TextComponent>
          ) : (
            ""
          )}
        </div>
        <span className="circle-icon-accordion">
          {checkSuccess ? (
            <FaCircleCheck color={"var(--white)"} size={20} />
          ) : accordion === index ? (
            <IoIosArrowDown
              style={{ rotate: "180deg" }}
              color={"var(--white)"}
              size={20}
            />
          ) : (
            <IoIosArrowDown color={"var(--white)"} size={20} />
          )}
        </span>
      </section>
      <div className={`form-persons-pay-flight${accordion !== index ? "-close" : ""}`}>
        <div  className={ errors.nom ? 'validate-input-voucher camp-form-person-hotel' : 'camp-form-person-hotel' }>
          <label htmlFor="">{t("fligth.names")} *</label>
          <div>
            <input
              type="text"
              onChange={(e) => {
                setNom(e.target.value);
                handleBlur("nom", e.target.value);
              }}
              style={{ width: "100%" }}
              value={nom === false ? "" : nom}
              placeholder={
                index === 1 && type === "adult"
                  ? t("fligth.placeholder_name")
                  : t("fligth.name")
              }
            />
            {errors.nom && <p className="span-simple-error">{ errors.nom }</p>}
          </div>
        </div>
        <div className={ errors.lastName ? "validate-input-voucher camp-form-person-hotel" : "camp-form-person-hotel" }>
          <label htmlFor="">{t("fligth.lastnames")} *</label>
          <div>
            <input
              type="text"
              onChange={(e) => {
                setLastName(e.target.value);
                handleBlur("lastName", e.target.value);
              }}
              value={lastName === false ? "" : lastName}
              placeholder={
                index === 1 && type === "adult"
                  ? t("fligth.placeholder_name")
                  : "Apellido"
              }
              style={{ width: "100%" }}
            />
            { errors.lastName && <p className="span-simple-error">{errors.lastName}</p> }
          </div>
        </div>
        {
          type === "adult" && index === 1 ?
          <div className={ errors.email ? "validate-input-voucher camp-form-person-hotel" : "camp-form-person-hotel" }>
              <label htmlFor="">Email *</label>
              <div>
                <input
                  type="text"
                  placeholder="Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    handleBlur("email", e.target.value);
                  }}
                  value={email === false ? "" : email}
                  style={{ width: "100%" }}
                />
                { errors.email && <p className="span-simple-error">{errors.email}</p> }
              </div>
          </div>
          : null
        }


        {
          type === "adult" && index === 1 ?
            <div className={ errors.tel ? "validate-input-voucher camp-form-person-hotel" : "camp-form-person-hotel" }>
              <label htmlFor="">Telefono *</label>
              <div>
                <input
                  type="text"
                  placeholder="Phone"
                  onChange={(e) => {
                    const value = e.target.value;
                    const onlyNumbers = value.replace(/\D/g, "");

                    setTel(onlyNumbers);
                    handleBlur("tel", onlyNumbers);
                  }}
                  value={tel === false ? "" : tel}
                  style={{ width: "100%" }}
                />
                { errors.tel && <p className="span-simple-error">{errors.tel}</p> }
              </div>
            </div>
            : ""
        }



        <div className={ errors.country ? 'validate-input-voucher camp-form-person-hotel' : 'camp-form-person-hotel' }>
          <label htmlFor="">{t("fligth.country")} *</label>
          {country === "" ? (
            <div style={{ position: 'relative', width: '100%' }}>
              <div>
                <input
                  type="text"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    handleBlur("country", e.target.value);
                  }}
                  placeholder={t("fligth.country")}
                  value={search === false ? "" : search}
                  style={{ width: '100%'}}

                />
                {errors.country && <p className="span-simple-error">{errors.country}</p> }
              </div>
              {loadingSearch && (
                <div className="modal-countries" style={{ paddingBlock: "10px", paddingInline: "30px", width: "100%"}}>
                  <CircularProgress
                    size={20}
                    style={{
                      borderRadius: "50%",
                      backgroundColor: "white",
                      zIndex: "9",
                    }}
                  />
                </div>
              )}
              {modalCountry && (
                <div className="modal-countries" style={{ width: "100%" }}>
                  <h4>Paises encontrados {allCountries.length}</h4>
                  <ul>
                    {allCountries.map((item, key) => {
                      return (
                        <li
                          key={key}
                          onClick={() => {
                            setCountry(item?.codeIso3Country);
                            setSearch(item?.nameCountry);
                            localStorage.setItem(`search${index}`, item?.nameCountry)
                            setModalCountry(false);
                            localStorage.setItem(`country${index}`, item?.codeIso3Country)
                          }}
                        >
                          {item?.nameCountry}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <div>
              <input
                type="text"
                value={search === false ? "" : search}
                onClick={() => {
                  setCountry("");
                  setSearch("");
                  setModalCountry(false);
                  handleBlur("country", "");
                }}
                placeholder={t("fligth.country")}
                style={{ width: "100%" }}
                readOnly
              />
              {errors.country && <p className="span-simple-error">{errors.country}</p> }
            </div>

          )}
        </div>

        <div className="camp-form-person-hotel-doc">
          <label htmlFor="">{t("fligth.type_number_document")} *</label>
          <div className={ errors.document ? "validate-input-voucher contain-inputs-selecteds" : "contain-inputs-selecteds" } style={{ flexWrap: "wrap", gap: "0px" }}>
            <div className="select-type-doc" style={{ borderColor: errors.typeDocument ? "var(--redPrimary)" : "", cursor: "pointer" }}
              onClick={() => {
                setModalTypeDoc(!modalTypeDoc);
              }}
            >
              <div
                className="select-onclick-modal-type"
              >
                <span>{ errors.typeDocument || typeDocument == "" ? "Tipo documento" : typeDocument}</span>
                <SvgIcon
                  color={"rgb(99, 99, 99)"}
                  name={iconList.IconArrowDown}
                  size={15}
                />
              </div>

              {modalTypeDoc && (
                <div className="modal-select-type-doc-flight">
                  <ul>
                    {typeFlight
                      ? listTypeDocInternational.map((item, indext) => {
                        return (
                          <li
                            key={indext}
                            onClick={() => {
                              setTypeDocument(item?.value);
                              localStorage.setItem(`typeDocument${index}`, item?.value)
                              setModalTypeDoc(false);
                              handleBlur("typeDocument", item?.value);
                            }}
                          >
                            {item?.field}
                          </li>
                        );
                      })
                      : listTypeDocColombia.map((item, indext) => {
                        return (
                          <li
                            key={indext}
                            onClick={() => {
                              setTypeDocument(item?.value);
                              localStorage.setItem(`typeDocument${index}`, item?.value)
                              setModalTypeDoc(false);
                              handleBlur("typeDocument", item?.value);
                            }}
                          >
                            {item?.field}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              )}
            </div>
            <input
              type="text"
              placeholder="Document"
              value={Document === false ? "" : Document}
              onChange={(e) => {
                setDocument(e.target.value);
                localStorage.setItem(`document${index}`, e.target.value)
                handleBlur("document", e.target.value);
              }}
              style={{ width: "68%" }}
            />
            {errors.document || errors.typeDocument ? <p className="span-simple-error">{ errors.document }</p> : ""}
          </div>
        </div>
        {typeFlight === true && (
          <div className={ errors.expiration ? "validate-input-voucher camp-form-person-hotel" : "camp-form-person-hotel" } style={{ flexWrap: "wrap", gap: "0px" }}>
            <label htmlFor="">{ t("fligth.document_expiration_date") } *</label>
            <input
              type="text"
              ref={inputDocRef}
              style={{ width: "100%" }}
              value={formatDate(dateDoc)}
              onClick={() => setIsCalendarVisibleDoc(true)}
              readOnly
            />
            <div className={`calendar-date-form-passenger${!isCalendarVisibleDoc ? "-close" : ""}`} ref={calendarDocRef}>
              <CalendarNew
                minDate={addMonths(new Date(), 1)}
                locale={locales[locale]}
                onChange={(date) => {
                  onChangeOnlyDateOneDoc(date);
                  setIsCalendarVisibleDoc(false);
                  handleBlur("expiration", date);
                }}
                date={dateDoc} // Mostrar fecha de ida seleccionada
              />
            </div>
            {errors.expiration ? <p className="span-simple-error">{ errors.expiration }</p> : ""}
          </div>
        )}

        {type === "adult" && (
          <div className={ errors.birth ? "validate-input-voucher camp-form-person-hotel" : "camp-form-person-hotel" } style={{ flexWrap: "wrap", gap: "0px" }}>
            <label htmlFor="">{t("fligth.birth_date")} *</label>
            <input
              type="text"
              ref={inputRef}
              style={{ width: "100%" }}
              value={formatDate(date)}
              onClick={() => setIsCalendarVisible(true)}
              readOnly
            />
            <div className={`calendar-date-form-passenger${!isCalendarVisible ? "-close" : ""}`} ref={calendarRef}>
              <CalendarNew
                minDate={new Date(1920, 0, 1)}
                maxDate={addMonths(new Date(), -144)}
                locale={locales[locale]}
                onChange={(date) => {
                  onChangeOnlyDateOne(date);
                  setIsCalendarVisible(false);
                }}
                date={date} // Mostrar fecha de ida seleccionada
              />
            </div>
            {errors.birth && <p className="span-simple-error">{ errors.birth }</p>}
          </div>
        )}

        {type === "child" && (
          <div className={ errors.birth ? "validate-input-voucher camp-form-person-hotel" : "camp-form-person-hotel" } style={{ flexWrap: "wrap", gap: "0px" }}>
            <label htmlFor="">{t("fligth.birth_date")} *</label>
            <input
              type="text"
              ref={inputRef}
              style={{ width: "100%" }}
              value={formatDate(date)}
              onClick={() => setIsCalendarVisible(true)}
              readOnly
            />
            <div className={`calendar-date-form-passenger${!isCalendarVisible ? "-close" : ""}`} ref={calendarRef}>
              <CalendarNew
                minDate={addYears(new Date(), -11)}
                maxDate={addYears(new Date(), -2)}
                locale={locales[locale]}
                onChange={(date) => {
                  onChangeOnlyDateOne(date);
                  setIsCalendarVisible(false);
                }}
                date={date} // Mostrar fecha de ida seleccionada
              />
            </div>
            {errors.birth && <p className="span-simple-error">{ errors.birth }</p>}
          </div>
        )}
        {type === "baby" ? (
          <div className={ errors.birth ? "validate-input-voucher camp-form-person-hotel" : "camp-form-person-hotel" } style={{ flexWrap: "wrap", gap: "0px" }}>
            <label htmlFor="">{t("fligth.birth_date")} *</label>
            <input
              type="text"
              ref={inputRef}
              style={{ width: "100%" }}
              value={formatDate(date)}
              onClick={() => setIsCalendarVisible(true)}
              readOnly
            />
            <div className={`calendar-date-form-passenger${!isCalendarVisible ? "-close" : ""}`} ref={calendarRef}>
              <CalendarNew
                minDate={addMonths(new Date(), -23)}
                maxDate={addDays(new Date(), -1)}
                locale={locales[locale]}
                onChange={(date) => {
                  onChangeOnlyDateOne(date);
                  setIsCalendarVisible(false);
                }}
                date={date} // Mostrar fecha de ida seleccionada
              />
            </div>
            {errors.birth && <p className="span-simple-error">{ errors.birth }</p>}
          </div>
        ) : type === "seat" ? (
          <div className={ errors.birth ? "validate-input-voucher camp-form-person-hotel" : "camp-form-person-hotel" } style={{ flexWrap: "wrap", gap: "0px" }}>
            <label htmlFor="">{t("fligth.birth_date")} *</label>
            <input
              type="text"
              ref={inputRef}
              style={{ width: "100%" }}
              value={formatDate(date)}
              onClick={() => setIsCalendarVisible(true)}
              readOnly
            />
            <div className={`calendar-date-form-passenger${!isCalendarVisible ? "-close" : ""}`} ref={calendarRef}>
              <CalendarNew
                minDate={addMonths(new Date(), -23)}
                maxDate={addDays(new Date(), -1)}
                locale={locales[locale]}
                onChange={(date) => {
                  onChangeOnlyDateOne(date);
                  setIsCalendarVisible(false);
                }}
                date={date} // Mostrar fecha de ida seleccionada
              />
            </div>
            {errors.birth && <p className="span-simple-error">{ errors.birth }</p>}
          </div>
        ) : (
          ""
        )}

        {type === "baby" ? (
          <div className={ errors.offspring ? "validate-input-voucher camp-form-person-hotel" : "camp-form-person-hotel" } style={{ flexWrap: "wrap", gap: "0px" }}>
            <label htmlFor="">{t("fligth.relationship")} *</label>
            <select
              name=""
              id=""
              style={{ width: "100%" }}
              onChange={(e) => {
                setOffspring(e.target.value);
                handleBlur("offspring", e.target.value);
              }}
              value={offspring}
            >
              <option value="">Seleccione</option>
              <option value="true">Si</option>
              <option value="false">No</option>
            </select>
            {errors.offspring && <p className="span-simple-error">{ errors.offspring }</p>}
          </div>
        ) : type === "seat" ? (
          <div className={ errors.offspring ? "validate-input-voucher camp-form-person-hotel" : "camp-form-person-hotel" } style={{ flexWrap: "wrap", gap: "0px" }}>
            <label htmlFor="">{t("fligth.relationship")} *</label>
            <select
              name=""
              id=""
              style={{ width: "100%" }}
              onChange={(e) => {
                setOffspring(e.target.value);
                handleBlur("offspring", e.target.value);
              }}
            >
              <option value="">Seleccione</option>
              <option value="true">Si</option>
              <option value="false">No</option>
            </select>
            {errors.offspring && <p className="span-simple-error">{ errors.offspring }</p>}
          </div>
        ) : (
          ""
        )}

        <div className="camp-form-person-hotel">
          <label htmlFor="">Sexo *</label>
          <div className="contain-sex-selected">
            <section
              className="sex-selected-pay"
              onClick={() => {
                setSex("F");
                handleBlur("sex", "F");
                localStorage.setItem(`sex${index}`, "F")
              }}
            >
              <div>
                <span
                  style={{
                    backgroundColor: `${sex === "F" ? "var(--bluePrimary)" : ""}`,
                  }}
                ></span>
              </div>

              <p style={{ color: "var(--bluePrimary)" }}>Femenino</p>
            </section>
            <section
              className="sex-selected-pay"
              onClick={() => {
                setSex("M");
                handleBlur("sex", "M");
                localStorage.setItem(`sex${index}`, "M")
              }}
            >
              <div>
                <span
                  style={{
                    backgroundColor: `${sex === "M" ? "var(--bluePrimary)" : ""}`,
                  }}
                ></span>
              </div>

              <p style={{ color: "var(--bluePrimary)" }}>Masculino</p>
            </section>
          </div>
          {errors.sex && <p className="span-simple-error">{ errors.sex }</p>}
        </div>

      </div>

    </div>
  );
};

export default PersonFormFlight;
