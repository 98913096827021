import React, { useState } from "react";
import "./Assets/styles.css";

function InputSimple({label, onInputChange}){

    const handleInputChange = (e) => {
        const value = e.target.value;
        onInputChange(value);
    };

    return(
        <div>
            <div className={"input-data-simple"}>
                <label htmlFor="">{label}</label>
                <input type="text"  onChange={handleInputChange}/>
            </div>
        </div>
    )
}

export default InputSimple;