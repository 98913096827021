import { useState, useRef, useEffect, useContext } from 'react';
import UserContext from '../../../../Context/user/userProvider';
import {wellezy_api} from '../../../../infra/api';
import { doctorURL } from '../../../../infra/urls';

function useCommercialProfileVideos() {
  const { userDetail, userToken } = useContext(UserContext);
  const fileInputRef = useRef(null);
  const sizeMax = 300;
  const maxNumberFiles = 10;
  const [error, setError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState('');
  const [isShoModal, setShowModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  function handleClick() {
    fileInputRef.current.click();
  };

  const handleChange = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > maxNumberFiles) {
      return setError(`Solo se permiten subir hasta ${maxNumberFiles} videos a la vez.`);
    } else {
      files.forEach((file, index) => {
        file.id = index;
        if (file.size > sizeMax * 1024) {
          file.errorMsg = `El peso máximo permitido por video es de ${sizeMax} KB.`;
        }
      });
    }
    setSelectedFiles(files);
  };

  function roundUpNumber(number) {
    const value = number / 1024
    const rounUp = value.toFixed(2);
    return `${rounUp} KB`;
  }

  function deleteFile(id) {
    const thoseRemaining = selectedFiles.filter((file) => file.id !== id);
    setSelectedFiles([...thoseRemaining]);
  }

  async function saveVideos() {
    if (isSaving) return;
    try {
      setIsSaving(true)
      const formData = new FormData();
      formData.append('user_id', userDetail.id);
      selectedFiles.forEach((video, index) => {
        formData.append('videos[]', video);
      });
      const url = doctorURL?.post?.uploadVideos;
      const result = await wellezy_api.post(url, formData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'multipart/form-data'
          },
        });
      if (result) {
        setSelectedFiles([]);
        setMessage(result?.data?.message)
        setShowModal(true);
      }
    } catch (error) {
      console.log("Error in saveVideos()", error)
    }
    finally {
      setIsSaving(false)
    }
  }

  useEffect(()=>{
    if(!isShoModal && message!== ''){
      setError(false);
      setMessage('');
    }
  },[isShoModal])

  

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setError(false);
      setMessage('');
    }
  }, [selectedFiles])

  return {
    isSaving,
    fileInputRef,
    handleClick,
    handleChange,
    error,
    message,
    isShoModal,
    setShowModal,
    selectedFiles,
    roundUpNumber,
    deleteFile,
    saveVideos,
  }
}

export default useCommercialProfileVideos;
