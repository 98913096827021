import React, { useEffect, useState } from 'react';

const AllCampsForm = () => {

  const [formData, setFormData] = useState({
  });


  const [FormErrors, setFormErrors] = useState({
  })


  const obtenerEstadoInicial = (formularios) => {
    const estadoInicial = {};
  
    formularios?.formulario?.forEach(campo => {
      if (campo.type === 'checkbox') {
        if (campo.options) {
          // Para checkboxes con opciones, inicializa cada opción con false
          campo.options.forEach(option => {
            estadoInicial[option.key] = false; // Inicializa cada opción
          });
        } else {
          // Para un solo checkbox
          estadoInicial[campo.key] = false;
        }
      } else if (campo.type === 'text' || campo.type === 'date') {
        estadoInicial[campo.key] = ""; // Inicializa como cadena vacía
      } else if (campo.type === 'select') {
        estadoInicial[campo.key] = ""; // O valor por defecto para select
      }
    });
  
    // Retorna el estado inicial y el nombre del procedimiento
    return { data: estadoInicial, pro: formularios.procedimiento };
  };
  

  const manejarCambio = (e, key, isCheckbox = false, procedimiento, camps) => {
    const value = isCheckbox ? e.target.checked : e.target.value;

    // Actualizar los datos del procedimiento correspondiente
    setFormData(prevData => {
        const updatedData = {
            ...prevData,
            [procedimiento]: {
                ...prevData[procedimiento],
                [key]: value
            }
        };

        // Revisar si el campo tiene dependencias
        camps?.formulario.forEach(campo => {
            if (campo.dependsOn && campo.dependsOn.key === key) {
                // Habilitar o deshabilitar el campo dependiente
                const isDependentFieldEnabled = value === campo.dependsOn.value;
                updatedData[procedimiento][campo.key] = isDependentFieldEnabled ? '' : null;
            }
        });

        return updatedData;
    });

    // Validar si el campo es requerido y está vacío
    const campo = camps?.formulario.find(campo => campo.key === key);
    if (campo?.required && !value) {
        setFormErrors(prevErrors => ({
            ...prevErrors,
            [procedimiento]: {
                ...prevErrors[procedimiento],
                [key]: 'Este campo es obligatorio'
            }
        }));
    } else {
        setFormErrors(prevErrors => ({
            ...prevErrors,
            [procedimiento]: {
                ...prevErrors[procedimiento],
                [key]: ''
            }
        }));
    }

    // Validar dependencias (si el campo dependiente está habilitado, se vuelve obligatorio)
    camps?.formulario.forEach(campo => {
        if (campo.dependsOn && campo.dependsOn.key === key) {
            const isDependentFieldEnabled = value === campo.dependsOn.value;
            const dependentValue = formData?.[procedimiento]?.[campo.key];

            if (isDependentFieldEnabled && campo.required && !dependentValue) {
                setFormErrors(prevErrors => ({
                    ...prevErrors,
                    [procedimiento]: {
                        ...prevErrors[procedimiento],
                        [campo.key]: 'Este campo es obligatorio'
                    }
                }));
            } else {
                setFormErrors(prevErrors => ({
                    ...prevErrors,
                    [procedimiento]: {
                        ...prevErrors[procedimiento],
                        [campo.key]: ''
                    }
                }));
            }
        }
    });
};




  const cirugiasConfig = {
    mamoplastia: {
      keywords: ["mamaria", "mamoplastia", "mama", "maxtopexia","pexia"],
      require_images: [
        "https://res.cloudinary.com/dovavvnjx/image/upload/v1727208766/PASOS_CIRUGI%CC%81AS_PASO_2_CIRUGI%CC%81A_MAMARIA_h4jzf2.jpg",
        "https://res.cloudinary.com/dovavvnjx/image/upload/v1727208766/PASOS_CIRUGI%CC%81AS_PASO_1_CIRUGI%CC%81A_CORPORAL_yqlmn9.jpg"
      ],
      formulario: [
        { label: "¿Cual es tu talla de brazier actual (aproximadamente)?", key: "size_brazier", type:'text', required: true },
        { label: '¿Qué talla de brazier deseas alcanzar?', key: "want_size", type: 'text', required: true },
        {
          label: '¿Qué esperas lograr?',
          key: 'hope_to_achieve',
          type: 'checkbox',
          options: [
            { key: 'add_size', label: 'Agregar tamaño' },
            { key: 'reduce_size', label: 'Reducir tamaño' },
            { key: 'get_up', label: 'Levantar' },
            { key: 'same_size_firmer', label: 'Mismo tamaño pero más firme' },
            { key: 'correct_asymmetry', label: 'Corregir asimetría' },
            { key: 'reduce_nipple', label: 'Reducir tamaño de los pezones' },
            { key: 'others', label: 'Otros' }
          ],
          required: false
        },
        {
          label: "¿Te has realizado alguna cirugía mamaria previamente?",
          key: "performed_breast_surgery",
          type: 'checkbox',
          required: false
        },
        {
          label: "Por favor detalla el tipo de cirugía y fecha",
          key: "performed_breast_surgery_description",
          type: 'text',
          required: false,
          dependsOn:{
            key: "performed_breast_surgery",
            value: true,  // Habilitar solo si el campo 'performed_breast_surgery' es true
          }
        },
        {
          label: "¿Sabes en qué lugar esta puesto tu implante?",
          key: "know_implant_place",
          type: 'select',
          options: [
            "Glandular",
            "Submuscular",
            "Muscular"
          ],
          dependsOn:{
            key: "performed_breast_surgery",
            value: true,  // Habilitar solo si el campo 'performed_breast_surgery' es true
          },
          required: false
        },
        {
          label: "¿Consideras hacerte una reducción o levantamiento?",
          key: "consider_reduction_lift",
          type: 'checkbox',
          required: false
        },
        {
          label: "¿Cuál es tu mayor preocupación o duda?",
          key: 'biggest_concern_doubt',
          type: 'checkbox',
          options: [
            { key: 'scars', label: 'Cicatrices' },
            { key: 'specialist_suitability', label: 'Adecuación del especialista' },
            { key: 'security_clearance', label: 'Seguridad del procedimiento' },
            { key: 'expected_results', label: 'Resultados esperados' },
            { key: 'recovery_time', label: 'Tiempo de recuperación' },
            { key: 'info_about_implants', label: 'Información sobre implantes' },
            { key: 'info_about_techniques', label: 'Información sobre técnicas' }
          ],
          required: false
        },
        {
          label: "¿Hay algo más que consideres importante mencionar?",
          key: "something_you_consider_important",
          type: 'text',
          required: false
        },
        {
          label: "¿Alguien te recomendó nuestros servicios?",
          key: "someone_recommended_us",
          type: 'checkbox',
          required: false
        },
        {
          label: "¿Quien nos recomendo?",
          key: "recommend_description",
          type: 'text',
          dependsOn:{
            key: "someone_recommended_us",
            value: true,  // Habilitar solo si el campo 'performed_breast_surgery' es true
          },
          required: false,
        }
      ]
    },
    rinoplastia: {
      keywords: ["rinoplastia","rino"],
      formulario: [

        {
          label: "¿Has sufrido fracturas de nariz?",
          key: "someone_recommended_us",
          type: 'checkbox',
          required: false
        },
        {
          label: "¿Quien nos recomendo? (si aplica)",
          key: "recommend_description",
          type: 'text',
          required: false
        }

      ]
    },
    liposuccion: {
      keywords: ["lipo","liposuccion","lipoabdominoplastia"],
      formulario: [
        {
          label: "¿Te has echo alguna lipo?",
          key: "someone_recommended_us",
          type: 'checkbox',
          required: false
        },
        {
          label: "¿Quien nos recomendo? (si aplica)",
          key: "recommend_description",
          type: 'text',
          required: true
        }

      ]
    }
  };


  const obtenerFormulariosPorTexto = (texto) => {
    const formulariosEncontrados = [];
  
    // Recorrer las configuraciones de las cirugías
    Object.keys(cirugiasConfig).forEach(procedimiento => {
      const { keywords, formulario, require_images } = cirugiasConfig[procedimiento];
  
      // Verificar si alguna palabra clave coincide con el texto ingresado
      if (keywords.some(keyword => texto.toLowerCase().includes(keyword))) {
        // Si hay coincidencias, agregar el procedimiento y el formulario encontrado al array
        formulariosEncontrados.push({
          procedimiento,   // Aquí se añade el nombre del procedimiento (ej: "Rinoplastia")
          formulario,       // Aquí se añade el formulario correspondiente
          require_images
        });
      }
    });
  
    return formulariosEncontrados;
  };
  

  return {
    cirugiasConfig,
    formData,
    setFormData,
    manejarCambio,
    obtenerFormulariosPorTexto,
    obtenerEstadoInicial,
    setFormErrors,
    FormErrors
  }
}

export default AllCampsForm