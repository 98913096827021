import React from 'react';
import {ReactComponent as IconPlane} from '../SuraDataPassengersDetail/SuraEditFormQuote/Assets/svg/IconPlane.svg'
import {ReactComponent as IconCalendar } from '../SuraDataPassengersDetail/SuraEditFormQuote/Assets/svg/IconCalendar.svg'
import { ReactComponent as IconPeople } from '../SuraDataPassengersDetail/SuraEditFormQuote/Assets/svg/IconPeople.svg'
import './Assets/Styles.css';
import CarouselSevices from '../../ui/views/share/components/CarouselServices/CarouselSevices';
import Find from '../../ui/views/share/components/Find/Find';
import { useTranslation } from 'react-i18next';

const SuraSummaryPayView = () => {

    //Traducción
    const [t] = useTranslation("global");

    return (
        <div>
            <div>
                <Find />
                <CarouselSevices current={t("carousel_services.travel_secure")}/>
            </div>
            <div className='btn-come-back-sura'>
                <button>Regresar</button>
            </div>
            <div className='data-summary-pay-sura'>
                <div className='data-summray-style-sura'>
                    <div className='title-data-summary-sura'>
                        <p>Resumen</p>
                    </div>
                    <hr className='hr-summary-pay-sura' />
                    <div className='data-basic-summary-sura'>
                        <div>
                            <IconPlane/>
                            <p>Colombia - Camboya</p>
                        </div>
                        <div>
                            <IconCalendar/>
                            <p>17 feb. 2024 - 22 feb. 2024</p>
                        </div>
                        <div>
                            <IconPeople/>
                            <p>Viajeros: 1</p>
                        </div>
                    </div>
                    <hr className='hr-summary-pay-sura' />
                    <div className='data-pay-summary-sura'>
                        <p>Producto: <span>ProductoViajesMov</span></p>
                        <p>Plan: <span>Viaje internacional aereo</span></p>
                        <p>Prepoliza: <span>XX00000000</span></p>
                        <p>Forma de pago: <span>Anual</span></p>
                        <p>Prima: <span>$ 000.000.00</span></p>
                        <p>Impuesto: <span>$ 00.000.00</span></p>
                    </div>
                </div>
            </div>
            <div className='p-price-total-sura'>
                <div>
                    <p>Total a pagar: <span>250.000</span></p>
                </div>
            </div>
            <div className='container-btn-buy-sura'>
                <button>Pagar</button>
            </div>
        </div>
    );
};

export default SuraSummaryPayView;
