import React from 'react'
import banner from '../../../assets/pictures/bannerRecovery.png'
import './styles.css'
import recoveryLetters from '../../../assets/images/Casas-de-recuperacion.png'
import { useTranslation } from 'react-i18next'

const BannerSpecialist = () => {

  const [t,i18n] = useTranslation("global")
  
  return (
    <div className='contain-banner-specialist'>
        <img src={banner} alt="" />
        <div className='effect-black-specialist'>
            <section className='text-Banner-specialist'>
               <img src={recoveryLetters} alt="espcialist" />
               <p>{t("recovery_house.text_banner")}</p>
            </section>
        </div>
    </div>
  )
}

export default BannerSpecialist