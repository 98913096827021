import React from 'react'
import CarRenta from '../../ui/views/CarRenta/CarRenta'

const CarRentaRoute = () => {
  return (
    <div>
        <CarRenta></CarRenta>
    </div>
  )
}

export default CarRentaRoute