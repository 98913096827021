import React, { useState } from "react";
import style from "./style.module.css";
import useLoginUser from "@/ui/viewModels/session/login.user.viewModel";
import useRegisterUser from "@/ui/viewModels/session/register.user.viewModel";
import { IoCloseOutline } from "react-icons/io5";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { LuLoader2 } from "react-icons/lu";
import SearchableInput from "../SearchableInput/SearchableInput";
import SvgIcon, { iconList } from "@/ui/views/share/components/iconSVG";

const Login = ({ setActiveLogin, url }) => {
  const {
    screensList,
    inputsList,
    btnDisable,
    showPassword,
    setShowPassword,
    loading,
    getInputError,
    onLogin,
  } = useLoginUser();

  return (
    <div className={style.modalFormLogin}>
      <h3>Inicia sesión</h3>
      <form className={style.formLabelInputs}>
        {inputsList.map((input, key) => {
          const error = getInputError(input);
          return (
            <div key={key} className={style.labelInput}>
              <label className={style.label}>{input.label}</label>
              <div className={`${style.input} ${error && style.error}`}>
                <input
                  type={input.type}
                  id={input.id}
                  value={input.value}
                  placeholder={input.placeholder}
                  onChange={input.func}
                  onBlur={input.onBlur}
                />
                {input.id === "password" && (
                  <button
                    type="button"
                    className={style.eyePassword}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {!showPassword ? (
                      <FaEye color="var(--greySecundary)" size={15} />
                    ) : (
                      <FaEyeSlash color="var(--greySecundary)" size={16} />
                    )}
                  </button>
                )}
              </div>
              {error && <p className={style.messageInput}>{error}</p>}
            </div>
          );
        })}
      </form>
      <div className={style.buttonsActions}>
        <div className={style.passwordLinks}>
          <div className={style.rememberPassword}>
            <input type="checkbox" id="rememberMe" name="rememberMe" />
            <label htmlFor="rememberMe">Recordar contraseña</label>
          </div>
          <Link className={style.linkRecovery} to="/recovery-password">
            Restablecer contraseña
          </Link>
        </div>

        <button
          onClick={() => !btnDisable && onLogin(true, url)}
          className={`${style.formBtnSend} ${btnDisable ? style.disabled : style.formBtnSendAvaliable
            }`}
        >
          {loading ? (
            <LuLoader2 size={19} className={style.spinLoginModal} />
          ) : (
            <p className="formBtnSendText">Iniciar sesión</p>
          )}
        </button>

        <div className={style.separationOr}>
          <div className="separation-line"></div>
          o
          <div className="separation-line"></div>
        </div>

        <button
          onClick={() => setActiveLogin(false)}
          className={style.register}
        >
          Registrarme
        </button>
      </div>
    </div>
  );
};

const Register = ({ setActiveLogin, url }) => {
  const {
    inputsList,
    btnDisabled,
    showPassword,
    setShowPassword,
    loading,
    inputErrors,
    handleRegister,
  } = useRegisterUser();

  const passwordRequirements = [
    { regex: /[A-Z]/, text: "Debe contener al menos una letra mayúscula" },
    { regex: /[a-z]/, text: "Debe contener al menos una letra minúscula" },
    { regex: /\d/, text: "Debe contener al menos un número" },
    {
      regex: /[!@#$%^&*(),.?":{}|<>]/,
      text: "Debe contener al menos un carácter especial",
    },
    { regex: /^.{8,16}$/, text: "Debe tener entre 8 y 16 caracteres" },
  ];

  const passwordInput = inputsList.find((input) => input.id === "password");

  // Verifica si el campo de contraseña existe
  if (!passwordInput) {
    throw new Error("El campo de contraseña no existe.");
  }
  const isPasswordEmpty = passwordInput.value.trim() === "";
  const isPasswordValid = !isPasswordEmpty && passwordRequirements.every((req) => passwordInput.value.match(req.regex));
  const validationResult = !isPasswordValid && isPasswordEmpty;
  const isPasswordValidPass = passwordRequirements.every((req) =>
    inputsList.find((input) => input.id === "password").value.match(req.regex)
  );

  return (
    <div className={style.modalFormLogin}>
      <h3>Registrate</h3>
      <form className={style.formLabelInputs}>
        {inputsList.map((input, key) => {
          const isError = inputErrors[input.id];
          const showBtn = ["password", "confirmPassword"].includes(
            input.id
          );

          return (
            <div key={key} className={style.userRegisterInputContainer}>
              {input.fields && input.type === "flex" ? (
                <div className={style.flexRowContainer}>
                  {input.fields.map((input, index) => (
                    <div key={index} className={style.flexRowContainerItem}>
                      {input.id === "prex_phone_public" && (
                        <div className={style.flexRowContainItemInput}>
                          <label className={style.label}>
                            {input.label}
                          </label>
                          <SearchableInput
                            name={input.id}
                            options={input.options}
                            value={input.value}
                            onChange={input.func}
                            placeholder={input.placeholder}
                            onBlur={input.onBlur}
                            type={input.type}
                            id={input.id}
                            inputErrors={inputErrors[input.id]}
                          />

                          {inputErrors[input.id] && (
                            <p className={style.messageInput}>
                              {inputErrors[input.id]}
                            </p>
                          )}
                        </div>
                      )}

                      {input.id === "phone_public" && (
                        <div className={style.flexRowContainItemInput}>
                          <label className={style.label}>
                            {input.label}
                          </label>
                          <div
                            className={`${style.input} ${inputErrors[input.id] && style.error}`}
                          >
                            <input
                              type={input.type}
                              name={input.id}
                              id={input.id}
                              value={input.value}
                              placeholder={input.placeholder}
                              onChange={input.func}

                              onBlur={input.onBlur}
                            />
                          </div>

                          {inputErrors[input.id] && (
                            <p className={style.messageInput}>
                              {inputErrors[input.id]}
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <label className={style.label}>
                    {input.label}
                  </label>
                  <div


                    className={`${style.input} ${isError && style.error}`}
                  >
                    <input
                      type={
                        input.id === "password" && showPassword
                          ? "text"
                          : input.type
                      }
                      name={input.id}
                      id={input.id}
                      value={input.value}
                      placeholder={input.placeholder}
                      onChange={input.func}
                      onBlur={input.onBlur}
                    />

                    {(input.id === "password" ||
                      input.id === "confirmPassword") && (
                        <button
                          type="button"
                          className={style.eyePassword}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {!showPassword ? (
                            <FaEye color="var(--greySecundary)" size={15} />
                          ) : (
                            <FaEyeSlash color="var(--greySecundary)" size={16} />
                          )}
                        </button>
                      )}
                  </div>
                </>
              )}

              {isError && (
                <p className={style.messageInput}>{isError}</p>
              )}
            </div>
          );
        })}

        <div className="passwordRequirementsContentMessage">
          {inputsList.some((input) => input.id === "password") && (
            <div
              className={`${validationResult || isPasswordValidPass
                ? "passwordRequirements close"
                : "passwordRequirements open"
                }`}
            >
              <div className="arrowRight"></div>
              <h4>Requisitos de la Contraseña:</h4>
              <ul>
                {passwordRequirements.map((req, index) => (
                  <li key={index}>
                    <SvgIcon
                      name={
                        inputsList
                          .find((input) => input.id === "password")
                          .value.match(req.regex)
                          ? iconList.IconCheckSucces
                          : iconList.IconCloseCircleOutLine
                      }
                      color={
                        inputsList
                          .find((input) => input.id === "password")
                          .value.match(req.regex)
                          ? "var(--greenPrimary)"
                          : "var(--redPrimary)"
                      }
                      size={20}
                    />
                    <span
                      style={{
                        color: inputsList
                          .find((input) => input.id === "password")
                          .value.match(req.regex)
                          ? "var(--greenPrimary)"
                          : "var(--redPrimary)",
                      }}
                    >
                      {req.text}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

      </form>
      <div className={style.buttonsActions}>
        <button
          onClick={() => !btnDisabled && handleRegister(true, url)}
          className={`${style.formBtnSend} ${btnDisabled ? style.disabled : style.formBtnSendAvaliable
            }`}
        >
          {loading ? (
            <LuLoader2 size={19} className={style.spinLoginModal} />
          ) : (
            <p className="formBtnSendText">Registrarme</p>
          )}
        </button>
        <div className={style.separationOr}>
          <div className="separation-line"></div>
          o
          <div className="separation-line"></div>
        </div>

        <button onClick={() => setActiveLogin(true)} className={style.register}>
          Iniciar sesión
        </button>
      </div>
    </div>
  );
};

const SessionModal = ({ activeSessionModal, setActiveSessionModal, url = false }) => {
  const [activeLogin, setActiveLogin] = useState(true);  

  return (
    <div
      className={`${style.modalContainerLogin} ${activeSessionModal ? style.open : style.close}`}
      onClick={() => setActiveSessionModal(false)}
    >
      <div
        className={`${style.modalContent} ${activeSessionModal ? style.open : style.close}`}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className={style.modalButtonClose}
          onClick={() => setActiveSessionModal(false)}
        >
          <IoCloseOutline size={25} color="var(--bluePrimary)" />
        </button>
        {activeLogin ? (
          <Login setActiveLogin={setActiveLogin} url={url} />
        ) : (
          <Register setActiveLogin={setActiveLogin} url={url} />
        )}
      </div>
    </div>
  );
};

export default SessionModal;
