import React, { useEffect, useState } from 'react';
import InputSimple from '../../TerrawindVoucherDetail/FormDataPassenger/InputSimple/InputSimple';
import InputDocumentSura from '../InputDocumentSura/InputDocumentSura';
import { DateRangePicker } from 'react-date-range';

import "./Assets/Styles.css";

const SuraFormDetailPassengers = ({index, typeDocument, setSend}) => {
    //fecha base
    const date = new Date();
    date.setDate(date.getDate() - 1)

    const [isPickerVisible, setPickerVisible] = useState(false);
    const [state, setState] = useState([{ startDate: date, endDate: date, key: 'selection' }]);

    //datos del viajero
    const [documentTypeId, setDocumentTypeId] = useState("");
    const [document, setDocument] = useState("");
    const [names, setNames] = useState("");
    const [lastNames, setLastNames] = useState("");
    const [countryBirth, setCountryBirth] = useState("");
    const [gender, setGender] = useState("");
    const [birthDate, setBirthDate] = useState(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
    const [city , setCity] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    //validación
    const [validate, setValidate] = useState("");

    const handleSelectChange = (e) => {
        setGender(e.target.value);
    }

    //formato de fecha
    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const [formattedBirthDate, setFormattedBirthDate] = useState(formatDate(birthDate));

    //Selección de fecha de nacimiento
    const handleSelectDate = (ranges) => {
        if (ranges.selection.startDate) {
            const startDate = new Date(ranges.selection.startDate);
            if (!isNaN(startDate)) {
                setBirthDate(startDate);
                setFormattedBirthDate(formatDate(startDate));
            }
        }

        setPickerVisible(false);
    }

    //Validación de datos
    const validateData = () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const currentDate = new Date();
        if(document === "" || document.length < 5 || /[\s\W]/.test(document)){
            setValidate("document");
            return;
        }
        if(names === "" || names.length < 3 || /\d/.test(names)){
            setValidate("names");
            return false;
        }else if(lastNames === "" || names.length < 3 || /\d/.test(names)){
            setValidate("lastNames");
            return false;
        }else if(countryBirth === ""){
            setValidate("countryBirth");
            return false;
        }else if(gender === ""){
            setValidate("gender");
            return false;
        }else if(city == ""){
            setValidate("city");
            return false;
        }else if(address === ""){
            setValidate("address");
            return false;
        }else if (phone === "" || isNaN(phone) || phone.length < 8){
            setValidate("phone");
            return false;
        }else if(email === "" || !emailRegex.test(email) || /[\s]/.test(email)){
            setValidate("email");
            return false;
        }else{
            setValidate("");
        }

        return true;
    }

    //efecto para validar datos
    useEffect(() => {
        if(validateData()){
            setSend(true);
        }else{
            setSend(false);
        }
    }, [documentTypeId, document, names, lastNames, countryBirth, gender, city, address, phone, email]);

    const showDatePicker = () => {
        setPickerVisible(true);
    }

    return (
        <div>
            <div className='p-traveler-sura'>
                <p>{index === 1 ? "Viajero Principal":`Viajero ${index}` }</p>
            </div>
            <div>
                <div className='container-inputs-sura'>
                    <div>
                        <InputDocumentSura  onInputChange={setDocument} validate={validate === "document"? true : false} setDocumentTypeId={setDocumentTypeId} typeDocument={typeDocument}/>
                    </div>
                    <div className={validate === "names"? 'container-input-sura input-validate-sura': 'container-input-sura'}>
                        <InputSimple label="Nombres" onInputChange={setNames}/>
                        {validate === "names" && <p className="validate-input-sura">El campo es requerido</p>}
                    </div>
                    <div className={ validate === "lastNames"? 'input-validate-sura container-input-sura': 'container-input-sura' }>
                        <InputSimple label="Apellidos" onInputChange={setLastNames} />
                        {validate === "lastNames" && <p className="validate-input-sura">El campo es requerido</p>}
                    </div>
                </div>
                <div className='container-inputs-sura'>
                    <div className={validate === "countryBirth"? 'input-validate-sura container-input-sura': 'container-input-sura'}>
                        <InputSimple label="Pais de nacimiento" onInputChange={setCountryBirth}/>
                        {validate === "countryBirth" && <p className="validate-input-sura">El campo es requerido</p>}
                    </div>
                    <div className='container-input-sura input-gender-sura'>
                        <label htmlFor="">Genero</label>
                        <select className={validate === "gender" ? "validate-gender": null} name="" id="" onChange={handleSelectChange}>
                            <option value="">Seleccione</option>
                            <option value="M">Masculino</option>
                            <option value="F">Femenino</option>
                        </select>
                        {validate === "gender" && <p className="validate-input-sura">El campo es requerido</p>}
                    </div>
                    <div className={ validate === "birthDate" ? "validate-input-voucher conatiner-input-date": "conatiner-input-date"}>
                        <label htmlFor="">Fecha de Nacimiento</label>
                        <button onClick={showDatePicker} className="data-picker-voucher-sura">
                        {formattedBirthDate}
                        </button>
                        {
                            isPickerVisible && (
                                <div style={{ position: 'absolute', zIndex: 999 }}>
                                    <DateRangePicker
                                        ranges={state}
                                        onChange={handleSelectDate}
                                        maxDate={date}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className='contact-emergency-sura'>
                <div className='p-data-contact'>
                    <p>Datos de contacto</p>
                </div>
                <div className='container-inputs-sura'>
                    <div className={validate === "city"? 'input-validate-sura container-input-sura': 'container-input-sura'}>
                        <InputSimple label="Ciudad" onInputChange={setCity}/>
                        { validate == "city"? <p className="validate-input-sura">El campo es requerido</p> : null}
                    </div>
                    <div className={validate === "address"? 'input-validate-sura container-input-sura': 'container-input-sura'}>
                        <InputSimple label="Dirección" onInputChange={setAddress}/>
                        { validate == "address"? <p className="validate-input-sura">El campo es requerido</p> : null}
                    </div>
                    <div className={validate === "phone"? 'input-validate-sura container-input-sura': 'container-input-sura'}>
                        <InputSimple label="Celular" onInputChange={setPhone}/>
                        { validate == "phone"? <p className="validate-input-sura">El campo es requerido</p> : null}
                    </div>
                </div>
                <div className='container-inputs-sura'>
                    <div className={validate === "email"? 'input-validate-sura container-input-sura': 'container-input-sura'}>
                        <InputSimple label="Correo electronico" onInputChange={setEmail} />
                        { validate == "email"? <p className="validate-input-sura">El campo es requerido</p> : null}
                    </div>
                </div>
            </div>
            <hr className='hr-passengers-sura' />
        </div>
    );
};

export default SuraFormDetailPassengers;
