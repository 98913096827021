import './styles.css';
import SearchFlights from "../search.flights.view";
function PurelyIframeFlight(){
return(
  <div className="purelyIframeFlight">
  <SearchFlights accompanied={false} isIframe={true}/>
  </div>
)
}

export default PurelyIframeFlight;