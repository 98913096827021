import React, { useEffect, useState } from "react";
import Find from "@/ui/views/share//components/Find/Find";
import CarouselSevices, {
  servicesTravel,
} from "@/ui/views/share//components/CarouselServices/CarouselSevices";
import styles from "./styles.module.css";
import SvgIcon, { iconList } from "@/ui/views/share/components/iconSVG";
import ModalContainer from "@/ui/views/share/components/modalContainer";
import Loading from "@/ui/views/share//components/loading";
import SuccessPay from "./successPay/SuccessPay";
import PendingPay from "./pendingPay/PendingPay";
import RejectPay from "./rejectPay/RejectPay";
import usePayFlights from "@/ui/viewModels/flights/usePayFlights";
import DetailPayFlight from "./detailPayFlight/DetailPayFlight";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { Skeleton } from "@mui/material";
import TextComponent from "@/ui/views/share//components/fonts/TextComponent";
import ReturnPage from "@/ui/views/share//components/return/ReturnPage";
import GalleryImg from "../../Hotel/DetailHotel/GalleryImg/GalleryImg";
import ModalCardDetail from "../../Hotel/HotelResult/allResultsCards/components/ModalCardDetail";
import { MdCameraAlt, MdFlight } from "react-icons/md";
import { GoCheckCircleFill } from "react-icons/go";
import { IoCloseCircle } from "react-icons/io5";
import { currencyFormat } from "@/ui/utils";
import AnyQuestions from "../../home/components/anyQuestion";
import { useTranslation } from "react-i18next";
import { TbLoader2 } from "react-icons/tb";
import { BiQuestionMark } from "react-icons/bi";
import Collapsible from "../../BuildPackage/pages/std/components/Collapsible/Collapsible";
import { useLocation } from "react-router-dom";

const FlightsPay = () => {

  const { flightInfo, incompleteInformation, loading, statePay } =
    usePayFlights();
  const [t, i18n] = useTranslation("global");

  const [flightUrl, setFlightUrl] = useState("/fligths");

  const [loadingImage, setLoadingImage] = useState(true);
  const [slideShow, setSlideShow] = useState(false);
  const [modalCardDetail, setModalDetail] = useState(false);
  const [fotos, setFotos] = useState([
    "https://images.pexels.com/photos/46148/aircraft-jet-landing-cloud-46148.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/4486521/pexels-photo-4486521.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/11190493/pexels-photo-11190493.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/9015525/pexels-photo-9015525.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/18214075/pexels-photo-18214075/free-photo-of-vuelo-volador-avion-aeronave.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/18139303/pexels-photo-18139303/free-photo-of-vuelo-volador-avion-aeronave.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/9298364/pexels-photo-9298364.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  ]);

  useEffect(() => {
    setTimeout(() => {
      setLoadingImage(false);
    }, 3000);
  }, []);

  function Image({ url }) {
    const onLoad = () => {
      setLoadingImage(false);
    };
    const onError = () => {
      setLoadingImage(true);
    };
    return (
      <img
        className="image-hotel-detail"
        src={url}
        onLoad={onLoad}
        onError={onError}
        alt="Hotel"
      />
    );
  }

  const [dollarFlight, setDollarFlight] = useState(
    localStorage.getItem("dollarFlight") === null
      ? "COP"
      : localStorage.getItem("dollarFlight")
  );
  const typeOfCoin = {
    name: dollarFlight,
    abbreviation: dollarFlight,
    simbol: "$",
  };

  function formatearFecha(fechaISO) {
    // Crear un objeto de fecha a partir de la cadena ISO
    const fecha = new Date(fechaISO);

    // Obtener los componentes de la fecha y hora
    const dia = fecha.getDate().toString().padStart(2, "0");
    const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const año = fecha.getFullYear();
    const hora = fecha.getHours().toString().padStart(2, "0");
    const minutos = fecha.getMinutes().toString().padStart(2, "0");

    // Formatear la fecha y hora según el formato deseado
    const fechaFormateada = `${dia}-${mes}-${año} ${hora}:${minutos}`;

    return fechaFormateada;
  }





  const [confirm, setConfirm] = useState(false);


  return (
    <div>
      <CarouselSevices current={servicesTravel[0].title} />
      <ReturnPage nav={flightUrl} reload={true} />

      {loading && <Loading size={90} />}
      <ModalCardDetail
        modalCardDetail={modalCardDetail}
        setModalDetail={setModalDetail}
        modalPay={true}
        statePay={statePay?.pay?.status}
        valuePay={statePay}
        value={currencyFormat(statePay?.pay?.price, typeOfCoin).total}
        dateHour={formatearFecha(statePay?.pay?.date)}
        statusPay={statePay?.pay?.status}
        incompleteInformation={incompleteInformation}
      />

      <div className={styles.methodPayContainer}>
        <div className={styles.methodPayHeader}>
          <h3>Estado de tu transaccion</h3>
          <p>El estatus de tu pago actualmente</p>
        </div>
        <div className={styles.methodPayContain}>
          <div className={styles.methodPayContainQuestions}>
            <div className={styles.methodPayContainerTermsContain}>
              <DetailPayFlight info={flightInfo} />
              <button
                onClick={() => setModalDetail(true)}
                className="contain-img-principal-hotel-button-pay"
              >
                Ver información
              </button>
            </div>
          </div>
          <div className={styles.methodPayContainInfo}>
            <div className={styles.methodPayImageContain}>
              <div className={styles.methodPayContainInfoContain}>
              <div
                  className={`${styles.contentStatePay} ${statePay?.pay?.status === "APPROVED"
                    ? styles.APPROVED
                    : statePay?.pay?.status === "PENDING"
                    ? styles.PENDING
                    : statePay?.pay?.status === "REJECTED"
                    ? styles.REJECTED
                    : ""}`}
                  
                >
                  {statePay?.pay?.status === "APPROVED" ? (
                    <GoCheckCircleFill size={40} />
                  ) : statePay?.pay?.status === "PENDING" ? (
                    <TbLoader2 size={40} />
                  ) : statePay?.pay?.status === "REJECTED" ? (
                    <IoCloseCircle size={40} />
                  ) : (
                    <BiQuestionMark size={40} />
                  )}
                  <div>
                    <div className={styles.contentStatePayMessage}>
                      <h3>Estado:</h3>
                      <p>
                        {statePay?.pay?.status === "APPROVED"
                          ? "Aprobada"
                          : statePay?.pay?.status === "PENDING"
                            ? "Pendiente"
                            : statePay?.pay?.status === "REJECTED"
                              ? "Denegada"
                              : "Cargando..."}
                      </p>
                    </div>
                    <p>
                      {statePay?.pay?.status === "APPROVED"
                        ? "¡Pago realizado con exito!"
                        : statePay?.pay?.status === "PENDING"
                          ? "¡Pago pendiente!"
                          : statePay?.pay?.status === "REJECTED"
                            ? "¡No fue posible realizar el pago!"
                            : "Cargando..."}
                    </p>
                  </div>
                </div>
                <div className={styles.methodPayContainInfoContainPadding}>
                
                <div>
                <h3>Compra finalizada</h3>
                <p>Total de la compra</p>
                </div>


                <div className={styles.methodValuesContaint}>
                  <div className={styles.methodValuesItem}>
                    <h4>{t("fligth.form_passenger.step3.sub_total")}:</h4>
                    <span>$ {currencyFormat(flightInfo?.priceWithOutTaxes, typeOfCoin).total}</span>
                  </div>

                  <div className={styles.methodValuesItem}>
                    <h4> {t("fligth.form_passenger.step3.other_taxes")}:</h4>
                    <span>$ {currencyFormat(flightInfo?.priceTaxes, typeOfCoin).total}</span>
                  </div>

                  <div className={styles.methodValuesItemTotal}>
                    <h4>{t("fligth.form_passenger.step3.total")}:</h4>
                    <span>$ {currencyFormat(flightInfo?.priceTotal + (flightInfo?.priceTotal * 0.05), typeOfCoin).total}</span>
                  </div>
                </div>
                </div>
              </div>

              <div className={styles.securityPay}>
                <SvgIcon
                  color={"var(--bluePrimary)"}
                  name={iconList.IconKey}
                  size={40}
                />
                <div>
                  <TextComponent
                    children={"Pago Seguro"}
                    size={"md"}
                    fontWeight={"bold"}
                    tag="h4"
                  />
                  <TextComponent
                    children={
                      "Los niveles más altos para los pagos con tarjetas"
                    }
                    size={"md2"}
                    fontWeight={"light"}
                    tag="span"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {statePay?.pay?.status === "APPROVED" &&
          (
            <p>Su transacción 3179595 fue aprobada por la entidad financiera. En caso de tener dudas sobre el vuelo, te recomendamos ponerte en contacto con nuestra línea de soporte/atención al cliente en 3004040116 o, alternativamente, puedes enviar un correo electrónico a compras@wellezy.com</p>
          )
        }
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default FlightsPay;
