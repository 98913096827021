import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Find from '../../share/components/Find/Find'
import CarouselSevices, { servicesTravel } from '../../share/components/CarouselServices/CarouselSevices'
import './styles.css'
import SvgIcon,{iconList} from '../../share/components/iconSVG'
import UseDetailRecovery from '../../../viewModels/RecoveryHouse/UseDetailRecovery'
import ModalQuo from './ModalQuo/ModalQuo'


const DetailsRecoveryHouse = () => {
  const {
    state
  } = useLocation()

  const {
    sendInformation,
    name,
    setName,
    email,
    setEmail,
    error,
    succesScreen,
    description,
    setDescription,
    tel,
    setTel,
    subject,
    setSubject,
    setModal,
    modal,
    loadingS
  } = UseDetailRecovery()

  let stars= []

  for (let i = 0; i < Number(state.stars); i++) {
    stars.push(<SvgIcon key={i} name={iconList.IconStar} color={'#FFD902'} size={20} />);
  }

  useEffect(() => {
    const body = document.querySelector('body');
  modal ? body.style.overflow = 'hidden' : body.style.overflow = '';
  }, [modal])

  return (
    <div>
      <Find />
      <div className='detail-hotel-route' style={{width:"90%"}}>
        <p>
          <Link to={'/RecoveryHouse'}>Casas Recuperacion</Link> {">"} <span>{state.name}</span>
        </p>
      </div>
      <div className='contain-detail-recovery-house'>
        <section className='contain-img-detail-recovery'>
          <img src={`${state.urlImage}${state.pathImage}`} alt={state.name} className='img-detail-recovery' />
        </section>
        <section className='contain-info-detail-recovery'>
          <div className='first-section-detail'>
            <h1>{state.name}</h1>
            <div className='star-hotel-card'>
            <span>
              {Number(state.stars).toFixed(1)}
            </span>
            <div>
              {stars}
            </div>
          </div>
            <p>
              Direccion:
              <br/>
              <span>
                {state.address}
              </span>
            </p>
            <p>
              Descripcion:
              <br/>
              <span>
                {state.description}
              </span>
            </p>
          </div>
          <div>
            <button onClick={()=>{
              setModal(true)
            }}>Realizar Pedido</button>
          </div>
        </section>
      </div>
      <CarouselSevices current={servicesTravel[12].title} />
      {
                modal &&
                <ModalQuo
                    setModal={setModal}
                    description={description}
                    setDescription={setDescription}
                    email={email}
                    setEmail={setEmail}
                    tel={tel}
                    setTel={setTel}
                    name={name}
                    setName={setName}
                    subject={subject}
                    setSubject={setSubject}
                    sendInformation={sendInformation}
                    succesScreen={succesScreen}
                    error={error}
                    id={state?.id}
                    loading={loadingS}
                />
            }
    </div>
  )
}

export default DetailsRecoveryHouse