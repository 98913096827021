import React from 'react'
import { useLocation } from 'react-router-dom'


const DetailsCosmetics = () => {

const {
  state
} = useLocation()

  return (
    <div>
      <ul>
        <li>{state.name}</li>
        <li>{state.description}</li>
        <li>{state.id}</li>
        <li>{state.price}</li>
      </ul>
    </div>
  )
}

export default DetailsCosmetics