import { PiUserCircleFill } from "react-icons/pi";
import useHeaderSession from "../../../../viewModels/session/header.session.viewModel";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function HeaderSession() {
  const [t, i18n] = useTranslation("global");
  const { isOpen, setIsOpen, cardRef, optionList, userDetail } =
    useHeaderSession();

  return (
    <div className={style.userAccount} ref={cardRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={style.buttonUserAccount}
      >
        {userDetail && userDetail?.profile_photo_url && (
          <img src={userDetail?.profile_photo_url} alt="User" />
        )}
        {!userDetail && <PiUserCircleFill size={45} color="var(--white)" />}
      </button>

      <div
        className={`${style.dropdownMenu} ${isOpen ? style.open : style.close}`}
      >
        <div className={style.arrowUp}></div>

        {userDetail && userDetail?.name && (
          <div className={style.userAccountActive}>
            <span className={style.userAccountActiveHello}>
              {t("browse_home.nav_bar.join_club.hello")},
            </span>{" "}
            {userDetail?.name}
          </div>
        )}
        <ul>
          {optionList.map((index, key) => {
            return (
              <li
                key={key}
                onClick={() => {
                  if (index.action) {
                    index.action();
                  }
                  setIsOpen(!isOpen);
                }}
              >
                <Link to={index?.url && index?.url}>{index.text}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default HeaderSession;
