import React from "react";
import TerrawindPaymentCard from "../../Components/TerrawindPaymentCard/TerrawindPaymenCard";

function TerrawindMethodPay() {
  return (
        <div>
            <TerrawindPaymentCard/>
        </div>
    )
}

export default TerrawindMethodPay;