import React from 'react'
import RateClassSelected from '../../Components/RateClassSelected/RateClassSelected'
const RateClass = () => {

    return (
      <div>
        <RateClassSelected/>
      </div>
    )
  
}

export default RateClass
