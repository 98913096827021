import React, { useEffect, useState } from "react";
import InputSimple from "./InputSimple/InputSimple";
import InputDocument from "./InputDocument/InputDocument";
import DatePicker, { ReactDatePicker } from "react-datepicker";

import { format } from "date-fns";
import { DateRangePicker } from "react-date-range";

import "react-datepicker/dist/react-datepicker.css";
import "./Assets/styles.css";

function FormDataPassenger({passengers, typeDocument, handleFormChange, index, setSend, long}){
    //fecha base
    const date = new Date();
    date.setDate(date.getDate() - 1)
    
    const [isPickerVisible, setPickerVisible] = useState(false);
    const [state, setState] = useState([{ startDate: date, endDate: date, key: 'selection' }]);

    //datos del viajero
    const [names, setNames] = useState("");
    const [lastNames, setLastNames] = useState("");
    const [documentTypeId, setDocumentTypeId] = useState("");
    const [document, setDocument] = useState("");
    const [birthDate, setBirthDate] = useState(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [city, setCity] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");

    //validación
    const [validate, setValidate] = useState("");

    //Selección de genero
    const handleSelectChange = (e) => {
        setGender(e.target.value);
    }

    //formato de fecha
    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const [formattedBirthDate, setFormattedBirthDate] = useState(formatDate(birthDate));

    //Selección de fecha de nacimiento
    const handleSelectDate = (ranges) => {
        if (ranges.selection.startDate) {
            const startDate = new Date(ranges.selection.startDate);
            if (!isNaN(startDate)) {
                setBirthDate(startDate);
                setFormattedBirthDate(formatDate(startDate));
            }
        }

        setPickerVisible(false);
    }

    const showDatePicker = () => {
        setPickerVisible(true);
    }

    //Validación de datos
    const validateData = () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        if (names === "" || names.length < 3 || /\d/.test(names)) {
            setValidate("names");
            return false;
        }else if(lastNames === "" || lastNames.length < 3 || /\d/.test(lastNames)){
            setValidate("lastNames");
            return false;
        }else if (documentTypeId === "3" && isNaN(document)) {
            setValidate("document");
            return false;
        }else if(documentTypeId === "" || document.length < 5 || document === "" || /[\s\W]/.test(document)){
            setValidate("document");
            return false;
        }else if (!emailRegex.test(email) || /[\s]/.test(email)) {
            setValidate("email");
            return false;
        }else if(gender === ""){
            setValidate("gender")
            return false
        }else if (city === "") {
            setValidate("city");
            return false
        }else if(address === ""){
            setValidate("address");
            return false
        }else if (isNaN(phone)){
            setValidate("phoneText");
            return false
        }else if(phone === "" || phone.length < 8){
            setValidate("phone");
            return false
        }else{
            setValidate("")
        }
        return true;
    }

    useEffect(() => {
        if (validateData()) {
            handleFormChange(
                index, 
                { 
                    passenger_first_name: names, 
                    passenger_last_name: lastNames, 
                    passenger_document_type_id: documentTypeId,
                    passenger_document_number: document, 
                    passenger_birth_date: formattedBirthDate,
                    passenger_email: email, 
                    passenger_gender: gender,
                    passenger_city: city, 
                    passenger_address: address, 
                    passenger_phone: phone, 
                }
            );
            if(index + 1 == long){
                setSend(true)
            }
        }else {
            setSend(false)
        }
    }, [names, lastNames, document, email, city, address, phone, gender, formattedBirthDate, documentTypeId])

    return(
        <div className="form-data-container">
            <div className="number-travel">
                <p>Viajero {passengers}</p>
            </div>
            <div className="inputs-data">
                <div className={ validate === "names" ? "validate-input-voucher": null}>
                    <InputSimple label="Nombres" onInputChange={setNames}/>
                    { validate == "names" ? <p className="span-simple-error">El campo es requerido</p> : null}
                </div>
                <div className={ validate === "lastNames" ? "validate-input-voucher": null}>
                    <InputSimple label="Apellidos" className={`input-lastName-${index}`} onInputChange={setLastNames} />
                    { validate == "lastNames" ? <p className="span-simple-error">El campo es requerido</p> : null}
                </div>
                <div>
                    <InputDocument onInputChange={setDocument} className={`document${index}`} validate={validate === "document"? true : false} setDocumentTypeId={setDocumentTypeId} typeDocument={typeDocument}/>
                </div>
            </div>
            <div className="inputs-data">
                <div className={ validate === "birthDate" ? "validate-input-voucher conatiner-input-date": "conatiner-input-date"}>
                    <label htmlFor="">Fecha de Nacimiento</label>
                    <button onClick={showDatePicker} className="data-picker-voucher">
                    {formattedBirthDate}
                    </button>
                    {
                        isPickerVisible && (
                            <div style={{ position: 'absolute', zIndex: 999 }}>
                                <DateRangePicker
                                    ranges={state}
                                    onChange={handleSelectDate}
                                    maxDate={date}
                                />
                            </div>
                        )
                    }
                </div>
                <div className={ validate === "email" ? "validate-input-voucher": null }>
                    <InputSimple label="Email" className={`input-email-${index}`} onInputChange={setEmail} />
                    { validate == "email" ? <p className="span-simple-error">El campo es requerido</p> : null}
                </div>
                <div className={`input-gender-${index} input-gender`}>
                    <label htmlFor="">Genero</label>
                    <select className={validate === "gender" ? "validate-gender": null} name="" id="" onChange={handleSelectChange}>
                        <option value="">Seleccione</option>
                        <option value="M">Masculino</option>
                        <option value="F">Femenino</option>
                    </select>
                    { validate == "gender" ? <p className="span-simple-error">El campo es requerido</p> : null}
                </div>
            </div>
            <div className="inputs-data">
                <div className={ validate === "city" ? "validate-input-voucher": null }>
                    <InputSimple label="Ciudad" className={`input-city-${index}`} onInputChange={setCity} />
                    { validate == "city" ? <p className="span-simple-error">La campo es requerida</p> : null}
                </div>
                <div className={validate === "address" ? "validate-input-voucher": null}>
                    <InputSimple label="Dirección" className={`input-direction-${index}`} onInputChange={setAddress} />
                    { validate == "address" ? <p className="span-simple-error">La campo es requerida</p> : null}
                </div>
                <div className={validate === "phone" || validate === "phoneText"? "validate-input-voucher": null}>
                    <InputSimple label="Teléfono" className={`input-phone-${index}`} onInputChange={setPhone}/>
                    { validate === "phone"  || validate === "phoneText"? <p className="span-simple-error">{validate === "phone" ? "El campo es requerido": validate === "phoneText" ? "El campo debe ser numerico": null}</p> : null}
                </div>
            </div>
        </div>
    )
}

export default FormDataPassenger;