import './styles.css';
function ActivityIndicator({
  size = 22,
  colorFill = '#cccccc'
}) {

  const colorMap = {
    black: 'rgba(0, 0, 0, 0.5)',
    white: 'rgba(255, 255, 255, 0.5)',
    red: 'rgba(255, 0, 0, 0.5)',
    green: 'rgba(0, 128, 0, 0.5)',
    blue: 'rgba(0, 0, 255, 0.5)',
    yellow: 'rgba(255, 255, 0, 0.5)',
    pink: 'rgba(255, 192, 203, 0.5)',
    orange: 'rgba(255, 165, 0, 0.5)',
    gray: 'rgba(128, 128, 128, 0.5)',
    brown: 'rgba(165, 42, 42, 0.5)',
    purple: 'rgba(128, 0, 128, 0.5)',
    cyan: 'rgba(0, 255, 255, 0.5)',
    magenta: 'rgba(255, 0, 255, 0.5)',
    silver: 'rgba(192, 192, 192, 0.5)',
    gold: 'rgba(255, 215, 0, 0.5)',
    lime: 'rgba(0, 255, 0, 0.5)',
    teal: 'rgba(0, 128, 128, 0.5)',
    indigo: 'rgba(75, 0, 130, 0.5)',
    maroon: 'rgba(128, 0, 0, 0.5)',
    navy: 'rgba(0, 0, 128, 0.5)',
    olive: 'rgba(128, 128, 0, 0.5)',
    aqua: 'rgba(0, 255, 255, 0.5)',
    salmon: 'rgba(250, 128, 114, 0.5)',
    tomato: 'rgba(255, 99, 71, 0.5)',
    coral: 'rgba(255, 127, 80, 0.5)',
    slategray: 'rgba(112, 128, 144, 0.5)',
    lightgray: 'rgba(211, 211, 211, 0.5)',
    darkgray: 'rgba(169, 169, 169, 0.5)',
    skyblue: 'rgba(135, 206, 235, 0.5)',
    forestgreen: 'rgba(34, 139, 34, 0.5)',
  };

  function hexToRgb(hex) {
    hex = hex.replace("#", "");
    const red = parseInt(hex.substring(0, 2), 16);
    const green = parseInt(hex.substring(2, 4), 16);
    const blue = parseInt(hex.substring(4, 6), 16);
    return {
      red: red,
      green: green,
      blue: blue,
      alpha: 0.5
    };
  }

  function getColorCircle() {
    let color;
    if (colorFill.startsWith("#")) {
      const RGBA = hexToRgb(colorFill);
      color = `rgba(${RGBA.red}, ${RGBA.green}, ${RGBA.blue}, ${RGBA.alpha})`;
    }
    else {
      color = colorMap[colorFill.toLowerCase()] || 'rgba(0, 0, 0, 1)';
    }
    const pixelBorder = size / 10 < 4 ? size / 10 : 4;
    
    return `${pixelBorder}px solid ${color}`
  }
  const colorBorder = getColorCircle();
  return (
    <div className="spinner" style={{
      width: size,
      height: size,
      border: colorBorder,
      borderTopColor: colorFill
    }} />
  )
}

export default ActivityIndicator;
