import React from 'react'
import SvgIcon, { iconList } from '../../../../share/components/iconSVG'
import "./styles.css"

const DetailPayTransport = ({ price, img, nom, passengers, covid, vehicle,meet }) => {

    function formatearCantidad(cantidad) {
        const cantidadFormateada = cantidad?.toLocaleString("es", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 1,
            maximumFractionDigits: 3,
        });

        return cantidadFormateada;
    }

    return (
        <div className='detail-pay-hotel'>
            <div className='header-pay-hotel'>
                <img src={img} alt={nom} />
                <div className='content-info'>
                    <h2>{nom}</h2>
                    <div style={{display:"flex", flexDirection:"column",gap:".5rem",alignItems:"flex-start"}}>
                        <span style={{fontSize:"14px"}}><SvgIcon color={"#004274"} name={iconList.IconTaxi} size={25} /> {vehicle}</span>
                        <span><SvgIcon color={"#004274"} name={iconList.IconPeoples} size={25} /> {passengers}</span>
                    </div>
                </div>
            </div>
            <div className='content-check-detail'>
                <section>
                    <p style={{ color: "#004274", fontWeight: "bold" }}>Prepared Covid </p>
                    <SvgIcon color={"#004274"} name={iconList.IconArrowLarge} size={20} />
                    <p>{covid === true ? "Si" : "No"}</p>
                </section>
                <section>
                    <p style={{ color: "#004274", fontWeight: "bold" }}>Meet and Greet </p>
                    <SvgIcon color={"#004274"} name={iconList.IconArrowLarge} size={20} />
                    <p>{meet === true ? "Si" : "No"}</p>
                </section>
            </div>
            <div className='line-separation-detail-pay'></div>
            <div className='row-detail-pay row-total-pay'>
                <p>Total</p>
                <span>{formatearCantidad(price)}</span>
            </div>
        </div>
    )
}

export default DetailPayTransport