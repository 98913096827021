import '../../../styles.css';
import CommercialInformation from "./components/commercialInformation";
import CommercialFormRow from "./components/commercialFormRow";
import CommercialArticles from './components/commercialArticles';
import CommercialOffers from './components/commercialOfferts';
import CommercialGallery from './components/commercialGallery';
import useCommercialProfile from '../../../../../viewModels/profile/commercial/commercialProfile.viewModel';

function CommercialProfile() {
  return (
    <div className="bigContainer">

      <div className="profileScreen_ContainerSideCenter">
        <CommercialInformation />
        <CommercialFormRow />
      </div>
      <div className="profileScreen_ContainerSideRight">
        <CommercialGallery/>
        {/* <CommercialOffers />
        <CommercialArticles /> */}
      </div>
    </div>
  )
}
export default CommercialProfile;
