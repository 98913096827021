import React from "react";
import TerrawindPlan from "../../Components/TerrawindPlan/TerrawindPlan";

function Terrawind() {
    return (
        <div>
            <TerrawindPlan />
        </div>
    )
}

export default Terrawind;