import React from 'react'
import banner from "../../assets/pictures/bannerShop.png"
import "./styles.css"
import Find from '../share/components/Find/Find'
import SvgIcon, { iconList } from '../share/components/iconSVG'
import Icons from './Icons'
import { servicesTravel } from '../share/components/CarouselServices/CarouselSevices'
import { useNavigate } from 'react-router-dom'

const ShopWellezy = () => {
const navigate = useNavigate()
 const navigatePage = (route)=>{
    navigate(route)
 }

    return (
        <>
            <Find></Find>
            <div className='content-shop-wellezy'>
                <div className='banner'>
                    <img src={banner} alt="" />
                </div>
                <section className='title'>
                    <p>Bienvenido a nuestra <span>Tienda Wellezy</span></p>
                    <p>¿Qué estas buscando?</p>
                </section>
                <section className='content-cards'>
                    {
                        servicesTravel.map((item, key) => {
                            return (
                                <>
                                <div className={`icons div${key}`} onClick={()=>{
                                    navigatePage(item.route)
                                }}>
                                    <SvgIcon name={item.img} size={70} color={"#004274"} key={item.id} />
                                    <span>{item.title}</span>
                                </div>
                                
                                </>
                                
                            )
                        })
                    }
                </section>
            </div>
        </>

    )
}

export default ShopWellezy