import { useState, useEffect, useContext } from 'react';
import UserContext from '../../../../Context/user/userProvider';
import {wellezy_api} from '../../../../infra/api';
import { doctorURL } from '../../../../infra/urls';

function useCommercialProfileInformation() {
  const { userDetail, userToken } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [dataCommercial, setDataCommercial] = useState(null);
  const [form, setForm] = useState(dataCommercial);

  function getFormDataText(event) {
    if (!isEditing) return;
    const value = event.target.value;
    const key = event.target.id;
    setForm({
      ...form,
      [key]: value
    })
  }

  function getFormDataBoolean(id, value) {
    if (!isEditing) return;
    setForm({
      ...form,
      [id]: value
    })
  }

  const typeInputs = {
    text: 'text',
    check: 'check',
    textArea: 'textArea'
  }

  const inputList = [
    { id: 'surname', isRequired: false, value: form?.surname || '', label: 'Nombre', inputTtpe: typeInputs?.text, placeHolder: '', change: getFormDataText },
    { id: 'speciality', isRequired: false, value: form?.speciality || '', label: 'Especialista en', inputTtpe: typeInputs?.text, placeHolder: '', change: getFormDataText },
    { id: 'schedule', isRequired: false, value: form?.schedule || '', label: 'Horarios de atención', inputTtpe: typeInputs?.textArea, placeHolder: '', change: getFormDataText },
    { id: 'professional_register', isRequired: false, value: form?.professional_register || '', label: 'Registro profesional', inputTtpe: typeInputs?.text, placeHolder: '', change: getFormDataText },
    { id: 'video', isRequired: false, value: form?.video || '', label: 'Videoconsulta online disponible', inputTtpe: typeInputs?.check, placeHolder: '', change: getFormDataBoolean },
    { id: 'web', isRequired: false, value: form?.web || '', label: 'Visitar web', inputTtpe: typeInputs?.text, placeHolder: '', change: getFormDataText },
    { id: 'description', isRequired: false, value: form?.description || '', label: 'Descripción', inputTtpe: typeInputs?.textArea, placeHolder: '', change: getFormDataText },
    { id: 'team', isRequired: false, value: form?.team || '', label: 'Equipo Humano', inputTtpe: typeInputs?.textArea, placeHolder: '', change: getFormDataText },
    { id: 'belonging_to', isRequired: false, value: form?.belonging_to || '', label: 'Pertenece a: ', inputTtpe: typeInputs?.textArea, placeHolder: '', change: getFormDataText },
  ];

  async function getProfileCommercial() {
    if (isLoading) return;
    try {
      setIsLoading(true)
      const url = doctorURL.get.profileProfessional;
      const data = { user_id: userDetail?.id };
      const respuesta = await wellezy_api.get(url, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        params: data,
      });
      if (respuesta) {
        setDataCommercial(respuesta?.data?.user);
        setForm(dataCommercial);
      }
    } catch (error) {
      console.log("Error in getProfileCommercial()", error);
    }
    finally {
      setIsLoading(false)
    }
  }

  async function save() {
    if (isSending) return;
    try {
      setIsSending(true)
      const url = doctorURL.post.profileInformation;
      const newData = { ...form }
      newData.user_id = userDetail?.id
      const response = await wellezy_api.post(url, newData, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if (response) {
        setMessage(response?.data?.message)
        setShowModal(true)
        await getProfileCommercial();
      }
    } catch (error) {
      console.log("Error in save(): ", error)
    }
    finally {
      setIsSending(false);
      setIsEditing(false);
    }
  }

  function cancel() {
    setIsEditing(false);
    setBtnDisable(true);
    setForm(dataCommercial);
  }

  useEffect(() => {
    if (isEditing && form !== dataCommercial?.user) {
      setBtnDisable(false);
    }
  }, [form])

  useEffect(() => {
    getProfileCommercial();
  }, [])

  return {
    isLoading,
    dataCommercial,
    inputList,
    isEditing,
    setIsEditing,
    cancel,
    typeInputs,
    save,
    btnDisable,
    showModal,
    setShowModal,
    message,
  }
}

export default useCommercialProfileInformation;
