import React, { useState, useEffect } from 'react';
import './styles.css';
import { CloseCircle } from 'iconsax-react';
import { global } from '../../../../global';



const {
  logos: {
    wellezyLogo
  }
} = global.img;

function SideBar({
  open,
  close,
  sideRight = false,
  width,
  child,
}) {


  
  const [windowWidth, setWindowWidth] = useState(0);

  function adjustSidebarSize() {
    const current = window?.innerWidth;
    const value = width <= current ? width : current;
    setWindowWidth(value);
  }

  window.addEventListener('resize', function () {
    adjustSidebarSize()
  });

  function getPositionBtnClose() {
    return sideRight ? { right: windowWidth - 50 } : { left: windowWidth - 50, }
  }

  function getDisplay() {
    let classDisplay = '';
    if (sideRight) {
      if (open) { classDisplay = 'sideBarSection-on-right' }
      else { classDisplay = 'sideBarSection-off-right' }
    }
    else {
      if (open) { classDisplay = 'sideBarSection-oo-left' }
      else { classDisplay = 'sideBarSection-off-left' }
    }
    return classDisplay
  }

  useEffect(() => {
    const body = document.querySelector('body');
    open ? body.style.overflow = 'hidden' : body.style.overflow = '';
    if (!open) return () => {
      setWindowWidth(0)
    }
    else {
      adjustSidebarSize()
    };
  }, [open]);

  return (
    <>
      {open &&
        <div className={`sideBarContainer ${getDisplay()}`}>
          <div className='sideBarSection' style={{ width: windowWidth, float: sideRight ? 'right' : 'left' }}>
            <div className={`sideBarSectionHead ${sideRight ? '' : ''}`}>
              <div className={`sideBarSectionHeadBtnClose`} onClick={() => close(!open)} style={getPositionBtnClose()}>
                <CloseCircle size="25" color="#FFFFFF" />
              </div>
              <div className='sideBarLogo'>
                <img src={wellezyLogo} alt='logo-wellezy' className='sideBarLogoImg' />
              </div>
            </div>
            <div className='sideBarSectionBody'>{child}</div>
          </div>
        </div>
      }
    </>
  )
}

export default SideBar;