import React, { useState, useEffect } from "react";
import { DateRange } from "react-date-range";
import "./Assets/styles.css";
import { Calendar } from "iconsax-react";
import { useTranslation } from "react-i18next";
import { es } from 'date-fns/locale';
import toast, { Toaster } from "react-hot-toast";

const HotelDateCard = ({ cardRef, setDatesSelected, setDateEntry, left, setCardRooms, defaultHotel }) => {
  const [t] = useTranslation("global");

  const [startDate, setStartDate] = useState(() => {
    const savedStartDate = localStorage.getItem("startDate");
    return savedStartDate ? new Date(savedStartDate) : new Date();
  });

  const [endDate, setEndDate] = useState(() => {
    const savedEndDate = localStorage.getItem("endDate");
    return savedEndDate ? new Date(savedEndDate) : new Date();
  });

  const [totalDate, setTotalDate] = useState(0);
  const [dateMark, setDateMark] = useState(startDate.getTime() !== endDate.getTime());

  const dateMin = new Date();
  const dateMax = new Date(dateMin);
  dateMax.setDate(dateMin.getDate() + 360);

  useEffect(() => {
    localStorage.setItem("startDate", startDate.toISOString());
    localStorage.setItem("endDate", endDate.toISOString());
    const daysDifference = Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));
    setTotalDate(daysDifference);
  }, [startDate, endDate]);

  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
  
    // Calcula la diferencia en días entre las fechas
    const differenceInTime = endDate.getTime() - startDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24); // Convertir milisegundos a días
  
    // Verificar si la selección es mayor a 15 días
    let adjustedEndDate = endDate;
    if (differenceInDays > 15) {
      // Si es mayor, ajustar la fecha de fin a 15 días después de la fecha de inicio
      adjustedEndDate = new Date(startDate);
      adjustedEndDate.setDate(startDate.getDate() + 15);
  
      // Opción: Puedes alertar al usuario que se ha ajustado la fecha de fin
      notify("La selección no puede ser mayor a 15 días.", "❌");
    }
  
    setStartDate(startDate);
    setEndDate(adjustedEndDate);
  
    setDatesSelected({
      entryDate: startDate.toLocaleDateString("en-us", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }),
      exitDate: adjustedEndDate.toLocaleDateString("en-us", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }),
    });
    setDateMark(startDate.getTime() !== adjustedEndDate.getTime());
  };
  
  

  const selectDates = () => {  
    setDatesSelected({
      entryDate: startDate.toLocaleDateString("en-us", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }),
      exitDate: endDate.toLocaleDateString("en-us", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }),
    });
  
    if (startDate.getTime() === endDate.getTime()) {
      notify("Debes seleccionar al menos un día", "❌");
      return;
    }
    setDateEntry(false);
  
    if (defaultHotel) {
      setCardRooms(true);
    }
  };

  const notify = (title, icon) =>
    toast(title, {
      icon: icon,
      style: {zIndex: 999999},
    });
  

  return (
    <>
    <Toaster position="top-center" reverseOrder={false} />
    <div className="hotelDateCard" ref={cardRef}>
      <div className="hotelDateCard_">
        <div
          className="layout-campo"
          style={{
            backgroundColor: "transparent",
            color: "inherit",
            width: "100%",
            padding: "10px",
          }}
        >
          <div className="destiny-hotel">
            <span style={{ color: "inherit" }}>
              {t("fligth.search_fligth.date")}
            </span>
            <p style={{ color: "inherit" }}>
              {startDate.toLocaleDateString("es-ES", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
              {" - "}
              {endDate.toLocaleDateString("es-ES", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </p>
          </div>
          <div className="destiny-hotel-icon">
            <Calendar size="32" color={"var(--bluePrimary)"} />
          </div>
        </div>
      </div>
      <DateRange
        locale={es}
        minDate={dateMin}
        maxDate={dateMax}
        editableDateInputs={true}
        // months={2}
        direction="horizontal"
        moveRangeOnFirstSelection={false}
        onChange={handleSelect}
        ranges={[{ startDate, endDate, key: 'selection' }]}
      />
      <div className="hotelDateCard_button">
        <button onClick={selectDates}>
          {t("hotel.search_engine.accommodation_of")} {totalDate} {t("hotel.search_engine.day_accommodation")}
        </button>
      </div>
    </div>
    </>
  );
};

export default HotelDateCard;
