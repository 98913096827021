import { useState, useEffect, useContext } from 'react';
import { channelRoomEntity } from '../../../infra/entities/channelRoom.entity';
import { videoSDK_api } from '../../../infra/api';
import { videoSdkURL } from '../../../infra/urls';
import { authToken } from '../../../infra/constants/tokens.video.sdk';
import UserContext from '../../../Context/user/userProvider';

function useInitialRoomPX() {
  const [mssg, setMssg] = useState('');
  const [namePX, setNamePX] = useState('');
  const [isErrorMeet, setIsErrorMeet] = useState(false);
  const [step, setStep] = useState(1);
  const [idRoom, setIdRoom] = useState('');
  const { userDetail } = useContext(UserContext);

  function changeName(value) {
    setNamePX(value)
  }

  function joinToMeet() {
    if (namePX) {
      setStep(2)
    }
  }

  async function validateIdRoom(id) {
    try {
      const response = await videoSDK_api.get(
        `${videoSdkURL?.get?.validatedMeeting}${id}`,
        {
          headers: {
            Authorization: authToken,
            "Content-Type": "application/json"
          },
        }
      )
      return !response?.data?.disabled;
    } catch (error) {
      console.log("Error in validateIdRoom(): ", error)
      return false;
    }
  }

  async function validateURL() {
    const path = window.location.pathname.split('/')
    const entityKey = path[2];
    const newIdRoom = path[3];
    const entityUI = channelRoomEntity.find((ent) => ent.id === entityKey);
    const isIdAvaliable = await validateIdRoom(newIdRoom)
    const newErrorMssg = !entityUI ? 'supplier unknown.' : !isIdAvaliable ? 'call identifier is not found' : '';
    setIdRoom(newIdRoom);
    setIsErrorMeet(!isIdAvaliable || !entityUI)
    setMssg(newErrorMssg)
  }

  useEffect(() => {
    if (userDetail && !namePX) {
      setNamePX(userDetail?.name);
    }
    validateURL();
  }, [])

  return {
    mssg,
    isErrorMeet,
    namePX,
    setNamePX,
    step,
    setStep,
    changeName,
    joinToMeet,
    authToken,
    idRoom,
  }
}

export default useInitialRoomPX;