import React from 'react'
import banner from "../../../assets/pictures/bannerHealth.png"
import SearchHealth from '../SearchHealth/SearchHealth'

const BannerHealthWellness = () => {
  return (
    <header className='header-fligths'>
        <div className='content-buscador-fli'>
          <SearchHealth/>
        </div>
        <div className='content-banner-t'>
          <img src={banner} alt="" />
        </div>
      </header>
  )
}

export default BannerHealthWellness