import {
  setCompletedSteps,
  setDataPackage,
  setRoutes,
} from "@/store/slices/dataStepz/dataStepzSlice";
import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const UseSpecialist = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let counter = 0;
  const condition = location.pathname;
  const itemsForPage = 10;
  let specialistBuild = "";
  let citytBuild = "";

  const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
  const dataSearch = useSelector((state) => state.dataSearchEngine);

  const [dataAlways, setDataAlways] = useState([]);
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [mostrarNext] = useState(true);
  const [urlImg, setUrlImg] = useState("");
  const [city, setCity] = useState("");
  const [name, setName] = useState("");
  const [modalNoFound, setModalNoFound] = useState(false);

  const url = window.location.href;
  const urlObj = new URL(url);

  if (condition.includes("/build-package/specialist")) {
    specialistBuild = dataSearch?.procedureData?.name;
    citytBuild = urlObj.searchParams.get("city");
  }

  function findSpecialist(page = 1, name = "", city = "", specialist = "") {
    if (
      condition === "/Specialists" ||
      (condition === "/build-package/specialist" && counter === 0) ||
      condition === "/build-package"
    ) {
      setLoading(true);
      // Reemplazar espacios múltiples por uno solo y codificar el símbolo '+'
      const specialistFormatted = specialist.trim().replace(/\s*\+\s*/g, ' + ');

      // Codificar los parámetros correctamente
      const url = `${process.env.REACT_APP_URL_API_FINANMED}/v2/search/docs?page=${page}&type_affiliate=Medico&name=${encodeURIComponent(name)}&city=${encodeURIComponent(city)}&speciality=${encodeURIComponent(specialistFormatted)}&wellezy=true&have_clinic=true`;


      return axios.get(url)
        .then((res) => {
          setLoading(false);
          setServices(res.data);

          if (res.data.data.length === 0) {
            setModalNoFound(true);
            setDataAlways(res.data.data);
            return false;
          } else {
            // if (condition === "/build-package") {
            //   const allClinicsFalse = res.data.data.every((item) => {
            //     return item.clinics.every((clinic) => clinic.wellezy === false);
            //   });

            //   if (allClinicsFalse) {
            //     setDataAlways(res.data.data);
            //     setModalNoFound(true);
            //     return false;
            //   }else{
            //     setDataAlways(res.data.data);
            //     return true;
            //   }
            // }else{
            setDataAlways(res.data.data);
            return true;
            // }
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          return false;
        });
    }
    return Promise.resolve(false);
  }

  function nextStep(slug, data) {
    dispatch(setDataPackage({
      ...dataPackage, specialist: data
    }));

    dispatch(setCompletedSteps([1]));

    navigate(`/build-package/clinic?slug=${slug}`);
  }

  useEffect(() => {
    setCity(citytBuild);
    findSpecialist(1, "", citytBuild, specialistBuild);
  }, [url]);

  const nextHandler = (condition, page) => {
    findSpecialist(
      condition ? page : Number(services?.current_page) + 1,
      name,
      city,
      specialistBuild
    );
  };

  const prevHandler = () => {
    findSpecialist(
      Number(services?.current_page) - 1,
      name,
      city,
      specialistBuild
    );
  };

  const filterSpecialist = (word) => {
    if (word === "All") {
      setDataAlways([...services].splice(0, itemsForPage));
    } else {
      const keyWord = word.toLowerCase();
      const newData = [];

      for (let index = 0; index < services.length; index++) {
        for (
          let index2 = 0;
          index2 < services[index].subcategories.length;
          index2++
        ) {
          let toLowerCaseW =
            services[index].subcategories[index2].name.toLowerCase();
          if (toLowerCaseW.includes(keyWord)) {
            newData.push(services[index]);
          }
        }
      }

      setDataAlways(newData);
    }
  };

  const filterCity = (city) => {
    setLoading(true);
    const url = `${process.env.REACT_APP_URL_API_AIOP}api/users/doctors`;
    axios
      .get(url, {
        params: {
          search: city,
        },
      })
      .then((res) => {
        counter += 1;
        setLoading(false);
        setServices(res.data.users);
        setUrlImg(res.data.url);
        return setDataAlways([...res.data.users].splice(0, itemsForPage));
      })
      .catch((error) => {
        counter += 1;
        setLoading(false);
        console.log(error);
      });
  };

  const detailSpecialist = (doctor, id) => {
    navigate(`/Specialists/${id}`);
  };

  return {
    dataAlways,
    loading,
    nextHandler,
    prevHandler,
    currentPage,
    services,
    mostrarNext,
    urlImg,
    filterSpecialist,
    filterCity,
    detailSpecialist,
    setCurrentPage,
    findSpecialist,
    setCity,
    setName,
    city,
    name,
    specialistBuild,
    condition,
    nextStep,
    dataPackage,
    modalNoFound,
    setModalNoFound,
  };
};
export default UseSpecialist;
