import { iconList } from "../iconSVG"
 

const ModelCarousel = () => {

    const servicesTravel = [
        {
            title: "Vuelos",
            img: iconList.IconPlane,
            route: '/fligths'
        },
        {
            title: "Hoteles",
            img: iconList.IconHotel,
            route: '/hotel'
        },
        {
            title: "Tours",
            img: iconList.IconBag,
            route: '/tours'
        },
        {
            title: "Traslados",
            img: iconList.IconTaxi,
            route: '/transport'
        },
        {
            title: "Renta de Autos",
            img: iconList.IconRentaCar,
            route: '/carRentals'
        },
        {
            title: "Paquetes",
            img: iconList.IconTravel,
            route: '/packages'
        },
        {
            title: "Seguros de Viaje",
            img: iconList.IconSecureTravel,
            route: '/travelSecure'
        },
        {
            title: "Especialistas",
            img: iconList.IconDoctor,
            route: '/Specialists'
        },
        {
            title: "Clinicas",
            img: iconList.IconHospital,
            route: '/Clinics'
        },
        {
            title: "Belleza y Bienestar",
            img: iconList.IconBeautiful,
            route: '/healthWellness'
        },
        {
            title: "Farmacia",
            img: iconList.IconMedicaments,
            route: '/Pharmacy'
        },
        {
            title: "Cosmeticos",
            img: iconList.IconDoctor,
            route: '/Cosmetics'
        },
        {
            title: "Casas Recuperacion",
            img: iconList.IconRestHouse,
            route: '/RecoveryHouse'
        },
    ]

   


  return {
    servicesTravel

  }
}

export default ModelCarousel