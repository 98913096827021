import React from 'react';
import SuraDataPassengersDetail from '../../Components/SuraDataPassengersDetail/SuraDataPassengersDetail';

const SuraDataPassengers = () => {
    return (
        <div>
            <SuraDataPassengersDetail />
        </div>
    );
};

export default SuraDataPassengers;
