import React from 'react'
import banner from '../../assets/pictures/bannerNosotros.png'
import './styles.css'
import InfoCard from './InfoCard/InfoCard'

const AboutUs = () => {
    return (
        <div>
            <div className='contain-banner-about-us'>
                <img src={banner} alt="" />
                <div>
                    <section>
                        <h1>Sobre Nosotros</h1>
                        <p>
                            Wellezy, la única startup en Colombia que provee soluciones
                            tecnológicas en turismo de salud y bienestar.
                        </p>
                        <br />
                        <p>
                            Nos enorgullecemos de ser líderes en la industria, ya que nos
                            preocupamos por ofrecer servicios enfocados en la seguridad,
                            ventajas económicas y calidad excepcional en el ámbito de la
                            salud. Nuestra misión es posicionar a Colombia como el país
                            número uno en turismo de salud y convertirnos en una marca
                            reconocida a nivel global. Además, nos destacamos por ser la
                            única plataforma que agremia a especialistas de la salud y a
                            las agencias participantes en el turismo de salud.
                        </p>
                    </section>
                </div>
            </div>
            <div className='content-info-cards-main'>
               <InfoCard /> 
            </div>
            
        </div>
    )
}

export default AboutUs