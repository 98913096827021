import React from "react";
import "./styles.css";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SvgIcon, { iconList } from "../../../../share/components/iconSVG";
import { global } from "../../../../../global";
import FlightSegment from "./FlightSegment";
import { useState, useEffect } from "react";
import TextComponent from "../../../../share/components/fonts/TextComponent";
import { currencyFormat } from "../../../../../utils";
import ImageJetSmart from "../../../../../../ui/views/flights/components/flightDetailResultsList/images/jetsmart.svg"
const {
  img: {
    logos: { AviancaLogo, LatamLogo, VivaLogo },
  },
} = global;

const DetailPayFlight = ({ acomodation, price, taxes, total, discount, typeOf }) => {
  const [t] = useTranslation("global");
  const { state } = useLocation();

  const [itinerary] = useState(state?.itinerary);
  const [segments, setSegments] = useState([]);
  const [flightType] = useState(() =>
    itinerary?.length > 1 ? "Ida y vuelta" : "Solo ida"
  );
  const [passengers, setPassengers] = useState("");

  useEffect(() => {
    const passengerString = getPassengerString(state?.passengersSelected);
    setPassengers(passengerString);

    const segments = state?.itinerary.map((item, key) => {
      const ImageAero = item.company === 'JA' ? ImageJetSmart : `https://pics.avs.io/60/60/${item.company}.png`;
      return {
        date: convertirFecha(item.dateDeparture),
        airlineLogo: ImageAero,
        departureTime: extractTime(item.dateDeparture),
        arrivalTime: extractTime(item.dateArrival),
        departureAirport: item.from,
        arrivalAirport: item.to,
        duration: calculateFlightDuration(item.dateDeparture, item.dateArrival),
        type: key === 1 ? "VUELTA" : "IDA",
      };
    });

    setSegments(segments);
  }, []);

  function getPassengerString(data) {
    const labels = {
      adult: "adultos",
      boy: "niños",
      baby: "bebés",
      seat: "bebes con asiento",
    };

    const parts = [];

    for (const key in data) {
      if (data[key] > 0) {
        parts.push(`${data[key]} ${labels[key]}`);
      }
    }

    return parts.join(", ");
  }

  function formatearCantidad(cantidad) {
    const cantidadFormateada = cantidad?.toLocaleString("es", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return cantidadFormateada;
  }

  function convertirFecha(fechaStr) {
    let partes = fechaStr.split(/[-T:+]/);
    let año = partes[0];
    let mes = partes[1];
    let dia = partes[2];

    let meses = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];
    let mesCompleto = meses[parseInt(mes) - 1];

    let fechaFormateada = `${dia} ${mesCompleto}, ${año}`;

    return fechaFormateada;
  }

  const typePass = {
    ADT: "Adulto",
    INS: "Bebe con silla",
    IN: "Bebe",
    CH: "Niño",
    INF:"Bebe"
  }

  const typeAcount = {
    ADT: "adult",
    INS: "seat",
    IN: "baby",
    CH: "boy",
    INF:"baby"
  } 

  function extractTime(dateString) {
    const date = new Date(dateString);
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  function calculateFlightDuration(departure, arrival) {
    const departureDate = new Date(departure);
    const arrivalDate = new Date(arrival);
    const durationMs = arrivalDate - departureDate;

    const durationMinutes = Math.floor(durationMs / (1000 * 60));
    const hours = Math.floor(durationMinutes / 60);
    const minutes = durationMinutes % 60;

    let durationStr = "";
    if (hours > 0) {
      durationStr += `${hours}h `;
    }
    durationStr += `${minutes}m`;

    return durationStr.trim();
  }

  return (
    <div className="detail-pay-hotel">
      <TextComponent
        children={"Detalle del pago"}
        fontWeight="bold"
        size={"md"}
        tag="h2"
      />
      {
        state.passengersPrice ?
        state.passengersPrice.map((item, key) => {
          return (
            <div className="row-detail-pay-normal" key={key}>
              <TextComponent size={"md2"} fontWeight="light">
                {typePass[`${item?.type}`]} X {item.count}
              </TextComponent>

              <TextComponent size={"md2"} fontWeight="light">
                $ {currencyFormat(item?.totalWhitoutTax, typeOf).total}
              </TextComponent>
            </div>
          )
        })
        :
        state.prices.map((item,key)=>{
          return(
            <div className="row-detail-pay-normal" key={key}>
              <TextComponent size={"md2"} fontWeight="light">
                {typePass[`${item?.type}`]} X {state.passengersSelected[`${typeAcount[`${item.type}`]}`]}
              </TextComponent>

              <TextComponent size={"md2"} fontWeight="light">
                $ {currencyFormat(item?.priceWithoutTaxt * state.passengersSelected[`${typeAcount[`${item.type}`]}`] , typeOf).total}
              </TextComponent>
            </div>
          )
        })
      }
      <div className="row-detail-pay-normal" style={{borderTop:"2px #004274 solid",paddingTop:"1rem"}}>
        <TextComponent size={"md2"} fontWeight="light">
          Boleto para {acomodation} {acomodation == 1 ? "Persona" : "Personas"}
        </TextComponent>

        <TextComponent size={"md2"} fontWeight="light">
          $ {currencyFormat(state?.priceWithOutTaxes, typeOf).total}
        </TextComponent>
      </div>
      <div className="row-detail-pay-normal">
        <p>{t("fligth.tax")}</p>
        <span>$ {currencyFormat(state?.priceTaxes, typeOf).total}</span>
      </div>

      <div className="row-detail-pay row-total-pay">
        <p>Total</p>
        <span>$ {currencyFormat(state?.priceTotal, typeOf).total}</span>
      </div>
      <div className="line-separation"></div>

      <TextComponent
        children={"Detalle de compra"}
        fontWeight="bold"
        size={"md"}
        tag="h2"
      />
      <div className="header-pay-hotel">
        <div className="flexDirectionRow">
          <span className="mr-10">
            <SvgIcon color={"#004274"} name={iconList.IconPlane} size={40} />{" "}
          </span>
          <div className="w-80">
            <TextComponent
              children={`${itinerary[0]?.from} - ${itinerary[0]?.to}`}
              className={"return-url m-0 pd-0"}
              fontWeight="bold"
              size={"md2"}
              tag="h5"
            />
            <small>
              {flightType}, {passengers}
            </small>
          </div>
        </div>
      </div>
      <br />

      {segments.map((item, key) => {
        return (
          <>
            <FlightSegment key={key} {...item} />
            <br />
          </>
        );
      })}

      <div className="line-separation"></div>

      {/* <TextComponent
        className={"return-url"}
        children={t("fligth.change_cancellation_policy")}
        fontWeight="bold"
        size={"md2"}
        tag="h3"
      />

      <div className="policyCancellation">
        <TextComponent children={"Cambios"} size={"md2"} fontWeight="light" />
        <div className="flexDirectionRow">
          <div className="mr-10">
            <SvgIcon color={"#004274"} name={iconList.IconCheck} size={30} />
          </div>
          <TextComponent
            children={"Permite (con costo)"}
            size={"md2"}
            fontWeight="light"
          />
        </div>
        <br />

        <TextComponent
          children={"Cancelación"}
          size={"md2"}
          fontWeight="light"
        />
        <div className="flexDirectionRow">
          <div className="mr-10">
            <SvgIcon color={"#004274"} name={iconList.IconCancel} size={30} />
          </div>

          <TextComponent
            children={"Permite (con costo)"}
            size={"md2"}
            fontWeight="light"
          />
        </div>
        <br />
        <div className="flexDirectionRow">
          <div className="mr-10">
            <SvgIcon color={"#004274"} name={iconList.IconInfo} size={30} />
          </div>
          <TextComponent
            className={"return-url"}
            children={"Ver política de cambios, cancelaciones y equipaje"}
            size={"md2"}
            fontWeight="bold"
            tag="h4"
          />
        </div>
      </div> */}

      {/* <div className="content-button-reservation-hotel">
        <button>Ver condiciones</button>
      </div> */}
    </div>
  );
};

export default DetailPayFlight;
