import './assets/styles.css'
import { Location, Message, CallCalling, Facebook, Monitor } from 'iconsax-react';
import React, { useRef } from 'react'
import styled from 'styled-components'
import { motion, useDragControls } from 'framer-motion'
import { Grid } from '@mui/material'

const MotionConstraints = styled(motion.div)`
    overflow-x: hidden;
  `;

const MotionBox = styled(motion.div)`
    width: 400vw;
  `

const ContactWellezy = () => {

    const constraintsRef = useRef(null)

    const contact = [
        {
            icon: <Location size="40" color="#3387c7" />,
            text: "Puntos de Atención"
        },
        {
            icon: <Message size="40" color="#3387c7" />,
            text: "Chat en Línea"
        },
        {
            icon: <Monitor size="40" color="#3387c7" />,
            text: "Llámanos a nuestras Líneas"
        },
        {
            icon: <CallCalling size="40" color="#3387c7" />,
            text: "Nosotros te llamamos"
        },
        {
            icon: <Facebook size="40" color="#3387c7" />,
            text: "Contáctanos por redes sociales"
        },
    ]

    const breakPoints = [
        { width: 100, itemsToShow: 2, itemsToScroll: 1 },
        { width: 500, itemsToShow: 3, itemsToScroll: 1 },
        { width: 800, itemsToShow: 5 },
        { width: 1200, itemsToShow: 5, itemsToScroll: 1 }
    ];

    return (
        <div className='contain-contact-wellezy'>
            <h3>¿Tienes alguna duda?</h3>
            <div className='contain-contact-flex'>
                <MotionConstraints className='slider_container' ref={constraintsRef}>
                    <MotionBox className='slider_' drag='x' dragConstraints={constraintsRef}  >
                        {
                            contact.map((topic) => {
                                return (
                                    <motion.div className='div_homeTurism' key={topic.text}>
                                        <Grid item xs={6} sm={6} md={3} lg={2} className='grid_cardHomeTurism'>
                                        <div className='individual-contact'>
                                        <span>{topic.icon}</span>
                                        <p>{topic.text}</p>
                                    </div> 
                                        </Grid>
                                    </motion.div>
                                )
                            })
                        }

                    </MotionBox>
                </MotionConstraints>
    
            </div>
        </div>
    )
}

export default ContactWellezy