import React, { useState } from 'react'
import "./assets/styles.css"
import { generarId, formatearFecha } from './helpers/index'
import Modal from "./Modal"
import Paginado from './Paginado'

const ServicesConductores = ({ data }) => {

  const [modal, setModal] = useState(false)
  const [gastoEditar, setGastoEditar] = useState({})
  const [animarModal, setAnimarModal] = useState(false)
  const [gastos, setGastos] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [mostrarNext, setMostrarNext] = useState(true)
  const itemsForPage = 10

  const [topic, setTopic] = useState([...data].splice(0,itemsForPage))
  const nextHandler = ()=>{
  const totalElementos = data.length;

  const nextPage = currentPage + 1

  const firtsIndex = nextPage * itemsForPage
  if(firtsIndex >= totalElementos) {
      setMostrarNext(false)
    return;
  }
  
  
  setCurrentPage(nextPage)
  setTopic([...data].splice(firtsIndex,itemsForPage))

}
const prevHandler = ()=>{
  setMostrarNext(true)
  const prevPage = currentPage === 0 ? currentPage : currentPage- 1
 
 

 const firtsIndex = prevPage * itemsForPage

 setTopic([...data].splice(firtsIndex,itemsForPage))
  setCurrentPage(prevPage)
}

  const handleNuevoGasto = () => {

    modal ? setModal(false) : setModal(true)

    setGastoEditar({})

    setTimeout(() => {
      setAnimarModal(true)
    }, 500)

  }

  const guardarGasto = gasto => {
    gasto.id = generarId()
    gasto.fecha = formatearFecha(Date.now())
    setGastos([...gastos, gasto])

    setAnimarModal(false)

    setTimeout(() => {
      modal ? setModal(false) : setModal(true)
    }, 500)
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })
  return (
    <div>
      <div className='container-header-conductores'>
        <div className='header-services'>
          <div className='principal-text'>
            <h1>Servicios de Conductores</h1>
            <p>"Viaja con seguridad y Confianza."</p>
          </div>
        </div>
      </div>
      <div className='container-cards-services'>
        {
          topic.map(topic => {
            return (
              <div>
                <div class="card-conductores">
                  <div class="card-header-conductores">
                    <img src={topic.car_image} alt="city" />
                  </div>
                  <div class="card-body-conductores">
                    <div className='contain-tag-conductores'>
                      <span class="tag-conductores tag-pink-conductores">{topic.language}</span>
                      <span class="tag-conductores tag-pink-conductores">{formatter.format(topic.price)}</span>
                      <span class="tag-conductores tag-pink-conductores">{topic.category}</span>

                    </div>
                    <h5>
                      {topic.city}
                    </h5>
                    <p>
                      {topic.address}
                    </p>
                    <p className='description-conductores'>
                      {topic.description}
                    </p>
                    <div class="user-conductores">
                      <img src={topic.img} alt="user" />
                      <div class="user-info-conductores">
                        <h4>{topic.name}</h4>
                        <small>{topic.departament}</small>
                      </div>
                    </div>
                  </div>
                  <div className='contain-button-services'>
                    <input  type="submit" onClick={()=>{handleNuevoGasto()}} value={"Agendar servicio"} />
                  </div>

                </div>

              </div>
            )
          })
        }
      </div>
      {modal &&
        <Modal
          gastoEditar={gastoEditar}
          setModal={setModal}
          modal={modal}
          animarModal={animarModal}
          setAnimarModal={setAnimarModal}

        />

      }
        <Paginado 
        nextHandler={nextHandler} 
        prevHandler={prevHandler} 
        currentPage={currentPage} 
        mostrarNext={mostrarNext}
        >

        </Paginado>
    </div>
  )
}

export default ServicesConductores