import React from 'react'

function ResultFlightDetails() {
  return (
    <div>
      
    </div>
  )
}

export default ResultFlightDetails
