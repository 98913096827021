import React from 'react'
import DetailsCosmetics from '../../ui/views/Cosmetics/DetailsCosmetics/DetailsCosmetics'

const DetailsCosmeticsRoute = () => {
  return (
    <div>
        <DetailsCosmetics/>
    </div>
  )
}

export default DetailsCosmeticsRoute