import React from 'react'
import { useState } from 'react'
import "./styles.css"

const DataPerson = ({setPhone,error,setEmail,nextStep,setIdPay}) => {


    return (
        <div className='content-info-initial'>
            <section className='camp-card-pay'>
                <label>Telefono</label>
                <input style={{ border: `${error === "p" ? "2px solid #c60000" : ""}` }} type='text' onChange={(e) => {
                    setPhone(e.target.value)
                    setIdPay(e.target.value)
                }}></input>
                {
                    error === "p" &&
                    <p className='text-none-camp'>Dato obligatorio</p>
                }
            </section>
            <section className='camp-card-pay'>
                <label>Email</label>
                <input style={{ border: `${error === "e" ? "2px solid #c60000" : ""}` }} type='text' onChange={(e) => setEmail(e.target.value)}></input>
                {
                    error === "e" &&
                    <p className='text-none-camp'>Dato obligatorio</p>
                }
            </section>
            <p className='btn-pay' onClick={nextStep}>Siguiente</p>
        </div>
    )
}

export default DataPerson