import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";


function useBrowseHome() {

    const navigate = useNavigate()
    const cardRefProcedure = useRef(null);
    const cardRefSpecialist = useRef(null);
    const cardRefDestination = useRef(null);
    const [disableBtn, setDisableBtn] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [tabOpen, setTabOpen] = useState(0);
    const [isOpenproceduresList, setIsOpenproceduresList] = useState(false);
    const [isOpenSpecialistList, setIsOpenSpecialistList] = useState(false);
    const [proceduresSelected, setProceduresSelected] = useState(null);
    const [specialistSelected, setSpecialistSelected] = useState(null);
    const [proceduresList, setProceduresList] = useState([]);
    const [specialistList, setSpecialistList] = useState([]);
    const [isOpenDestinationsList, setIsOpenDestinationsList] = useState(false);
    const [destinationsSelected, setDestinationsSelected] = useState(null);
    const [destinationsList, setDestinationsList] = useState([]);;

    function chooseProcedure(value) {
        setProceduresSelected(value);
        
        setIsOpenproceduresList(false);
    }

    function chooseSpecislist(value) {
        setSpecialistSelected(value);
        searchProceduresForSpecialist(value)
        setIsOpenSpecialistList(false);
    }

    function chooseDestination(value) {
        setDestinationsSelected(value);
        setIsOpenDestinationsList(false);
    }

    function goToWelezyShop() {
    }

    function searchForProceduresOnSite() {
        navigate(`/TourismHealth/result?procedures=${proceduresSelected?.id}&specialist=${specialistSelected?.id}`, {
            state: {
                procedure: proceduresSelected?.id,
                specialist: specialistSelected?.id
            }
        })
    }

    useEffect(() => {
        if (proceduresSelected || specialistSelected) {
            setDisableBtn(false);
        }
    }, [proceduresSelected, destinationsSelected])


    useEffect(() => {
        if (isOpenproceduresList) { setTabOpen(1); }
        else if (isOpenDestinationsList) { setTabOpen(2); }
        else { setTabOpen(0); }
    }, [isOpenproceduresList, isOpenDestinationsList])

    useEffect(() => {
        const urlProcedure = `${process.env.REACT_APP_URL_API_FINANMED}/category/sub/47`
        const urlSpecialist = `${process.env.REACT_APP_URL_API_FINANMED}/category`
        const url = `${process.env.REACT_APP_URL_API_FINANMED}`;
        const urlCities = `${process.env.REACT_APP_URL_API_FINANMED}/list/ciudades`
        axios.get(urlProcedure, {
            params: {
                search: ''
            }
        })
            .then(res => {
                setProceduresList(res.data)
            })
            .catch(error => {
            })

        axios.get(urlCities)
            .then(res => {
                setDestinationsList(res.data)
            })
            .catch(err => {
                console.log(err)
            })

        axios.get(urlSpecialist, {
            params: {
                search: ''
            }
        })
            .then(res => {
                setSpecialistList(res.data)
            })
            .catch(error => {
            })
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);

        setIsOpenproceduresList(false);
    }, [])


    const searchProceduresForSpecialist = (value) => {
        setLoading(true);
        const urlProcedure = `${process.env.REACT_APP_URL_API_FINANMED}/category/sub/${value.id}`
        axios.get(urlProcedure)
            .then(res => {
                setProceduresList(res.data)
                setLoading(false);
            })
            .catch(error => {
            })

        setIsOpenproceduresList(false);
    }

    return {
        disableBtn,
        loading,
        tabOpen,
        cardRefProcedure,
        cardRefDestination,
        proceduresSelected,
        isOpenproceduresList,
        setIsOpenproceduresList,
        proceduresList,
        chooseProcedure,
        destinationsSelected,
        isOpenDestinationsList,
        setIsOpenDestinationsList,
        destinationsList,
        chooseDestination,
        searchForProceduresOnSite,
        goToWelezyShop,
        loadingSearch,
        specialistList,
        setSpecialistList,
        chooseSpecislist,
        specialistSelected,
        isOpenSpecialistList,
        cardRefSpecialist,
        setIsOpenSpecialistList
    }
}
export default useBrowseHome;