import React from 'react'
import "./styles.css"

const EmailPackages = () => {
    return (
        <div className='content-email-packages'>
            <h2>¿A donde enviamos tus vouchers?</h2>
            <p>El email que elijas será fundamental para que gestiones tu reserva y recibas información importante sobre tu viaje</p>

            <section className='content-camp-email-packages'>
                <div className='camp-passenger-form' style={{marginBottom:"1rem"}}>
                    <label htmlFor="">EMAIL (DONDE RECIBIRÁS TUS VOUCHERS)</label>
                    <input type="text" placeholder='micorreo@example.com' />
                </div>
                <div className='camp-passenger-form' style={{marginBottom:"1rem"}}>
                    <label htmlFor="">CONFIRMA TU EMAIL</label>
                    <input type="text" placeholder='micorreo@example.com' />
                </div>
            </section>
        </div>
    )
}

export default EmailPackages