import React from 'react'
import PaymentgGatewayCard from '../../Components/PaymentgGatewayCard/PaymentgGatewayCard'

const Paymentgateway = () => {
    return (
        <div>
            <div style={{ width: '100%', height: '85px' , backgroundColor: '#004274'}} />
            <PaymentgGatewayCard/>
        </div>
      )
    }

    export default Paymentgateway


    