import useMyTravels from "../../../../../viewModels/profile/MyTravels/myTravels.vieModel";
import ActivityIndicator from '../../../../share/components/activityIndicator';
import '../../../styles.css';
import GlocalCard from "./components/globalCard";
import './styles.css';

import SvgIcon from "../../../../share/components/iconSVG";

function MyTravels() {
  const {
    itemList,
    filterOptions,
    currentFilter,
    setCurrentFilter,
  } = useMyTravels();


  return (
    <div className="bigContainer myTravels">
      <div className="profileScreen_ContainerSideCenter">
        {itemList.map((index, key) => {
          return (
            <GlocalCard key={key} data={index} />
          )
        })}
      </div>

      <div className="profileScreen_ContainerSideRight">
        {
          filterOptions && filterOptions.length > 0 && filterOptions.map((index, key) => {
            const isActive = index.id === currentFilter;
            const colorStatus = isActive ? '#FFFFFF' : '#004274'
            return (
              <div key={key} onClick={()=>setCurrentFilter(index?.id)}
              className={`optionFilter ${isActive ? 'optionFilterOn' : 'optionFilterOff'}`}>
                <div className="optionFilterIcon">
                  <SvgIcon
                    name={index?.icon}
                    color={colorStatus}
                    size={30}
                  />
                </div>
                <p className="optionFilterText" style={{ color: colorStatus }}>
                  {index.name}
                </p>
              </div>
            )
          })}
      </div>

    </div>
  )
}

export default MyTravels;
