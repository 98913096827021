import React from 'react';
import PayPackageTourism from '../../Components/PayPackageTourism/PayPackageTourism';

const PayPackageTourismRoute = () => {
    return (
        <div>
           <PayPackageTourism />
        </div>
    );
}

export default PayPackageTourismRoute;