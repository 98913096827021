import React from 'react'
import DetailPackages from '../../ui/views/Packages/DetailPackages/DetailPackages'

const DetailPackagesRoute = () => {
  return (
    <div>
        <DetailPackages/>
    </div>
  )
}

export default DetailPackagesRoute