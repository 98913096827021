import React from 'react'
import "./assets/styles.css"
import { IconButton } from '@mui/material'
import {  ArrowLeft2, ArrowRight2, } from 'iconsax-react'
 
const Paginado = ({nextHandler, prevHandler, currentPage,mostrarNext}) => {
  return (
    <div className='paginado-services'>
        <IconButton onClick={prevHandler}>
          <ArrowLeft2 size="40" color="#004274"></ArrowLeft2>
        </IconButton>
       
        
        <span className='current-pag'>{currentPage}</span>
     
        
        <IconButton onClick={nextHandler}>
          <ArrowRight2 size="40" color="#004274"></ArrowRight2>
        </IconButton>
    </div>
  )
}

export default Paginado