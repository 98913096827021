import React, { useState } from "react";

function InputDocument({onInputChange, setDocumentTypeId, validate}){

    const handleInputChange = (e) => {
        const value = e.target.value;
        onInputChange(value);
    }

    const handleSelectChange = (e) => {
        setDocumentTypeId(e.target.value);
    }

    return(
        <div>
            <div className="input-document-label">
                <label htmlFor="">Documento de identidad *</label>
            </div>
            <div className={validate ? "input-document-validate input-document": "input-document"}>
                <div className="select-document">
                    <select name="" id="" onChange={handleSelectChange}>
                        <option value="">Tipo</option>
                        <option value="CC">Cedula de ciudadania</option>
                        <option value="TI">Tarjeta de identidad</option>
                        <option value="CE">Cedula de extranjeria</option>
                        <option value="NIT">Número de Identificación Tributaria</option>
                        <option value="RUT">Registro único tributario</option>
                        <option value="PPN">Pasaporte</option>
                    </select>
                </div>
                <div className="input-document-container">
                    <input type="text" placeholder="Numero" onChange={handleInputChange}/>
                </div>
            </div>
            { validate ? <p className="span-simple-error">El campo es requerido</p> : null}
        </div>
    )
}

export default InputDocument;