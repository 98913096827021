import React from 'react'
import "./styles.css"
import FormPassenger from './FormPassenger/FormPassenger'

const PayFormPackages = ({travellers, setTravellers, paidPackages}) => {


  const adult = JSON.parse(localStorage.getItem("flightSelected")).passengersSelected.adult

  const boy = JSON.parse(localStorage.getItem("flightSelected")).passengersSelected.boy

  const baby = JSON.parse(localStorage.getItem("flightSelected")).passengersSelected.baby

  const persons = adult + boy + baby

  const passenger = []

  for (let index = 1; index <= persons ; index++) {
      passenger.push(<FormPassenger key={index} index={index} travellers={travellers} setTravellers={setTravellers} paidPackages={paidPackages}></FormPassenger>)
  }

  return (
    <div className='content-form-passenger'>
        <h2>¿Quienes Viajan?</h2>
        {passenger.map((item,index)=>{
          return(
            item
          )
        })}
    </div>
  )
}

export default PayFormPackages