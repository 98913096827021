import React from 'react'
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import Loading from '../../share/components/loading'
import { useTranslation } from 'react-i18next'

const AllCardsHealth = ({ loading, dataHealth, urlImage,detailHealth }) => {

    const [t,i18n] = useTranslation("global")
    return (
        <div className='contain-all-clinics'>
            {
                loading === true ?

                    <Loading size={100} />
                    :

                    (
                            dataHealth.length === 0 ?
                            <h2>{t('clinics.no_result')}</h2>
                            :

                                dataHealth.map((item, key) => {
                                return (
                                    <div onClick={()=>{detailHealth(item)}} key={key} className='card-clinics'>
                                        <img src={`${urlImage}${item.image}`} alt="" />
                                        <div className='card-clinic-info'>
                                            <div className='layout-clinics-a'>
                                                <div>
                                                    <h3>{item.name}</h3>
                                                    <p>{item.address}</p>
                                                </div>
                                                <div>
                                                    <span>{item.stars.toFixed(1)} <SvgIcon name={iconList.IconStar} color={'#ffff00'} size={15} /></span>
                                                </div>
                                            </div>
                                            <div className='layout-clinics-b'>
                                                <p>
                                                    <span key={key} style={{ color: 'rgb(134, 134, 134)', fontSize: '12px', fontWeight: '200' }} >
                                                        {item.city.name}
                                                    </span>
                                                </p>
                                                <span >{t("clinics.buttons.see_more")}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                    )

            }
        </div>
    )
}

export default AllCardsHealth