import { useState } from 'react'
import { iconList } from '../../../views/share/components/iconSVG';



function useMyTravels() {

  const [currentFilter, setCurrentFilter] = useState(1);


  const filterOptions = [
    {
      id: 1,
      name: 'Vuelos',
      icon: iconList.IconPlane
    },
    {
      id: 2,
      name: 'Hoteles',
      icon: iconList.IconHotel
    },
    {
      id: 3,
      name: 'Tours',
      icon: iconList.IconBag
    },
    {
      id: 4,
      name: 'Translados',
      icon: iconList.IconTaxi
    },
    {
      id: 5,
      name: 'Alquiler de autos',
      icon: iconList.IconRentaCar
    },
    {
      id: 6,
      name: 'Paquetes',
      icon: iconList.IconTravel
    },
  ]


  const dataTravel = [
    {
      type: 'travel',
      banner: 'https://media-cdn.tripadvisor.com/media/photo-s/16/1a/ea/54/hotel-presidente-4s.jpg',
      roundTrip: true
    },
    {
      type: 'travel',
      banner: 'https://media-cdn.tripadvisor.com/media/photo-s/16/1a/ea/54/hotel-presidente-4s.jpg',
      roundTrip: true
    },
    {
      type: 'travel',
      banner: 'https://media-cdn.tripadvisor.com/media/photo-s/16/1a/ea/54/hotel-presidente-4s.jpg',
      roundTrip: false
    },
  ]
  const dataTour = [
    {
      type: 'tour',
      banner: 'https://concepto.de/wp-content/uploads/2012/03/turismo-e1552499811477.jpg',
    },
    {
      type: 'tour',
      banner: 'https://concepto.de/wp-content/uploads/2012/03/turismo-e1552499811477.jpg',
    },
    {
      type: 'tour',
      banner: 'https://concepto.de/wp-content/uploads/2012/03/turismo-e1552499811477.jpg',
    },
  ]
  const dataHotel = [
    {
      type: 'hotel',
      banner: 'https://media-cdn.tripadvisor.com/media/photo-s/16/1a/ea/54/hotel-presidente-4s.jpg',
    },
    {
      type: 'hotel',
      banner: 'https://media-cdn.tripadvisor.com/media/photo-s/16/1a/ea/54/hotel-presidente-4s.jpg',
    },
    {
      type: 'hotel',
      banner: 'https://media-cdn.tripadvisor.com/media/photo-s/16/1a/ea/54/hotel-presidente-4s.jpg',
    },
  ]
  const dataTrasport = [
    {
      type: 'transportation',
      banner: 'https://cdn2.atraccion360.com/media/aa/styles/gallerie/public/images/2019/05/chofer01.jpg',
    },
  ]
  const dataVehicle = [
    {
      type: 'vehicle',
      banner: 'https://www.revistaautocrash.com/wp-content/uploads/2020/11/RENAULT-Logan-3-e1604937448766.jpg',
    },
    {
      type: 'vehicle',
      banner: 'https://www.revistaautocrash.com/wp-content/uploads/2020/11/RENAULT-Logan-3-e1604937448766.jpg',
    },
  ]




  const dataPackage = [
    {
      type: 'travel',
      banner: 'https://media-cdn.tripadvisor.com/media/photo-s/16/1a/ea/54/hotel-presidente-4s.jpg',
      roundTrip: false
    },
    {
      type: 'travel',
      banner: 'https://media-cdn.tripadvisor.com/media/photo-s/16/1a/ea/54/hotel-presidente-4s.jpg',
      roundTrip: true
    },
    {
      type: 'tour',
      banner: 'https://concepto.de/wp-content/uploads/2012/03/turismo-e1552499811477.jpg',
    },
    {
      type: 'hotel',
      banner: 'https://media-cdn.tripadvisor.com/media/photo-s/16/1a/ea/54/hotel-presidente-4s.jpg',
    },
    {
      type: 'transportation',
      banner: 'https://cdn2.atraccion360.com/media/aa/styles/gallerie/public/images/2019/05/chofer01.jpg',
    },
    {
      type: 'vehicle',
      banner: 'https://www.revistaautocrash.com/wp-content/uploads/2020/11/RENAULT-Logan-3-e1604937448766.jpg',
    },
  ]

  const data = {
    1: dataTravel,
    2: dataHotel,
    3:  dataTour,
    4: dataTrasport,
    5: dataVehicle,
    6: dataPackage,
  }

  const itemList = data[currentFilter];

  return {
    itemList,
    filterOptions,
    currentFilter,
    setCurrentFilter
  }
}

export default useMyTravels