import React from 'react'
import DetailsHealth from '../../ui/views/HealthWellness/DetailsHealth/DetailsHealth'

const DetailsHealthRoute = () => {
  return (
    <div>
       <DetailsHealth/>
    </div>
  )
}

export default DetailsHealthRoute