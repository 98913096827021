import React, { useEffect, useRef, useState } from 'react'
import styles from "./styles.module.css"
import SvgIcon, { iconList } from '@/ui/views/share/components/iconSVG'
import Collapsible from '../components/Collapsible/Collapsible';
import ProcedureModal from '../components/ProcedureModal/ProcedureModal';

const FormProcedure = ({
    camps,
    change,
    setFormData,
    isOpen,
    onOpen,
    formError,
    formData,
    formsFilter, FormErrors, uploadedImages, handleImageUpload, validateForm, index
}) => {


    const [height, setHeight] = useState(0);
    const contentRef = useRef(null);

    useEffect(() => {
        if (contentRef.current) {
            if (isOpen) {
                setHeight(contentRef.current.scrollHeight);  // Establece la altura del contenido cuando está abierto
            } else {
                setHeight(0);  // Colapsa a 0 cuando está cerrado
            }
        }
    }, [isOpen, formData]);

    


    return (
        <Collapsible title={camps?.procedimiento} index={index + 2} border={true} open={onOpen} isOpenT={isOpen} id={index}>

            <div ref={contentRef} className={styles.camps_proce}>
                <div className={styles.leftColumn}>
                    {camps?.formulario.slice(0, Math.ceil(camps.formulario.length / 2)).map((campo, idx) => {
                        // Verificar si el campo depende de otro y determinar si debe estar deshabilitado
                        const isDisabled = campo.dependsOn
                            ? formData?.[camps.procedimiento]?.[campo.dependsOn.key] !== campo.dependsOn.value
                            : false;

                        return (
                            <div
                                key={idx}
                                style={{
                                    flexDirection: campo?.type === 'checkbox' && !campo?.options && 'row-reverse',
                                    justifyContent: campo?.type === 'checkbox' && !campo?.options && 'flex-end',
                                    opacity: isDisabled ? 0.5 : 1, // Estilo visual para indicar que está deshabilitado
                                    display: isDisabled ? 'none' : ''
                                }}
                                className={styles.camp}
                            >
                                <label>{campo?.label}</label>

                                {/* Renderizar el campo según su tipo, con la validación de deshabilitar */}
                                {campo?.type === 'text' && (
                                    <input
                                        type="text"
                                        style={{ borderColor: formError[camps?.procedimiento]?.[campo?.key] && 'var(--redPrimary)' }}
                                        required={campo?.required}
                                        disabled={isDisabled}
                                        onChange={(e) => change(e, campo?.key, false, camps?.procedimiento, camps)}
                                    />
                                )}

                                {campo?.type === 'date' && (
                                    <input
                                        type="date"
                                        required={campo?.required}
                                        disabled={isDisabled}
                                        onChange={(e) => change(e, campo?.key, false, camps?.procedimiento, camps)}
                                    />
                                )}

                                {campo?.type === 'checkbox' && campo?.options && (
                                    <>
                                        {campo?.options?.map(option => (
                                            <div key={option.key} className={styles.checkboxCont}>
                                                <input
                                                    type="checkbox"
                                                    id={option.key}
                                                    disabled={isDisabled}
                                                    onChange={(e) => change(e, option.key, true, camps?.procedimiento, camps)}
                                                />
                                                <label htmlFor={option.key} style={{ marginLeft: '5px' }}>
                                                    {option.label}
                                                </label>
                                            </div>
                                        ))}
                                    </>
                                )}

                                {campo?.type === 'checkbox' && !campo?.options && (
                                    <div className={styles.checkboxCont}>
                                        <input
                                            type="checkbox"
                                            id={campo?.key}
                                            disabled={isDisabled}
                                            style={{ borderColor: formError[camps?.procedimiento]?.[campo?.key] && 'var(--redPrimary)' }}
                                            onChange={(e) => change(e, campo?.key, true, camps?.procedimiento, camps)}
                                        />
                                    </div>
                                )}

                                {/* Campo tipo select */}
                                {campo?.type === 'select' && campo?.options && (
                                    <select
                                        required={campo?.required}
                                        disabled={isDisabled}
                                        style={{ borderColor: formError[camps?.procedimiento]?.[campo?.key] && 'var(--redPrimary)' }}
                                        onChange={(e) => change(e, campo?.key, false, camps?.procedimiento, camps)}
                                    >
                                        <option value="">Selecciona una opción</option>
                                        {campo?.options.map((option, key) => (
                                            <option key={key} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                        );
                    })}
                </div>

                <div className={styles.rightColumn}>
                    {camps?.formulario.slice(Math.ceil(camps.formulario.length / 2)).map((campo, idx) => {
                        // Verificar si el campo depende de otro y determinar si debe estar deshabilitado
                        const isDisabled = campo.dependsOn
                            ? formData?.[camps.procedimiento]?.[campo.dependsOn.key] !== campo.dependsOn.value
                            : false;

                        return (
                            <div
                                key={idx}
                                style={{
                                    flexDirection: campo?.type === 'checkbox' && !campo?.options && 'row-reverse',
                                    justifyContent: campo?.type === 'checkbox' && !campo?.options && 'flex-end',
                                    opacity: isDisabled ? 0.5 : 1, // Estilo visual para indicar que está deshabilitado
                                    display: isDisabled ? 'none' : ''
                                }}
                                className={styles.camp}
                            >
                                <label>{campo?.label}</label>

                                {/* Renderizar el campo según su tipo, con la validación de deshabilitar */}
                                {campo?.type === 'text' && (
                                    <input
                                        type="text"
                                        style={{ borderColor: formError[camps?.procedimiento]?.[campo?.key] && 'var(--redPrimary)' }}
                                        required={campo?.required}
                                        disabled={isDisabled}
                                        onChange={(e) => change(e, campo?.key, false, camps?.procedimiento, camps)}
                                    />
                                )}

                                {campo?.type === 'date' && (
                                    <input
                                        type="date"
                                        required={campo?.required}
                                        disabled={isDisabled}
                                        onChange={(e) => change(e, campo?.key, false, camps?.procedimiento, camps)}
                                    />
                                )}

                                {campo?.type === 'checkbox' && campo?.options && (
                                    <>
                                        {campo?.options?.map(option => (
                                            <div key={option.key} className={styles.checkboxCont}>
                                                <input
                                                    type="checkbox"
                                                    id={option.key}
                                                    disabled={isDisabled}
                                                    onChange={(e) => change(e, option.key, true, camps?.procedimiento, camps)}
                                                />
                                                <label htmlFor={option.key} style={{ marginLeft: '5px' }}>
                                                    {option.label}
                                                </label>
                                            </div>
                                        ))}
                                    </>
                                )}

                                {campo?.type === 'checkbox' && !campo?.options && (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            id={campo?.key}
                                            disabled={isDisabled}
                                            style={{ borderColor: formError[camps?.procedimiento]?.[campo?.key] && 'var(--redPrimary)' }}
                                            onChange={(e) => change(e, campo?.key, true, camps?.procedimiento, camps)}
                                        />
                                    </div>
                                )}

                                {/* Campo tipo select */}
                                {campo?.type === 'select' && campo?.options && (
                                    <select
                                        required={campo?.required}
                                        disabled={isDisabled}
                                        style={{ borderColor: formError[camps?.procedimiento]?.[campo?.key] && 'var(--redPrimary)' }}
                                        onChange={(e) => change(e, campo?.key, false, camps?.procedimiento, camps)}
                                    >
                                        <option value="">Selecciona una opción</option>
                                        {campo?.options.map((option, key) => (
                                            <option key={key} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className={styles.containFormTitleItemImages}>
                <div className={styles.formTitleItemImages}>
                    <h4>Fotografías Requeridas ({camps?.procedimiento})* </h4>
                    <p>Estas son las indicaciones para lograr tomar las foto correctamente</p>
                </div>
                <ProcedureModal
                    formsFilter={formsFilter}
                    FormErrors={FormErrors}
                    uploadedImages={uploadedImages}
                    handleImageUpload={handleImageUpload}
                    validateForm={validateForm}
                />

                {formsFilter.map((_, index) => {
                    // Asegúrate de acceder correctamente al `item` correspondiente
                    const item = formsFilter[index]; // Si formsFilter tiene objetos que incluyen `procedimiento`

                    return (
                        FormErrors[item.procedimiento]?.require_images && (
                            <p key={index} style={{ color: "var(--redPrimary)" }}>
                                {FormErrors[item.procedimiento]?.require_images.message}
                            </p>
                        )
                    );
                })}

            </div>
        </Collapsible>
    )
}

export default FormProcedure