

import React from 'react'
import { Link as LinkScroll } from 'react-scroll'
import { ArrowDown2 } from 'iconsax-react'
import NuestrosAliados from './Assets/Img/NuestrosAliados.jpg'
import './Assets/styles.css'




const TurismHeader = () => {



    return (

        <div className='alliesmHeader'>

            <div className='allies-header-contain'>
                <div className='allies-bg-header' />
                <img src={NuestrosAliados}  />
            </div>


            <div className='container-header-allies-info'>
                <div className='title-header-allies'>
                    <h1>Nuestros aliados</h1>
                </div>

                <div className='turismHeader-info_text-label'>
                    <p>It is a long established fact tehat a reader will be distracted by the readable content of a page when loojing at its loyout it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here',making it look like readable English. Many desktop publishing packages and web page edit.</p>
                </div>
                <div className='header_button-container'>

                    <LinkScroll to='MainCategory' spy={true} smooth={true} offset={-120} duration={500}>
                        Ver Más 
                    </LinkScroll>
                    <span><ArrowDown2 size="32" color="#004274" /></span>
                </div>
            </div>


        </div>
    )
}

export default TurismHeader

