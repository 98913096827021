import React from 'react'
import "./styles.css"

const SendInvoices = () => {
    return (
        <div className='content-send-vouchers'>
            <section className='titles-vouchers'>
                <h2>¿A donde envianos tus vouchers?</h2>
                <p>El email que elijas será fundamental para que gestiones tu reserva y recibas información importante sobre tu viaje</p>
            </section>
            <form action="" className='form-send-vouchers'>
                <div className='camp-form-send-vouchers'>
                    <label htmlFor="">Email (Donde recibiras tus vouchers)</label>
                    <input type="text" placeholder="tuemail@gmail.com" />
                </div>
                <div className='camp-form-send-vouchers'>
                    <label htmlFor="">Confirma el email</label>
                    <input type="text" placeholder="tuemial@gmail.com" />
                </div>
            </form>
        </div>
    )
}

export default SendInvoices