import React from 'react';

import { ReactComponent as IconCheck } from '../Assets/svg/IconCheck.svg'

const ScreenSuccessPay = ({ checkVoucher, errorDescription, product, amount, dataVoucher, goToBuyAgain }) => {
  return (
    <div className="container-voucher-terrawind">
        {
        checkVoucher ?
        <div className="voucher-terrawind-error">
            <p>{errorDescription}</p>
        </div>
        :
        <div className="voucher-terrawind">
            <div className="title-generate-voucher">
                <p><span><IconCheck /></span>COMPRA FINALIZADA</p>
            </div>
            <div className="product-price-terrawind">
                <p>Gracias por tu compra. Tu transacción se ha completa con éxito</p>
                <p>Producto: <span>{product}</span></p>
                <p>Cantidad pagada: <span>{amount} COP</span></p>
            </div>
            <div className="container-table-terrawind">
                <table className="table-voucher-terrawind">
                    <thead>
                        <tr>
                            <th>Pasajero</th>
                            <th>Voucher</th>
                            <th>Descargar PDF</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Array.isArray(dataVoucher) ? (
                                dataVoucher.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.voucher_number}</td>
                                        <td><a href={`https://sandbox.setw.net/emision/voucher.php?voucher_number=${item.voucher_number}&voucher_key=${item.voucher_key}&change_lang=XX`} className="btn-download-terrawind">Descargar</a></td>
                                    </tr>
                                ))
                            ):(
                                <tr>
                                    <td>1</td>
                                    <td>{dataVoucher.voucher_number}</td>
                                    <td><a href={`https://sandbox.setw.net/emision/voucher.php?voucher_number=${dataVoucher.voucher_number}&voucher_key=${dataVoucher.voucher_key}&change_lang=XX`} className="btn-download-terrawind">Descargar</a></td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
            <div className="btn-buy-again">
                <button className="btn-download-terrawind" onClick={goToBuyAgain}>Volver a comprar</button>
            </div>
        </div>
    }
    </div>
  );
};

export default ScreenSuccessPay;
