export const pathIframe = [
  '/iframe/fligths',
  '/register',
  '/login',
  '/iframe/fligth',
  '/iframe/flight/search',
  '/iframe/flightPassengersForm',
  '/virtual-meeting-rooms/:entity/:id',
];

export  const pathWidget = [
  '/widget/fligths',
  '/widget/hotel',
  '/widget/hotel/:id',
  '/widget/hotels/:id',
  '/widget/reservation',
  '/widget/tours',
  '/widget/packages',
  '/widget/packages/:id',
]

export const widgetSefvicesList = [
  'fligths',
  'hotel',
  'tours',
  'packages',
];



export const widgetsList = [
  { path: '/widget/fligths', options: widgetSefvicesList  },
  { path: '/flight/search', options: widgetSefvicesList  },


  { path: '/widget/hotel', options: widgetSefvicesList  },
  { path: '/widget/hotels', options: widgetSefvicesList  },
  { path: '/widget/hotels/:id', options: widgetSefvicesList  },
  { path: '/widget/hotel/:id', options: widgetSefvicesList  },
  { path: '/widget/hotels/reservation', options: widgetSefvicesList  },


  { path: '/widget/tours', options: widgetSefvicesList  },


  { path: '/widget/packages', options: widgetSefvicesList  },
  { path: '/widget/packages/:id', options: widgetSefvicesList  },
]