import React from "react";
import TerrawindVaoucherDetail from "../../Components/TerrawindVoucherDetail/TerrawindVoucher";

function TerraWindVoucher(){
    return(
        <div>
            <TerrawindVaoucherDetail/>
        </div>
    )
}

export default TerraWindVoucher;