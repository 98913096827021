import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import CarouselSevices, { servicesTravel } from '../../share/components/CarouselServices/CarouselSevices'
import Find from '../../share/components/Find/Find'
import { useTranslation } from "react-i18next"
import CardPackages from './CardPackages'
import './styles.css'
import SearchPackages from '../SearchPackage/SearchPackages'
import SvgIcon, { iconList } from '../../share/components/iconSVG'

const ResultPackages = () => {

    const { state } = useLocation()
    const [t, i18n] = useTranslation("global")
    const [dropdownMobile, setDropdownMobile] = useState(false)

    return (
        <div>
            <Find />
            <CarouselSevices current={t("carousel_services.packages")} />
            <section className='search-packs-mobile'>
                <span className='contain-icon-search' onClick={()=>{
                    setDropdownMobile(!dropdownMobile)
                }}>
                    <SvgIcon name={iconList.IconSearch} color={''} size={30} />
                </span>
                {
                    dropdownMobile &&
                    <div className='contain-search-pack-mobile'>
                        <SearchPackages width={100} />
                    </div>
                }
            </section>
            <section className='contain-layout-results-pack'>
                <div className='contain-search-pack'>
                    <SearchPackages width={100} />
                </div>
                <div className='contain-all-pack-results' >

                    {
                        state.data.length === 0 ?

                            <h2 style={{ textAlign: "center" }}>
                                No hay resultados disponibles
                            </h2>
                            :
                            state.data.map((item, key) => {
                                return (
                                    <CardPackages key={key} data={item}  />
                                )
                            })
                    }
                </div>
            </section>

        </div>
    )
}
export default ResultPackages