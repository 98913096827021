import React, { useState } from 'react'
import "./assets/styles.css"
import { generarId, formatearFecha } from './helpers/index'
import Modal from "./Modal"
import Paginado from './Paginado'


const ServicesFarmacia = ({ data }) => {
  const [modal, setModal] = useState(false)
  const [gastoEditar, setGastoEditar] = useState({})
  const [animarModal, setAnimarModal] = useState(false)
  const [gastos, setGastos] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
    const [mostrarNext, setMostrarNext] = useState(true)
    const itemsForPage = 10
  
    const [topic, setTopic] = useState([...data].splice(0,itemsForPage))
    
    const nextHandler = ()=>{
    const totalElementos = data.length;

    const nextPage = currentPage + 1

    const firtsIndex = nextPage * itemsForPage
    if(firtsIndex >= totalElementos) {
        setMostrarNext(false)
      return;
    }
    
    
    setCurrentPage(nextPage)
    setTopic([...data].splice(firtsIndex,itemsForPage))

  }
  const prevHandler = ()=>{
    setMostrarNext(true)
   const prevPage = currentPage - 1
   
   if(prevPage < 0)return;

   const firtsIndex = prevPage * itemsForPage

   setTopic([...data].splice(firtsIndex,itemsForPage))
    setCurrentPage(prevPage)
  }

  const handleNuevoGasto = () => {

    modal ? setModal(false) : setModal(true)

    setGastoEditar({})

    setTimeout(() => {
      setAnimarModal(true)
    }, 500)

  }

  const guardarGasto = gasto => {
    gasto.id = generarId()
    gasto.fecha = formatearFecha(Date.now())
    setGastos([...gastos, gasto])

    setAnimarModal(false)

    setTimeout(() => {
      modal ? setModal(false) : setModal(true)
    }, 500)
  }
  return (
    <div>
      <div className='container-header-farmacia'>
        <div className='header-services'>
          <div className='principal-text'>
            <h1>Servicios Farmaceuticos</h1>
            <p>"La mayor calidad en productos podras encontrarla aca."</p>
          </div>
        </div>
      </div>
      <div className='container-cards-services'>




        {
          topic.map(topic => {
            return (
              <div>
                  <div class="card-farmacia">
                    <div class="card-header-farmacia">
                      <img src={topic.logo} alt="city" />
                    </div>
                    <div class="card-body-farmacia">
                      <span class="tag-farmacia tag-pink-farmacia">{topic.city}</span>
                      <h5>
                        {topic.address}
                      </h5>
                      <div class="user-farmacia">
                        <img src={topic.logo} alt="user" />
                        <div class="user-info-farmacia">
                          <h4>{topic.name}</h4>
                          <small>Telefono: {topic.phone}</small>
                        </div>
                      </div>
                    </div>
                    <div className='contain-button-services'>
                    <input  type="submit" onClick={()=>{handleNuevoGasto()}} value={"Agendar servicio"} />
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
      {modal &&
        <Modal
          gastoEditar={gastoEditar}
          setModal={setModal}
          modal={modal}
          animarModal={animarModal}
          setAnimarModal={setAnimarModal}

        />

      }
       <Paginado 
        nextHandler={nextHandler} 
        prevHandler={prevHandler} 
        currentPage={currentPage} 
        mostrarNext={mostrarNext}
        >

        </Paginado>
    </div>
  )
}

export default ServicesFarmacia