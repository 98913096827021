import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import SvgIcon,{iconList} from '../../share/components/iconSVG'
import Find from '../../share/components/Find/Find'
import CarouselSevices,{servicesTravel} from '../../share/components/CarouselServices/CarouselSevices'
import "./styles.css"
import { currencyFormat } from '../../../utils'

const DetailsPharmacy = () => {

  const {
    state
  } = useLocation()
  
  

  
  return (


    <div>
      <ul>
      <Find />
      <div className='detail-hotel-route' style={{width:"90%"}}>
        <p>
          <Link to={'/Pharmacy'}>Farmacia</Link> {">"} <span>{state.name}</span>
        </p>
      </div>
      <div className='contain-detail-recovery-house'>
        <section className='contain-img-detail-recovery'>
          <img src={`${state.urlImage}${state.pathImage}`} alt={state.name} className='img-detail-recovery shadow-img-pharmacy' />
        </section>
        <section className='contain-info-detail-recovery'>
          <div className='first-section-detail'>
            <h1>{state.name}</h1>
            <div className='star-hotel-card'>
            <span>
              {state.quantity}
            </span>
            <span>
              {currencyFormat(state.price).total}
            </span>
          </div>
            <p>
              Descripcion:
              <br/>
              <span>
                {state.description}
              </span>
            </p>
          </div>
          <div>
            <button>Realizar Pedido</button>
          </div>


        </section>
      </div>
      <CarouselSevices current={servicesTravel[12].title} />
        <li>{state.name}</li>
        <li>{state.description}</li>
        <li>{state.id}</li>
        <li>{state.price}</li>
      </ul>
    </div>
  )
}

export default DetailsPharmacy