import React from 'react'
import TravelLanding from '../../Components/TravelLanding/TravelLanding'

const TravelRoute = () => {
  return (
    <div>
        <TravelLanding></TravelLanding>
    </div>
  )
}

export default TravelRoute