import React, { useState } from 'react'
import "./styles.css"
import SvgIcon, { iconList } from '../../../../share/components/iconSVG'
import { useTranslation } from 'react-i18next'

const SelectProcedures = ({procedure,setProcedure}) => {

    const [modalProcedures, setModalProcedures] = useState(false)

    const procedures = [
        "Liposuccion",
        "Abdominoplastia",
        "Rinoplastia",
        "Mamarias",
        "Bleroplastia",
        "Bichetomia"
    ]

    const [selectProcedures, setSelectProcedures] = useState("")

    const [t] = useTranslation("global")

    return (
        <div className='content-select-procedures'>
            <h3 className='title-select-procedures'>
                {t("specialist.booking_flow.tratement_your_like")}
            </h3>
            <section className='contain-camp-select-procedures'>
                <label htmlFor="">{t("specialist.booking_flow.tratement")}</label>
                <div className='camp-select-procedures' onClick={() => {
                    setModalProcedures(!modalProcedures)
                }}>
                    <span>{procedure === "" ? t("specialist.booking_flow.select_tratement") : procedure}</span>
                    <SvgIcon name={iconList.IconArrowDown2} size={20} color={"#004274"} />
                    {
                        modalProcedures &&
                        <div className='modal-procedures-select'>
                            <ul>
                                {
                                    procedures.map((index,key)=>{
                                        return(
                                            <li key={key} onClick={()=>{setProcedure(index)}}>{index}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    }
                </div>

            </section>
            <section className='contain-procedures-popular'>
                <h3>{t("specialist.booking_flow.tratement_popular")}</h3>
                <div className='content-procedures-popular'>
                    <span onClick={()=>{
                        setProcedure("Lipoabdominoplastia")
                    }}>Lipoabdominoplastia</span>
                    <span onClick={()=>{
                        setProcedure("Mamoplastia")
                    }}>Mamoplastia</span>
                    <span onClick={()=>{
                        setProcedure("Lipotransferencia")
                    }}>Lipotransferencia</span>
                    <span onClick={()=>{
                        setProcedure("Rinoplastia")
                    }}>Rinoplastia</span>
                </div>
            </section>
        </div>
    )
}

export default SelectProcedures