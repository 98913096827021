import React from "react";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";

const DatePicker = ({ show, hidden, selectedDate, setSelectedDate, isBirthday, setBirthDate, setOperationDate }) => {
  const [t] = useTranslation("global");

  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date);
      const formattedDate = formatDate(date);
      if(setBirthDate) {
        setBirthDate(formattedDate);
      }
      if(setOperationDate) {
        setOperationDate(formattedDate);
      }
      console.log(formattedDate);
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const validSelectedDate = selectedDate || new Date();

  const minDate = isBirthday ? new Date(1900, 0, 1) : new Date(new Date().setDate(new Date().getDate() + 1));

  return (
    <div className={styles.dateRange}>
      <div className={styles.dateRangeOnly}>
        <Calendar
          date={validSelectedDate}
          onChange={handleDateChange}
          minDate={minDate}
          maxDate={isBirthday ? new Date() : new Date(2100, 11, 31)}
          className="custom-calendar"
        />
      </div>
      <div className={styles.containButtonSelectedDate}>
        <button onClick={() => hidden(false)}>
          {selectedDate ? "Date selected" : "Select the date"} {validSelectedDate.toLocaleDateString("es-ES")}
        </button>
      </div>
    </div>
  );
};

export default DatePicker;
