import AsyncStorage from '@react-native-async-storage/async-storage';
const keys = {
  authUserSession : '@authUserSession',
  location: '@location',
  servicesMenu: '@servicesMenu',
  isWidget: '@isWidget',
}

//establece data
async function set(key, data){
  try {
    const dataExist = !! data;
    if(dataExist){
      const parseData = JSON.stringify(data);
      await AsyncStorage.setItem(key,parseData);
    } 
    return true;
  } catch (error) {
    console.error(`AsyncStorage. error set -> ${key}`,error)
  }
}

//obtener data
async function get(key){
  try {
    const result = await AsyncStorage.getItem(key);
    return result !== null ? JSON.parse(result): result;
  } catch (error) {
    console.error(`AsyncStorage. error get -> ${key}`,error)
  }
}

//borrar data
async function reset(key){
  try {
    await AsyncStorage.removeItem(key);
    return true;
  } catch (error) {
    console.error(`AsyncStorage. error reset -> ${key}`,error)
  }
}

export {
    set,
    get,
    reset,
    keys
};
