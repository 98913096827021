import React from 'react'
import "./styles.css"

const PhonePackages = () => {
    return (
        <div className='content-phone-packages'>
            <h2>¿A que numero podemos llamarte?</h2>
            <div className='camp-passenger-form' style={{ marginBottom: "1rem" }}>
                <label htmlFor="">Codigo de pais</label>
                <input type="text" placeholder='+57' />
            </div>
            <div className='camp-passenger-form' style={{ marginBottom: "1rem" }}>
                <label htmlFor="">Numero</label>
                <input type="text" placeholder='Ingresa el numero' />
            </div>
        </div>
    )
}

export default PhonePackages