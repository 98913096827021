import React, { useEffect, useState } from 'react';
import './styles.css';
import SvgIcon, { iconList } from '../../../iconSVG/index';

const FiltersResult = ({ dataT, data, setData, position, top, setDropdown }) => {
    const filterStars = (stars) => {
        let result = [];

        if (stars === 'all') {
            result = [...dataT];
        } else {
            result = dataT.filter((item) => {
                if (item.stars) {
                    return Math.floor(item.stars) === stars;
                } else if (item.information_doctor?.stars) {
                    return Math.floor(item.information_doctor.stars) === stars;
                }
                return false;
            });
        }

        setData(result.slice(0, 10));
       setDropdown(false)
    };

    useEffect(() => {
        
    }, [data])


    return (
        <div className='contain-filters-global' style={{position: position, top: top}}>
            <h2>Estrellas</h2>
            <div className='contain-list-stars'>
                <ul>
                    <li>
                        <input type='radio' name='grupo' id='opcion1' onClick={() => filterStars('all')} />
                        <label htmlFor='opcion1'>Todas las Estrellas</label>
                    </li>
                    <li>
                        <input type='radio' name='grupo' id='opcion2' onClick={() => filterStars(5)} />
                        <label htmlFor='opcion2'>
                            <SvgIcon color={'#FFD902'} name={iconList.IconStar} size={15} />
                            <SvgIcon color={'#FFD902'} name={iconList.IconStar} size={15} />
                            <SvgIcon color={'#FFD902'} name={iconList.IconStar} size={15} />
                            <SvgIcon color={'#FFD902'} name={iconList.IconStar} size={15} />
                            <SvgIcon color={'#FFD902'} name={iconList.IconStar} size={15} />
                        </label>
                    </li>
                    <li>
                        <input type='radio' name='grupo' id='opcion3' onClick={() => filterStars(4)} />
                        <label htmlFor='opcion3'>
                            <SvgIcon color={'#FFD902'} name={iconList.IconStar} size={15} />
                            <SvgIcon color={'#FFD902'} name={iconList.IconStar} size={15} />
                            <SvgIcon color={'#FFD902'} name={iconList.IconStar} size={15} />
                            <SvgIcon color={'#FFD902'} name={iconList.IconStar} size={15} />
                        </label>
                    </li>
                    <li>
                        <input type='radio' name='grupo' id='opcion4' onClick={() => filterStars(3)} />
                        <label htmlFor='opcion4'>
                            <SvgIcon color={'#FFD902'} name={iconList.IconStar} size={15} />
                            <SvgIcon color={'#FFD902'} name={iconList.IconStar} size={15} />
                            <SvgIcon color={'#FFD902'} name={iconList.IconStar} size={15} />
                        </label>
                    </li>
                    <li>
                        <input type='radio' name='grupo' id='opcion5' onClick={() => filterStars(2)} />
                        <label htmlFor='opcion5'>
                            <SvgIcon color={'#FFD902'} name={iconList.IconStar} size={15} />
                            <SvgIcon color={'#FFD902'} name={iconList.IconStar} size={15} />
                        </label>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default FiltersResult;