import { useState, useEffect } from 'react';
import styles from './styles.module.css';
import ModalContainer from '@/ui/views/share/components/modalContainer';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useNavigate, useLocation } from 'react-router-dom';

const FlightTimer = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const initialTimeInSeconds = 1200; // 20 minutos
    const [timeLeft, setTimeLeft] = useState(initialTimeInSeconds);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const { controlNumberPNR } = state || {};
        // const savedPNR = localStorage.getItem('savedPNR');
        // const savedTime = localStorage.getItem('purchaseTime');
        // const savedTimestamp = localStorage.getItem('purchaseTimestamp');
        const now = Date.now();
        resetTimer(controlNumberPNR);
        // if (controlNumberPNR && controlNumberPNR !== savedPNR) {
        //     resetTimer(controlNumberPNR);
        // } else if (savedTime && savedTimestamp) {
        //     const timeElapsed = Math.floor((now - parseInt(savedTimestamp, 10)) / 1000);
        //     const updatedTimeLeft = Math.max(initialTimeInSeconds - timeElapsed, 0);
        //     setTimeLeft(updatedTimeLeft);

        //     if (updatedTimeLeft === 0) {
        //         handleTimeout();
        //     }
        // } else {
        //     resetTimer(controlNumberPNR);
        // }
    }, [state]);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prev) => {
                const newTimeLeft = prev - 1;
                // localStorage.setItem('purchaseTime', newTimeLeft.toString());

                if (newTimeLeft <= 0) {
                    clearInterval(timer);
                    handleTimeout();
                }

                return newTimeLeft;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);


    // Limpiar el timer al salir o redirigir
    useEffect(() => {
        const handleUnload = () => {
            clearTimer();
        };

        // Detectar cuando el usuario sale de la página o cambia de ruta
        window.addEventListener('beforeunload', handleUnload);
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, []);

    const clearTimer = () => {
        localStorage.removeItem('savedPNR');
        localStorage.removeItem('purchaseTime');
        localStorage.removeItem('purchaseTimestamp');
    };

    const resetTimer = (controlNumberPNR) => {
        const now = Date.now();
        // localStorage.setItem('savedPNR', controlNumberPNR);
        // localStorage.setItem('purchaseTime', initialTimeInSeconds.toString());
        // localStorage.setItem('purchaseTimestamp', now.toString());
        setTimeLeft(initialTimeInSeconds);
    };

    const handleTimeout = () => {
        setIsModalOpen(true);
        const urlFlights = localStorage.getItem('urlFlights');

        resetTimer(state?.controlNumberPNR);

        setTimeout(() => {
            navigate(urlFlights, { replace: true });
        }, 5000);
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    return (
        <>
            <div className={`${styles.purchaseTimer} ${!timeLeft ? styles.expiredTime : ""}`}>
                {timeLeft > 0 ? (
                    <h2 className={styles.timeLeft}>Tiempo estimado:
                        <span>
                            {formatTime(timeLeft)}
                        </span>
                    </h2>
                ) : (
                    <h2 className={styles.expired}>El tiempo se ha agotado.</h2>
                )}
            </div>

            <ModalContainer show={isModalOpen} width={500} hidden={setIsModalOpen}>
                <div className={styles.modalAlertTime}>
                    <h2>El tiempo se ha agotado</h2>
                    <p>Por favor, vuelva a realizar la compra.</p>

                    <div className={styles.loadContainer}>
                        <AiOutlineLoading3Quarters className={styles.load} size={30} />
                        Redireccionando...
                    </div>
                </div>
            </ModalContainer>
        </>
    );
};

export default FlightTimer;
