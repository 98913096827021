import React from 'react'
import Detailsclinics from '../../ui/views/clinics/DetailsClinics/Detailsclinics'

const DetailsClinicsRoute = () => {
  return (
    <div>
        <Detailsclinics/>
    </div>
  )
}

export default DetailsClinicsRoute