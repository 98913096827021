import {useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom'


const UseFind = () => {


const {state, pathname}= useLocation()

const itemsForPage = 10;
const [dataHotels, setDataHotels] = useState(state.data.slice(0, itemsForPage));
const [services, setServices] = useState(state.data);
const [currentPage, setCurrentPage] = useState(0);
const [mostrarNext, setMostrarNext] = useState(true);

useEffect(() => {
  setDataHotels(state?.data.slice(0, itemsForPage))
  setServices(state?.data)
  window.scrollTo(0, 0);
}, [pathname])


const nextHandler = () => {
  const totalElementos = services.length;
  const nextPage = currentPage + 1;
  const firstIndex = nextPage * itemsForPage;
  
  if (firstIndex >= totalElementos) {
    setMostrarNext(false);
    return;
  }

  setCurrentPage(nextPage);
  setDataHotels(services.slice(firstIndex, firstIndex + itemsForPage));
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 500);
};

const prevHandler = () => {
  setMostrarNext(true);
  const prevPage = currentPage - 1;

  if (prevPage < 0) return;

  const firstIndex = prevPage * itemsForPage;

  setDataHotels(services.slice(firstIndex, firstIndex + itemsForPage));
  setCurrentPage(prevPage);
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 500);
  
};

  return {
    prevHandler,
    nextHandler,
    mostrarNext,
    currentPage,
    dataHotels,
    setDataHotels,
    services
  }
}

export default UseFind