import React from "react";
import Find from "../share/components/Find/Find";
import CarouselSevices, {
  servicesTravel,
} from "../share/components/CarouselServices/CarouselSevices";
import BannerRecovery from "./BannerRecoveryHouse/BannerRecoveryHouse";
import UseRecovery from "../../viewModels/RecoveryHouse/UseRecovery";
import AllCardsRecovery from "./AllCardRecovery/AllCardsRecovery";
import Paginado from "./Paginado/Paginado";
import ButtonsFilters from "./ButtonsFilters/ButtonsFilters";
import { useTranslation } from "react-i18next";
import AnyQuestions from "../../../ui/views/home/components/anyQuestion";

const RecoveryHouse = () => {
  const [t, i18n] = useTranslation("global");
  const {
    dataRecovery,
    urlImage,
    currentPage,
    nextHandler,
    prevHandler,
    mostrarNext,
    services,
    loading,
    personalizeFind,
    detailRecovery,
  } = UseRecovery();

  return (
    <div>
      <Find />
      <CarouselSevices current={t("carousel_services.recovery_house")} />
      <BannerRecovery />
      <ButtonsFilters filterNamePharmacy={personalizeFind} />
      <AllCardsRecovery
        dataRecovery={dataRecovery}
        loading={loading}
        urlImage={urlImage}
        detailRecovery={detailRecovery}
      />
      <Paginado
        currentPage={currentPage}
        data={services}
        mostrarNext={mostrarNext}
        nextHandler={nextHandler}
        prevHandler={prevHandler}
      />
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default RecoveryHouse;
