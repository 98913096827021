import React from 'react';

import { ReactComponent as IconProcess } from "../Assets/svg/IconProcess.svg"

const ScreenProcessPay = ({product, amount, goToBuyAgain}) => {
  return (
    <div className="container-voucher-terrawind">
        <div className="voucher-terrawind-error pay-rejected-terrawind">
            <div>
                <p className="title-p-pay-process-terrawind"><span><IconProcess/></span> Compra en proceso</p>
            </div>
            <div className="details-fail-pay">
                <p>Tu compra está en proceso. Gracias por tu paciencia. ¡Pronto tendrás noticias!</p>
                <p>Producto: <span>{product}</span></p>
                <p>Cantidad a pagar: <span>{amount}</span></p>
            </div>
            <div className="btn-buy-again">
                <button className="btn-download-terrawind" onClick={goToBuyAgain}>Volver a comprar</button>
            </div>
        </div>
    </div>
  );
};

export default ScreenProcessPay;
