import React from "react";
import styles from "./styles.module.css";
import Collapsible from "@/ui/views/BuildPackage/pages/std/components/Collapsible/Collapsible";
import AnyQuestions from "@/ui/views/home/components/anyQuestion";
import { useTranslation } from "react-i18next";
import ReturnPage from "../return/ReturnPage";
import CarouselSevices from "../CarouselServices/CarouselSevices";

const TermAndConditions = () => {
  const [t] = useTranslation("global");
  const questtions = [
    {
      question: "¿Como tener informacion de mi vuelo?",
      info: "Te llegara info al correo con los ticketes de abordaje",
    },
    {
      question: "¿Como tener informacion de mi vuelo?",
      info: "Te llegara info al correo con los ticketes de abordaje",
    },
    {
      question: "¿Como tener informacion de mi vuelo?",
      info: "Te llegara info al correo con los ticketes de abordaje",
    },
    {
      question: "¿Como tener informacion de mi vuelo?",
      info: "Te llegara info al correo con los ticketes de abordaje",
    },
  ];

  return (
    <>
    <CarouselSevices current={t("carousel_services.fligth")} />
    <ReturnPage />
      <div className={styles.container}>
        <div className={styles.methodPayHeader}>
          <h3>Terminos y condiciones</h3>
          <p>Reglas y políticas que rigen el uso de nuestros servicios.</p>
        </div>

        <div className={styles.contain}>
          <div className={styles.methodPayContainerTermsContain}>
            <div className={styles.methodPayContainTermsContain}>
              <div className={styles.methodTerms}>
                <h6>1. Introducción</h6>
                <p>content: "Bienvenido a nuestro servicio. Estos términos y condiciones describen las reglas y regulaciones para el uso del sitio web. Al acceder a este sitio web, asumimos que aceptas estos términos y condiciones en su totalidad. No continúes usando nuestro sitio web si no aceptas todos los términos y condiciones establecidos en esta página."</p>
              </div>

              <div className={styles.methodTerms}>
                <h6>2. Licencia</h6>
                <p>A menos que se indique lo contrario, los propietarios de este sitio web y sus licenciantes son propietarios de los derechos de propiedad intelectual de todo el contenido en el sitio web. Todos los derechos de propiedad intelectual están reservados.</p>
              </div>

              <div className={styles.methodTerms}>
                <h6>3. Restricciones</h6>
                <p>Estás específicamente restringido de todo lo siguiente: Publicar cualquier material del sitio web en cualquier otro medio; vender, sublicenciar y / o comercializar cualquier material del sitio web; usar este sitio web de cualquier manera que tenga o pueda tener un efecto negativo en el rendimiento del sitio web; utilizar este sitio web en contra de las leyes y regulaciones aplicables, o de cualquier manera que pueda causar daño al sitio web o a cualquier persona o entidad.</p>
              </div>
            </div>
          </div>
          <div className={styles.methodPayContainQuestionsContain}>
            <h5>Preguntas frecuentes</h5>
            <div className={styles.methodPayContainQuestionsContainCollapsible}>
              {questtions.map((question, index) => (
                <Collapsible key={index} border={false} title={question.question}>
                  {question.info}
                </Collapsible>
              ))}
            </div>
          </div>
        </div>
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </>
  );
};

export default TermAndConditions;
