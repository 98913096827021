import React from 'react'
import AlliesDetailsHeader from '../../Components/AlliesDetailsHeader/AlliesDetailsHeader'
import AlliesDetailsMain from '../../Components/AlliesDetailsMain/AlliesDetailsMain'

const Allies = () => {
  return (
    <div style={{margin:0, padding:0}}>
      <AlliesDetailsHeader />
        <AlliesDetailsMain />
    </div>
        
   
  )
}

export default Allies
