import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import "./Assets/Styles.css";

const DateRangePickerComponentSura = ({ setDateStart, setDateEnd, setValidate, validate }) => {
    const [isPickerVisible, setPickerVisible] = useState(false);
    const [datePicks, setDatePicks] = useState([]);
    const [state, setState] = useState([{ startDate: new Date(), endDate: null, key: 'selection' }]);
    const [days, setDays] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const handleButtonClick = () => {
        setPickerVisible(true);
        setDatePicks([]);
        setStartDate("");
        setEndDate("");
    }

    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const handleSelect = (ranges) => {
        const newDatePicks = [...datePicks, ranges.selection];
        if (newDatePicks.length >= 2) {
            setPickerVisible(false);
        }
        setDatePicks(newDatePicks);
        setState([ranges.selection]);

        const startDate = new Date(ranges.selection.startDate);
        const endDate = new Date(ranges.selection.endDate);
        setStartDate(formatDate(startDate));
        setEndDate(formatDate(endDate));

        const diffTime = Math.abs(endDate - startDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; 
        setDays(diffDays);

        setDateStart(formatDate(startDate));
        setDateEnd(formatDate(endDate));
        setValidate("");
    }

    return (
        <div className="input" style={{ position: 'relative', width: "100%" }}>
            <label htmlFor="">Salida y regreso</label>
            <button onClick={handleButtonClick} className={validate == "date" ? "calendar-button input-style input-validate": "calendar-button input-style"}>
                {startDate && endDate ? `${startDate} - ${endDate}` : "Salida y regreso"}
            </button>
            { validate === "date" ? (<p className="validate-p">El campo es requerido</p>): "" }
            {isPickerVisible && (
                <div style={{ position: 'absolute', zIndex: 999, width: "100%" }}>
                    <DateRangePicker
                        ranges={state}
                        onChange={handleSelect}
                        minDate={new Date()}
                    />
                </div>
            )}
        </div>
    );
};

export default DateRangePickerComponentSura;
