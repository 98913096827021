import React from "react";
import Find from "../share/components/Find/Find";
import CarouselSevices from "../share/components/CarouselServices/CarouselSevices";
import { Link } from "react-router-dom";
import banner from "../../assets/pictures/bannerAmbulance.png";
import SvgIcon, { iconList } from "../share/components/iconSVG";
import { useTranslation } from "react-i18next";
import SearchAmbulance from "./searchAmbulance/SearchAmbulance";
import "./styles.css";
import AnyQuestions from "../../../ui/views/home/components/anyQuestion";

const Ambulance = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <div className="contain-ambulance">
        <Find />
        <CarouselSevices current={t("carousel_services.ambulance")} />
        <header className="header-fligths">
          <div className="content-buscador-fli">
            <SearchAmbulance />
          </div>
          <div className="content-banner-t">
            <img src={banner} alt="" />
          </div>
        </header>
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default Ambulance;
