import React, { useEffect, useState, useCallback, memo } from "react";
import Logo from "./Assets/svg/Terrawind.svg"
import PassengerAgeComponent from "./PassengerAgeComponent/PassengerAgeComponent";
import DateRangePickerComponent from "./DateRangePickerComponent/DateRangePickerComponent";
import SelectDestination from "./SelectDestination/SelectDestination";
import SelectOrigin from "./SelectOrigin/SelectOrigin";

import { useNavigate } from "react-router-dom";

import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"
import "./Assets/styles.css";

const SelectOriginMemo = memo(SelectOrigin);
const SelectDestinationMemo = memo(SelectDestination);
const DateRangePickerComponentMemo = memo(DateRangePickerComponent);
const PassengerAgeComponentMemo = memo(PassengerAgeComponent);

function FormPlan({isModalOpen, closeModal}){

    const [origin, setOrigin] = useState("");
    const [destination, setDestination] = useState("");
    const [dateEnd, setDateEnd] = useState("");
    const [dateStart, setDateStart] = useState("");
    const [email, setEmail] = useState("");
    const [passengerAges, setPassengerAges] = useState([]);
    const [validate, setValidate] = useState("");

    const navigate = useNavigate();

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const validateForm = useCallback(() => {
        if(origin === ""){
            return "origin";
        }else if(destination === ""){
            return "destination";
        }else if(dateStart === "" || dateEnd === ""){
            return "date";
        }else if(email === "" || !validateEmail(email)){
            return "email";
        }else if(passengerAges.length === 0){
            return "age";
        }else{
            return "";
        }
    }, [origin, destination, dateStart, dateEnd, email, passengerAges]);

    const postPlan = useCallback(async () => {
        const validationError = validateForm();
        if (validationError !== "") {
            setValidate(validationError);
            return;
        }

        const data = {
            "passengers": passengerAges.length,
            "date_from": dateStart,
            "date_to": dateEnd,
            "passengers_ages": passengerAges,
            "destination": destination,
            "origin": origin
        }

        const encodedData = encodeURIComponent(JSON.stringify(data));
        navigate(`/travelSecure/terrawind/${encodedData}`);
        window.scrollTo(0, 0);
    }, [validateForm, passengerAges, dateStart, dateEnd, destination, origin, navigate]);

    const handleClickOutside = (event) => {
        setOrigin("");
        setDestination("");
        setDateEnd("");
        setDateStart("");
        setEmail("");
        setPassengerAges([]);
        event.stopPropagation();
        closeModal();
    }

    const handleClickedInside = (event) => {
        event.stopPropagation();
    }

    if(!isModalOpen){
        return null;
    }

    return(
        <div className="form-plan-container" onClick={handleClickOutside}>
            <div className="form-header-container" onClick={handleClickedInside}>
                <div>
                    <p>COTIZA TU ASISTENCIA DE VIAJE</p>
                </div>
                <div>
                    <img src={Logo} alt="Terrawind" />
                </div>
            </div>
            <div className="form-plan" onClick={handleClickedInside}>
                <div className="place-date-day">
                    <div className="container-select-place">
                        <div>
                            <SelectOriginMemo 
                                origin={origin}
                                setOrigin={setOrigin} 
                                validate={validate}
                                setValidate={setValidate}
                            />
                        </div>
                        <div>
                            <SelectDestinationMemo 
                                destination={destination}
                                setDestination={setDestination}
                                validate={validate}
                                setValidate={setValidate}
                            />
                        </div>
                    </div>
                    <div className="container-select-date">
                        <DateRangePickerComponentMemo 
                            setDateStart={setDateStart} 
                            setDateEnd={setDateEnd} 
                            setValidate={setValidate} 
                            validate={validate}
                        />
                    </div>
                </div>
                <div className="email-multi-trip">
                    <div className="input-multi-trip">
                        <label className="label-multi-trip" htmlFor="">Multiviajes</label>
                        <input type="checkbox" id="checkbox" />
                        <label htmlFor="checkbox"></label>
                    </div>
                    <div className="input-email-terrawind">
                        <label htmlFor="">Recibe la cotización en tu email</label>
                        <input 
                            type="email" 
                            className={ validate == "email" ? "input-validate": null } 
                            placeholder="E-mail" 
                            value={email} 
                            onChange={(e) => {
                                setEmail(e.target.value);
                                if (e.target.value === "" || !validateEmail(e.target.value)) {
                                    setValidate("email");
                                } else {
                                    setValidate("");
                                }
                            }}
                        />
                        { validate === "email" ? (<p className="validate-p">El campo es requerido</p>): ""}
                    </div>
                </div>
                <div className="destinatio-age">
                    <PassengerAgeComponentMemo 
                        passengerAges={passengerAges} 
                        setPassengerAges={setPassengerAges} 
                        setValidate={setValidate}
                        validate={validate} 
                    />
                </div>
                <div className="quote-now">
                    <button className="input-style button-quote" onClick={postPlan}>Cotizar</button>
                </div>
            </div>
        </div>
    )
}

export default FormPlan;