import React from 'react'
import Cosmetics from '../../ui/views/Cosmetics/Cosmetics'

const CosmeticsRoute = () => {
  return (
    <div>
        <Cosmetics/>
    </div>
  )
}

export default CosmeticsRoute