import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosArrowRoundForward,
} from "react-icons/io";
import { HiArrowLongRight } from "react-icons/hi";
import "./assets/styles.css";
import { useTranslation } from "react-i18next";
import TextComponent from "../../ui/views/share/components/fonts/TextComponent";
import ContainerSeparations from "../ContainerSeparations/ContainerSepartaion";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";

const NewsSlider = () => {
  const t = useTranslation("global")[0];

  const cards = [
    {
      img: "https://www.semana.com/resizer/8tovOrQy5xi5LilM3LrmslxUpQo=/1280x720/smart/filters:format(jpg):quality(80)/cloudfront-us-east-1.images.arcpublishing.com/semana/7T25MNDXIBANXKU6MUAHX6CLQM.jpg",
      fecha: "11:46 AM",
      text: t("browse_home.news_wellezy.firts_new"),
      link: "https://www.semana.com/economia/empresas/articulo/la-aplicacion-que-busca-impulsar-el-turismo-en-salud-dentro-de-colombia/202339/",
    },
    {
      img: "https://i0.wp.com/folou.co/wp-content/uploads/2023/02/Odolontologia.jpg?w=1200&ssl=1",
      fecha: "11:46 AM",
      text: t("browse_home.news_wellezy.second_new"),
      link: "https://folou.co/mundo/la-plataforma-que-impulsa-el-turismo-de-salud-en-colombia/",
    },
    {
      img: "https://www.portafolio.co/files/article_content/uploads/2023/03/11/640c0c1c3523a.jpeg",
      fecha: "12:07 PM",
      text: t("browse_home.news_wellezy.three_new"),
      link: "https://www.portafolio.co/amp/negocios/empresas/wezelly-la-empresa-que-apuesta-por-ser-agencia-de-viajes-de-salud-579756",
    },
    {
      img: "https://www.portafolio.co/files/article_content/uploads/2023/03/11/640ce8ae01886.jpeg",
      fecha: "03:57 PM",
      text: t("browse_home.news_wellezy.for_new"),
      link: "https://www.portafolio.co/amp/economia/finanzas/turismo-colombia-recibio-a-mas-de-cuatro-millones-de-turistas-en-2022-579764",
    },

    {
      img: "https://i0.wp.com/folou.co/wp-content/uploads/2023/02/Odolontologia.jpg?w=1200&ssl=1",
      fecha: "11:46 AM",
      text: t("browse_home.news_wellezy.second_new"),
      link: "https://folou.co/mundo/la-plataforma-que-impulsa-el-turismo-de-salud-en-colombia/",
    }
  ];

  const carouselRef = useRef(null);
  const [cardWidth, setCardWidth] = useState(0);
  const [cardsToShow, setCardsToShow] = useState(3);
  const [currentIndex, setCurrentIndex] = useState(0);

  const updateCardWidth = () => {
    if (carouselRef.current) {
      const cardElement = carouselRef.current.querySelector(".carousel-card");
      if (cardElement) {
        setCardWidth(cardElement.offsetWidth + 15); // Considerar el gap en el cálculo del cardWidth
      }
    }
  };

  const updateCardsToShow = () => {
    if (carouselRef.current) {
      const cardElement = carouselRef.current.querySelector(".carousel-card");
      if (cardElement) {
        if (window.innerWidth <= 600) {
          setCardsToShow(1);
          setCardWidth(cardElement.offsetWidth + 20);
        } else if (window.innerWidth <= 800) {
          setCardsToShow(2);
        } else {
          setCardsToShow(3);
        }
      }
    }
  };

  useEffect(() => {
    updateCardWidth();
    updateCardsToShow();

    const handleResize = () => {
      updateCardWidth();
      updateCardsToShow();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextClick = () => {
    const maxIndex = cards.length - cardsToShow;
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, maxIndex));
  };

  const [touchStartX, setTouchStartX] = useState(0);

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    const maxIndex = cards.length - cardsToShow;

    if (touchStartX - touchEndX > 50) {
      setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, maxIndex));
    } else if (touchStartX - touchEndX < -50) {
      setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }
  };

  return (
    <>
      <ContainerSeparations height={40} />
      <div className="carousel-container" ref={carouselRef}>
        <button
          className="carousel-button-new left"
          onClick={handlePrevClick}
          disabled={currentIndex === 0}
        >
          <MdOutlineKeyboardArrowLeft />
        </button>
        <div className="carousel-wrapper">
          <div
            className="carousel-content"
            style={{
              transform: `translateX(-${currentIndex * cardWidth}px)`,
              transition: "transform 0.3s ease-in-out",
              display: "flex",
            }}
          >
            {cards.map((item, index) => (
              <div
                key={index}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                className="carousel-card"
              >
                <div className="Slide">
                  <div className="SlideImage">
                    <img src={item.img} alt={item.text} />
                  </div>
                  <div className="contentNewSliderNews">
                    <h3>
                      <span>Medellin</span> - {item.fecha}
                    </h3>
                    <p className="contentNewSliderNewsDescription">
                      {item.text}
                    </p>
                    <a target="_blank" href={item.link}>
                      Leer más <IoIosArrowRoundForward />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button
          className="carousel-button-new right"
          onClick={handleNextClick}
          disabled={currentIndex >= cards.length - cardsToShow}
        >
          <MdOutlineKeyboardArrowRight />
        </button>
      </div>
      <ContainerSeparations height={40} />
    </>
  );
};

export default NewsSlider;
