import React from 'react'
import ResultPaymentDetail from '../../Components/ResultPaymentDetail/ResultPaymentDetail'

import './Assets/styles.css'
function ResultPayment() {
  return (
    <div className='caontainer'>
        <div className='nav'/>
     <ResultPaymentDetail/>
    </div>
  )
}

export default ResultPayment
