import React, { useState } from "react";
import styles from "./styles.module.css";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { MdFlight, MdLuggage } from "react-icons/md";
import { BsFillLuggageFill } from "react-icons/bs";
import { FaLuggageCart } from "react-icons/fa";
import shoppingCart from "./carts";
import { FaCar, FaHotel } from "react-icons/fa6";
import { RiHotelFill } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import Paginado from "@/ui/views/Hotel/HotelResult/Paginado/Paginado";

const MyCart = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Puedes ajustar esto según tus necesidades

  // Función para manejar el cambio en la selección del checkbox
  const handleCheckboxChange = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  // Función para eliminar un artículo específico
  const handleRemoveItem = (itemToRemove) => {
    setSelectedItems(selectedItems.filter((item) => item !== itemToRemove));
  };

  // Función para eliminar todos los artículos seleccionados
  const handleRemoveAll = () => {
    setSelectedItems([]);
  };

  const calculateTotal = () => {
    const total = selectedItems.reduce((total, item) => total + item.price, 0);
    return `${new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(total)} COP`;
  };

  // Funciones de manejo de paginado
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = shoppingCart.slice(indexOfFirstItem, indexOfLastItem);

  const nextHandler = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(shoppingCart.length / itemsPerPage))
    );
  };

  const prevHandler = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const formatPriceCOP = (price) => {
    return `${new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price)} COP`;
  };

  return (
    <div className={styles.cartSection}>
      <div className={styles.cart}>
        <h3>Carrito de compras</h3>

        <div className={styles.cartItems}>
          {currentItems.length > 0 ? (
            currentItems.map((item, index) => (
              <div key={index} className={styles.itemCard}>
                <div className={styles.itemHeader}>
                  <div className={styles.itemHeaderIconText}>
                    <div className={styles.itemHeaderIconTitle}>
                      <div className={styles.icon}>
                        {item.type === "vuelo" && <MdFlight />}
                        {item.type === "hotel" && <RiHotelFill />}
                        {item.type === "traslado" && <FaCar />}
                      </div>
                      <div className={styles.itemHeaderText}>
                        <h4 className={styles.title}>{item.title}</h4>
                        <p className={styles.shortDescription}>
                          {item.description}
                        </p>
                      </div>
                    </div>
                    {item.type === "vuelo" && (
                      <div className={styles.itemImage}>
                        <img
                          src="https://res.cloudinary.com/dovavvnjx/image/upload/v1725434827/image_39_tmdmsl.png"
                          alt="Logo"
                        />
                      </div>
                    )}
                  </div>

                  <p className={styles.itemDate}>{item.date}</p>
                </div>
                <div className={styles.itemDetails}>
                  <p className={styles.longDescription}>
                    {item.longDescription}
                  </p>

                  <div className={styles.itemBaggage}>
                    {item.baggage && (
                      <ul className={styles.luggageList}>
                        {item.baggage.map((baggage, baggageIndex) => (
                          <li key={baggageIndex} className={styles.luggageItem}>
                            {baggage === "1" && <MdLuggage />}
                            {baggage === "2" && <BsFillLuggageFill />}
                            {baggage === "3" && <FaLuggageCart />}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>

                <button className={styles.detailsButton}>
                  {item.viewDetailsButton}
                </button>
                <div className={styles.priceButton}>
                  <div className={styles.price}>
                    {formatPriceCOP(item.price)}
                  </div>
                  <div className={styles.checkPayButton}>
                    <label className={styles.container}>
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                      />
                      <div className={styles.checkmark}></div>
                    </label>
                    <Link to="/cart-checkout" className={styles.payButton}>
                      Ir a pagar
                    </Link>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.noItems}>
              <div className={styles.message}>
                El carrito está vacío
                <AiOutlineExclamationCircle
                  size={20}
                  color="var(--bluePrimary)"
                />
              </div>
              <Link to="/" className={styles.buy}>
                Ir a comprar
              </Link>
            </div>
          )}
        </div>

        {currentItems.length > 0 && (
          <Paginado
            nextHandler={nextHandler}
            prevHandler={prevHandler}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            data={shoppingCart}
            itemsPerPage={itemsPerPage}
            pageRangeDisplayed={5}
          />
        )}
      </div>

      <div className={styles.total}>
        <h3>Resumen</h3>

        <div className={styles.totalItems}>
          {selectedItems.length > 0 ? (
            <div className={styles.selectedItems}>
              <ul className={styles.selectedItemsUl}>
                {selectedItems.map((item, index) => (
                  <li key={index} className={styles.selectedItem}>
                    <div className={styles.icon}>
                      {item.type === "vuelo" && <MdFlight />}
                      {item.type === "hotel" && <RiHotelFill />}
                      {item.type === "traslado" && <FaCar />}
                    </div>
                    <h4 className={styles.title}>{item.title}</h4>
                    <p>{item.description}</p>
                    <h4 className={styles.priceItem}>
                      {formatPriceCOP(item.price)}
                    </h4>
                    <button
                      className={styles.removeItemButton}
                      onClick={() => handleRemoveItem(item)}
                    >
                      <IoClose size={20} />
                    </button>
                  </li>
                ))}
              </ul>
              <button
                className={styles.removeAllButton}
                onClick={handleRemoveAll}
              >
                Eliminar todos
              </button>
            </div>
          ) : (
            <div className={styles.noItems}>
              <div className={styles.message}>Nada seleccionado aún</div>
            </div>
          )}

          <div className={styles.priceTotal}>
            <h4>Precio total:</h4>
            <h4 className={styles.totalValue}>{calculateTotal()}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCart;
