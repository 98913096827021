import React from 'react'
import DetailsPharmacy from '../../ui/views/pharmacy/DetailsPharmacy/DetailsPharmacy'

const DetailsPharmacyRoute = () => {
  return (
    <div>
        <DetailsPharmacy/>
    </div>
  )
}

export default DetailsPharmacyRoute