import React from 'react'
import './styles.css'

import nostros from "../../../assets/images/Nosotros.png"
import SvgIcon, { iconList } from '../../share/components/iconSVG'

const InfoCard = () => {
    return (
        <>
            <div className='contain-info-cards-about'>
                <div className='conten-card-info-about'>
                    <div className=''>
                        <SvgIcon name={iconList.IconCertificate} size={50} />
                    </div>

                    <div className='card-about-us'>
                        <h2>
                            Enfoque en la seguridad
                            y calidad de servicios:
                        </h2>
                        <p>
                            En Wellezy, la seguridad y el bienestar de nuestros clientes son
                            nuestra máxima prioridad. Trabajamos en conjunto con los
                            principales especialistas de la salud y hospitales de renombre
                            en Colombia, asegurando que todos los procedimientos y
                            tratamientos se lleven a cabo bajo estrictas normas de calidad
                            y seguridad. Nuestra amplia red de proveedores de servicios de
                            salud ha sido cuidadosamente seleccionada para ofrecer
                            atención médica de primer nivel y resultados sobresalientes.
                        </p>
                    </div>
                </div>
                <div className='conten-card-info-about'>
                    <div className=''>
                        <SvgIcon name={iconList.IconAddPerson} size={50} />
                    </div>

                    <div className='card-about-us'>
                        <h2>
                            Ventajas económicas
                            y posición de liderazgo:
                        </h2>
                        <p>
                            Nos enorgullecemos de ofrecer ventajas económicas significativas
                            a nuestros clientes. Gracias a nuestras alianzas estratégicas y
                            nuestro liderazgo en la industria, podemos negociar los mejores
                            precios para los servicios de salud y bienestar en Colombia.
                            Nuestra plataforma se ha convertido en el destino preferido para
                            aquellos que buscan tratamientos médicos de calidad a precios
                            accesibles, lo que nos posiciona como la mejor opción en el mercado
                        </p>
                    </div>
                </div>
                <div className='conten-card-info-about'>
                    <div className=''>
                        <SvgIcon name={iconList.IconCoin} size={50} />
                    </div>

                    <div className='card-about-us'>
                        <h2>
                            Agremiación de especialistas
                            de la salud y agencias de turismo:
                        </h2>
                        <p>
                            En Wellewzy, hemos logrado una unión única entre especialistas
                            de la salud y agencias de turismo. Somos la única plataforma que
                            agremia a estos actores clave en el turismo de salud, lo que nos
                            permite ofrecer una experiencia completa y coordinada para
                            nuestros clientes. Al estar conectados con organizaciones como
                            IATA y RETUS, podemos garantizar que nuestros servicios cumplan
                            con los más altos estándares de calidad y seguridad
                        </p>
                    </div>
                </div>
                <div className='conten-card-info-about'>
                    <div className=''>
                        <SvgIcon name={iconList.IconWorld} size={50} />
                    </div>

                    <div className='card-about-us'>
                        <h2>
                        Desarrollo de un GDS para
                        una experiencia global:
                        </h2>
                        <p>
                        Nuestro compromiso con la innovación nos llevó a desarrollar un
                        sistema de distribución global (GDS) diseñado específicamente
                        para conectar a empresas, médicos y agencias de turismo
                        participantes en Wellewzy. Este GDS brinda a nuestros socios la
                        capacidad de ofrecer un servicio global, ampliando sus
                        oportunidades de negocio y mejorando la experiencia del cliente.
                        Esta herramienta nos destaca como líderes en tecnología y en la
                        entrega de servicios de turismo de salud y bienestar.
                        </p>
                    </div>
                </div>
            </div>
            <div className='content-img-about-us'>
                <img src={nostros} alt="img-nosotros" />
            </div>

        </>

    )
}

export default InfoCard