import React from 'react'
import FormMoreInfo from '../../Components/FormMoreInfo/FormMoreInfo'

const Form = () => {
  return (
    <div>
        <FormMoreInfo />
    </div>
  )
}

export default Form