

import React from 'react'
import FlightCheKoutPaymentCard from '../FlightCheKoutPaymentCard/FlightCheKoutPaymentCard'


const PaymentgatewayTest = () => {
    return (
        <div>
           <FlightCheKoutPaymentCard/>
        </div>
      )
    }

export default PaymentgatewayTest
