import React from 'react'

const Api = () => {


    const Loggin = `${process.env.REACT_APP_URL_API_FLY}pollitan`
    const cityUrl = `${process.env.REACT_APP_URL_API_FLY}pollitan/city`
    const originUrl = `${process.env.REACT_APP_URL_API_FLY}pollitan/origin`
    const destinyUrl = `${process.env.REACT_APP_URL_API_FLY}pollitan/destination`
    const servicesUrl = `${process.env.REACT_APP_URL_API_FLY}pollitan/product`
    

  return {
    Loggin,
    cityUrl,
    originUrl,
    destinyUrl,
    servicesUrl
  }
}

export default Api