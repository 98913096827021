import React from 'react'
import HealtWellness from '../../ui/views/HealthWellness/HealtWellness'

const HealthWellnessRoute = () => {
  return (
    <div>
        <HealtWellness/>
    </div>
  )
}

export default HealthWellnessRoute