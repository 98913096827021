import './styles.css';
import SvgIcon, { iconList } from '../../../../../../share/components/iconSVG';

const ComponentColumn = ({ width = 25, label, value = false, hiddenBorder = false }) => {
  return (
    <div className='ComponentColumn'
      style={{
        borderRight: `solid ${hiddenBorder ? 0 : 1}px #004274`,
        width: `${width}%`,
      }}>
      <p className='ComponentColumnLabel'>{label}</p>
      {value &&
        <p className='ComponentColumnValue'>{value}</p>
      }
    </div>
  )
}

const ComponentRow = ({ text = '', icon }) => {
  return (
    <div className='ComponentRow'>
      <div className='ComponentRowIcon'>
        <SvgIcon
          color={'#004274'}
          size={30}
          name={icon}
        />
      </div>
      <p className='ComponentRowText'>{text}</p>
    </div>
  )
}

const ComponentDetailFlight = () => {
  const bagColor = true ? '#FFF' : '#000';
  return (
    <div className='componentDetailFlight'>
      <div className='componentDetailFlightInfo'>
        <p className='componentDetailFlightInfoType'>IDA</p>
        <p className='componentDetailFlightInfoDate'>26 may. 2023
        </p>
      </div>
      <div className='componentDetailFlightBags'>
        <SvgIcon color={bagColor} name={iconList.IconBagSmall} size={25} />
        <SvgIcon color={bagColor} name={iconList.IconBagMedium} size={25} />
        <SvgIcon color={bagColor} name={iconList.IconBagBig} size={25} />
      </div>
    </div>
  )
}

const CardTravel = ({ data }) => {
  return (
    <div className='cardContainer'>
      <div className='cardContainerInfo travel'>
        {/* 
        */}
        <div className='onlyTravelRow'>
          <div className='onlyTravelRowJoin'>
            <div className='onlyTravelRowJoinIcon'>
              <SvgIcon
                name={iconList.IconPlane}
                color={'#004274'}
                size={40}
              />
            </div>

            <div className='CMT_header'>
              <p className='title'>Medellín - Bogotá</p>
              <p className='subtitle'>Ida y vuelta, 1 adulto</p>
            </div>
          </div>
          <div className='CMT_header'>
            <div className='CMT_headerNumber'>
              <p className='superTitleUpper'>50</p>
              <p className='superTitleLower'>min</p>
            </div>
          </div>
        </div>
        <div className='onlyTravelRow'>
          <div className='CMT_body'>
            <div className='CMT_bodyColumn'>
              <ComponentDetailFlight />
              <div className='containerAirlineImg'>
                <img className='containerAirlineImgLogo' src='https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Latam-logo_-v_%28Indigo%29.svg/2560px-Latam-logo_-v_%28Indigo%29.svg.png' />
              </div>
              <div className='ComponentColumnWrapper'>
                <ComponentColumn width={25} label={'MDE'} value={'05:45'} />
                <ComponentColumn width={25} label={'Directo'} />
                <ComponentColumn width={25} label={'BOG'} value={'06:41'} />
                <ComponentColumn width={25} label={'Duración'} value={'56m'} hiddenBorder />
              </div>
            </div>
          </div>
          {data?.roundTrip &&
            <div className='CMT_body'>
              <div className='CMT_bodyColumn'>
                <ComponentDetailFlight />
                <div className='containerAirlineImg'>
                  <img className='containerAirlineImgLogo' src='https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Latam-logo_-v_%28Indigo%29.svg/2560px-Latam-logo_-v_%28Indigo%29.svg.png' />
                </div>
                <div className='ComponentColumnWrapper'>
                  <ComponentColumn width={25} label={'MDE'} value={'05:45'} />
                  <ComponentColumn width={25} label={'Directo'} />
                  <ComponentColumn width={25} label={'BOG'} value={'06:41'} />
                  <ComponentColumn width={25} label={'Duración'} value={'56m'} hiddenBorder />
                </div>
              </div>
            </div>
          }
        </div>
        <div className='onlyTravelRow'>
          <div className='CMT_footer'>
            <div className='CMT_btn'>
              <p className='CMT_btnText'>Ver más detalles</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const CardHotel = ({ data }) => {
  return (
    <div className='cardContainer'>
      <div className='cardContainerBanner'>
        <img src={data.banner} style={{ width: '100%', height: '100%' }} />
      </div>
      <div className='cardContainerInfo'>
        <div className='CMT_header'>
          <p className='title'>Hotel Black Usaquen</p>
          <p className='subtitle'>Bogota Usaquen, a 11,73 km del centro</p>
          <div>
            <p className='CMT_header_link'>Ver en el mapa</p>
          </div>
        </div>
        <div className='CMT_body'>
          <ComponentRow
            icon={iconList?.IconRestRoom}
            text='4 noches, 2 personas'
          />
          <ComponentRow
            icon={iconList?.IconFood}
            text='Desayuno incluido'
          />
        </div>
        <div className='ComponentColumnWrapper'>
          <ComponentColumn width={28} label={'Fecha de Ingreso'} value={'03/03/2024'} />
          <ComponentColumn width={22} label={'Check in'} value={'14:00'} />
          <ComponentColumn width={28} label={'Fecha de Ingreso'} value={'03/03/2024'} />
          <ComponentColumn width={22} label={'Check out'} value={'12:00'} hiddenBorder />
        </div>
        <div className='CMT_footer'>
          <div className='CMT_btn'>
            <p className='CMT_btnText'>Ver más detalles</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const CardTransportation = ({ data }) => { return (<div className='cardContainer'></div>) }
const CardVehicle = ({ data }) => { return (<div className='cardContainer'></div>) }

const CardTour = ({ data }) => {
  return (
    <div className='cardContainer'>
      <div className='cardContainerBanner'>
        <img src={data.banner} style={{ width: '180px', height: '200px' }} />
      </div>
      <div className='cardContainerInfo'>
        <div className='CMT_header'>
          <p className='title'>Tour Isla Benito</p>
          <p className='subtitle'>2 personas</p>
        </div>
        <div className='CMT_body'>
          <p className='CMT_description'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euis- mod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.</p>
        </div>
        <div className='ComponentColumnWrapper'>
          <ComponentColumn width={44} label={'Salida desde:'} value={'Puerto Benito'} />
          <ComponentColumn width={36} label={'Fecha'} value={'04/05/2024'} />
          <ComponentColumn width={20} label={'Hora'} value={'11:00'} hiddenBorder />
        </div>
        <div className='CMT_footer'>
          <div className='CMT_btn'>
            <p className='CMT_btnText'>Ver más detalles</p>
          </div>
        </div>
      </div>
    </div>
  )
}



function GlocalCard({ data }) {
  const cardTypes = {
    travel: CardTravel,
    hotel: CardHotel,
    tour: CardTour,
    transportation: CardTransportation,
    vehicle: CardVehicle,
  }
  const CardComponent = cardTypes[data?.type];
  return (
    <div className="glocalCardTravel">
      {CardComponent && <CardComponent data={data} />}
    </div>
  )
}

export default GlocalCard;