import React from 'react'
import DetailSpecialist from '../../ui/views/Specialist/DetailSpecialist/DetailSpecialist'

const SpecialistDetailsRoute = () => {
  return (
    <div>
        <DetailSpecialist/>
    </div>
  )
}

export default SpecialistDetailsRoute