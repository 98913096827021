import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const usePharmacy = () => {
  const navigate = useNavigate();
  const [dataPharmacy, setDataPharmacy] = useState([]);
  const location = useLocation();
  const condition = location.pathname;
  let counter = 0;
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const itemsForPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [mostrarNext, setMostrarNext] = useState(true);

  function findPharmacy() {
    if (condition === "/Pharmacy" && counter === 0) {
      setLoading(true);
      const url = `${process.env.REACT_APP_URL_API_AIOP_STG}api/products`
      axios
        .get(url, {
          params: {
            search: "",
          },
        })
        .then((res) => {
          counter += 1;
          setLoading(false);
          setServices(res.data.products);
          setDataPharmacy([...res.data.products].splice(0, itemsForPage));
        })
        .catch((error) => {
          counter += 1;
          setLoading(false);
          console.log(error);
        });
    }
  }

  useEffect(() => {
    findPharmacy();
  }, []);

  const nextHandler = () => {
    const totalElementos = services.length;

    const nextPage = currentPage + 1;

    const firtsIndex = nextPage * itemsForPage;
    if (firtsIndex >= totalElementos) {
      setMostrarNext(false);
      return;
    }

    setCurrentPage(nextPage);
    setDataPharmacy([...services].splice(firtsIndex, itemsForPage));
  };

  const prevHandler = () => {
    setMostrarNext(true);
    const prevPage = currentPage - 1;

    if (prevPage < 0) return;

    const firtsIndex = prevPage * itemsForPage;

    setDataPharmacy([...services].splice(firtsIndex, itemsForPage));
    setCurrentPage(prevPage);
  };

  const filterNamePharmacy = (name) => {
    setLoading(true);
    const url = `${process.env.REACT_APP_URL_API_AIOP_STG}api/products`
    axios
      .get(url, {
        params: {
          search: name,
        },
      })
      .then((res) => {
        counter += 1;
        setLoading(false);
        setServices(res.data.products);
        setDataPharmacy([...res.data.products].splice(0, itemsForPage));
      })
      .catch((error) => {
        counter += 1;
        setLoading(false);
        console.log(error);
      });
  };

  function generateUniqueId() {
    return "xxxxx-xxxx-4xxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const detailPharmacy = (cosmetic) => {
    navigate(`/Pharmacy/${generateUniqueId()}`, {
      state: {
        name: cosmetic.name,
        id: cosmetic.id,
        urlImage: "https://staging.aiop.com.co/storage/",
        pathImage: cosmetic.images[0].url,
        quantity: cosmetic.quantity,
        description: cosmetic.description,
        price: cosmetic.price,
      },
    });
  };

  return {
    dataPharmacy,
    loading,
    nextHandler,
    prevHandler,
    currentPage,
    mostrarNext,
    services,
    filterNamePharmacy,
    detailPharmacy,
  };
};

export default usePharmacy;
