//dependences
import React, { useState, useEffect } from "react";
import routesDocumentation from "@/data/documentation/drawer/drawer";
import { match } from "path-to-regexp";
import { Routes, Route, useLocation } from "react-router-dom";

//providers, store and styles
import "./app.css";
import { TransportProvider } from "./Context/transport/TransportProvider";
import { UserDetailsProvider } from "./Context/user/userProvider";
import { SearchEnginePackageProvider } from "./Context/SearchEnginePackage/SearchEnginePackageProvider";
import { set, keys } from "./ui/localStorage";

//new routes clean architecture
import Header from "./ui/views/share/components/header";
import LayoutDocumentaton from "@/layouts/documentation";
import Home from "./ui/views/home";
import Footer from "./ui/views/share/components/footer";
import ProfileUser from "./ui/views/profileUser";
import UserRegister from "./ui/views/session/register";
import UserLogin from "./ui/views/session/login";
import Flights from "./ui/views/flights/flights.view";
import ResultPackages from "./ui/views/Packages/ResultPackages/ResultPackages";
import InitialRoomPX from "./ui/views/videoSDK/px/initialRoom";
import PassengerInformationForm from "./ui/views/flights/passenger.information.form.view";
import PurelyIframeFlight from "./ui/views/flights/purelyIframe";
import ResultSearchGlobal from "./ui/views/share/components/Find/ResultSearchGlobal/ResultSearchGlobal";
import MakeReservation from "./ui/views/Hotel/MakeReservation/MakeReservation";
import PqrsPrimaryScreen from "./ui/views/pqrs/primaryScreen";
import PackagesPay from "./ui/views/Packages/PackagesPay/PackagesPay";
import Ambulance from "./ui/views/ambulance/Ambulance";
import ShopWellezy from "./ui/views/shopWellezy/ShopWellezy";
import BookingFlow from "./ui/views/Specialist/DetailSpecialist/BookingFlow/BookingFlow";
import BookingFlowClinics from "./ui/views/clinics/DetailsClinics/BookingFlow/BookingFlow";
import TourismHealth from "./ui/views/tourismHealth/TourismHealth";
import ResultSearchTourism from "./ui/views/tourismHealth/resultSearch/ResultSearchTourism";
import ReservationTransport from "./ui/views/transport/reservationTransport/ReservationTransport";
import DetailPackhealth from "./ui/views/tourismHealth/detailPackHealth/DetailPackhealth";
import ResultSearchPack from "./ui/views/tourismHealth/resultSearchPack/ResultSearchPack";
import TypePackages from "./ui/views/Packages/typePackages/TypePackages";

//old routes
import Procedures from "./Routes/Procedures/Procedures";
import Doctors from "./Routes/Doctors/Doctors";
import Doctor from "./Routes/Doctor/Doctor";
import Nurses from "./Routes/Nurses/Nurses";
import Nurse from "./Routes/Nurse/Nurse";
import Turism from "./Routes/Turism/Turism";
import Allies from "./Routes/Allies/Allies";
import Tour from "./Routes/Tour/Tour";
import FlightSelected from "./Routes/FlightSelected/FlightSelected";
import FlightDetails from "./Routes/FlightDetails/FlightDetails";
import ProcedureCategory from "./Routes/ProcedureCategory/ProcedureCategory";
import ResultPayment from "./Routes/ResultPayment/ResultPayment";
import Form from "./Routes/Form/Form";
import AboutUs from "./Routes/AboutUs/AboutUs";
import RateClass from "./Routes/RateClass/RateClass";
import Paymentgateway from "./Routes/Paymentgateway/Paymentgateway";
import ServicesCategory from "./Routes/ServicesCategory/ServicesCategory";
import ShoppingCart from "./Routes/ShoppingCart/ShoppingCart";
import FlightsCheckout from "./Routes/FlightsCheckout/FlightsCheckout";
import RouteVisualizador from "./Routes/Visualizador/RouteVisualizador";
import TravelRoute from "./Routes/TravelRoute/TravelRoute";
import FlightRoute from "./Routes/FlightRoute/FlightRoute";
import HotelRoute from "./Routes/HotelRoute/HotelRoute";
import Tours from "./Routes/Tours/Tours";
import TransportRoute from "./Routes/TransportRoute/TransportRoute";
import PackagesRoute from "./Routes/PackagesRoute/PackagesRoute";
import CarRentaRoute from "./Routes/CarRentaRoute/CarRentaRoute";
import TransportResultsSearch from "./Routes/TransportRoute/TransportResultsSearch";
import TravelSecureRoute from "./Routes/travelSecureRoute/TravelSecureRoute";
import SpecialistRoute from "./Routes/SpecialistRoute/SpecialistRoute";
import ClinicsRoute from "./Routes/ClinicsRoute/ClinicsRoute";
import SpecialistDetailsRoute from "./Routes/SpecialistRoute/SpecialistDetailsRoute";
import HealthWellnessRoute from "./Routes/HealthWellnessRoute/HealthWellnessRoute";
import PharmacyRoute from "./Routes/PharmacyRoute/PharmacyRoute";
import RecoveryHouseRoute from "./Routes/RecoveryHouseRoute/RecoveryHouseRoute";
import CosmeticsRoute from "./Routes/CosmeticsRoute/CosmeticsRoute";
import DetailsClinicsRoute from "./Routes/ClinicsRoute/DetailsClinicsRoute";
import DetailsCosmeticsRoute from "./Routes/CosmeticsRoute/DetailsCosmeticsRoute";
import DetailsRecoveryHouseRoute from "./Routes/RecoveryHouseRoute/DetailsRecoveryHouseRoute";
import DetailsPharmacyRoute from "./Routes/PharmacyRoute/DetailsPharmacyRoute";
import DetailsHealthRoute from "./Routes/HealthWellnessRoute/DetailsHealthRoute";
import HotelResultRoute from "./Routes/HotelRoute/HotelResultRoute";
import HotelDetailRoute from "./Routes/HotelRoute/HotelDetailRoute";
import DetailPackagesRoute from "./Routes/PackagesRoute/DetailPackagesRoute";
import { pathIframe, widgetsList, pathWidget } from "./infra/constants/app";

import Terrawind from "./Routes/Terrawind/Terrawind";
import TerrawindMethodPay from "./Routes/TerrawindMethodPay/TerrawindMethodPay";
import TerraWindVoucher from "./Routes/TerrawindVoucher/TerrawindVoucher";
import TerrawindGenerateVoucher from "./Routes/TerrawindGenerateVoucher/TerrawindGenerateVoucher";
import SuraDataPassengers from "./Routes/SuraDataPassengers/SuraDataPassengers";
import BookingFlowHealt from "./ui/views/tourismHealth/detailPackHealth/BookingFlow/BookingFlow";
import BookingFlowReservation from "./ui/views/tourismHealth/resultSearchPack/BookingFlow/BookingFlow";
import WellezyNews from "./ui/views/news/WellezyNews";
import PayHotel from "./ui/views/Hotel/MakeReservation/PayHotel/PayHotel";
import SuraSummaryPayRoute from "./Routes/SuraSummaryPay/SuraSummaryPay";
import PackageDataPassengerRoute from "./Routes/PackageDataPassengerRoute/PackageDataPassengerRoute";
import PayPackageTourismRoute from "./Routes/PayPackageTourismRoute/PayPackageTourismRoute";
import PackageTourismVoucherRoute from "./Routes/PackageTourismVoucherRoute/PackageTourismVoucherRoute";

import { PrivacyPolicies } from "./Routes/PrivacyPolicies/PrivacyPolicies";
import PayTransport from "./ui/views/transport/reservationTransport/PayTransport/PayTransport";
import FlightsPay from "./ui/views/flights/flightsPay/FlightsPay";
import MethodPayFlights from "./ui/views/flights/passenger.information.form.view/components/step3/index";
import PayHealthPack from "./ui/views/tourismHealth/payPackTourismHealth/PayHealthPack";
import Loading from "./ui/views/share/components/loading";
import Contact from "./ui/views/contact/Contact";
import ScrollToTop from "./Components/ScrollToTop";

// Build Package Steps
import StepPackage from "./ui/views/BuildPackage/pages/pageOne/StepOne";
import StepSpecialist from "./ui/views/BuildPackage/pages/pageTwo/StepTwo";
import StepClinic from "./ui/views/BuildPackage/pages/PageThree/StepThree";
import StepFlight from "./ui/views/BuildPackage/pages/StepFlight/StepFlight";

// Detail Specialist and Clinic
import DetailDoc from "./ui/views/BuildPackage/pages/DetailDoc/DetailDoc";
import DetailClinic from "./ui/views/BuildPackage/pages/DetailClinic/DetailClinic";

// Routes Documentation
import DocumentationHome from "@/pages/documentation/home";
import DocumentationInputs from "@/pages/documentation/inputs/Inputs";
import DocumentationCss from "@/pages/documentation/css/Css";
import DocumentationTypography from "@/pages/documentation/typography/Typography";
import StepHotel from "./ui/views/BuildPackage/pages/StepHotel/StepHotel";
import StepTransport from "./ui/views/BuildPackage/pages/StepTransport/StepTransport";
import routesBuildPackage from "./data/documentation/buildPackage/RoutesBuildPackage";
import StepRoom from "./ui/views/BuildPackage/pages/StepRoom/StepRoom";
import StepAdditional from "./ui/views/BuildPackage/pages/StepAdditional/StepAdditional";
import CartCheckout from "./ui/views/CartCheckout";
import Std from "./ui/views/BuildPackage/pages/std/Std";
import RouteChangeDetector from "./Routes/RouteChangeDetector/RouteChangeDetector";
import Terms from "./Routes/Terms/Terms";

const App = () => {
  let showFooterAndNav = true;
  let pageDocumentation = false;
  let pageBuildPackage = false;
  const allPaths = [...pathWidget, ...pathIframe];
  const url = useLocation().pathname;
  const [loading, setLoading] = useState(false);
  for (let i = 0; i < allPaths.length; i++) {
    const pathPattern = allPaths[i];
    const pathMatcher = match(pathPattern);
    if (pathMatcher(window.location.pathname)) {
      showFooterAndNav = false;
      break;
    }
  }

  routesDocumentation.map((documentation) => {
    if (documentation.route === url) {
      showFooterAndNav = false;
      pageDocumentation = true;
    }
  });

  routesBuildPackage.map((buildPackage) => {
    if (buildPackage.route === url) {
      showFooterAndNav = true;
      pageBuildPackage = true;
    }
  });

  if (url === "/documentation") {
    showFooterAndNav = false;
  }

  async function setServicesMenu() {
    try {
      let list = [];
      const isWidget = widgetsList.find(
        (w) => w.path === window.location.pathname
      );

      if (isWidget) {
        list = isWidget.options;
      }
      const widgetEnable = !!isWidget;
      await set(keys?.isWidget, { enable: widgetEnable });
      await set(keys?.servicesMenu, list);
    } catch (error) {
      console.log("Error in setServicesMenu():", error);
    }
  }

  useEffect(() => {
    setServicesMenu();
  }, []);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [url]);

  return (
    <UserDetailsProvider>
      <TransportProvider>
        <SearchEnginePackageProvider>
          <div className="app">
            {showFooterAndNav && <Header />}
            {loading && <Loading />}
            <ScrollToTop />
            {pageDocumentation ? (
              <LayoutDocumentaton>
                <Routes>
                  <Route path="/documentation" element={<DocumentationHome />} />
                  <Route path="/documentation/inputs" element={<DocumentationInputs />} />
                  <Route path="/documentation/css" element={<DocumentationCss />} />
                  <Route path="/documentation/typography" element={<DocumentationTypography />} />
                </Routes>
              </LayoutDocumentaton>
            ) : (
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/:id" element={<Home />} />
                <Route path="/procedures" element={<Procedures />} />
                <Route path="/doctors" element={<Doctors />} />
                <Route path="/doctor/:id" element={<Doctor />} />
                <Route path="/nurse/:id" element={<Nurse />} />
                <Route path="/Visualizador" element={<RouteVisualizador />} />
                <Route path="/nurses" element={<Nurses />} />
                <Route path="/turism" element={<Turism />} />
                <Route path="/allies" element={<Allies />} />
                <Route path="/resultSearch/:id" element={<ResultSearchGlobal />} />
                <Route path="/privacy-policies" element={<PrivacyPolicies />} />
                <Route path="/tour/:id" element={<Tour />} />
                <Route path="/procedure/:id" element={<ProcedureCategory />} />
                <Route path="/services/:id" element={<ServicesCategory />} />
                <Route path="/carRentals" element={<CarRentaRoute />} />
                <Route path="/package/results" element={<ResultPackages />} />
                <Route path="/Travel" element={<TravelRoute />} />
                <Route path="/form" element={<Form />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/Specialists" element={<SpecialistRoute />} />
                <Route path="/Specialists/:id" element={<SpecialistDetailsRoute />} />
                <Route path="/Clinics" element={<ClinicsRoute />} />
                <Route path="/Clinics/:id" element={<DetailsClinicsRoute />} />
                <Route path="/flightsCheckout" element={<FlightsCheckout />} />
                <Route path="/resultPayment" element={<ResultPayment />} />
                <Route path="/rateClass" element={<RateClass />} />
                <Route path="/paymentgateway" element={<Paymentgateway />} />{" "}
                <Route path="/shoppingCart" element={<ShoppingCart />} />
                <Route path="/profile" element={<ProfileUser />} />
                <Route path="/register" element={<UserRegister />} />
                <Route path="/login" element={<UserLogin />} />
                <Route path="/flightPassengersForm" element={<PassengerInformationForm />} />
                <Route path="/iframe/flightPassengersForm" element={<PassengerInformationForm />} />
                <Route path="/Pharmacy" element={<PharmacyRoute />} />
                <Route path="/Pharmacy/:id" element={<DetailsPharmacyRoute />} />
                <Route path="/healthWellness" element={<HealthWellnessRoute />} />
                <Route path="/healthWellness/:id" element={<DetailsHealthRoute />} />
                <Route path="/RecoveryHouse" element={<RecoveryHouseRoute />} />
                <Route path="/iframe/fligth" element={<PurelyIframeFlight />} />
                <Route path="/RecoveryHouse/:id" element={<DetailsRecoveryHouseRoute />} />
                <Route path="/Cosmetics" element={<CosmeticsRoute />} />
                <Route path="/Cosmetics/:id" element={<DetailsCosmeticsRoute />} />
                <Route path="/virtual-meeting-rooms/:entity/:id" element={<InitialRoomPX />} />
                <Route path="/pqrs" element={<PqrsPrimaryScreen />} />
                <Route path="/ambulance" element={<Ambulance />} />
                <Route path="/shopWellezy" element={<ShopWellezy />} />
                <Route path="/Specialist/reservation/:id" element={<BookingFlow />} />
                <Route path="/Clinic/reservation/:id" element={<BookingFlowClinics />} />
                <Route path="/TourismHealth" element={<TourismHealth />} />
                <Route path="/TourismHealth/:id" element={<ResultSearchTourism />} />
                <Route path="/TourismHealthPack/:id" element={<ResultSearchPack />} />
                <Route path="/TourismHealth/DetailProcedure/:id" element={<DetailPackhealth />} />
                <Route path="/TourismHealth/reservation/:id" element={<BookingFlowHealt />} />
                <Route path="/TourismHealth/reservation" element={<BookingFlowReservation />} />
                <Route path="/TourismHealth/pay-state" element={<PayHealthPack />} />
                <Route path="/wellezy-news" element={<WellezyNews />} />
                
                {/* Transport */}
                <Route path="/transport" element={<TransportRoute />} />
                <Route path="/transport/:id" element={<TransportResultsSearch />} />
                <Route path="/transport/reserve" element={<ReservationTransport />} />
                <Route path="/transport/PayTransport" element={<PayTransport />} />
                
                {/*fligths*/}
                <Route path="/flight/selected" element={<FlightSelected />} />
                <Route path="/flight/:id" element={<Flights />} />
                <Route path="/iframe/flight/:id" element={<Flights />} />
                <Route path="/flightDetails" element={<FlightDetails />} />
                <Route path="/fligths" element={<FlightRoute />} />
                <Route path="/iframe/fligths" element={<FlightRoute />} />
                <Route path="/widget/fligths" element={<FlightRoute />} />
                <Route path="/flight/methodPay" element={<MethodPayFlights />} />
                <Route path="/flight/flightsPay" element={<FlightsPay />} />
                
                {/*hotel*/}
                <Route path="/hotel" element={<HotelRoute />} />
                <Route path="/hotel/:id" element={<HotelDetailRoute />} />
                <Route path="/hotels/:id" element={<HotelResultRoute />} />
                <Route path="/hotels/reservation" element={<MakeReservation />} />
                <Route path="/hotels/PayHotel" element={<PayHotel />} />
                <Route path="/widget/hotel" element={<HotelRoute />} />
                <Route path="/widget/hotel/:id" element={<HotelDetailRoute />} />
                <Route path="/widget/hotels/:id" element={<HotelResultRoute />} />
                <Route path="/widget/hotels/reservation" element={<MakeReservation />} />
                {/* <Route path='/hotels/Payment' element={<PayHotel />} /> */}

                {/*tours*/}
                <Route path="/tours" element={<Tours />} />
                <Route path="/widget/tours" element={<Tours />} />
                
                {/*packages*/}
                <Route path="/packages" element={<PackagesRoute />} />
                <Route path="/packages/:id" element={<DetailPackagesRoute />} />
                <Route path="/packagesType" element={<TypePackages />} />
                <Route path="/packages/hotels/:id" element={<HotelResultRoute />} />
                <Route path="/packages/flight/:id" element={<Flights />} />
                <Route path="/packages/pay" element={<PackagesPay />} />
                <Route path="/packages/hotel/:id" element={<HotelDetailRoute />} />
                <Route path="/widget/packages" element={<PackagesRoute />} />
                <Route path="/widget/packages/:id" element={<DetailPackagesRoute />} />
                <Route path="/widget/packages/passenger/:data" element={<PackageDataPassengerRoute />} />
                <Route path="/widget/packages/pay/:id" element={<PayPackageTourismRoute />} />
                <Route path="/packages/voucher" element={<PackageTourismVoucherRoute />} />
                
                {/*travel secure*/}
                <Route path="/travelSecure" element={<TravelSecureRoute />} />
                
                {/*terrawind*/}
                <Route path="/travelSecure/terrawind/:data" element={<Terrawind />} />
                <Route path="/travelSecure/terrawind/voucher/:data" element={<TerraWindVoucher />} />
                <Route path="/travelSecure/terrawind/methodPay/:data" element={<TerrawindMethodPay />} />
                <Route path="/travelSecure/terrawind/generateVoucher/" element={<TerrawindGenerateVoucher />} />

                {/*sura*/}
                <Route path="/travelSecure/sura/passengers" element={<SuraDataPassengers />} />
                <Route path="/travelSecure/sura/summaryPay" element={<SuraSummaryPayRoute />} />
                <Route path="/contact" element={<Contact />} />

                {/* Build Package steps*/}
                <Route path="/build-package" element={<StepPackage />} />
                <Route path="/build-package/specialist" element={<StepSpecialist />} />
                <Route path="/build-package/specialist/:id" element={<DetailDoc />} />
                <Route path="/build-package/clinic" element={<StepClinic />} />
                <Route path="/build-package/clinic/:id" element={<DetailClinic />} />
                <Route path="/build-package/flight/:id" element={<StepFlight />} />
                <Route path="/build-package/hotel/:id" element={<RouteChangeDetector><StepHotel /></RouteChangeDetector>} />
                <Route path="/build-package/room/:id" element={<StepRoom />} />
                <Route path="/build-package/transport/:id" element={<StepTransport />} />
                <Route path="/build-package/additional" element={<StepAdditional />}/>
                <Route path="/build-package/std" element={<Std />} />
                <Route path="/documentation" element={<DocumentationHome />} />
                <Route path="/cart-checkout" element={<CartCheckout />} />
                <Route path="/terms&conditions" element={<Terms />} />
              </Routes>
            )}
            {showFooterAndNav && <Footer />}
          </div>
        </SearchEnginePackageProvider>
      </TransportProvider>
    </UserDetailsProvider>
  );
};

export default App;
