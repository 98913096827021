import useCommercialProfileFormRow from '../../../../../../../viewModels/profile/commercial/commercialProfile.formRow.viewModel';
import ActivityIndicator from '../../../../../../share/components/activityIndicator';
import SvgIcon, { iconList } from '../../../../../../share/components/iconSVG';
import InputCheck, { displayMethod, shapes } from '../../../../../../share/components/inputs/inputCheck';
import ModalContainer, { sliceFrom } from '../../../../../../share/components/modalContainer';
import '../../../../../styles.css';
import './styles.css';

function CommercialFormRow() {
  const {
    isLoading,
    showModal,
    setShowModal,
    message,
    inputList,
    isEditing,
    setIsEditing,
    isSending,
    typeInputs,
    btnDisable,
    cancel,
    save,
  } = useCommercialProfileFormRow();

  return (
    <div className='profileScreen_CardShadow'>
      <ModalContainer
        show={showModal}
        hidden={setShowModal}
        sizeWidth={50}
        sizeHeight={50}
        from={sliceFrom.right}
      >
        <div className='CIFR modalContainerCIFR'>
          <SvgIcon
            name={iconList.IconCheckSucces}
            color={'#58D68D'}
            size={120}
          />
          <p className='CIFR modalText'>{message}</p>
        </div>
      </ModalContainer>

      {
        isLoading &&
        <div className='containerLoadingSimle'>
          <ActivityIndicator size={40} colorFill='#004274' />
        </div>
      }
      {
        !isLoading &&
        <div className='CIFR headerCIFR'>
          <p className='CIFR titleCIFR'></p>
          {!isEditing && <p className='CIFR toEdit' onClick={() => setIsEditing(true)}>Editar</p>}
        </div>
      }
      {!isLoading &&
        <div>
          {inputList && inputList.map((index, key) => {
            return (
              <div key={key} className='CIFR inputGroup'>
                <p className='CIFR inputLabel'>
                  {index.isRequired && <b>*</b>}
                  {index?.label}</p>
                {index?.inputType === typeInputs.text &&
                  <div className='CIFR containerInput'>
                    <input
                      className={`CIFR input ${isEditing && 'inputEditing'}`}
                      placeholder={index?.placeHolder}
                      value={index?.value}
                      id={index?.id}
                      onChange={index?.change}
                      type={index?.type}
                    />
                  </div>
                }
                {index?.inputType === typeInputs.textArea &&
                  <div className='CIFR containerInput'>
                    <textarea
                      className={`CIFR textarea ${isEditing && 'inputEditing'}`}
                      placeholder={index?.placeHolder}
                      value={index?.value}
                      id={index?.id}
                      onChange={index?.change}
                      rows="5"
                    ></textarea>
                  </div>
                }
                {index?.inputType === typeInputs.check &&
                  <div>
                    <InputCheck
                      id={index?.id}
                      shape={shapes.square}
                      methodDisplay={displayMethod.wrap}
                      list={[
                        { isActive: index?.value, change: index?.change, label: 'si' },
                        { isActive: !index?.value, change: index?.change, label: 'no' }
                      ]}
                    />
                  </div>
                }
              </div>
            )
          })}
        </div>
      }
      {isEditing &&
        <div className='CIFR footerCIFR'>
          <div onClick={() => !isLoading && cancel()} className={`CIFR outlineBtnCIFR ${isLoading && 'fillBtnCIDisable'}`}>
            <p className='CIFR outlineTextCIFR'>Cancelar</p>
          </div>

          <div onClick={() => !btnDisable && save()} className={`CIFR fillBtnCIFR ${btnDisable && 'fillBtnCIDisable'}`}>
            {isSending ?
              <>
                <ActivityIndicator
                  size={18}
                  colorFill={'#FFFFFF'}
                />
              </>
              :
              <p className='CIFR fillTextCIFR'>Guardar</p>
            }
          </div>
        </div>
      }
    </div>
  )
}

export default CommercialFormRow;