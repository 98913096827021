import React from 'react'
import { DatePicker } from '@material-ui/pickers'

const DateQuestion = () => {
  return (
    <div>
        <DatePicker />
    </div>
  )
}

export default DateQuestion