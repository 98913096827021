import React, { useState } from "react";

function InputPhone({onInputChange, setDocumentTypeId, validate}){

    const handleInputChange = (e) => {
        const value = e.target.value;
        onInputChange(value);
    }

    const handleSelectChange = (e) => {
        setDocumentTypeId(e.target.value);
    }

    return(
        <div>
            <div className="input-document-label">
                <label htmlFor="">Telefono *</label>
            </div>
            <div className={validate ? "input-document-validate input-document": "input-document"}>
                <div className="select-document">
                    <select name="" id="" onChange={handleSelectChange}>
                        <option value="">+0</option>
                        <option value="+57">+57</option>
                    </select>
                </div>
                <div className="input-document-container">
                    <input type="text" placeholder="Numero" onChange={handleInputChange}/>
                </div>
            </div>
            { validate ? <p className="span-simple-error">El campo es requerido</p> : null}
        </div>
    )
}

export default InputPhone;