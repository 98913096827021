import React, { useState, useEffect } from "react";
import Carousel from "../../share/components/CarouselServices/CarouselSevices";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../share/components/loading";
import SvgIcon, { iconList } from "../../share/components/iconSVG";
import "./styles.css";
import BestExperiences from "../BestExperiences/BestExperiences";
import ReturnPage from "../../share/components/return/ReturnPage";
import DisabledModal from "../../share/components/disabledModal/disabledModal";
import Paginado from "../../Hotel/HotelResult/Paginado/Paginado";

const ResultSearchPack = () => {
  const { state, pathname } = useLocation();
  const url = `${process.env.REACT_APP_URL_API_AIOP}api/medic_packages`;

  const [loading, setLoading] = useState(false);
  const [dataResult, setdataResult] = useState([]);
  let partes = pathname.split("/");
  let resultado = partes[partes.length - 1];
  const [wordSearch, setWordSearch] = useState(
    state?.keyWork == null ? resultado : state?.keyWork
  );

  const [, setServices] = useState([]);
  const navigate = useNavigate();

  // Supongamos que tienes la URL
const urlOnSite = window.location.href;

// Usamos URLSearchParams para extraer los parámetros
const params = new URLSearchParams(new URL(urlOnSite).search);

// Extraer valores específicos
const procedure = params.get('procedure');
const specialist = params.get('specialist');

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil((dataResult || []).length / itemsPerPage);
  let indexOfLastItem = currentPage * itemsPerPage;
  let indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = dataResult.slice(indexOfFirstItem, indexOfLastItem);

  const getInformation = () => {
    axios
      .get(url, {params:{
        subcategory: procedure,
        category: specialist
      }})
      .then((res) => {
        setdataResult(res.data.packs.data);
        setServices(res.data.packs.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getInformation();
  }, []);

  const detailPack = (item, id) => {
    navigate(`/TourismHealth/DetailProcedure/${id}`, {
      state: {
        data: item,
      },
    });
  };

  const goReservation = () => {
    navigate(`/TourismHealth/reservation`);
  };

  const nextHandler = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
    window.location.href = "#title";
  };

  const prevHandler = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
    window.location.href = "#title";
  };

  return (
    <>
      <Carousel />
      <ReturnPage />
      <div className="content-all-result-torism">
        <h2 className="title-result-search" id="title">
          Los mejores paquetes de tratamientos médicos de salud y bienestar en
          todo el mundo
        </h2>
        <p className="phrace-result-search">
          Miles de mejores paquetes de tratamientos médicos
        </p>
        <section className="layout-result-filter">
          <div className="all-result-search-tourism">
            {loading === true ? (
              <Loading size={100} />
            ) : currentItems?.length === 0 ? (
              <h2>No hay resultados disponibles</h2>
            ) : (
              <>
                {currentItems.map((item, index) => {
                  return (
                    <div className="card-pack-interest-r" key={index}>
                      <section className="part-one-r">
                        <div className="content-img-interest-r">
                          <img
                            src={`${process.env.REACT_APP_URL_API_AIOP}storage/medic_pack/${item.image}`}
                            alt=""
                          />
                        </div>
                        <div className="content-info-r">
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "900",
                              color: "var(--bluePrimary)",
                              textTransform: "uppercase",
                            }}
                          >
                            {item.name}
                          </p>
                          <div>
                            <div
                              className="content-icons-r"
                              style={{ marginBlock: "10px" }}
                            >
                              <SvgIcon
                                color={"var(--bluePrimary)"}
                                name={iconList.IconPinMap}
                                size={20}
                              />
                              <span style={{ fontSize: "12px" }}>
                                {item?.destination}
                              </span>
                            </div>
                            <div
                              className="content-icons-r"
                              style={{ marginBlock: "10px" }}
                            >
                              <SvgIcon
                                color={"var(--bluePrimary)"}
                                name={iconList.IconCheckSucces}
                                size={20}
                              />
                              <span style={{ fontSize: "12px" }}>
                                Precio a partir de{" "}
                                {item?.price.toLocaleString("de-DE")}{" "}
                                {item?.currency}
                              </span>
                            </div>
                          </div>
                          <div className="content-btns-r">
                            <button
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                detailPack(item, item.id);
                              }}
                            >
                              Ver paquete
                            </button>
                          </div>
                        </div>
                      </section>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className="content-buttons-cotizar">
            <div className="btns-detail-health">
              <button onClick={goReservation}>Reservar</button>

              <DisabledModal
                item={{ route: "https://wa.me/573004040116", disable: true }}
                active={false}
                linkExit={true}
              >
                <button>Contactar </button>
              </DisabledModal>
            </div>
          </div>
        </section>
      </div>

      <Paginado
        currentPage={currentPage}
        data={dataResult}
        nextHandler={nextHandler}
        prevHandler={prevHandler}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        pageRangeDisplayed={5}
      />
      <BestExperiences />
    </>
  );
};

export default ResultSearchPack;
