import { useState, useEffect, useContext } from 'react';
import UserContext from '../../../../Context/user/userProvider';
import {wellezy_api} from '../../../../infra/api';
import { doctorURL } from '../../../../infra/urls';


function useCommercialProfileSpecialities() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const { userDetail, userToken } = useContext(UserContext);
  const [specialtiesList, setSpecialtiesList] = useState([]);
  const [userSpecialtiesList, setUserSpecialtiesList] = useState([]);
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isShowSpecialities, setIsShowSpecialities] = useState(false);
  const [specialitiesSelectes, setSpecialitiesSelectes] = useState(userSpecialtiesList);
  const [isSetChangesSpecialities, setIsSetChangesSpecialities] = useState(false);


  function isSelectedThisSpeciality(id) {
    return specialitiesSelectes.find((elem) => elem.id === id);
  }

  function selecterSpeciality(id, value) {
    if (value) {
      const add = specialtiesList.find((elem) => elem.id === id);
      setSpecialitiesSelectes([
        ...specialitiesSelectes,
        add
      ])
    } else {
      const res = specialitiesSelectes.filter((elem) => elem.id !== id);
      setSpecialitiesSelectes([...res])
    }
  }

  async function getSpeciality() {
    try {
      const url = doctorURL?.get?.specialityList;
      const data = { user_id: userDetail?.id };
      const respuesta = await wellezy_api.get(url, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        params: data,
      });
      if (respuesta) {
        setSpecialtiesList(respuesta?.data)
      }
    } catch (error) {
      console.log("Error in getSpeciality)(")
    }
  }

  async function getUserSpeciality() {
    try {
      const url = doctorURL.get.userSpecialityList;
      const data = { user_id: userDetail?.id };
      const result = await wellezy_api.get(url, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        params: data,
      });
      if (result) {
        setUserSpecialtiesList(result?.data?.user)
      }
    } catch (error) {
      console.log("Error in getSpeciality)(")
    }
  }

  function cancelSpecialities() {
    setIsShowSpecialities(false)
    setSpecialitiesSelectes(userSpecialtiesList);
  }


  async function saveSpecialities() {
    if (isSending) return;
    try {
      setIsSending(true)
      const url = doctorURL.post.saveSpecialities
      const senData = {
        user_id: userDetail?.id,
        speciality: []
      }
      for (const s in specialitiesSelectes) {
        const id = specialitiesSelectes[s]?.id
        senData.speciality.push(id)
      }

      const result = await wellezy_api.post(url, senData, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      },
        );


      if (result && result?.status === 200) {
        setIsShowSpecialities(false);
        setIsSetChangesSpecialities(false);
        await getUserSpeciality()

        setShowModal(true)
        setMessage('Datos actualizados!')
      }
    } catch (error) {
      console.log("Error in saveSpecialities()", error)
    }
    finally {
      setIsSending(false)
    }
  }

  function compareSpecialitiesList() {
    const idsA = specialitiesSelectes.map(objeto => objeto.id);
    const idsB = userSpecialtiesList.map(objeto => objeto.id);
    // const idsIguales = idsA.filter(id => idsB.includes(id));
    const idsDiferentes = idsA.filter(id => !idsB.includes(id));
    const idsFaltantes = idsB.filter(id => !idsA.includes(id));
    const res = idsDiferentes.length > 0 || idsFaltantes.length > 0
    setIsSetChangesSpecialities(res)
  }

  useEffect(() => {
    if (!isShowSpecialities) cancelSpecialities()
  }, [isShowSpecialities])

  useEffect(() => {
    compareSpecialitiesList()
  }, [specialitiesSelectes])


  useEffect(() => {
    async function get() {
      try {
        setIsLoading(true)
        await getSpeciality()
        await getUserSpeciality()
      } catch (error) {
        console.log("Error loading specialities: ", error)
      }
      finally {
        setIsLoading(false)
      }
    }
    get();
  }, [])

  return {
    isLoading,
    isSending,
    specialtiesList,
    userSpecialtiesList,
    message,
    showModal,
    isShowSpecialities,
    isSetChangesSpecialities,
    isSelectedThisSpeciality,
    selecterSpeciality,
    setIsShowSpecialities,
    cancelSpecialities,
    saveSpecialities,
    setShowModal

  }

}
export default useCommercialProfileSpecialities;
