import React from "react";
import Find from "../share/components/Find/Find";
import CarouselServices, {
  servicesTravel,
} from "../share/components/CarouselServices/CarouselSevices";
import ButtonsFilters from "./buttonsFilters/ButtonsFilters";
import UseHealthWellness from "../../viewModels/HealthWellness/UseHealthWellness";
import AllCardsHealth from "./AllCardHealth/AllCardsHealth";
import Paginado from "./Paginado/Paginado";
import { useTranslation } from "react-i18next";
import BannerHealthWellness from "./BannerHealthWellness/BannerHealthWellness";
import AnyQuestions from "../../../ui/views/home/components/anyQuestion";

const HealtWellness = () => {
  const [t, i18n] = useTranslation("global");
  const {
    dataHealth,
    urlImage,
    currentPage,
    nextHandler,
    prevHandler,
    mostrarNext,
    services,
    loading,
    personalizeFind,
    detailHealth,
  } = UseHealthWellness();
  return (
    <div>
      <Find />
      <CarouselServices current={t("carousel_services.health_wellness")} />
      <BannerHealthWellness />
      <ButtonsFilters personalizerFind={personalizeFind} />
      <AllCardsHealth
        dataHealth={dataHealth}
        loading={loading}
        urlImage={urlImage}
        detailHealth={detailHealth}
      />
      <Paginado
        currentPage={currentPage}
        data={services}
        mostrarNext={mostrarNext}
        nextHandler={nextHandler}
        prevHandler={prevHandler}
      />
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default HealtWellness;
