import React, { useState, useEffect, useRef } from 'react'
import CarouselSevices from '@/ui/views/share/components/CarouselServices/CarouselSevices'
import styles from "./styles.module.css"
import axios from 'axios'
import Loading from '@/ui/views/share/components/loading'
import useHeaderSession from '@/ui/viewModels/session/header.session.viewModel'
import InputProcedures from '@/ui/views/CartCheckout/components/specialist-clinic/components/InputProcedures/InputProcedures'
import useSearchBuildPackage from '@/Hooks/BuildPackage/useBuildPackageSearchEngine/UseBuildPackageSearchEngine'
import StepHome from "../../components/Step/StepHome";
import StepStep from "../../components/Step/StepStep";
import ModalAction from "../../components/ModalAction/ModalAction";
import SessionModal from '@/ui/views/session/components/SessionModal/SessionModal'
import AnyQuestions from '@/ui/views/home/components/anyQuestion'
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setCurrentStep, setDataPackage, setLastSearch, setProceduresData, setRoutes, } from "@/store/slices/dataStepz/dataStepzSlice";
import { useTranslation } from "react-i18next";
import DataSearch from '../../components/DataSearch/DataSearch'
import InputNacionality from '../../components/SearchBuildPackage/components/SingleInputs/InputNacionality'
import AllCampsForms from './AllCampsForm'
import FormProcedure from './formProcedure/FormProcedure'
import DatePicker from './components/SelectedDate/DatePicker'
import TooltipContainer from '@/ui/views/share/components/tooltipContainer'
import { LuCalendarCheck, LuCalendarPlus } from 'react-icons/lu'
import InputNacionalityStd from './components/InputNacionality/InputNacionalityStd'
import ProcedureModal from './components/ProcedureModal/ProcedureModal'
import Recapchat from '@/ui/views/share/components/recapchat/Recapchat'
import UseRecapchat from '@/Hooks/useRecapchat/UseRecapchat'
import toast, { Toaster } from "react-hot-toast";

const Std = () => {
    const {
        verifyCaptcha,
        captchaValue,
        siteKey,
        onChangeCaptcha
    } = UseRecapchat()
    const { userDetail } = useHeaderSession()
    const navigate = useNavigate()
    const [activeFormIndex, setActiveFormIndex] = useState(null);
    const [activeSession, setActiveSession] = useState(false)
    const [paceholders, setPaceholders] = useState("");
    const [nacionality, setNacionality] = useState("");
    const [nacionalityDropdown, setNacionalityDropdown] = useState(false);
    const [dataNacionality, setDataNacionality] = useState([]);
    const [loadinNa, setLoadinNa] = useState(false)

    const notify = (title, icon) =>
    toast(title, {
      icon: icon,
    });

    const {
        cirugiasConfig,
        manejarCambio,
        formData,
        setFormData,
        obtenerFormulariosPorTexto,
        obtenerEstadoInicial,
        FormErrors,
        setFormErrors
    } = AllCampsForms()



    const [eps, setEps] = useState("")
    const [height, setHeight] = useState("")
    const [weight, setWeight] = useState("")
    const [phone, setPhone] = useState(null)
    const [prefixPhone, setPrefixPhone] = useState(null)
    const [typeProcedure, setTypeProcedure] = useState("")
    const [haveChild, setHaveChild] = useState(false)
    const [quantityChild, setQuantityChild] = useState("")
    const [drinkAlcohol, setDrinkAlcohol] = useState(false)
    const [smoke, setSmoke] = useState(false)
    const [sons, setSons] = useState(false)
    const [whichSons, setWhichSons] = useState("")
    const [haveSurgery, setHaveSurgery] = useState(false)
    const [whichSurgery, setWhichSurgery] = useState("")
    const [haveIllness, setHaveIllness] = useState(false)
    const [whichIllness, setWhichIllness] = useState("")
    const [drugsConsume, setDrugsConsume] = useState(false)
    const [whichDrugs, setWhichDrugs] = useState("")
    const [consumeMedicaments, setConsumeMedicaments] = useState(false)
    const [whichMedicaments, setWhichMedicaments] = useState("")
    const [youAlergicKnowed, setYouAlergicKnowed] = useState(false)
    const [whichAlergicKnowed, setWhichAlergicKnowed] = useState("")
    const [youAlergic, setYouAlergic] = useState(false)
    const [whichAlergic, setWhichAlergic] = useState("")
    const [validation, setValidation] = useState(false)
    const [terms, setTerms] = useState(false)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [birthDate, setBirthDate] = useState("")
    const [operationDate, setOperationDate] = useState("")

    const [procedures, setProcedures] = useState([])

    const [t] = useTranslation("global");
    const dispatch = useDispatch();
    const location = useLocation();

    const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
    const dataSearch = useSelector((state) => state.dataSearchEngine);
    const especialtieAvliable = useSelector((state) => state.dataStepz.especialtieAvliable);

    const valueFlight = dataPackage.flight ? dataPackage.flight.priceTotal : 0;
    const valueHotel = dataPackage.hotel ? dataPackage.hotel.price : 0;

    const totalPrice = 3000000 + valueFlight + valueHotel;



    const [isConfirmed, setIsConfirmed] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsConfirmed(event.target.checked);
    };

    const setSpetVariableData = useSelector((state) => state.dataSearchEngine.spetVariableData);
    const idEspecialtieData = useSelector((state) => state.dataSearchEngine.idEspecialtieData);
    const procedureData = useSelector((state) => state.dataSearchEngine.procedureData);

    const formsFilter = obtenerFormulariosPorTexto(procedureData?.name)



    const routes = useSelector((state) => state.dataStepz.routes);

    const [dataStepzTitles, setDataStepzTitles] = useState([]);
    const [activeStep, setActiveStep] = useState(1);
    const [completedSteps, setCompletedSteps] = useState([1]);

    const handleStepClick = (step) => {
        if (step <= activeStep + 1) {
            setActiveStep(step);
            if (!completedSteps.includes(step)) {
                setCompletedSteps([...completedSteps, step]);
            }
        }
    };

    useEffect(() => {
        if (setSpetVariableData === 2) {
            dispatch(setCurrentStep(6));
        } else {
            dispatch(setCurrentStep(5))
        }

        dispatch(
            setRoutes({
                ...routes,
                std: {
                    route: location.pathname,
                    search: location.search,
                },
            })
        );
        const url = `${process.env.REACT_APP_URL_API_FINANMED}/category/sub/47`
        axios.get(url)
            .then((res) => {
                setProcedures(res.data)
            })
            .catch(err => {
                console.log(err)
            })

        if (!userDetail) {
            setActiveSession(true)
        }


    }, []);

    useEffect(() => {
        const inicialFormData = {};

        formsFilter.forEach(item => {
            const { data, pro } = obtenerEstadoInicial(item);
            inicialFormData[pro] = data; // Asigna el data inicial por procedimiento
        });

        // Establece el estado una vez
        setFormData(inicialFormData);
    }, []);

    useEffect(() => {
        let titles = [];
        if (setSpetVariableData === 0) {
            titles = ["Hotel", "Especialista", "Clínica", "Resumen", "STD"];
        } else if (setSpetVariableData === 1) {
            titles = ["Vuelo", "Especialista", "Clínica", "Resumen", "STD"];
        } else if (setSpetVariableData === 2) {
            titles = ["Vuelo", "Hotel", "Especialista", "Clínica", "Resumen", "STD"];
        } else if (setSpetVariableData === 3) {
            titles = ["Especialista", "Clínica", "Vuelo", "Hotel", "Traslado", "Resumen", "STD"];
        }
        setDataStepzTitles(titles);
    }, [setSpetVariableData]);




    const sendStd = () => {

        const body = {
            eps: eps,
            height: height,
            weight,
            number_children: !haveChild ? "0" : quantityChild,
            previous_surgery: haveSurgery ? whichSurgery : "No",
            major_disease: haveIllness ? whichIllness : "No",
            drugs: drugsConsume ? whichDrugs : "No",
            drink_medication: consumeMedicaments ? whichMedicaments : "No",
            allergic_medication: youAlergic ? whichAlergic : "No",
            type_procedure: typeProcedure,
            smoke: smoke ? "Si" : "No",
            alcohol: drinkAlcohol ? "Si" : "No",
            name: userDetail.name,
            email: userDetail.email,
            nacionality: nacionality,
            date_operation: operationDate,
            birth_date: birthDate,
            form: formData
        }

        if (
            !height ||
            !weight ||
            (sons === true && !whichSons) ||
            (haveSurgery === true && !whichSurgery) ||
            (haveIllness === true && !whichIllness) ||
            (drugsConsume === true && !whichDrugs) ||
            (consumeMedicaments === true && !whichMedicaments) ||
            (youAlergicKnowed === true && !whichAlergicKnowed) ||
            (youAlergic === true && !whichAlergic) ||
            !terms ||
            !birthDate ||
            !operationDate ||
            !validateForm()
        ) {
            setValidation(true)
            validateForm()
        } else {
                setValidation(false)
                setLoading(true)
                let bodyPackage = {};

                if (especialtieAvliable) {
                    bodyPackage = {
                        specialtie_id: idEspecialtieData,
                        clinic_id: dataPackage.clinic.id,
                        procedure_id: procedureData.id,
                        name: userDetail.name,
                        email: userDetail.email,
                        phone: `+${prefixPhone} ${phone}`,
                    }

                    if (dataPackage.flight) {
                        let itinerary = [];

                        let itineraryFrom = {
                            dateArrival: dataPackage?.flight?.itinerary?.[0]?.dateArrival || '',
                            dateDeparture: dataPackage?.flight?.itinerary?.[0]?.dateDeparture || '',
                            from: dataPackage?.flight?.itinerary?.[0]?.from || '',
                            to: dataPackage?.flight?.itinerary?.[0]?.to || '',
                            company: dataPackage?.flight?.itinerary?.[0]?.company || '',
                            family: dataPackage?.flight?.itinerary?.[0]?.family || ''
                        };

                        itinerary = [itineraryFrom]

                        if (dataPackage?.flight?.itinerary?.length > 1) {
                            let itineraryTo = {
                                dateArrival: dataPackage.flight.itinerary[1]?.dateArrival || '',
                                dateDeparture: dataPackage.flight.itinerary[1]?.dateDeparture || '',
                                from: dataPackage.flight.itinerary[1]?.from || '',
                                to: dataPackage.flight.itinerary[1]?.to || '',
                                company: dataPackage.flight.itinerary[1]?.company || '',
                                family: dataPackage.flight.itinerary[1]?.family || ''
                            };

                            itinerary = [...itinerary, itineraryTo];
                        }

                        let flight = {
                            company: dataPackage.flight.company,
                            currency: dataPackage.flight.currency,
                            itinerary,
                            price: dataPackage.flight.priceTotal,
                        }

                        bodyPackage.flight = flight
                    }

                    if (dataPackage.hotel) {
                        let hotel = {
                            arrival: dataPackage.hotel.arrival,
                            departure: dataPackage.hotel.departure,
                            checkIn: dataPackage.hotel.dataHot.checkIn,
                            checkOut: dataPackage.hotel.dataHot.checkOut,
                            country: dataPackage.hotel.country,
                            countryClient: dataPackage.hotel.countryClient,
                            hotel: dataPackage.hotel.hotel,
                            price: dataPackage.hotel.price,
                            stars: dataPackage.hotel.stars,
                        }

                        bodyPackage.hotel = hotel
                    }
                }

                const urlPackage = `https://crmedic.pdtclientsolutions.com/api/wellezy/store/package`

                const url = `${process.env.REACT_APP_URL_API_FINANMED}/store/std`
                axios.post(url, body,)
                    .then((res) => {
                        if (especialtieAvliable) {
                            axios.post(urlPackage, bodyPackage)
                                .then((res) => {
                                    sendDataAiop()
                                })
                                .catch(err => {
                                    setLoading(false)
                                    console.log(err)
                                })
                        } else {
                            sendDataAiop()
                        }
                    })
                    .catch(err => {
                        setLoading(false)
                        console.log(err)
                    })

           
        }
    }

    const sendDataAiop = () => {
        const url = `${process.env.REACT_APP_URL_API_AIOP}api/searchs/build/package`

        const body = {
            "user_id": userDetail?.id,
            "type_search": dataSearch?.spetVariableData == 0 ? "Hotel" : dataSearch?.spetVariableData == 1 ? "Vuelo" : dataSearch?.spetVariableData == 2 ? "Vuelo + Hotel" : "",
            "origin": dataSearch.originSelectedFlightData,
            "destination": dataSearch.cityHotelData,
            "dep_date": dataSearch.dateGoingSelectedData,
            "arr_date": dataSearch.dateReturnSelectedData
        }

        axios.post(url, body)
            .then(res => {
                setLoading(false)
                setOpen(true)
                dispatch(setLastSearch({}))
                dispatch(setDataPackage({}))
                dispatch(setRoutes({}))
                dispatch(setProceduresData([]))
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
            })
    }

    const closeModal = (moda) => {
        navigate("/")
    }

    useEffect(() => {
        if (!userDetail) {
            setActiveSession(true)
        } else {
            setActiveSession(false)
        }

    }, [userDetail])

    const findCountryClient = (country) => {
        setLoadinNa(true);
        const url = `${process.env.REACT_APP_URL_API_FLY}restel/country`;
        axios
            .post(url, {
                code: country,
            })
            .then((res) => {
                setDataNacionality(res.data);
                setLoadinNa(false);
            })
            .catch((err) => {
                console.log(err);
                setLoadinNa(false);
            });
    };

    const selectedNacionality = (code, name) => {
        setNacionality(name);
    };





    const handleFormOpen = (index) => {
        // Si se hace clic en el formulario ya abierto, ciérralo; de lo contrario, ábrelo
        setActiveFormIndex((prevIndex) => (prevIndex === index ? null : index));
    };


    const validateForm = () => {
        let valid = true;
        const errors = {};

        formsFilter.forEach(camps => {
            const procedimiento = camps.procedimiento;

            // Verificar imágenes requeridas
            if (camps?.require_images?.length > 0) {
                const uploadedImages = formData[procedimiento]?.require_images || {};
                const uploadedCount = Object.keys(uploadedImages).length; // Contar cuántas imágenes han sido subidas

                if (uploadedCount < camps.require_images.length) {
                    valid = false;
                    errors[procedimiento] = {
                        ...errors[procedimiento],
                        require_images: {
                            message: `Debes subir ${camps.require_images.length} imágenes.`
                        }
                    };
                }
            }

            camps.formulario.forEach(campo => {
                const value = formData[procedimiento]?.[campo.key];

                // Verificar si el campo depende de otro
                const isDisabled = campo.dependsOn
                    ? formData[procedimiento]?.[campo.dependsOn.key] !== campo.dependsOn.value
                    : false;

                if (isDisabled) {
                    return; // Pasar al siguiente campo sin hacer la validación
                }

                // Si el campo es requerido y está vacío, marcar error
                if (campo.required && !value) {
                    valid = false;
                    errors[procedimiento] = {
                        ...errors[procedimiento],
                        [campo.key]: 'Este campo es obligatorio'
                    };
                }
            });
        });
        setFormErrors(errors);
        return valid;
    };



    const [uploadedImages, setUploadedImages] = useState({});

    const handleImageUpload = (e, procedimiento, index) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64Image = reader.result; // Base64 de la imagen

                // Actualizar el estado de las imágenes subidas (para la vista previa, puedes seguir usando la URL temporal)
                setUploadedImages(prevState => ({
                    ...prevState,
                    [procedimiento]: {
                        ...prevState[procedimiento],
                        [index]: URL.createObjectURL(file), // Guardar la URL temporal para la vista previa
                    }
                }));

                // Actualizar formData con la imagen en formato Base64
                setFormData(prevData => ({
                    ...prevData,
                    [procedimiento]: {
                        ...prevData[procedimiento],
                        require_images: {
                            ...prevData[procedimiento]?.require_images,
                            [index]: base64Image // Guardar la imagen en Base64
                        }
                    }
                }));

                // Limpiar errores si la imagen fue subida correctamente
                setFormErrors(prevErrors => {
                    const newErrors = { ...prevErrors };

                    // Si había un error en la imagen, eliminarlo
                    if (newErrors[procedimiento]?.require_images?.[index]) {
                        delete newErrors[procedimiento].require_images[index];

                        // Si ya no quedan errores de imágenes, eliminar la propiedad 'require_images'
                        if (Object.keys(newErrors[procedimiento].require_images).length === 0) {
                            delete newErrors[procedimiento].require_images;
                        }

                        // Si el procedimiento ya no tiene errores, eliminar la entrada del procedimiento
                        if (Object.keys(newErrors[procedimiento]).length === 0) {
                            delete newErrors[procedimiento];
                        }
                    }

                    return newErrors;
                });
            };

            // Leer el archivo como Base64
            reader.readAsDataURL(file);
        }
    };



    const [selectedBirthDate, setSelectedBirthDate] = useState(null);
    const [selectedOperationDate, setSelectedOperationDate] = useState(null);
    const [showBirthCalendar, setShowBirthCalendar] = useState(false);
    const [showOperationCalendar, setShowOperationCalendar] = useState(false);
    const calendarRef = useRef();

    const formatDate = (date) => {
        if (!date) return "";
        return date.toLocaleDateString("es-ES", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setShowBirthCalendar(false);
                setShowOperationCalendar(false);
            }
        };


        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    useEffect(() => {
        // Verificar si hay algún error en los formularios
        const formWithErrorIndex = formsFilter.findIndex(camps => {
            const procedimiento = camps?.procedimiento;
            const formErrorsForProcedure = FormErrors[procedimiento];
    
            // Si el procedimiento tiene errores, devolver el índice
            return formErrorsForProcedure && Object.keys(formErrorsForProcedure).some(key => formErrorsForProcedure[key]);
        });
    
        // Si se encontró un formulario con errores, actualizamos el estado para abrir ese formulario
        if (formWithErrorIndex !== -1) {
            setActiveFormIndex(formWithErrorIndex);
        }
    }, [FormErrors, formsFilter]);
    


    return (
        <>
         <Toaster position="top-center" reverseOrder={false} />
            {
                loading &&
                <Loading />
            }
            <SessionModal activeSessionModal={activeSession} setActiveSessionModal={setActiveSession} />
            <ModalAction open={open} setOpen={closeModal} />
            <CarouselSevices />
            <div className={styles.layoutMax}>
                {
                    especialtieAvliable && (
                        <>
                            <div className={styles.itemSectionAll}>
                                <StepHome />
                                {dataStepzTitles.map((item, index) => (
                                    <StepStep
                                        key={index}
                                        title={item}
                                        step={index + 1}
                                        onClick={handleStepClick}
                                        dataPackage={dataPackage}
                                    />
                                ))}
                            </div>

                            <DataSearch />
                        </>
                    )
                }
                <div className={styles.layout}>
                    <form action="" className={styles.form}>
                        <div className={styles.formTitle}>
                            <legend>Formulario STD</legend>
                            <p>Sistema de Triaje y Diagnostico</p>
                        </div>


                        <div className={styles.formSeparationInfo}>
                            <div className={styles.formSeparationItem}>

                                <div className={styles.formTitleItem}>
                                    <h4>1. Datos Personales </h4>
                                    <p>Llena tus datos personales si aún no lo has hecho.</p>
                                </div>

                                <div className={styles.formItemsData}>
                                    <div className={styles['camp-two-check-column']}>
                                        <label>
                                            <span>Sr / Sra: </span>
                                            {userDetail?.name}
                                        </label>
                                        <label>
                                            <span>Correo: </span>
                                            {userDetail?.email}
                                        </label>
                                        {
                                            userDetail?.prex_phone_public && userDetail?.phone_public && (
                                                <label><span>Celular: </span>{userDetail?.prex_phone_public} {userDetail?.phone_public}</label>
                                            )
                                        }
                                    </div>



                                    <div className={styles.formTitleItemAnyDescription}>
                                        <p>Información adicional</p>
                                    </div>


                                    <div className={styles["camp-one"]}>
                                        <InputNacionalityStd
                                            nacionalityDropdown={nacionalityDropdown}
                                            setNacionalityDropdown={setNacionalityDropdown}
                                            nacionality={nacionality}
                                            validationForm={validation}
                                            paceholders={paceholders}
                                            findCountryClient={findCountryClient}
                                            loading={loadinNa}
                                            dataNacionality={dataNacionality}
                                            selectedNacionality={selectedNacionality}
                                        />

                                    </div>
                                    <div className={styles["camp-two"]} ref={calendarRef}>
                                        {/* Fecha de Nacimiento */}
                                        <div className={styles.center}>
                                            <label htmlFor="">Fecha Nacimiento *</label>
                                            <div
                                                onClick={() => { setShowBirthCalendar(true); setShowOperationCalendar(false); }}
                                                className={`${styles.selectedDate} ${!birthDate && validation === true ? styles.obligatory : ""}`}
                                            >
                                                {formatDate(selectedBirthDate) ? formatDate(selectedBirthDate) : "dd/mm/yyyy"}
                                                <LuCalendarCheck color="var(--blueLightTransparent)" size={17} />
                                            </div>

                                            {showBirthCalendar && (
                                                <TooltipContainer
                                                    show={showBirthCalendar}
                                                    hidden={setShowBirthCalendar}
                                                    sizeWidth={55}
                                                    sizeHeight={55}
                                                >
                                                    <DatePicker
                                                        setBirthDate={setBirthDate}
                                                        show={showBirthCalendar}
                                                        hidden={setShowBirthCalendar}
                                                        selectedDate={selectedBirthDate}
                                                        setSelectedDate={setSelectedBirthDate}
                                                        isBirthday={true}
                                                    />
                                                </TooltipContainer>
                                            )}
                                        </div>

                                        {/* Fecha de Operación */}
                                        <div className={styles.right}>
                                            <label htmlFor="">Fecha que deseas operarte *</label>
                                            <div
                                                onClick={() => { setShowOperationCalendar(true); setShowBirthCalendar(false); }}
                                                className={`${styles.selectedDate} ${!operationDate && validation === true ? styles.obligatory : ""}`}
                                            >
                                                {formatDate(selectedOperationDate) ? formatDate(selectedOperationDate) : "dd/mm/yyyy"}

                                                <LuCalendarPlus color="var(--blueLightTransparent)" size={17} />
                                            </div>

                                            {showOperationCalendar && (
                                                <TooltipContainer
                                                    show={showOperationCalendar}
                                                    hidden={setShowOperationCalendar}
                                                    sizeWidth={55}
                                                    sizeHeight={55}
                                                >
                                                    <DatePicker
                                                        setOperationDate={setOperationDate}
                                                        show={showOperationCalendar}
                                                        hidden={setShowOperationCalendar}
                                                        selectedDate={selectedOperationDate}
                                                        setSelectedDate={setSelectedOperationDate}
                                                        isBirthday={false}
                                                    />
                                                </TooltipContainer>
                                            )}
                                        </div>
                                    </div>

                                    <div className={styles["camp-three"]}>
                                        {/* <div className={styles.left}>
    <label htmlFor="">EPS *</label>
    <input type="text" placeholder='Sura' className={!eps && validation === true ? styles.obligatory : ""} onChange={(e) => {
        setEps(e.target.value)
    }} />
</div> */}
                                        <div className={styles.center}>
                                            <label htmlFor="">Estatura *</label>
                                            <input type="number" placeholder='PJ 1.60' className={!height && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                                setHeight(e.target.value)
                                            }} />
                                        </div>
                                        <div className={styles.right}>
                                            <label htmlFor="">Peso *</label>
                                            <input type="number" placeholder='PJ 60Kg' className={!weight && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                                setWeight(e.target.value)
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className={styles.formSeparationItem}>


                                <div className={styles.formTitleItemAnyDescription}>
                                    <p>Preguntas importantes</p>
                                </div>


                                <div className={styles.formItemsCheckboxes}>
                                    <div className={styles['camp-two-check-column']}>
                                        <div className={styles.checkboxCont}>
                                            <input
                                                type="checkbox"
                                                checked={drinkAlcohol}
                                                onChange={() => {
                                                    setDrinkAlcohol(!drinkAlcohol)
                                                }}
                                            />
                                            <label htmlFor="">¿Consume alcohol?</label>
                                        </div>
                                    </div>

                                    <div className={styles['camp-two-check-column']}>
                                        <div className={styles.checkboxCont}>
                                            <input
                                                type="checkbox"
                                                checked={smoke}
                                                onChange={() => {
                                                    setSmoke(!smoke)
                                                }}
                                            />
                                            <label htmlFor="">¿Fuma?</label>
                                        </div>
                                    </div>

                                    <div className={styles['camp-two-check-middle']}>
                                        <div className={styles.checkboxCont}>
                                            <input
                                                type="checkbox"
                                                checked={sons}
                                                onChange={() => {
                                                    setSons(!sons)
                                                }}
                                            />
                                            <label htmlFor="">¿Tienes hijos?</label>
                                        </div>
                                        {
                                            sons &&
                                            <div className={styles['camp-middle']}>
                                                <label htmlFor="">¿Cuantos y que edades? *</label>
                                                <input type="text" placeholder='PJ 2 (2 años) (10 años) ' className={!whichSurgery && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                                    setWhichSons(e.target.value)
                                                }} />
                                            </div>
                                        }

                                    </div>

                                    <div className={styles['camp-two-check-middle']}>
                                        <div className={styles.checkboxCont}>
                                            <input
                                                type="checkbox"
                                                checked={haveSurgery}
                                                onChange={() => {
                                                    setHaveSurgery(!haveSurgery)
                                                }}
                                            />
                                            <label htmlFor="">¿Se ha practicado alguna cirugia?</label>
                                        </div>
                                        {
                                            haveSurgery &&
                                            <div className={styles['camp-middle']}>
                                                <label htmlFor="">Cirugias Anteriores *</label>
                                                <input type="text" placeholder='PJ Liposuccion' className={!whichSurgery && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                                    setWhichSurgery(e.target.value)
                                                }} />
                                            </div>
                                        }

                                    </div>
                                    <div className={styles['camp-two-check-middle']}>
                                        <div className={styles.checkboxCont}>
                                            <input
                                                type="checkbox"
                                                checked={haveIllness}
                                                onChange={() => {
                                                    setHaveIllness(!haveIllness)
                                                }}
                                            />
                                            <label htmlFor="">¿Sufre alguna enfermedad importante?</label>
                                        </div>
                                        {
                                            haveIllness &&
                                            <div className={styles['camp-middle']}>
                                                <label htmlFor="">¿Cuales? *</label>
                                                <input type="text" className={!whichIllness && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                                    setWhichIllness(e.target.value)
                                                }} />
                                            </div>
                                        }

                                    </div>

                                    <div className={styles['camp-two-check-middle']}>
                                        <div className={styles.checkboxCont}>
                                            <input
                                                type="checkbox"
                                                checked={drugsConsume}
                                                onChange={() => {
                                                    setDrugsConsume(!drugsConsume)
                                                }}
                                            />
                                            <label htmlFor="">¿Consume algun estupefaciente?</label>
                                        </div>
                                        {
                                            drugsConsume &&
                                            <div className={styles['camp-middle']}>
                                                <label htmlFor="">¿Cuales? *</label>
                                                <input type="text" className={!whichDrugs && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                                    setWhichDrugs(e.target.value)
                                                }} />
                                            </div>
                                        }

                                    </div>
                                    <div className={styles['camp-two-check-middle']}>
                                        <div className={styles.checkboxCont}>
                                            <input
                                                type="checkbox"
                                                checked={consumeMedicaments}
                                                onChange={() => {
                                                    setConsumeMedicaments(!consumeMedicaments)
                                                }}
                                            />
                                            <label htmlFor="">¿Tomas algun medicamento?</label>
                                        </div>
                                        {
                                            consumeMedicaments &&
                                            <div className={styles['camp-middle']}>
                                                <label htmlFor="">Medicamentos que toma *</label>
                                                <input type="text" className={!whichMedicaments && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                                    setWhichMedicaments(e.target.value)
                                                }} />
                                            </div>
                                        }

                                    </div>

                                    <div className={styles['camp-two-check-middle']}>
                                        <div className={styles.checkboxCont}>
                                            <input
                                                type="checkbox"
                                                checked={youAlergicKnowed}
                                                onChange={() => {
                                                    setYouAlergicKnowed(!youAlergicKnowed)
                                                }}
                                            />
                                            <label htmlFor="">¿Tienes alguna alergia conocida?</label>
                                        </div>
                                        {
                                            youAlergicKnowed &&
                                            <div className={styles['camp-middle']}>
                                                <label htmlFor="">¿A qué? *</label>
                                                <input type="text" className={!whichAlergicKnowed && validation === true ? styles.obligatory : ""} onChange={(e) => {
                                                    setWhichAlergicKnowed(e.target.value)
                                                }} />
                                            </div>
                                        }
                                    </div>

                                    <div className={styles['camp-two-check-middle']}>
                                        <div className={styles.checkboxCont}>
                                            <input
                                                type="checkbox"
                                                checked={youAlergic}
                                                onChange={() => {
                                                    setYouAlergic(!youAlergic)
                                                }}
                                            />
                                            <label htmlFor="">¿Es alergico algun medicamento o sutura?</label>
                                        </div>
                                        {
                                            youAlergic &&
                                            <div className={styles['camp-middle']}>
                                                <label htmlFor="">¿Cuales? *</label>
                                                <input className={!whichAlergic && validation === true ? styles.obligatory : ""} type="text" onChange={(e) => {
                                                    setWhichAlergic(e.target.value)
                                                }} />
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>







                    </form>
                    <div className={styles.previewDate}>
                        <section className={styles.contPrev}>

                            {
                                formsFilter.length > 0 ?
                                    formsFilter.map((item, index) => {
                                        return (
                                            <FormProcedure
                                                key={index}
                                                camps={item}
                                                change={manejarCambio}
                                                setFormData={setFormData}
                                                isOpen={activeFormIndex === index}  // Abre solo si el índice coincide
                                                onOpen={(index3) => handleFormOpen(index3)}  // Actualiza el formulario activo
                                                formError={FormErrors}
                                                formData={formData}
                                                index={index}
                                                formsFilter={formsFilter}
                                                FormErrors={FormErrors}
                                                uploadedImages={uploadedImages}
                                                handleImageUpload={handleImageUpload}
                                                validateForm={validateForm}
                                            />
                                        )
                                    })
                                    :
                                    <p>
                                        No hay forms
                                    </p>
                            }

                            {/* {
                                formsFilter.map(item => (
                                    <div className={styles.container_photos} key={item.procedimiento}>
                                        <p>Fotografías Requeridas ({item.procedimiento}) *</p>
                                        {
                                            FormErrors[item.procedimiento]?.require_images &&
                                            <p style={{ color: "var(--redPrimary)" }}>
                                                {FormErrors[item.procedimiento]?.require_images.message}
                                            </p>
                                        }
                                        <div className={styles.grid_container}>
                                            {
                                                item.require_images.map((img, index) => {
                                                    const uploadedImage = uploadedImages[item.procedimiento]?.[index];


                                                    return (
                                                        <div key={index}>
                                                            <img
                                                                src={uploadedImage || img}
                                                                alt="Procedure"
                                                            />
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                style={{ backgroundColor: "var(--white)" }}
                                                                onChange={(e) => handleImageUpload(e, item.procedimiento, index)}
                                                            />
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                ))
                            } */}






                        </section>


                        <div className={styles.contContinue}>
                            <div className={styles.contNote}>
                                <span>Nota:</span>
                                <p>
                                    Para continuar debes de llenar todos los datos del formulario, <br /> para facilitar tu valoración con nuestro sistema del STD
                                </p>
                            </div>

                            <div className={styles.checkTerms}>
                                <input type="checkbox" id='confirm' className={!terms && validation === true ? styles.obligatory : ""}
                                    checked={terms}
                                    onChange={() => {
                                        setTerms(!terms)
                                    }}
                                />
                                <label htmlFor='confirm'>
                                    He leído y acepto las <span>Politicas de Tratamientos de Datos Personales</span>
                                </label>
                            </div>
                            

                            <button className={styles.btnContinue} disabled={!terms} style={{
                                opacity: !terms ? "0.5" : "1"
                            }} onClick={sendStd}>Continuar</button>


                        </div>

                    </div>
                </div>
            </div>
            <AnyQuestions title={t("browse_home.have_doubt")} />
        </>
    )
}



export default Std;
