import React, { useEffect, useState, useRef } from 'react'
import './assets/styles.css'
import '../Header/Assets/styles.css'

import styled from 'styled-components'
import { motion} from 'framer-motion'
import { Grid } from '@mui/material'
import FlightsFind from '../FlightFind'
import bannerEje from "./assets/img/RECURSOS10.jpg"
import health from "./assets/img/RECURSOS03.png"
import hospital from "./assets/img/RECURSOS02.png"
import bag from "./assets/img/RECURSOS06.png"
import food from "./assets/img/RECURSOS05.png"
import lettersEje from "./assets/img/RECURSOS04.png"
import compra from "./assets/img/travel04.jpg"
import download from "./assets/img/travel05.jpg"
import ContactWellezy from '../ContactWellezy/ContactWellezy';
import letterSantander from './assets/img/lettersSantander.png'
import santanderbanner from './assets/img/santanderbanner.jpg'
import CarouselSevices from '../../ui/views/share/components/CarouselServices/CarouselSevices'
import Find from '../../ui/views/share/components/Find/Find'
import FlightsTopHoteles from '../FlightsTopHoteles/FlightsTopHoteles';

const MotionConstraints = styled(motion.div)`
    overflow-x: hidden;
  `;

const MotionBox = styled(motion.div)`
    width: 100vw;
  `

const conoce = [
  {
    title: 'Eje cafetero',
    img: bannerEje
  },
  {
    title: "Cartagena",
    img: "https://i0.wp.com/theunknownenthusiast.com/wp-content/uploads/2022/07/calle-de-la-iglesia-carrera-4-catedral-de-santa-catalina-cartagena.jpg?fit=853%2C640&ssl=1"
  }
]

const TravelLanding = () => {

  const constraintsRef = useRef(null)
  const [letters, setLetters] = useState(0)
  
  const autoCarousel = useRef(null)
  const sliderDate = [
    {id: 1, img: bannerEje, title: lettersEje},
    {id: 2, img: santanderbanner, title: letterSantander},
    
]

  const nextFunc = () => {
      setLetters(letters == 1 ? 2 : 1)
      if(autoCarousel.current){
        if(autoCarousel.current.children.length > 0) {
          const firstCard = autoCarousel.current.children[0]
           
          autoCarousel.current.style.transition = `300ms ease-out all`
          const widthCard = autoCarousel.current.children[0].offsetWidth
          autoCarousel.current.style.transform = `translateX(-${widthCard}px)`
 
          const transition = () => {
              autoCarousel.current.style.transition = 'none'
              autoCarousel.current.style.transform = `translateX(0)`
 
              autoCarousel.current.appendChild(firstCard)
 
              /*Después que se ejecute lo elimino para que el boton de anterior funcione*/
              autoCarousel.current.removeEventListener('transitionend', transition)
          }
 
          autoCarousel.current.addEventListener('transitionend', transition)
       }
      }
      
  }

  useEffect(() => {
      
      setLetters(1)
          
      
  }, [])

  useEffect(() => {
    setInterval(() => {
        nextFunc()
    }, 20000)
}, [letters])

  return (
    <div>
      <header className='header-travel' >
        <div className='flightsHeaderSlider'>
          <div className='flightsHeaderSlider_'>
            <div className='flightsHeaderSlide_cards' ref={autoCarousel}>
              {sliderDate.map((date) => {
                return (
                  <div key={date.id} className='flightsHeaderSlider_date'>
                    <div className='flightsHeaderSlider_img'>
                      <img src={date.img} alt={date.id} />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className='efect-black'>
          <div className='contain-items-header'>
            {
              letters == 1 &&
              <img className='image-letters' src={lettersEje} alt="" />
            }
            {
              letters == 2 &&
              <img className='image-letters' src={letterSantander} alt="" />
            }

            <div>
              <ul>
                <li>Del 14 al 17 de Abril</li>
                <li>Del 31 de Marzo al 3 Abril</li>
              </ul>
            </div>
          </div>
          <div className='contain-items-header'>
            <div className='layout-titles'>
              <span></span>
              <h3>Tarifas desde</h3>
              <span></span>
            </div>

            <div>
              <p className='price-packages'>$599.000</p>
            </div>
            <div className='for-person'>
              <p >Por Persona</p>
            </div>


            <div className='layout-sub-titles'>
              <span></span>
              <h3>Incluye</h3>
              <span></span>
            </div>
            <div className='contain-icons-h'>
              <ul>
                <li><img src={health} alt="" /></li>
                <li><img src={hospital} alt="" /></li>
                <li><img src={bag} alt="" /></li>
                <li><img src={food} alt="" /></li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <Find>

      </Find>
      <main>


        <CarouselSevices>

        </CarouselSevices>


        <section className='find-fligth-t'>
          <FlightsFind></FlightsFind>
        </section>
        <section className='video-youtube'>
          <div className='contain-video'>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/bkvRLckKpAg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>

            </iframe>
          </div>
        </section>
        <section className='conoce-travel'>
          <div className='section-conoce-travel'>
            <h3>CONOCE LAS OFERTAS QUE TENEMOS PARA TÍ</h3>
            <div>
              <img src="https://media.istockphoto.com/id/1205573583/es/foto/una-mujer-muy-joven-usando-su-tel%C3%A9fono-m%C3%B3vil-mientras-trabaja-con-un-ordenador-port%C3%A1til.jpg?s=612x612&w=0&k=20&c=C19eqeHXanloSFEjENC39Bfx7hk6l6JzIXr0JI8TiNQ=" alt="" />
              <p>Tienes dudas eligiendo tu destino aquí tenemos una variedad de paquetes para ti</p>
            </div>
          </div>
          <div className='section-conoce-travel-b'>
            <h3>DESTINOS MÁS VISTOS HOY</h3>
            <div className='layout-conoce'>
              <MotionConstraints className='slider_container' ref={constraintsRef}>
                <MotionBox className='slider_' drag='x'  dragConstraints={constraintsRef}>
                  {
                    conoce.map((info) => {
                      return (
                        <motion.div className='div_homeTurism' key={info.title}>
                          <Grid item xs={6} sm={6} md={3} lg={2} className='grid_cardHomeTurism'>
                            <div>
                              <img src={info.img} alt={info.title} />
                              <p className='section-conoce-travel-b-p'>{info.title}</p>
                            </div>
                          </Grid>
                        </motion.div>
                      )
                    })
                  }

                </MotionBox>
              </MotionConstraints>
            

            </div>

          </div>
        </section>

        <section className='lugares-top'>
          <h3>
            <b>Lugares Mas Visitados</b><br /> Viaja a los destinos mas destacados
          </h3>
          <div className='line-top'></div>
          <FlightsTopHoteles keys={"lugares"}></FlightsTopHoteles>
        </section>
        <section className='hoteles-top'>
          <h3>
            <b>Los hoteles mas destacados</b><br /> Sientete como en casa, relajate y descansa en los mejores lugares
          </h3>
          <div className='line-top'></div>
          <FlightsTopHoteles keys={"hotels"}></FlightsTopHoteles>
        </section>
        <section className='download-app'>
          <img src={download} alt="" />
          <img src={compra} alt="" />
        </section>
      </main>
      <section>
        <ContactWellezy></ContactWellezy>
      </section>
    </div>
  )
}

export default TravelLanding