import React, { useState } from 'react';
import { Record, RecordCircle, TickSquare, Stop } from 'iconsax-react'
import '../styles.css';

const defaultProperties = {
  size: '20',
  color: '#104172',
  variant: 'Bold',
}

export const displayMethod = {
  block: { name: 'block', class: 'displaycolumn' },
  flex: { name: 'flex', class: 'displayRow' },
  wrap: { name: 'wrap', class: 'displayWrap' },
}

export const shapes = {
  circle: {
    isActive: <RecordCircle size={defaultProperties.size} color={defaultProperties.color} variant={defaultProperties.variant} />,
    notActive: <Record size={defaultProperties.size} color={defaultProperties.color} /> 
  },
  square: {
    isActive: <TickSquare size={defaultProperties.size} color={defaultProperties.color} variant={defaultProperties.variant} />,
    notActive: <Stop size={defaultProperties.size} color={defaultProperties.color} />
  },
}



function InputCheck({ methodDisplay = displayMethod.wrap, list = [], shape = shapes?.circle , id = ''}) {

  return (
    <div className={methodDisplay?.class}>
      {
        list.length > 0 && list.map((index, key) => {
          return (
            <div key={key} className='optionCheck' onClick={() => index?.change(id, key === 0 ? true : false )}>
              {index?.isActive
                ? shape.isActive
                : shape.notActive
              }
              <p className='textSimpleBlue' style={{ marginLeft: 3 }}>{index?.label}</p>
            </div>
          )
        })
      }
    </div>
  )
}


export default InputCheck;