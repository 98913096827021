import React, { useState } from 'react'
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import { useTranslation } from 'react-i18next'

const ButtonsFilters = ({filterNamePharmacy}) => {
    const [dropdownFindName, setDropdownFindName] = useState(false)
    const [dropdownPersonalizer, setDropdownPersonalizer] = useState(false)
    const [KeyWord, setKeyWord] = useState('')
    const [KeyWordB, setKeyWordB] = useState('')
    const [t,i18n] = useTranslation("global")

    return (
        <div className='contain-buttons-filters-pharmacy'>
            <span className='find-name-pharmacy' >
                <div className='layout-find-pharmacy-a'>
                    <SvgIcon name={iconList.IconMedicaments} color={'#004274'} size={55} />
                </div>
                <div className='layout-find-pharmacy-b' onClick={()=>{setDropdownFindName(!dropdownFindName)}}>
                    <p className='layout-find-pharmacy-b-title'>
                        {t("pharmacy.what_product")}
                    </p>
                    <p className='layout-find-pharmacy-b-p'>
                        {KeyWord === '' ? t("pharmacy.name_product") : KeyWord}
                    </p>
                </div>
                <div className='layout-find-pharmacy-c'  onClick={()=>{setDropdownFindName(!dropdownFindName)}}>
                    <SvgIcon name={iconList.IconSearch} color={'#004274'} size={40} />
                </div>
                {
                    dropdownFindName &&
                    <div className='dropdown-personalize-find-clinics'>
                        <input type="text" onChange={(e) => { setKeyWord(e.target.value) }} />
                        <span onClick={() => {
                            filterNamePharmacy(KeyWord)
                            setDropdownFindName(!dropdownFindName)
                        }}>{t("clinics.buttons.search")}</span>
                    </div>
                }
            </span>
            <span className='find-peronalizer-pharmacy' >
                <div className='layout-find-personalizer-pharmacy-a'>
                    <SvgIcon name={iconList.IconFilters} color={'#004274'} size={45} />
                </div>
                <div className='layout-find-personalizer-pharmacy-b' onClick={()=>{setDropdownPersonalizer(!dropdownPersonalizer)}}>
                    <p className='layout-find-pharmacy-b-title'>
                        {t("clinics.buttons.filters")}
                    </p>
                    <p className='layout-find-pharmacy-b-p'>
                        {KeyWordB === '' ? t("clinics.buttons.custom_search") : KeyWordB}
                    </p>
                </div>
                <div className='layout-find-personalizer-pharmacy-c' onClick={()=>{setDropdownPersonalizer(!dropdownPersonalizer)}}>
                    <SvgIcon name={iconList.IconArrowDown} size={30} color={'#004274'} />
                </div>
                {
                     dropdownPersonalizer &&
                     <div className='dropdown-personalize-find-clinics'>
                         <input type="text" onChange={(e) => { setKeyWordB(e.target.value) }} />
                         <span onClick={() => {
                             filterNamePharmacy(KeyWordB)
                             setDropdownPersonalizer(!dropdownPersonalizer)
                         }}>{t("clinics.buttons.search")}</span>
                     </div>
                }
            </span>
        </div>
    )
}

export default ButtonsFilters