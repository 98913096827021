import React, { useState, useEffect } from 'react';
import './styles.css';
import { useTranslation } from "react-i18next"



function ChangeLanguage() {
  const userLanguage =  navigator.language || navigator.userLanguage;
  
  const languagesList = [
    { name: 'english', code: 'en', flag: 'https://i.pinimg.com/222x/0d/3f/e0/0d3fe0512090d289833e55c38ec19a1b.jpg' },
    { name: 'spanish', code: 'es', flag: 'https://static.vecteezy.com/system/resources/previews/008/070/285/non_2x/spain-flag-icon-in-official-color-and-proportion-correctly-free-vector.jpg' },
  ]
  const [t, i18n] = useTranslation("home")
  const [isShowList, setIsShowList] = useState(false)
  const [currentLanguage, setcurrentLanguage] = useState(null);

  function chooseLanguage(value) {
    if (value === currentLanguage) return;
    setcurrentLanguage(value)
    setIsShowList(false)
  }

  useEffect(() => {
    if(userLanguage.startsWith('es')){
      setcurrentLanguage(languagesList[1]);
    }else{
      setcurrentLanguage(languagesList[0]);
    }
    
  }, [])

  return (
    <div className='changeLanguageContainer'>
      <div className='changeLanguageCurrentLanguage'>
        <div className='changeLanguageCircle' onClick={() => setIsShowList(!isShowList)}>
          <img src={currentLanguage?.flag} alt='' className='changeLanguageCircleFlag' />
        </div>
      </div>
      <div className='changeLanguageList'>

        {isShowList && languagesList.map((index, key) => {
          return (
            <div key={key} className={`changeLanguageContainerOption ${currentLanguage && index?.code === currentLanguage?.code && 'notAvailable'}`} onClick={() => chooseLanguage(index)}>
              <div className='changeLanguageCircle'>
                <img src={index?.flag} alt='' onClick={() => {
                  i18n.changeLanguage(index.code)
                }} className='changeLanguageCircleFlag' />
              </div>
            </div>
          )
        })
        }

      </div>
    </div>
  )
}

export default ChangeLanguage;

