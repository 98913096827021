import React from 'react';

import { ReactComponent as IconFail } from '../Assets/svg/IconFail.svg'

const ScreenFailPay = ({product, amount, goToBuyAgain}) => {
  return (
    <div className="container-voucher-terrawind">
        <div className="voucher-terrawind-error pay-rejected-terrawind">
            <div>
            <p className="title-p-pay-rejected-terrawind"><span><IconFail/></span> Tarjeta rechazada</p>
            </div>
            <div className="details-fail-pay">
                <p>Lo sentimos tu transacción no se ha completado con éxito</p>
                <p>Producto: <span>{product}</span></p>
                <p>Cantidad intentada pagar: <span>{amount}</span></p>
            </div>
            <div className="btn-buy-again">
                <button className="btn-download-terrawind" onClick={goToBuyAgain}>Volver a comprar</button>
            </div>
        </div>
    </div>
  );
};

export default ScreenFailPay;
