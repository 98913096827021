import React, { useState } from 'react';
import styles from './styles.module.css';
import ModalContainer from '@/ui/views/share/components/modalContainer';
import { FaRegFileImage } from 'react-icons/fa6';
import { MdFileDownloadDone, MdFileUpload } from 'react-icons/md';
import { BsCheckCircleFill } from 'react-icons/bs';

const ProcedureModal = ({ formsFilter, FormErrors, uploadedImages, handleImageUpload, validateForm }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => {
        setIsOpen(!isOpen);
        validateForm()
    };

    return (
        <>
            <button onClick={toggleModal} className={styles.openModalButton}>
                Adjuntar fotografías requeridas * <MdFileUpload size={20} />
            </button>

            <ModalContainer

                show={isOpen}
                setHidden={setIsOpen}
                hiddsetActiveChangeServiceen={setIsOpen}
                width={890}
                height={700}
            >
                <div className={styles.container_form_photos}>
                    {formsFilter.map(item => (
                        <div className={styles.container_photos} key={item.procedimiento}>
                            <h3>Fotografías Requeridas ({item.procedimiento}) *</h3>
                            {FormErrors[item.procedimiento]?.require_images && (
                                <p style={{ color: "var(--redPrimary)" }}>
                                    {FormErrors[item.procedimiento]?.require_images.message}
                                </p>
                            )}
                            <div className={styles.grid_container}>
                                {item?.require_images?.map((img, index) => {
                                    const uploadedImage = uploadedImages[item.procedimiento]?.[index];
                                    return (
                                        <div key={index} className={styles.itemFileImage}>
                                            <div className={styles.itemFileImageContent}>
                                                <img
                                                    src={uploadedImage || img}
                                                    alt={`Imagen ${index + 1}`}
                                                />
                                            </div>
                                            <label htmlFor={`file-${index}`} className={`${styles.custumFileUpload}  ${uploadedImage ? styles.uploaded : ''}`}>
                                                <div className={`${styles.icon} ${uploadedImage ? styles.uploaded : ''}`}>
                                                    {
                                                        uploadedImage ? (<MdFileDownloadDone size={18} />) : (<FaRegFileImage size={18} />)
                                                    }
                                                </div>
                                                <div className="text">
                                                    <span className={`${styles.uploadedText} ${uploadedImage ? styles.uploaded : ''}`}>
                                                        {uploadedImage ? `IMAGEN_${index + 1}` : "Adjuntar fotografía"}
                                                    </span>
                                                </div>
                                                <input
                                                    id={`file-${index}`}
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => handleImageUpload(e, item.procedimiento, index)}
                                                />
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>


                        </div>
                    ))}

                    <div className={styles.positionButton}>
                        <button onClick={toggleModal} className={styles.openModalButton}>
                            Confirmar <BsCheckCircleFill size={17} />
                        </button>
                    </div>
                </div>
            </ModalContainer>
        </>
    );
};

export default ProcedureModal;
