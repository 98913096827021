import React, { useState, useEffect } from "react";
import './styles.css';
import BrowseHome from "../browseHome";
import { useLocation } from "react-router-dom";
function PrimarySection({pathname}) {

 
  // const listImages = [
  //   'https://media.istockphoto.com/id/1181180872/es/foto/pareja-joven-viendo-contenido-multimedia-de-internet-en-tableta-digital.jpg?s=612x612&w=0&k=20&c=htAl1QMX0CQvkjm5y2MsOy2Vjze6MR6dlUsXW8LIFEA=',

  //   'https://cdn.pixabay.com/photo/2017/08/17/07/47/travel-2650303_1280.jpg',


  //   'https://img.freepik.com/foto-gratis/taxista-preparandose-cliente_23-2149273258.jpg?w=1380&t=st=1686665841~exp=1686666441~hmac=030b158596bcee937509430a0f7ed261f0255c54af02ac63bae6bd922f63f34e',


  //   'https://cdn.pixabay.com/photo/2021/02/03/00/10/receptionists-5975962_1280.jpg',

  //   'https://img.freepik.com/foto-gratis/adultos-tiro-completo-que-viajan-ninos_23-2149272093.jpg?w=1380&t=st=1686445214~exp=1686445814~hmac=4596e554f86409354a3109e33a6731590bd17e1b3211d98aba35b8f4267ceaab',


  //   'https://img.freepik.com/foto-gratis/vista-arriba-rejuvenecimiento-hermosa-mujer-disfrutando-procedimientos-cosmetologia-salon-belleza-dermatologia-manos-resplandores-azules-salud-terapia-botox_197531-2783.jpg?w=1380&t=st=1686445300~exp=1686445900~hmac=fe68f78837241edaaaa4a249de6f0b3abf32ca3662772888904623b2ab248f2f'
  // ]

  // const textExample = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.';
  // const list = [
  //   { id: 0, url: listImages[0], text: textExample, alignLeft: true, showButton: true, buttonText: 'Ver más', buttonAction: () => {}},
  //   { id: 1, url: listImages[1], text: textExample, alignLeft: true, showButton: true, buttonText: 'Ver más', buttonAction: () => {}},
  //   { id: 2, url: listImages[2], text: textExample, alignLeft: false, showButton: true, buttonText: 'Ver más', buttonAction: () => {}},
  //   { id: 3, url: listImages[3], text: textExample, alignLeft: true, showButton: true, buttonText: 'Ver más', buttonAction: () => {}},
  //   { id: 4, url: listImages[4], text: textExample, alignLeft: false, showButton: true, buttonText: 'Ver más', buttonAction: () => {}},
  //   { id: 5, url: listImages[5], text: textExample, alignLeft: true, showButton: true, buttonText: 'Ver más', buttonAction: () => {}},
  // ]
  // const [currentBanner, setCurrentBanner] = useState(list[0]);

  // const handleClick = (index) => {
  //   setCurrentBanner(index);
  // };

  // useEffect(() => {
  //   const maxPosition = list.length
  //   const newId = currentBanner.id + 1
  //   const nextPositionn = newId === maxPosition ? 0 : newId;
  //   const nextBanner = list[nextPositionn];
  //   setTimeout(() => {
  //     // setCurrentBanner(nextBanner);
  //   }, 10000);
  // }, [currentBanner])

  return (
    <section className='homePrimarySection'>
      <BrowseHome pathname={pathname}/>
      {/* <div className='homePrimarySectionLeft'>
        <div className="homePrimarySectionLeftInfo"
        style={{ alignItems: currentBanner?.alignLeft? 'flex-start':'flex-end'}}>
          <p className="homePrimarySectionLeftInfoText" style={{
            textAlign: currentBanner?.alignLeft? 'left': 'right'
          }}>{currentBanner?.text}
          </p>
          {currentBanner?.showButton &&
            <div onClick={currentBanner?.buttonAction} className="homePrimarySectionLeftInfoBtn">
              <p className="homePrimarySectionLeftInfoBtnText">{currentBanner?.buttonText}</p>
            </div>
          }
        </div>
        <img src={currentBanner?.url} alt='' className="homePrimarySectionImg" />
      </div>
      <div className='homePrimarySectionRight'>
        <div className="homePrimarySectionRightCardContainer">
          {
            list.map((index, key) => {
              return (
                <div key={key} className={`homePrimarySectionRightCard ${index?.id === currentBanner.id ? 'homePrimarySectionRightCardOn' : ''}`} onClick={() => handleClick(index)}>
                  <img src={index?.url} alt='' className="homePrimarySectionRightCardImg" />
                </div>
              )
            })
          }
        </div>
      </div> */}
    </section>
  )
}

export default PrimarySection;
