import React, { useEffect, useState } from 'react';

import './Assets/styles.css'
import InputSimple from '../../TerrawindVoucherDetail/FormDataPassenger/InputSimple/InputSimple';
import { DateRangePicker } from 'react-date-range';
import InputPhone from './InputPhone/InputPhone';

const PackageTourismPassenger = ({index, setSend, handleFormChange, long, key, setFormValidations}) => {
    // Fecha base
    const date = new Date();
    date.setDate(date.getDate() - 1)

    const [isPickerVisible, setPickerVisible] = useState(false);
    const [isPickerVisiblePasaport, setPickerVisiblePasaport] = useState(false);
    const [state, setState] = useState([{ startDate: date, endDate: date, key: 'selection' }]);

    // Datos del viajero
    const [gender, setGender] = useState("");
    const [names, setNames] = useState("");
    const [lastNames, setLastNames] = useState("");
    const [birthDate, setBirthDate] = useState(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
    const [nacionality, setNacionality] = useState("");
    const [pasaport, setPasaport] = useState("");
    const [expiratePasaport, setExpiratePasaport] = useState(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
    const [email, setEmail] = useState("");
    const [prefixPhone, setPrefixPhone] = useState("");
    const [phone, setPhone] = useState("");

    // Validación
    const [validate, setValidate] = useState("");

    // Formato de fecha
    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const [formattedBirthDate, setFormattedBirthDate] = useState(formatDate(birthDate));
    const [formattedExpiredPasaport, setFormattedExpiredPasaport] = useState(formatDate(expiratePasaport));

    // Selección de fecha de nacimiento
    const handleSelectDate = (ranges) => {
        if (ranges.selection.startDate) {
            const startDate = new Date(ranges.selection.startDate);
            if (!isNaN(startDate)) {
                setBirthDate(startDate);
                setFormattedBirthDate(formatDate(startDate));
            }
        }

        setPickerVisible(false);
    }

    // Seleeción de fecha de expiración de pasaporte

    const handleSelectDatePasaport = (ranges) => {
        if (ranges.selection.startDate) {
            const startDate = new Date(ranges.selection.startDate);
            if (!isNaN(startDate)) {
                setExpiratePasaport(startDate);
                setFormattedExpiredPasaport(formatDate(startDate));
            }
        }

        setPickerVisiblePasaport(false);
    }

    // Validación de campos

    const validateData = () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const currentDate = new Date();

        if(gender === ""){
            setValidate("gender");
            return false;
        }else if(names === "" || names.length < 3 || /\d/.test(names)){
            setValidate("names");
            return false;
        }else if(lastNames === "" || names.length < 3 || /\d/.test(names)){
            setValidate("lastNames");
            return false;
        }else if(nacionality === ""){
            setValidate("nacionality");
            return false;
        }else if(pasaport === ""){
            setValidate("pasaport");
            return false;
        }else if(email === "" || !emailRegex.test(email)){
            setValidate("email");
            return false;
        }else if(phone === "" || isNaN(phone) || phone.length < 8){
            setValidate("phone");
            return false;
        }else if(prefixPhone === ""){
            setValidate("phone");
            return false;
        }else{
            setValidate("");
        }

        return true;
    }

    useEffect(() => {
        if(validateData()){
            handleFormChange(
                index, 
                { 
                    name: names, 
                    lastname: lastNames, 
                    email: email,
                    birthdate: formattedBirthDate,
                    nacionality: nacionality,
                    passport: pasaport,
                    passport_expiration_date: formattedExpiredPasaport,
                    prefix_number_contact: prefixPhone,
                    number_contact: phone
                }
            );

            setFormValidations(prevState => {
                const newState = [...prevState];
                newState[index] = true;
                return newState;
            });
        }else{
            setFormValidations(prevState => {
                const newState = [...prevState];
                newState[index] = false;
                return newState;
            });
        }
    }, [gender, names, lastNames, nacionality, pasaport, email, phone, prefixPhone])

    const showDatePicker = () => {
        setPickerVisible(true);
    }

    const showDatePickerPasaport = () => {
        setPickerVisiblePasaport(true);
    }

    return(
        <div className="package-tourism-passenger">
            <div className='p-traveler-tourism-package'>
                <p>Viajero {index + 1}</p>
            </div>
            <div>
                <div className='container-inputs-sura'>
                    <div className='container-input-sura input-gender-tourism-package'>
                        <label htmlFor="">Genero *</label>
                        <select className={validate === "gender" ? "validate-gender": null} name="" id="" onChange={(e) => setGender(e.target.value)}>
                            <option value="">Seleccione</option>
                            <option value="M">Masculino</option>
                            <option value="F">Femenino</option>
                        </select>
                        {validate === "gender" && <p className="validate-input-sura">El campo es requerido</p>}
                    </div>
                    <div className={validate === "names"? 'container-input-sura input-validate-sura': 'container-input-sura'}>
                        <InputSimple label="Nombres *" onInputChange={setNames}/>
                        {validate === "names" && <p className="validate-input-sura">El campo es requerido</p>}
                    </div>
                    <div className={ validate === "lastNames"? 'input-validate-sura container-input-sura': 'container-input-sura' }>
                        <InputSimple label="Apellidos *" onInputChange={setLastNames} />
                        {validate === "lastNames" && <p className="validate-input-sura">El campo es requerido</p>}
                    </div>
                </div>
                <div className='container-inputs-sura'>
                    <div className={ validate === "birthDate" ? "validate-input-voucher conatiner-input-date": "conatiner-input-date"}>
                        <label htmlFor="">Fecha de Nacimiento *</label>
                        <button onClick={showDatePicker} className="data-picker-voucher-tourism-package">
                        {formattedBirthDate}
                        </button>
                        {
                            isPickerVisible && (
                                <div style={{ position: 'absolute', zIndex: 999 }}>
                                    <DateRangePicker
                                        ranges={state}
                                        onChange={handleSelectDate}
                                        maxDate={date}
                                    />
                                </div>
                            )
                        }
                    </div>
                    <div className={validate === "nacionality"? 'input-validate-sura container-input-sura': 'container-input-sura'}>
                        <InputSimple label="Nacionalidad *" onInputChange={setNacionality}/>
                        {validate === "nacionality" && <p className="validate-input-sura">El campo es requerido</p>}
                    </div>
                    <div className={validate === "pasaport"? 'input-validate-sura container-input-sura': 'container-input-sura'}>
                        <InputSimple label="Pasaporte *" onInputChange={setPasaport}/>
                        {validate === "pasaport" && <p className="validate-input-sura">El campo es requerido</p>}
                    </div>
                </div>
                <div className='container-inputs-sura'>
                    <div className={ validate === "birthDate" ? "validate-input-voucher conatiner-input-date": "conatiner-input-date"}>
                        <label htmlFor="">Fecha expiración de pasaporte *</label>
                        <button onClick={showDatePickerPasaport} className="data-picker-voucher-tourism-package">
                        {formattedExpiredPasaport}
                        </button>
                        {
                            isPickerVisiblePasaport && (
                                <div style={{ position: 'absolute', zIndex: 999 }}>
                                    <DateRangePicker
                                        ranges={state}
                                        onChange={handleSelectDatePasaport}
                                        minDate={date}
                                    />
                                </div>
                            )
                        }
                    </div>
                    <div className={validate === "email"? 'input-validate-sura container-input-sura': 'container-input-sura'}>
                        <InputSimple label="Correo *" onInputChange={setEmail}/>
                        {validate === "email" && <p className="validate-input-sura">El campo es requerido</p>}
                    </div>
                    <div>
                        <InputPhone  onInputChange={setPhone} validate={validate === "phone"? true : false} setDocumentTypeId={setPrefixPhone} typeDocument={setPhone}/>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default PackageTourismPassenger;