import React from 'react'
import TravelSecure from '../../ui/views/travelSecure/TravelSecure'

const TravelSecureRoute = () => {
  return (
    <div>
        <TravelSecure/>
    </div>
  )
}

export default TravelSecureRoute