import React, { useState } from 'react'
import "./styles.css"
import SelectedForm from './SelectedForm/SelectedForm'
import { useEffect } from 'react'

const FormPassenger = ({ index, travellers, setTravellers, paidPackages }) => {

  const [paisSelected, setPaisSelected] = useState(false)
  const [documentation, setDocumentation] = useState(false)
  const [daySelected, setDaySelected] = useState(false)
  const [monthSelected, setMonthSelected] = useState(false)
  const [yearSelected, setYearSelected] = useState(false)
  const [sex, setSex] = useState("")

  const [typeDocument, setTypeDocument] = useState("CI")
  const [dayOption, setDayOption] = useState("")
  const [monthOption, setMonthOption] = useState("")
  const [yearOption, setYearOption] = useState("")
  const [lastName, setLastName] = useState("")
  const [name, setName] = useState("")
  const [country, setCountry] = useState("Colombia")
  const [document, setDocument] = useState("")
  const [errCamp, setErrCamp] = useState(false)

  const traveler = {
    name: name,
    lastName: lastName,
    country: country,
    document: document,
    typeDocument: typeDocument,
    birthDate: `${dayOption}-${monthOption}-${yearOption}`,
    sex: `${sex === "m" ? "Male" : "Female"}`
  }



  const documentacion = [
    "CI",
    "TI",
    "PAS",
    "CE"
  ]

  useEffect(() => {
    const condition = paidPackages(traveler)
    if (condition !== true) {
      setErrCamp(condition)
      setSex("")
    } else {
      setErrCamp(false)
    }
  }, [sex])


  const paises = [
    "Colombia",
    "Peru",
    "Ecuador",
    "Argentina"
  ]

  const day = [];
  for (let i = 1; i <= 31; i++) {
    day.push(i.toString());
  }



  const month = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  const year = [];
  for (let i = 2023; i >= 1940; i--) {
    year.push(i.toString());
  }

  return (
    <div className={`contain-form-passenger ${index === 1 ? "" : "border-top"}`}>
      <h3 className='title-adult'>Person {index}</h3>
      {
        index === 1 &&
        <p className='text-responsability'>
          Será responsable de hacer el check-in y el check-out en el alojamiento
        </p>
      }

      <form action="" >
        <div className='camp-passenger-form'>
          <label htmlFor="">Nombres</label>
          <input style={{border:`${errCamp === "name" ? "2px solid #c60000" : ""}`}} type="text" placeholder='Como figura en el documento de viajes' onChange={(e) => {
            setName(e.target.value)
          }} />
          {
            errCamp === "name" &&
            <p className='text-none-camp'>
              Debes llenar este campo
            </p>
          }
        </div>
        <div className='camp-passenger-form'>
          <label htmlFor="">Apellidos</label>
          <input style={{border:`${errCamp === "lastName" ? "2px solid #c60000" : ""}`}} type="text" placeholder='Como figura en el documento de viajes' onChange={(e) => {
            setLastName(e.target.value)
          }} />
          {
            errCamp === "lastName" &&
            <p className='text-none-camp'>
              Debes llenar este campo
            </p>
          }
        </div>
        <div className='camp-passenger-form'>
          <label htmlFor="">Pais de residencia</label>
          <SelectedForm options={paises} seeSelected={paisSelected} setSeeSelected={setPaisSelected} setSelectedOption={setCountry} selectedOption={country} />

        </div>
        <div className='camp-passenger-form'>
          <label htmlFor="">Tipo y numero de documento</label>
          <div className='contain-documentation'>
            <SelectedForm options={documentacion} seeSelected={documentation} setSeeSelected={setDocumentation} selectedOption={typeDocument} setSelectedOption={setTypeDocument} />
            <input style={{border:`${errCamp === "document" ? "2px solid #c60000" : ""}`}} type="text" placeholder='Digita tu documento' onChange={(e) => {
              setDocument(e.target.value)
            }} />
            {
            errCamp === "document" &&
            <p className='text-none-camp'>
              Debes llenar este campo
            </p>
          }
          </div>
        </div>
        <div>
          <label htmlFor="">Fecha de Nacimiento</label>
          <div className='contain-date-natale'>
            <SelectedForm options={day} seeSelected={daySelected} setSeeSelected={setDaySelected} selectedOption={dayOption} setSelectedOption={setDayOption} errCamp={errCamp}/>
            <SelectedForm options={month} seeSelected={monthSelected} setSeeSelected={setMonthSelected} selectedOption={monthOption} setSelectedOption={setMonthOption} errCamp={errCamp}/>
            <SelectedForm options={year} seeSelected={yearSelected} setSeeSelected={setYearSelected} selectedOption={yearOption} setSelectedOption={setYearOption} errCamp={errCamp}/>
          </div>
        </div>
        <div className='camp-passenger-form'>
          <label htmlFor="">Sexo</label>
          <div className='contain-sex-selected'>
            <section className='sex-selected-pay' onClick={() => {
              setSex("f")
              paidPackages(traveler)
            }}>

              <div>
                <span style={{ backgroundColor: `${sex === "f" ? "#004274" : ""}` }}>

                </span>
              </div>

              <p>
                Femenino
              </p>
            </section>
            <section className='sex-selected-pay' onClick={() => {
              setSex("m")
              paidPackages(traveler)
            }}>
              <div>
                <span style={{ backgroundColor: `${sex === "m" ? "#004274" : ""}` }}>

                </span>
              </div>

              <p>
                Masculino
              </p>
            </section>
          </div>
        </div>
      </form>
    </div>
  )
}

export default FormPassenger