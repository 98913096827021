export const fligths = {}

export const doctorURL = {
  post: {
    profileInformation: 'doctor/profile/information',
    profileMethods: 'doctor/profile/methods',
    saveSpecialities: 'doctor/profile/speciality',
    uploadImages: 'doctor/profile/image',
    uploadVideos: 'doctor/profile/video',
  },
  get: {
    profileProfessional: 'doctor/profile/professional',
    specialityList: 'speciality',
    userSpecialityList: 'doctor/profile/speciality/show',
    getDayCalendary: 'doctor/diaries',
    appointmentsFilter:'appointments',
    appointmentsPending: 'appointments/pending',
  },
  put:{
    appointmentsUpdate: 'appointments/update',
  }
}

export const videoSdkURL = {
  post: {
    rooms: 'v2/rooms',
    deactivateRoom:'v2/rooms/deactivate',
    
  },
  get: {
    validatedMeeting: 'v2/rooms/validate/',
    fetchRoom:'v2/rooms/',
    fetchRooms:'v2/rooms/',
  }
}

