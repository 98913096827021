import React, { useState } from 'react'
import "./assets/styles.css"
import Paginado from './Paginado'



const ServicesTraductores = ({data}) => {
  const [currentPage, setCurrentPage] = useState(0)
    const [mostrarNext, setMostrarNext] = useState(true)
    const itemsForPage = 10
  
    const [topic, setTopic] = useState([...data].splice(0,itemsForPage))
    const nextHandler = ()=>{
    const totalElementos = data.length;

    const nextPage = currentPage + 1

    const firtsIndex = nextPage * itemsForPage
    if(firtsIndex >= totalElementos) {
        setMostrarNext(false)
      return;
    }
    
    
    setCurrentPage(nextPage)
    setTopic([...data].splice(firtsIndex,itemsForPage))

  }
  const prevHandler = ()=>{
    setMostrarNext(true)
   const prevPage = currentPage - 1
   
   if(prevPage < 0)return;

   const firtsIndex = prevPage * itemsForPage

   setTopic([...data].splice(firtsIndex,itemsForPage))
    setCurrentPage(prevPage)
  }
  const value = data.length;
  return (
    <div>
       <div className='container-header-translators'>
        <div className='header-services'>
          <div className='principal-text'>
            <h1>Translator Services</h1>
            <p>"The Best Translators at Your Disposal."</p>
          </div>
        </div>
      </div>
      <div className='container-cards-services'>
        {
           value ?
           data.map(topic => {
             return (
               <div>
                 <h2>{topic.name}</h2>
               </div>
             )
           })
           :
           <div className='translator-nothing'>
             <img src="https://cdn-icons-png.flaticon.com/512/6843/6843910.png" alt="" />
             <h2>There are no translators available at the moment</h2>
           </div>
        }
      </div>
      <Paginado 
        nextHandler={nextHandler} 
        prevHandler={prevHandler} 
        currentPage={currentPage} 
        mostrarNext={mostrarNext}
        >

        </Paginado>
    </div>
  )
}

export default ServicesTraductores