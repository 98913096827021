

const Keywords_Transport = () => {

  // ONLY_FROM_AIRPORT = hacia el hotel 
  // ONLY_TO_AIRPORT = hacia el aeropuerto 
  // ROUND_TRIP=  ida y vuelta

  const Words = Object.freeze({
    RoundTrip: 'ROUND_TRIP',
    onlyFromAiport: 'ONLY_FROM_AIRPORT',
    onlyToAirport:'ONLY_TO_AIRPORT'
  })

  return {
    Words
  }
}

export default Keywords_Transport