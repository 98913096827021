import { Card, CardActionArea, CardActions, CardContent, CardMedia, IconButton, Rating, Typography } from '@mui/material'
import React from 'react'
import { Heart } from 'iconsax-react'
import './Assets/styles.css'
import { Link } from 'react-router-dom'


const CardHomeTurism = ({ cardImage, cardTitle, cardDescription, star, cardPrice, cardFrom, link }) => {


  const aleatorio = (minimo,maximo)=> {
    return Math.floor(Math.random() * ((maximo+1)-minimo)+minimo);
}

  return (
    <Card className='cardHomeTurism'>
      <div className='cardHomeTurism_ActionArea'>
        <div style={{width:"100%"}}>
          <CardActionArea className='cardHomeTurism_link'>
           
              <h2 className='cardHomeTurism_title'  >{cardTitle}</h2>
            
          </CardActionArea>
        </div>
        <div className='cardHomeTurism_media'>
          <div className='text-image-hotels'>
            <CardMedia component='img' className='cardHomeTurismImg' image={cardImage} alt={cardTitle} />
            <p>
              <span>
                {cardFrom}
              </span>
                {cardPrice}
            </p>
          </div>
          
          <CardActions className='cardHomeTurism_heart'>
            <IconButton className='cardHomeTurism-heart_IconButton'>
              <Heart size="32" color="white" />
            </IconButton>
          </CardActions>
        </div>

        <div style={{width: "100%"}}>
          <CardActions className='cardHomeTurism_CardAction'>
            <div className='cardHomeTurism_IconButton'>
              <Rating name="size-large" defaultValue={star} size="large" />
              <span className='number-rating'>{star + ".0 " + `(${aleatorio(100,1000)})`}</span>
            </div>
          </CardActions>
        </div>
        <div>
          <Typography className='cardHomeTurism_description' color='black' >{cardDescription}</Typography>
        </div>
      </div>
    </Card>
  )
}

export default CardHomeTurism