import React from 'react'
import ShoppingCartDetail from '../../Components/ShoppingCartDetail/ShoppingCartDetail'

import './Assets/styles.css'
const ShoppingCart = () => {
  return (
    <div className='caontainer'>
        <div className='nav'/>
     <ShoppingCartDetail/>
    </div>
  )
}

export default ShoppingCart
