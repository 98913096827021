import React from 'react'
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import "./styles.css"
import { useTranslation } from 'react-i18next'

const SearchAmbulance = () => {
    
    const [t] = useTranslation("global")

    return (
        <div className='content-search-ambulance'>
            <div className='content-legend-ambulance'>
                <SvgIcon name={iconList.IconAmbulance} size={50} color={"#004274"} />
                <h3>{t("ambulance.title")}</h3>
            </div>
            {/* <div className='camp-ambulance'>
                <SvgIcon name={iconList.IconListMedicaments} size={30} color={"#004274"} />
                <div>
                    <h4>
                        Especialidad
                    </h4>
                    <p>
                        Odontologia, Cirugia plast...
                    </p>
                </div>
                <SvgIcon name={iconList.IconArrowDown2} size={15} color={"#004274"} />

            </div> */}
            <div className='camp-ambulance'>
                <SvgIcon name={iconList.IconPinMap} size={30} color={"#004274"} />
                <div>
                    <h4>
                    {t("ambulance.where")}
                    </h4>
                    <p>
                    {t("ambulance.abrevement_city")}
                    </p>
                </div>
                <SvgIcon name={iconList.IconArrowDown2} size={15} color={"#004274"} />

            </div>
            <button className='search-ambulance'>
                <SvgIcon name={iconList.IconSearch} size={30} color={"#ffffff"}/>
            </button>
            <div className='camp-ambulance' style={{marginTop:"1.5rem"}}>
                <SvgIcon name={iconList.IconFilters} size={30} color={"#004274"} />
                <div>
                    <h4>
                    {t("ambulance.filters")}
                    </h4>
                    <p>
                    {t("ambulance.personalize_search")}
                    </p>
                </div>
                <SvgIcon name={iconList.IconArrowDown2} size={15} color={"#004274"} />
            </div>
        </div>
    )
}

export default SearchAmbulance