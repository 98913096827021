import React, { useEffect, useState } from 'react';
import SuraFormDetailPassengers from './SuraFormDetailPassengers/SuraFormDetailPassengers';
import SuraEditFormQuote from './SuraEditFormQuote/SuraEditFormQuote';
import Find from '../../ui/views/share/components/Find/Find';
import CarouselSevices from '../../ui/views/share/components/CarouselServices/CarouselSevices';
import AnyQuestions from '../../ui/views/home/components/anyQuestion';

import "./Assets/Styles.css";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function SuraDataPassengersDetail() {
    //Variables de estado
    const [adviser, setAdvicer] = useState(false);
    const [typeDocument, setTypeDocument] = useState([]);
    const [setSend, setSendData] = useState(false);

    //Traducción
    const [t] = useTranslation("global");

    //Navegación

    const navigate = useNavigate();

    //Replica de formularios dependiendo de la cantidad de pasajeros
    function replicateForms() {
        let forms = [];
        for (let i = 0; i < 3; i++) {    
            forms.push(
                <SuraFormDetailPassengers 
                    key={i} 
                    index={i+1}
                    typeDocument={typeDocument} 
                    setSend={setSendData}
                />
            );
        }
        return forms;
    }

    //Navegar a resumen de pago
    function goToViewSummary() {
        navigate("/travelSecure/sura/summaryPay");
    }

    return (
        <div>
            <div>
                <Find />
                <CarouselSevices current={t("carousel_services.travel_secure")}/>
            </div>
            <div className='container-all-passengers-sura'>
                <div className='container-header-sura'>
                    <p>Seguro de viajes sura</p>
                </div>
                <div>
                    <SuraEditFormQuote/>
                </div>
                <div className='container-data-passengers'>
                    <div className='container-form-passengers-sura'>
                        {
                            replicateForms()
                        }
                    </div>
                    <div className='container-data-description-sura'>
                        <div>
                            <p>Seguro de viaje internacional aéreo</p>
                        </div>
                        <div className='p-include'>
                            <p>Incluye:</p>
                        </div>
                        <div>
                            <ul>
                                <li>Gastos de atención médica</li>
                                <li>Respaldo por cancelación de viaje</li>
                                <li>Equipaje protegido</li>
                                <li>Respaldo por accidentes personales</li>
                                <li>Acompañamiento en viaje</li>
                            </ul>
                        </div>
                        <div className='container-price-sura'>
                            <p>$ 451.578 COP</p>
                        </div>
                        <div>
                            <p className='see-more-venefit-sura'>Ver más veneficios</p>
                        </div>
                        <div className='container-adviser-sura'>
                            <div>
                                <p>¿Recibiste acompañamiento de un asesor SURA?</p>
                            </div>
                            <div>
                                <label className="switch-sura">
                                    <input type="checkbox" onChange={() => setAdvicer(!adviser)} />
                                    <span className="slider"></span>
                                </label>
                            </div>
                        </div>
                        {
                            adviser ?
                                <div className='input-adviser-sura'>
                                    <input type="text" />
                                </div>
                                :
                                null
                        }
                        <div className='container-terms-conditions'>
                            <div>
                                <label className="checkbox-container">
                                    <input type="checkbox" />
                                    <span className="custom-checkbox"></span>
                                </label>
                            </div>
                            <div className='container-p-terms-sura'>
                                <p className='p-authorization-sura'> Autorizo el uso responsable de mi información y acepto <span className='p-terms-conditions-sura'>Terminos y condiciones </span> </p>
                            </div>
                        </div>
                        <div className='btn-quote-sura'>
                            <button onClick={goToViewSummary}>Comprar</button>
                        </div>
                    </div>
                </div>
            </div>
            <AnyQuestions title={"¿Tienes alguna duda?"} />
        </div>
    );
}

export default SuraDataPassengersDetail;
