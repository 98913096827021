import React from 'react'
import RecoveryHouse from '../../ui/views/RecoveryHouse/RecoveryHouse'

const RecoveryHouseRoute = () => {
  return (
    <div>
        <RecoveryHouse/>
    </div>
  )
}

export default RecoveryHouseRoute