import React from 'react'
import DetailsRecoveryHouse from '../../ui/views/RecoveryHouse/DetailsRecoveryHouse/DetailsRecoveryHouse'

const DetailsRecoveryHouseRoute = () => {
  return (
    <div>
        <DetailsRecoveryHouse/>
    </div>
  )
}

export default DetailsRecoveryHouseRoute