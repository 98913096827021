import React from "react";
import "./Assets/Styles.css";

import { ReactComponent as CheckIcon} from "./Assets/svg/CheckIcon.svg"

function ModalAlert({ isModalOpen, closeModal }) {

    const handleClickOutside = (event) => {
        event.stopPropagation()
        closeModal()
    }

    const handleClickedInside = (event) => {
        event.stopPropagation()
    }
    if(!isModalOpen){
        return null
    }

    return(
        <div className="modal-success-container" onClick={handleClickOutside}>
            <div className="alert-success-container" onClick={handleClickedInside}>
                <CheckIcon/>
                <div className="p-hecho-package-success">
                    <p>Hecho</p>
                </div>
                <div style={{ marginBlock: "50px", paddingInline: "70px", textAlign: "center" }}>
                    <p>Tenemos el placer de comunicarle que su solicitud ha sido evaluada y su cotización ha sido aprobada.</p>
                </div>
            </div>
            <button className="alert-button-container" onClick={closeModal}>
                Aceptar
            </button>
        </div>
    )
}

export default ModalAlert;