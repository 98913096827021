import useCommercialProfileInformation from '../../../../../../../viewModels/profile/commercial/commercialProfile.information.viewModel';
import ActivityIndicator from '../../../../../../share/components/activityIndicator';
import SvgIcon, { iconList } from '../../../../../../share/components/iconSVG';
import InputCheck, { displayMethod, shapes } from '../../../../../../share/components/inputs/inputCheck';
import ModalContainer, { sliceFrom} from '../../../../../../share/components/modalContainer';
import '../../../../../styles.css';
import './styles.css';
function CommercialInformation() {
  const {
    isLoading,
    inputList,
    isEditing,
    setIsEditing,
    cancel,
    typeInputs,
    save,
    btnDisable,
    showModal,
    setShowModal,
    message,
  } = useCommercialProfileInformation()

  return (
    <div className='profileScreen_CardShadow'>
      <ModalContainer
        show={showModal}
        hidden={setShowModal}
        sizeWidth={50}
        sizeHeight={50}
        from={sliceFrom.right}
      >
        <div className='CI modalContainerCI'>
          <SvgIcon
            name={iconList.IconCheckSucces}
            color={'#58D68D'}
            size={120}
          />
          <p className='CI modalText'>{message}</p>
        </div>
      </ModalContainer>
      {
        isLoading &&
        <div className='containerLoadingSimle'>
          <ActivityIndicator size={40} colorFill='#004274' />
        </div>
      }
      {
        !isLoading &&
        <div className='CI headerCI'>
          <p className='CI titleCI'>Información comercial</p>
          {!isEditing && <p className='CI toEdit' onClick={() => setIsEditing(true)}>Editar</p>}
        </div>
      }
      {!isLoading &&
        <div>
          {inputList && inputList.map((index, key) => {
            return (
              <div key={key} className='CI inputGroup'>
                <p className='CI inputLabel'>
                  {index.isRequired && <b>*</b>}
                  {index?.label}
                </p>
                {index?.inputTtpe === typeInputs.text &&
                  <div className='CI containerInput'>
                    <input
                      className={`CI input ${isEditing && 'inputEditing'}`}
                      placeholder={index?.placeHolder}
                      value={index?.value}
                      id={index?.id}
                      onChange={index?.change}
                    />
                  </div>
                }
                {index?.inputTtpe === typeInputs.textArea &&
                  <div className='CI containerInput'>
                    <textarea
                      className={`CI textarea ${isEditing && 'inputEditing'}`}
                      placeholder={index?.placeHolder}
                      value={index?.value}
                      id={index?.id}
                      onChange={index?.change}
                      rows="5"
                    ></textarea>
                  </div>
                }
                {index?.inputTtpe === typeInputs.check &&
                  <div>
                    <InputCheck
                      id={index?.id}
                      shape={shapes.square}
                      methodDisplay={displayMethod.wrap}
                      list={[
                        { isActive: index?.value, change: index?.change, label: 'si' },
                        { isActive: !index?.value, change: index?.change, label: 'no' }
                      ]}
                    />
                  </div>
                }
              </div>
            )
          })}
        </div>
      }

      {isEditing &&
        <div className='CI footerCI'>
          <div onClick={() => !isLoading && cancel()} className={`CI outlineBtnCI ${isLoading && 'fillBtnCIDisable'}`}>
            <p className='CI outlineTextCI'>Cancelar</p>
          </div>
          <div onClick={() => !btnDisable && save()} className={`CI fillBtnCI ${btnDisable && 'fillBtnCIDisable'}`}>
            {isLoading ?
              <>
                <ActivityIndicator
                  size={18}
                  colorFill={'#FFFFFF'}
                />
              </>
              :
              <p className='CI fillTextCI'>Guardar</p>
            }
          </div>
        </div>
      }
    </div>
  )
}

export default CommercialInformation;
