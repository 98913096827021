import wellezy_logo from '../../ui/assets/logos/wellezy.png';

export const channelRoomEntity = [
  {
    id: 'wellezy',
    name: 'Wellezy',
    logo: wellezy_logo,
    colorAlfa: '#004274',
    colorBeta: '#104172',
    colorGamma: '#FFD902'
  },
]