import React, { useEffect, useState } from "react";
import "./styles.css";
import SvgIcon, { iconList } from "../../../share/components/iconSVG";
import { useTranslation } from "react-i18next";
import ModalContainer from "../../../share/components/modalContainer";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { FaCircleCheck, FaPlus } from "react-icons/fa6";
import { HiUsers } from "react-icons/hi2";
import { MdRoomService } from "react-icons/md";
import ModalCardDetail from "../../HotelResult/allResultsCards/components/ModalCardDetail";
import toast, { Toaster } from "react-hot-toast";
import TextComponent from "../../../share/components/fonts/TextComponent";
import { FaRegCircleDot, FaRegCircle } from "react-icons/fa6";
import { FaRestroom } from "react-icons/fa";
import { MdBedroomParent } from "react-icons/md";
import ModalChange from "../../components/ModalChange/ModalChange";
import { PiFireFill } from "react-icons/pi";
import Tooltip from "@/Components/Tooltip/Tooltip";

const HabDetail = ({
  Hab,
  typeAcomodation,
  buttonBooking,
  joinReserve,
  deleteReserve,
  persons,
  rooms,
  people,
  selectedRoom,
  id,
  setSelectedRoom,
  arrival,
  departure,
  bestOffer
}) => {
  const [selectHab, setSetselectHab] = useState({});
  const [alertList, setAlertList] = useState(false);
  const [habOption, setHabOption] = useState(null);
  const [habPersonOption, setPersonHabOption] = useState(null);
  const [t] = useTranslation("global");

  const [errChangeServices, setErrChangeServices] = useState(false);

  const [modalCardDetail, setModalDetail] = useState(false);
  const [firstValueFromLin, setFirstValueFromLin] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);

  const [show, setShow] = useState(false);
  const [activeChangeService, setActiveChangeService] = useState(false);

  const arrivalDate = new Date(arrival);
  const departureDate = new Date(departure);

  const differenceInTime = departureDate - arrivalDate;

  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  const nights = differenceInDays - 1;

  const selectedHabFunction = (item) => {
    setSelectedItem(item);
    setSetselectHab(item);
    let bodyNew = item?.reg[0];
    bodyNew["acomodation"] = typeAcomodation;
    bodyNew["name"] = item["@attributes"]?.desc;
    setHabOption(bodyNew);
    setSetselectHab((prevState) => {
      return { ...prevState, acomodation: typeAcomodation };
    });
  };

  useEffect(() => {
    if (selectedRoom === id) {
      setSelectedItem(null);
      setSetselectHab({});
      setSelectedRoom("");
    }
  }, [selectedRoom]);

  function formatearCantidad(cantidad) {
    const cantidadFormateada = cantidad.toLocaleString("es", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return cantidadFormateada;
  }

  const optionNr = (tipoReembolso, include) => {
    const alojamientoEncontrado = selectHab?.reg?.find((hab) => {
      return (
        hab["@attributes"].cod === include &&
        hab["@attributes"].nr === tipoReembolso
      );
    });

    if (alojamientoEncontrado === undefined) {
      setErrChangeServices(true);
      setTimeout(() => {
        setErrChangeServices(false);
      }, 2000);
    } else {
      alojamientoEncontrado["acomodation"] = typeAcomodation;
      alojamientoEncontrado["name"] = selectHab["@attributes"]?.desc;
      setHabOption(alojamientoEncontrado);
    }
  };

  const selectedItemDeleteSelected = (item) => {
    setActiveChangeService(false);
  };

  const handleScroll = () => {
    window.scrollTo({
      top: document.body.scrollHeight - window.innerHeight,
      behavior: "smooth",
    });
  };

  const getAccommodationInfo = (accommodation) => {
    if (!accommodation) return { adults: 0, children: 0 };

    const [adults, children] = accommodation.split("-").map(Number);
    return {
      adults: isNaN(adults) ? 0 : adults,
      children: isNaN(children) ? 0 : children,
    };
  };

  const { adults, children } = getAccommodationInfo(people["@attributes"].cod);

  const peopleItem = people["@attributes"].cod;

  const getFirstValueAfterHash = (data) => {
    const parts = data.split("#");
    return parts[1] || ""; // Obtén el primer valor después del primer '#'
  };

  useEffect(() => {
    const firstValues = people?.hab
      .flatMap((hab) =>
        hab?.reg.flatMap((reg) => {
          const data = reg?.lin[0]; // Accede a la primera posición de lin
          if (data) {
            return getFirstValueAfterHash(data);
          }
          return null;
        })
      )
      .filter(Boolean); // Filtra valores nulos

    setFirstValueFromLin(firstValues[0] || ""); // Guarda solo el primer valor
  }, [people]);

  const [bestOfferHab, setBestOfferHab] = useState(false);

  const cheapestHab = Hab.reduce((cheapest, option) => {
    const currentPrice = Number(option?.reg[0]["@attributes"]?.prr);
    if (currentPrice < cheapest) {
      return currentPrice;
    }
    return cheapest;
  }, Number.MAX_VALUE);

  console.log(selectedItem);

  return (
    <>
      <div className="contain-hab-detail">
        <div className="contain-hab-detail-item">
          <h3 style={{ color: "#1e5b98", fontSize: 18, fontWeight: 600 }}>
            Habitaciones para {adults} {adults === 1 ? "adulto" : "adultos"}{" "}
            {children} {children === 1 ? "niño" : "niños"}
            <FaRestroom size={20} color="#1e5b98" />
          </h3>
          <section className="contain-all-hab">
            {Hab.map((option, key) => {
              const optionPrice = Number(option?.reg[0]["@attributes"]?.prr);
              const isCheapest = optionPrice === cheapestHab;
              return (
                <div
                  key={key}
                  className="card-hab-detail"
                  onClick={() => {
                    joinReserve(option, peopleItem, id, firstValueFromLin);
                    handleScroll();
                    selectedHabFunction(option);
                    if (isCheapest) {
                      setBestOfferHab(true);
                    } else {
                      setBestOfferHab(false);
                    }
                  }}
                >
                  <div
                    className={`content-info-hab ${selectedItem === option ? "active" : ""
                      }`}
                  >
                    <div className="content-info-hab-icon">
                      {selectedItem === option ? (
                        <FaRegCircleDot color={"var(--bluePrimary)"} />
                      ) : (
                        <FaRegCircle color={"var(--bluePrimary)"} />
                      )}
                    </div>

                    {isCheapest && 
                    <Tooltip
                      position="right"
                      content="Este hospedaje cuenta con un <br/> descuento por tiempo limitado. ¡Aprovecha ahora!"
                    >
                      <span className="bestOfferHab">
                        <PiFireFill size={20} /> 
                        Super Oferta
                      </span>
                    </Tooltip>}
                    <div className={`content-info-hab-content ${isCheapest && 'paddingTop'}`}>
                      <h2>{option["@attributes"]?.desc}</h2>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 10,
                        }}
                      >
                        <TextComponent size={"md2"}>
                          <MdBedroomParent size={18} color={"var(--bluePrimary)"} />
                          ({firstValueFromLin}) Habitaciones
                        </TextComponent>

                        <TextComponent size={"md2"}>
                          <HiUsers size={18} color={"var(--bluePrimary)"} />
                          ({peopleItem}){" "}
                          {t("fligth.search_fligth.adults")},{" "}
                          {t("fligth.search_fligth.childrens")}
                        </TextComponent>

                        <TextComponent size={"md2"}>
                          <FaCircleCheck size={18} color={"var(--bluePrimary)"} />
                          {t("hotel.days_advance")}:{" "}
                          {differenceInDays}
                        </TextComponent>

                        <TextComponent size={"md2"}>
                          <FaCircleCheck size={18} color={"var(--bluePrimary)"} />
                            {t("hotel.nights_spending")}:{" "}
                            {nights}
                        </TextComponent>

                        <TextComponent size={"md2"}>
                          <FaCircleCheck size={18} color={"var(--bluePrimary)"} />
                            {option?.reg[0]["@attributes"]?.cod}
                        </TextComponent>

                      </div>

                      <h2 style={{ fontWeight: "600", marginTop: 5 }}>
                        $
                        {formatearCantidad(
                          Number(option?.reg[0]["@attributes"]?.prr)
                        )}
                      </h2>
                    </div>


                  </div>
                </div>
              );
            })}
          </section>
        </div>
        <section className="contain-price-info-hab">
          {selectHab["@attributes"] ? (
            <>
              {/* <span>{persons} {persons > 1 ? 'Personas' : 'Persona'}</span>  */}

              {alertList && (
                <TextComponent className="alertList">
                  {t("hotel.only_add")}
                </TextComponent>
              )}
              <div
                style={{ display: "flex", flexDirection: "column", gap: 30 }}
              >

                <div
                  style={{ display: "flex", flexDirection: "column", gap: 10 }}
                >
                  <TextComponent
                    className="price-Hab-select"
                    fontWeight="bold"
                    size={"lg"}
                  >
                    ${formatearCantidad(Number(habOption["@attributes"]?.prr))}
                  </TextComponent>
                  <TextComponent color={"#616161"} size={"md2"}>
                    {t("hotel.include_taxes")}
                  </TextComponent>
                </div>

                <div>
                  <TextComponent
                    size={"md"}
                    color={"#002474"}
                    fontWeight="bold"
                  >
                    {t("hotel.info_reservation")}
                  </TextComponent>
                  <ul style={{ fontSize: 14 }}>
                    <li
                      style={{ display: "flex", alignItems: "center", gap: 10 }}
                    >
                      <FaCircleCheck size={18} color={"#004274"} />
                      {selectHab["@attributes"]?.desc}
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <FaCircleCheck size={18} color={"#004274"} />
                      <p
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 10,
                        }}
                      >
                        {habOption["@attributes"]?.cod}
                        <span
                          style={{ fontWeight: "bold", fontSize: "14px" }}
                          onClick={() => {
                            setActiveChangeService(true);
                          }}
                        >
                          Cambiar
                        </span>
                      </p>
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      {habOption["@attributes"]?.nr === "No reembolsable" ? (
                        <>
                          <FaCircleCheck size={18} color={"#004274"} />
                          <p
                            onClick={() => {
                              optionNr(
                                "Reembolsable",
                                habOption["@attributes"]?.cod
                              );
                            }}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {habOption["@attributes"]?.nr}
                            {errChangeServices && (
                              <span
                                style={{
                                  color: "#ff6b6b",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                }}
                              >
                                Habitacion no es reembolsable
                              </span>
                            )}
                          </p>
                        </>
                      ) : (
                        <>
                          <FaCircleCheck size={18} color={"#004274"} />
                          <p
                            onClick={() => {
                              optionNr(
                                "No reembolsable",
                                habOption["@attributes"]?.cod
                              );
                            }}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {habOption["@attributes"]?.nr}
                            {errChangeServices && (
                              <span
                                style={{
                                  color: "#ff6b6b",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                }}
                              >
                                Habitacion es reembolsable
                              </span>
                            )}
                          </p>
                        </>
                      )}
                    </li>
                  </ul>
                </div>
              </div>

              <ModalChange
                selectHab={selectHab}
                show={show}
                setShow={setShow}
              />

              <div
                style={{ display: "flex", flexDirection: "column", gap: 10 }}
              >
                <span className="what-include" onClick={() => setShow(true)}>
                  {t("hotel.what_include_price")}
                </span>
              </div>
            </>
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: 20,
              }}
            >
              <h2 className="unselected-hab">{t("hotel.no_selected")}</h2>
              <MdRoomService size={30} color="1e5b98" />
            </div>
          )}
        </section>
      </div>

      <ModalContainer
        show={activeChangeService}
        hiddsetActiveChangeServiceen={setActiveChangeService}
      >
        <div className="content-services-modal-hab">
          <TextComponent tag="h3" fontWeight="bold" size={"md"}>
            Escoje tu comodidad
          </TextComponent>
          <ul className="list-change-services-hab">
            {selectHab?.reg?.map((tarifa, index) => {
              const isSelected =
                tarifa["@attributes"]?.cod === habOption["@attributes"]?.cod;
              return (
                <li
                  onClick={() => {
                    optionNr(
                      habOption["@attributes"]?.nr,
                      tarifa["@attributes"]?.cod
                    );
                    selectedItemDeleteSelected(tarifa);
                  }}
                  key={index}
                  style={{
                    backgroundColor: isSelected ? "#d3e2ff" : "transparent",
                    fontWeight: isSelected ? "bold" : "normal",
                  }}
                >
                  <FaCircleCheck
                    size={18}
                    color={isSelected ? "#004274" : "#ccc"}
                  />
                  {tarifa["@attributes"]?.cod}
                </li>
              );
            })}
          </ul>
        </div>
      </ModalContainer>
    </>
  );
};

export default HabDetail;
