import {useState} from 'react'
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import './styles.css'
import { useTranslation } from 'react-i18next'

const ButtonsFilters = ({personalizerFind }) => {
    const [t] = useTranslation("global")
    const [dropdownPersonalizeFind, setDropdownPersonalizeFind] = useState(false)
    const [dropdownCityFind, setDropdownCityFind] = useState(false)
    const [KeyWord, setKeyWord] = useState('')

  return (
    <div className='contain-btn-filters-specialist'>
            
            <button>
                <SvgIcon name={iconList.IconBeautiful} size={60} color={'#004274'} />
                <section onClick={()=>{setDropdownCityFind(!dropdownCityFind)}} className='layout-btn-specialist'>
                    <h3>
                        {t("health_wellness.services")}
                    </h3>
                    <p>{t('health_wellness.description')}</p>
                   
                </section>
               
            </button>
            <button >
                <SvgIcon name={iconList.IconFilters} size={60} color={'#004274'} />
                <section onClick={()=>{setDropdownPersonalizeFind(!dropdownPersonalizeFind)}} className='layout-btn-specialist' >
                    <h3>
                       {t('clinics.buttons.filters')}
                    </h3>
                    <p>{t('clinics.buttons.custom_search')}</p>
                    <SvgIcon name={iconList.IconArrowDown} size={30} color={'#004274'} />
                </section>
                {
                    dropdownPersonalizeFind &&
                    <div className='dropdown-personalize-find-clinics'>
                        <input type="text" onChange={(e)=>{setKeyWord(e.target.value)}}/>
                        <span onClick={()=>{
                            personalizerFind(KeyWord)
                            setDropdownPersonalizeFind(!dropdownPersonalizeFind)
                        }}>{t("clinics.buttons.search")}</span>
                   </div>
                }
            </button>
        </div>
  )
}

export default ButtonsFilters