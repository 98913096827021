import React from 'react'
import ProcedureCategoryC from '../../Components/ProcedureCategoryC/ProcedureCategoryC'
import {useLocation} from "react-router-dom"


const ProcedureCategory = () => {

  const location = useLocation()
  const position = location.pathname.split('/')[2]
  const data = location.state


  return (
    <div>
      {data.map((cate)=> {
        console.log("1")
        if(cate.id == position){
        
          return <ProcedureCategoryC data={cate} key={cate.id}/>
        }
       
      })}
        
    </div>
  )
}

export default ProcedureCategory