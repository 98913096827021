import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const UseHealthWellness = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const condition = location.pathname;
  let counter = 0;
  const [loading, setLoading] = useState(false);
  const [dataHealth, setDataHealth] = useState([]);
  const [urlImage, setUrlImage] = useState("");
  const [services, setServices] = useState([]);
  const itemsForPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [mostrarNext, setMostrarNext] = useState(true);

  function findServices() {
    const url = `${process.env.REACT_APP_URL_API_AIOP_STG}api/service`
    if (condition === "/healthWellness" && counter === 0) {
      setLoading(true);
      axios
        .get(url, {
          params: {
            search: "",
          },
        })
        .then((res) => {
          counter += 1;
          setLoading(false);
          setServices(res.data.service);
          setUrlImage(res.data.url);
          setDataHealth([...res.data.service].splice(0, itemsForPage));
        })
        .catch((error) => {
          counter += 1;
          setLoading(false);
          console.log(error);
        });
    }
  }

  useEffect(() => {
    findServices();
  }, []);

  const nextHandler = () => {
    const totalElementos = services.length;

    const nextPage = currentPage + 1;

    const firtsIndex = nextPage * itemsForPage;
    if (firtsIndex >= totalElementos) {
      setMostrarNext(false);
      return;
    }

    setCurrentPage(nextPage);
    setDataHealth([...services].splice(firtsIndex, itemsForPage));
  };

  const prevHandler = () => {
    setMostrarNext(true);
    const prevPage = currentPage - 1;

    if (prevPage < 0) return;

    const firtsIndex = prevPage * itemsForPage;

    setDataHealth([...services].splice(firtsIndex, itemsForPage));
    setCurrentPage(prevPage);
  };

  const personalizeFind = (keyWord) => {
    setLoading(true);
    const url = `${process.env.REACT_APP_URL_API_AIOP_STG}api/service`
    axios
      .get(url, {
        params: {
          search: keyWord,
        },
      })
      .then((res) => {
        counter += 1;
        setLoading(false);
        setServices(res.data.service);
        setUrlImage(res.data.url);
        setDataHealth([...res.data.service].splice(0, itemsForPage));
      })
      .catch((error) => {
        counter += 1;
        setLoading(false);
        console.log(error);
      });
  };

  function generateUniqueId() {
    return "xxxxx-xxxx-4xxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const detailHealth = (healthWellness) => {
    navigate(`/HealthWellness/${generateUniqueId()}`, {
      state: {
        name: healthWellness.name,
        id: healthWellness.id,
        urlImage: urlImage,
        pathImage: healthWellness.image,
        stars: healthWellness.stars,
        description: healthWellness.description,
        address: healthWellness.address,
      },
    });
  };

  return {
    dataHealth,
    urlImage,
    currentPage,
    nextHandler,
    prevHandler,
    mostrarNext,
    services,
    loading,
    personalizeFind,
    detailHealth,
  };
};

export default UseHealthWellness;
